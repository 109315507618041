import { _ as e } from "./tslib.es6-7f1b1220.js";
import { c as t, u as n, h as i, n as a } from "./lit-element-161a1699.js";
import { p as r, i as s, a as o, q as h, c as d } from "./decorators-7e9cd43b.js";
import { c as l } from "./class-map-31af68b2.js";
import { BaseElement as m } from "./BaseElement.esm.min.js";
import { h as c } from "./host.styles-cf05db22.js";
import { e as u } from "./event.decorator-98549cbc.js";
import "./interactive-icon.component.esm.min.js";
import "./if-defined-123567fc.js";
import { B as g } from "./types-814d68fb.js";
import { getBreakpointForWidth as p, compareMediaBreakpoint as b } from "./component.utils.esm.min.js";
import "./mixin.utils.esm.min.js";
import "./form-data-handling.mixin-3ddec2ca.js";
import "./dom.utils.esm.min.js";
import "./form-validation.mixin-0a6c3c34.js";
import "./delegate-focus.mixin-95600d1f.js";
import { s as f } from "./style-map-a37d7105.js";
import "./portal.utils.esm.min.js";
import "./error-message.component.esm.min.js";
import "./unsafe-html-97738913.js";
import "./user-menu-button.component.esm.min.js";
import "./menu-item.component.esm.min.js";
import "./select.component.esm.min.js";
import "./select-divider.component.esm.min.js";
import "./select-option.component.esm.min.js";
import "./select-all.component.esm.min.js";
import "./select-menu.component.esm.min.js";
import "./select-placeholder.component.esm.min.js";
import "./isObject-07b7923e.js";
import "./searchbar-input.component.esm.min.js";
import "./searchbar-results-menu.component.esm.min.js";
import "./searchbar.component.esm.min.js";
const v = t`${n(":host {\n  align-items: center;\n  background-color: var(--zui-color-headerbar-background);\n  display: flex;\n  height: calc(var(--zui-gu) * 8);\n  justify-content: space-between;\n  width: 100%;\n}\n\n.left-aligned,\n.left-aligned-content,\n.right-aligned,\n.right-aligned-content {\n  align-items: center;\n  display: flex;\n  min-width: 0;\n}\n:host([media=XS]) .left-aligned, :host([media=S]) .left-aligned,\n:host([media=XS]) .left-aligned-content,\n:host([media=S]) .left-aligned-content,\n:host([media=XS]) .right-aligned,\n:host([media=S]) .right-aligned,\n:host([media=XS]) .right-aligned-content,\n:host([media=S]) .right-aligned-content {\n  flex: 1;\n}\n\n.right-aligned,\n.right-aligned-content {\n  justify-content: flex-end;\n}\n\n:host([media=XS]) .right-aligned, :host([media=S]) .right-aligned {\n  flex: 0 0 auto;\n}\n:host(:not([show-searchbar])) .right-aligned {\n  flex-shrink: 0;\n}\n\n.right-aligned-content {\n  flex-shrink: 0;\n}\n\n.centered-searchbar {\n  align-items: center;\n  display: flex;\n  flex: 1;\n  height: 100%;\n  justify-content: center;\n  min-width: 0;\n  width: 100%;\n}\n.centered-searchbar.hidden {\n  display: none;\n}\n:host([media=XS]) .centered-searchbar, :host([media=S]) .centered-searchbar {\n  flex: 0;\n}\n\nslot[name=icon]::slotted(*) {\n  flex-shrink: 0;\n  margin-left: calc(var(--zui-gu) * 3);\n}\n\nslot[name=productName] {\n  display: flex;\n  flex: 1;\n  min-width: 0;\n}\n\nslot[name=productName]::slotted(zui-headerbar-product-name) {\n  padding: 0 calc(var(--zui-gu) * 3);\n  width: 100%;\n}\n\nslot[name=searchbar]::slotted(zui-searchbar) {\n  width: 100%;\n}\n:host([show-searchbar]) slot[name=searchbar]::slotted(zui-searchbar) {\n  padding: 0 calc(var(--zui-gu) * 3);\n}\n\n.slotted-buttons {\n  display: flex;\n}\n\nslot[name=userMenuButton]::slotted(zui-user-menu-button) {\n  border-left: 1px solid var(--zui-color-headerbar-button-divider-border-color);\n  max-width: calc(var(--zui-gu) * 32);\n}")}`;
let _ = class extends m {
  constructor() {
    super(...arguments), this.media = g.L, this.showSearchbar = !1, this.theme = "light", this._hasSearchbar = !1, this._headerbarResizeObserver = new ResizeObserver(([{
      contentRect: e
    }]) => {
      requestAnimationFrame(() => {
        this._width = e.width, this.media = p(e.width);
      });
    }), this._rightAlignedContentResizeObserver = new ResizeObserver(([{
      contentRect: e
    }]) => {
      requestAnimationFrame(() => {
        this._widthRight = e.width;
      });
    });
  }
  static get styles() {
    return [c, v];
  }
  emitHeaderbarSearchbarIconSelectedEvent() {
    this.dispatchEvent(new CustomEvent("headerbar-searchbar-icon-selected", {
      bubbles: !0,
      composed: !0
    }));
  }
  get _maxOfRightWidth() {
    var e;
    return null !== (e = this._widthRight) && void 0 !== e ? e : 0;
  }
  get _isMediaLargerSmall() {
    return b(this.media, ">", g.S);
  }
  get _maxRightWidth() {
    return this._hasSearchbar && this._isMediaLargerSmall ? `${this._maxOfRightWidth}px` : "auto";
  }
  get _leftAlignedWidth() {
    return this._hasSearchbar && this._isMediaLargerSmall ? "max-width" : "width";
  }
  get _maxLeftWidth() {
    return this._hasSearchbar && this._isMediaLargerSmall ? this._width - this._maxOfRightWidth - 378 + "px" : "auto";
  }
  get _showSearchbar() {
    return this._isMediaLargerSmall && this._width - 2 * this._maxOfRightWidth > 378;
  }
  disconnectedCallback() {
    this._headerbarResizeObserver.unobserve(this), this._rightAlignedContentResizeObserver.unobserve(this._rightAlignedContentElement), super.disconnectedCallback();
  }
  _handleHeaderbarSearchbarIconSelected() {
    this.emitHeaderbarSearchbarIconSelectedEvent();
  }
  _handleHeaderbarSearchbarSlotChange() {
    var e;
    this._hasSearchbar = (null === (e = this._assignedSearchbar) || void 0 === e ? void 0 : e.length) > 0;
  }
  _updateUserMenuButtonAvatarOnly() {
    if (Array.from(this._assignedUserMenuButtons).every(e => e.avatarOnly)) return;
    const e = b(this.media, "<", g.M);
    this._assignedUserMenuButtons.forEach(t => t.avatarOnlyExternally = e);
  }
  firstUpdated() {
    this._headerbarResizeObserver.observe(this), this._rightAlignedContentResizeObserver.observe(this._rightAlignedContentElement);
  }
  updated(e) {
    e.has("media") && this._updateUserMenuButtonAvatarOnly();
  }
  render() {
    return i`<div class="left-aligned" style="${f({
      [this._leftAlignedWidth]: this._maxLeftWidth
    })}"><div class="left-aligned-content"><slot name="icon"></slot><slot name="productName"></slot></div></div><div class="centered-searchbar ${l({
      hidden: !this._hasSearchbar || !this._showSearchbar
    })}"><slot name="searchbar" @slotchange="${this._handleHeaderbarSearchbarSlotChange}"></slot></div><div class="right-aligned" style="min-width:${this._maxRightWidth}">${this._hasSearchbar && !this._showSearchbar ? i`<zui-headerbar-icon-button @click="${this._handleHeaderbarSearchbarIconSelected}" emphasis="default" id="icon-button-search" size="m"><zui-icon-search-search slot="icon"></zui-icon-search-search></zui-headerbar-icon-button>` : a}<div class="right-aligned-content"><div class="slotted-buttons"><slot name="iconButtons"></slot></div><slot @slotchange="${this._updateUserMenuButtonAvatarOnly}" name="userMenuButton"></slot></div></div>`;
  }
};
e([r({
  reflect: !0,
  type: String
})], _.prototype, "media", void 0), e([r({
  reflect: !0,
  type: Boolean,
  attribute: "show-searchbar"
})], _.prototype, "showSearchbar", void 0), e([r({
  reflect: !0,
  type: String
})], _.prototype, "theme", void 0), e([u()], _.prototype, "emitHeaderbarSearchbarIconSelectedEvent", null), e([s()], _.prototype, "_hasSearchbar", void 0), e([s()], _.prototype, "_width", void 0), e([s()], _.prototype, "_widthRight", void 0), e([o(".right-aligned-content")], _.prototype, "_rightAlignedContentElement", void 0), e([h("searchbar", !0, "zui-searchbar")], _.prototype, "_assignedSearchbar", void 0), e([h("userMenuButton", !0, "zui-user-menu-button")], _.prototype, "_assignedUserMenuButtons", void 0), _ = e([d("zui-headerbar")], _);
export { _ as Headerbar };
