import { _ as t } from "./tslib.es6-7f1b1220.js";
import { c as e, u as a, h as i } from "./lit-element-161a1699.js";
import { p as s, c as r } from "./decorators-7e9cd43b.js";
import { BaseElement as o } from "./BaseElement.esm.min.js";
import { h as n } from "./host.styles-cf05db22.js";
const l = e`${a(":host {\n  --zui-title-stack-meta-item-separator-height: calc(var(--zui-gu) * 1.75);\n  --zui-title-stack-meta-item-separator-width: calc(var(--zui-gu) * 0.125);\n  ---zui-title-stack-meta-item-height: initial;\n  ---zui-title-stack-meta-item-separator-offset: calc(\n    (var(---zui-title-stack-meta-item-height) - var(--zui-title-stack-meta-item-separator-height)) / 2\n  );\n}\n\n:host {\n  display: inline;\n  padding: 0 calc(var(--zui-gu) * 1);\n  position: relative;\n}\n\n.separator {\n  position: absolute;\n  bottom: auto;\n  left: 0;\n  right: auto;\n  top: var(---zui-title-stack-meta-item-separator-offset);\n  height: var(--zui-title-stack-meta-item-separator-height);\n  width: var(--zui-title-stack-meta-item-separator-width);\n  background: var(--zui-color-title-stack-separator);\n  display: block;\n}\n\n:host([hide-separator]) {\n  padding-left: 0;\n}\n:host([hide-separator]) .separator {\n  display: none;\n}")}`;
let p = class extends o {
  constructor() {
    super(...arguments), this.size = "m", this.hideSeparator = !1;
  }
  static get styles() {
    return [n, l];
  }
  render() {
    return i`<span class="separator"></span><slot></slot>`;
  }
};
t([s({
  reflect: !0
})], p.prototype, "size", void 0), t([s({
  reflect: !0,
  attribute: "hide-separator",
  type: Boolean
})], p.prototype, "hideSeparator", void 0), p = t([r("zui-title-stack-meta-item")], p);
export { p as TitleStackMetaItem };
