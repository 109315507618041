import { _ as e } from "./tslib.es6-7f1b1220.js";
import { c as t, u as r, h as i } from "./lit-element-161a1699.js";
import { p as a, b as s, c } from "./decorators-7e9cd43b.js";
import { BaseElement as d } from "./BaseElement.esm.min.js";
import { h as l } from "./host.styles-cf05db22.js";
import { e as n } from "./event.decorator-98549cbc.js";
import "./interactive-icon.component.esm.min.js";
import "./if-defined-123567fc.js";
import "./dom.utils.esm.min.js";
import "./_commonjsHelpers-82e0321a.js";
import { g as o, l as u, u as m, f as p, j as h, v as D, n as v, i as y } from "./date-picker.utils-7bc542a5.js";
import "./grid-cell.component.esm.min.js";
import "./header-cell.component.esm.min.js";
import "./picker-header.component.esm.min.js";
import "./picker-grid.component.esm.min.js";
const _ = t`${r(".picker-grid {\n  margin-top: calc(var(--zui-gu) * 3);\n}")}`;
let b = class extends d {
  constructor() {
    super(...arguments), this.decadeSelectDisabled = !1, this.disabledYears = [], this.locale = o(), this.max = null, this.min = null, this.selectedYear = null;
  }
  static get styles() {
    return [l, _];
  }
  get _maxDateDT() {
    return this.max ? u.DateTime.fromJSDate(this.max) : void 0;
  }
  get maxDate() {
    return this.max;
  }
  set maxDate(e) {
    console.warn("Deprecated property maxDate used. Use max instead."), this.max = e;
  }
  get _minDateDT() {
    return this.min ? u.DateTime.fromJSDate(this.min) : void 0;
  }
  get minDate() {
    return this.min;
  }
  set minDate(e) {
    console.warn("Deprecated property minDate used. Use min instead."), this.min = e;
  }
  get _selectedYearDT() {
    return this.selectedYear ? u.DateTime.fromJSDate(this.selectedYear) : void 0;
  }
  get currentDecade() {
    if (!this._internalCurrentDecade) {
      const e = u.DateTime.now();
      return this._selectedYearDT ? this._selectedYearDT.minus({
        years: this._selectedYearDT.year % 10
      }).startOf("year").toJSDate() : e.minus({
        years: e.year % 10
      }).startOf("year").toJSDate();
    }
    return this._internalCurrentDecade;
  }
  set currentDecade(e) {
    const t = this._internalCurrentDecade;
    this._internalCurrentDecade = e, this.requestUpdate("currentDecade", t);
  }
  get _currentDecadeDT() {
    return u.DateTime.fromJSDate(this.currentDecade);
  }
  emitYearPickerDecadeSelectedEvent(e) {
    this.dispatchEvent(new CustomEvent("yearPickerDecadeSelected", {
      bubbles: !0,
      composed: !0,
      detail: e
    })), this.dispatchEvent(new CustomEvent("year-picker-decade-selected", {
      bubbles: !0,
      composed: !0,
      detail: e
    }));
  }
  emitYearPickerNextDecadeSelectedEvent(e) {
    this.dispatchEvent(new CustomEvent("yearPickerNextDecadeSelected", {
      bubbles: !0,
      composed: !0,
      detail: e
    })), this.dispatchEvent(new CustomEvent("year-picker-next-decade-selected", {
      bubbles: !0,
      composed: !0,
      detail: e
    }));
  }
  emitYearPickerPreviousDecadeSelectedEvent(e) {
    this.dispatchEvent(new CustomEvent("yearPickerPreviousDecadeSelected", {
      bubbles: !0,
      composed: !0,
      detail: e
    })), this.dispatchEvent(new CustomEvent("year-picker-previous-decade-selected", {
      bubbles: !0,
      composed: !0,
      detail: e
    }));
  }
  emitYearPickerYearSelectedEvent(e) {
    this.dispatchEvent(new CustomEvent("yearPickerYearSelected", {
      bubbles: !0,
      composed: !0,
      detail: e
    })), this.dispatchEvent(new CustomEvent("year-picker-year-selected", {
      bubbles: !0,
      composed: !0,
      detail: e
    }));
  }
  get _gridCellYears() {
    return m(this._currentDecadeDT);
  }
  _headerValue() {
    const e = this._gridCellYears;
    return `${e[0].year} - ${e[9].year}`;
  }
  get _disabledGridCellConditions() {
    const e = p(this.disabledYears);
    return [e => !!this._minDateDT && e.toMillis() < this._minDateDT.toMillis(), e => !!this._maxDateDT && e.toMillis() > this._maxDateDT.toMillis(), t => h(t, e)];
  }
  get _gridCellFocusConditions() {
    return [() => !!this._selectedYearDT && D(this._selectedYearDT, this._currentDecadeDT)];
  }
  get _gridCellSelectedConditions() {
    return [e => !!this._selectedYearDT && v(e, this._selectedYearDT)];
  }
  get _gridCellSubtleConditions() {
    return [e => !D(e, this._currentDecadeDT)];
  }
  _isGridCellDisabled(e) {
    return this._disabledGridCellConditions.some(t => t(e));
  }
  _canFocusGridCell() {
    return this._gridCellFocusConditions.every(e => e());
  }
  _getGridCellEmphasis(e) {
    const t = this._gridCellSelectedConditions.every(t => t(e)),
      r = this._gridCellSubtleConditions.every(t => t(e));
    return t ? "selected" : r ? "subtle" : "default";
  }
  _handleYearSelected({
    detail: e
  }) {
    const {
      selected: t,
      value: r
    } = e;
    t.disabled || (this.selectedYear = u.DateTime.fromISO(r).startOf("year").toJSDate(), this.emitYearPickerYearSelectedEvent({
      value: this.selectedYear
    }));
  }
  _handleDecadeSelected() {
    this.emitYearPickerDecadeSelectedEvent({
      value: this.currentDecade
    });
  }
  _handleNextDecadeSelected() {
    this.currentDecade = this._currentDecadeDT.plus({
      years: 10
    }).toJSDate(), this.emitYearPickerNextDecadeSelectedEvent({
      startOfDecade: this.currentDecade,
      endOfDecade: this._currentDecadeDT.plus({
        years: 9
      }).toJSDate()
    });
  }
  _handlePreviousDecadeSelected() {
    this.currentDecade = this._currentDecadeDT.minus({
      years: 10
    }).toJSDate(), this.emitYearPickerPreviousDecadeSelectedEvent({
      startOfDecade: this.currentDecade,
      endOfDecade: this._currentDecadeDT.plus({
        years: 9
      }).toJSDate()
    });
  }
  _updateFocus() {
    if (!this._canFocusGridCell()) return;
    const e = Array.from(this._pickerGridCells).find(e => !!this._selectedYearDT && v(u.DateTime.fromISO(e.value), this._selectedYearDT));
    null == e || e.focus();
  }
  updated(e) {
    e.has("selectedYear") && this._updateFocus();
  }
  render() {
    return i`<zui-picker-header ?disabled="${this.decadeSelectDisabled}" value="${this._headerValue()}" @picker-header-current-selected="${this._handleDecadeSelected}" @picker-header-next-selected="${this._handleNextDecadeSelected}" @picker-header-previous-selected="${this._handlePreviousDecadeSelected}"><zui-interactive-icon slot="icon-left"><zui-icon-arrow-outline-arrow-outline-actually-centred-left></zui-icon-arrow-outline-arrow-outline-actually-centred-left></zui-interactive-icon><zui-interactive-icon slot="icon-right"><zui-icon-arrow-outline-arrow-outline-actually-centred-right></zui-icon-arrow-outline-arrow-outline-actually-centred-right></zui-interactive-icon></zui-picker-header><zui-picker-grid class="picker-grid" columns="4" @picker-grid-cell-selected="${this._handleYearSelected}">${this._gridCellYears.map(e => {
      const t = this._isGridCellDisabled(e),
        r = this._getGridCellEmphasis(e);
      return i`<zui-picker-grid-cell ?disabled="${t}" emphasis="${r}" slot="pickerGridCells" style="--zui-picker-grid-cell-width:56px;--zui-picker-grid-cell-height:56px" value="${e}">${e.year}</zui-picker-grid-cell>`;
    })}</zui-picker-grid>`;
  }
};
e([a({
  reflect: !0,
  type: Boolean,
  attribute: "decade-select-disabled"
})], b.prototype, "decadeSelectDisabled", void 0), e([a({
  type: Array,
  attribute: !1
})], b.prototype, "disabledYears", void 0), e([a({
  reflect: !0,
  type: String
})], b.prototype, "locale", void 0), e([a({
  reflect: !0,
  type: String,
  converter: y
})], b.prototype, "max", void 0), e([a({
  reflect: !0,
  type: String,
  attribute: "max-date",
  converter: y
})], b.prototype, "maxDate", null), e([a({
  reflect: !0,
  type: String,
  converter: y
})], b.prototype, "min", void 0), e([a({
  reflect: !0,
  type: String,
  attribute: "min-date",
  converter: y
})], b.prototype, "minDate", null), e([a({
  reflect: !0,
  type: String,
  attribute: "selected-year",
  converter: y
})], b.prototype, "selectedYear", void 0), e([a({
  reflect: !0,
  type: String,
  attribute: "current-decade",
  converter: y
})], b.prototype, "currentDecade", null), e([n()], b.prototype, "emitYearPickerDecadeSelectedEvent", null), e([n()], b.prototype, "emitYearPickerNextDecadeSelectedEvent", null), e([n()], b.prototype, "emitYearPickerPreviousDecadeSelectedEvent", null), e([n()], b.prototype, "emitYearPickerYearSelectedEvent", null), e([s("zui-picker-grid-cell")], b.prototype, "_pickerGridCells", void 0), b = e([c("zui-date-picker-year-picker")], b);
export { b as DatePickerYearPicker };
