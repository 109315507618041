import { _ as e } from "./tslib.es6-7f1b1220.js";
import { c as t, u as s, h as i, n as o } from "./lit-element-161a1699.js";
import { p as l, q as n, c as a } from "./decorators-7e9cd43b.js";
import { BaseElement as c } from "./BaseElement.esm.min.js";
import { h as d } from "./host.styles-cf05db22.js";
import { e as r } from "./event.decorator-98549cbc.js";
import { i as p } from "./if-defined-123567fc.js";
const h = t`${s(":host {\n  display: inline-block;\n}\n\n:host(:focus) {\n  outline: none;\n}")}`;
let b = class extends c {
  constructor() {
    super(...arguments), this.closable = !1, this.disabled = !1;
  }
  static get styles() {
    return [d, h];
  }
  emitCloseEvent() {
    this.dispatchEvent(new CustomEvent("close", {
      bubbles: !0,
      composed: !1
    }));
  }
  _handleClick(e) {
    if (this.disabled) return e.preventDefault(), e.stopImmediatePropagation(), void e.stopPropagation();
    this.emitCloseEvent();
  }
  _handleKeyDown({
    key: e
  }) {
    this.disabled || "Enter" === e && this.emitCloseEvent();
  }
  _updateIconSize() {
    this._iconSlotNodes.forEach(e => e.setAttribute("size", "s"));
  }
  firstUpdated() {
    this._updateIconSize();
  }
  render() {
    return i`<zui-pill-content ?disabled="${this.disabled}" tabindex="${p(this.closable && !this.disabled ? 0 : void 0)}"><slot></slot><slot name="icon" @slotchange="${this._updateIconSize}" slot="icon"></slot>${this.closable ? i`<zui-interactive-icon ?disabled="${this.disabled}" emphasis="subtle" slot="iconClose" tabindex="${p(this.closable && !this.disabled ? 0 : void 0)}" @click="${this._handleClick}" @keydown="${this._handleKeyDown}"><zui-icon-close size="xs"></zui-icon-close></zui-interactive-icon>` : o}</zui-pill-content>`;
  }
};
e([l({
  reflect: !0,
  type: Boolean
})], b.prototype, "closable", void 0), e([l({
  reflect: !0,
  type: Boolean
})], b.prototype, "disabled", void 0), e([r()], b.prototype, "emitCloseEvent", null), e([n("icon", !0, "[zuiIcon]")], b.prototype, "_iconSlotNodes", void 0), b = e([a("zui-pill")], b);
export { b as Pill };
