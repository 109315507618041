import { _ as e } from "./tslib.es6-7f1b1220.js";
import "./lit-element-161a1699.js";
import { p as t } from "./decorators-7e9cd43b.js";
import { BaseElement as a } from "./BaseElement.esm.min.js";
class r extends a {
  constructor() {
    super(), this.disabled = !1, this._readonly = !1, "ElementInternals" in window && "setFormData" in window.ElementInternals && (this.internals = this.attachInternals()), this.onFormData = this.onFormData.bind(this);
  }
  static get formAssociated() {
    return !0;
  }
  get readonly() {
    return this._readonly;
  }
  set readonly(e) {
    const t = this._readonly;
    this._readonly = e, this.requestUpdate("readonly", t);
  }
  get value() {
    return this._value;
  }
  set value(e) {
    var t;
    const a = this._value;
    this._value = this.parseValue(e), null === (t = this.internals) || void 0 === t || t.setFormValue(this.serializeValue(e)), this.requestUpdate("value", a).then(() => this.onValueChanged());
  }
  get form() {
    var e;
    return (null === (e = this.internals) || void 0 === e ? void 0 : e.form) || this.parentForm;
  }
  get type() {
    return this.localName;
  }
  get validity() {
    var e;
    return null === (e = this.internals) || void 0 === e ? void 0 : e.validity;
  }
  get validationMessage() {
    var e;
    return null === (e = this.internals) || void 0 === e ? void 0 : e.validationMessage;
  }
  get willValidate() {
    var e;
    return null === (e = this.internals) || void 0 === e ? void 0 : e.willValidate;
  }
  checkValidity() {
    var e;
    return null === (e = this.internals) || void 0 === e ? void 0 : e.checkValidity();
  }
  reportValidity() {
    var e;
    return null === (e = this.internals) || void 0 === e ? void 0 : e.reportValidity();
  }
  connectedCallback() {
    var e;
    super.connectedCallback(), [null, void 0].includes(this.internals) || (this.parentForm = this.closest("form"), null === (e = this.parentForm) || void 0 === e || e.addEventListener("formdata", this.onFormData));
  }
  disconnectedCallback() {
    var e;
    null === (e = this.parentForm) || void 0 === e || e.removeEventListener("formdata", this.onFormData), delete this.parentForm, super.disconnectedCallback();
  }
  formAssociatedCallback(e) {}
  formResetCallback() {
    this.value = void 0;
  }
  formDisabledCallback(e) {}
  formStateRestoreCallback() {}
  onFormData(e) {
    e.formData.append(this.name, this.serializeValue(this.value));
  }
  onValueChanged() {}
  serializeValue(e) {
    return null == e ? void 0 : e.toString();
  }
  parseValue(e) {
    return e;
  }
}
e([t({
  reflect: !0,
  type: Boolean
})], r.prototype, "disabled", void 0), e([t({
  reflect: !0,
  type: Boolean
})], r.prototype, "readonly", null), e([t({
  reflect: !0,
  type: String
})], r.prototype, "name", void 0), e([t({
  reflect: !0
})], r.prototype, "value", null);
export { r as FormElement };
