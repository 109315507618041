import { _ as e } from "./tslib.es6-7f1b1220.js";
import { c as t, u as s, h as i } from "./lit-element-161a1699.js";
import { p as n, i as a, a as o, c as r } from "./decorators-7e9cd43b.js";
import { c as h } from "./class-map-31af68b2.js";
import { BaseElement as l } from "./BaseElement.esm.min.js";
import { h as d } from "./host.styles-cf05db22.js";
import "./types-814d68fb.js";
import { isStringEmpty as c, isSlotEmpty as m } from "./component.utils.esm.min.js";
const p = t`${s(":host {\n  display: block;\n}\n\n/* font styling */\n#message-container > h1 {\n  color: var(--zui-color-inline-message-header-text);\n  font: var(--zui-typography-subtitle1);\n  margin: 0;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n\n#message-container > p {\n  color: var(--zui-color-inline-message-message-text-without-header);\n  font: var(--zui-typography-body);\n  margin: 0;\n  overflow: hidden;\n}\n\n:host([size=s]) #message-container > p {\n  font: var(--zui-typography-label1);\n}\n\n.header-shown #message-container > p {\n  color: var(--zui-color-inline-message-message-text-with-header);\n}\n\n#root:not(.header-shown) #header-text-slot {\n  display: none;\n}\n\n/* layout */\n#root {\n  display: flex;\n  width: 100%;\n}\n\n#message-container {\n  align-self: center;\n  flex-grow: 1;\n  margin-bottom: calc(var(--zui-gu) * 0.25);\n  margin-left: calc(var(--zui-gu) * 2);\n  width: 0;\n}\n\n:host([size=s]) #message-container {\n  margin-left: calc(var(--zui-gu) * 1);\n}\n\n#icon-container {\n  align-self: flex-start;\n  margin-bottom: calc(calc(var(--zui-gu) * 11) / 16);\n  margin-top: calc(calc(var(--zui-gu) * 11) / 16);\n}\n\n:host([size=s]) #icon-container {\n  margin-bottom: calc(var(--zui-gu) * 0.25);\n  margin-top: calc(var(--zui-gu) * 0.25);\n}")}`;
let u = class extends l {
  constructor() {
    super(...arguments), this._isHeaderSet = !1, this._size = "m", this._initialized = !1;
  }
  static get styles() {
    return [d, p];
  }
  get headerText() {
    return this._headerText;
  }
  set headerText(e) {
    const t = this._headerText;
    this._headerText = e, this._initialized && t !== e && (this._updateHeaderSetState(), this.requestUpdate("header-text", e));
  }
  get size() {
    return this._size;
  }
  set size(e) {
    const t = this._size;
    this._size = e, this._initialized && t !== e && (this._setIconSize(), this._checkSizeAndHeader());
  }
  _checkSizeAndHeader() {
    "s" === this.size && this._hasHeaderText() && console.warn("header will not be visible when size='s'");
  }
  _hasHeaderText() {
    return !c(this.headerText) || !m(this._headerTextSlot);
  }
  _onHeaderTextSlotChanged() {
    this._updateHeaderSetState();
  }
  _updateHeaderSetState() {
    this._isHeaderSet = this._hasHeaderText(), this._checkSizeAndHeader();
  }
  _onIconSlotChanged() {
    this._setIconSize();
  }
  _setIconSize() {
    this._iconSlot.assignedNodes().forEach(e => {
      e instanceof HTMLElement && this._setSizeAttributeToIcon(e);
    });
  }
  _setSizeAttributeToIcon(e) {
    switch (this.size) {
      case "s":
        e.setAttribute("size", "m");
        break;
      case "l":
        e.setAttribute("size", "xl");
        break;
      case "m":
        e.setAttribute("size", "l");
        break;
      default:
        this.size;
    }
  }
  firstUpdated() {
    this._updateHeaderSetState(), this._setIconSize(), this._initialized = !0;
  }
  render() {
    return i`<div id="root" class="${h({
      "header-shown": "s" !== this.size && this._isHeaderSet
    })}"><div id="icon-container"><slot name="icon" @slotchange="${this._onIconSlotChanged}"></slot></div><div id="message-container"><h1><slot id="header-text-slot" name="headerText" @slotchange="${this._onHeaderTextSlotChanged}">${this.headerText}</slot></h1><p><slot id="text-slot"></slot></p></div></div>`;
  }
};
e([n({
  reflect: !0,
  attribute: "header-text"
})], u.prototype, "headerText", null), e([n({
  reflect: !0
})], u.prototype, "size", null), e([a()], u.prototype, "_isHeaderSet", void 0), e([o('slot[name="headerText"]')], u.prototype, "_headerTextSlot", void 0), e([o('slot[name="icon"]')], u.prototype, "_iconSlot", void 0), u = e([r("zui-inline-message")], u);
export { u as InlineMessage };
