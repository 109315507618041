import { _ as t } from "./tslib.es6-7f1b1220.js";
import { c as e, u as o, h as a } from "./lit-element-161a1699.js";
import { p as i, q as s, c as n } from "./decorators-7e9cd43b.js";
import "./class-map-31af68b2.js";
import { BaseElement as r } from "./BaseElement.esm.min.js";
import { h as d } from "./host.styles-cf05db22.js";
import { e as l } from "./event.decorator-98549cbc.js";
import "./types-814d68fb.js";
import { commaListConverter as p } from "./component.utils.esm.min.js";
import "./style-map-a37d7105.js";
import "./content-accordion-details.component.esm.min.js";
const c = e`${o(":host {\n  justify-content: center;\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n}")}`;
let h = class extends r {
  constructor() {
    super(...arguments), this.size = "m", this.integrated = !1, this.disabled = !1, this.multi = !1, this.value = [], this._propagateDisabled = !1;
  }
  static get styles() {
    return [d, c];
  }
  emitContentAccordionOpenStateChanged() {
    this.dispatchEvent(new CustomEvent("content-accordion-open-state-changed", {
      bubbles: !1,
      composed: !1,
      cancelable: !1,
      detail: {
        value: this.value
      }
    }));
  }
  _propagateState() {
    this._assignedAccordion.forEach(t => {
      this._propagateDisabled && (t.disabled = this.disabled), t.integrated = this.integrated, t.size = this.size, this.value.includes(t.value) ? t.setAttribute("open", "") : t.removeAttribute("open");
    });
  }
  _handleOpenStateChanged(t) {
    t.stopPropagation(), t.stopImmediatePropagation(), t.detail.opened ? this.multi ? this.value = [...this.value, t.detail.value] : this.multi || (this.value = [t.detail.value]) : this.value = this.value.filter(e => e !== t.detail.value), this.emitContentAccordionOpenStateChanged();
  }
  _handleSlotChange() {
    this._propagateState();
  }
  update(t) {
    super.update(t), (this.disabled || t.get("disabled")) && (this._propagateDisabled = !0);
  }
  updated(t) {
    super.updated(t), this._propagateState();
  }
  render() {
    return a`<slot @content-accordion-details-open-state-changed="${this._handleOpenStateChanged}" @slotchange="${this._handleSlotChange}"></slot>`;
  }
};
t([i({
  reflect: !0,
  type: String
})], h.prototype, "size", void 0), t([i({
  reflect: !0,
  type: Boolean
})], h.prototype, "integrated", void 0), t([i({
  reflect: !0,
  type: Boolean
})], h.prototype, "disabled", void 0), t([i({
  reflect: !0,
  type: Boolean
})], h.prototype, "multi", void 0), t([i({
  reflect: !0,
  type: String,
  converter: p
})], h.prototype, "value", void 0), t([l()], h.prototype, "emitContentAccordionOpenStateChanged", null), t([s("", !0, "zui-content-accordion-details")], h.prototype, "_assignedAccordion", void 0), h = t([n("zui-content-accordion")], h);
export { h as ContentAccordion };
