import { _ as e } from "./tslib.es6-7f1b1220.js";
import { c as t, u as n, h as a } from "./lit-element-161a1699.js";
import { p as i, a as r, s as l, c as s } from "./decorators-7e9cd43b.js";
import { c as o } from "./class-map-31af68b2.js";
import { BaseElement as p } from "./BaseElement.esm.min.js";
import { h as u } from "./host.styles-cf05db22.js";
import { e as h } from "./event.decorator-98549cbc.js";
import "./types-814d68fb.js";
import { isDefined as d, numberUndefinedConverter as c } from "./component.utils.esm.min.js";
import { r as v } from "./date-picker.utils-7bc542a5.js";
const m = (e, t) => `${e}`.padStart(t, "0"),
  _ = (e, t) => {
    const n = e[t];
    if (null !== n) return n instanceof b ? n : n instanceof HTMLSpanElement ? _(n, t) : void 0;
  },
  y = e => _(e, "nextElementSibling");
var g;
const f = t`${n(":host {\n  /* width is set in the date-picker-input and the defaults are (DD) 20px - (MM) 24px - (YYYY) 30px */\n  --zui-date-picker-input-part-placeholder-width: 0;\n  ---zui-date-picker-input-part-input-width: 0;\n}\n\ninput {\n  background-color: transparent;\n  border: none;\n  box-shadow: none;\n  color: var(--zui-color-text-default);\n  font: var(--zui-typography-body);\n  height: calc(var(--zui-gu) * 3.75);\n  line-height: calc(var(--zui-gu) * 3.75);\n  padding: 0;\n  width: var(---zui-date-picker-input-part-input-width);\n}\n:host([disabled]) input {\n  opacity: var(--zui-disabled-opacity);\n}\ninput.show-placeholder {\n  width: var(--zui-date-picker-input-part-placeholder-width);\n}\ninput:focus {\n  outline: none;\n}\ninput:active {\n  outline: none;\n}\ninput::placeholder {\n  color: var(--zui-color-placeholder-input);\n}\n:host([readonly]) input::placeholder {\n  color: transparent;\n  opacity: 0;\n}\ninput::-moz-placeholder {\n  opacity: 1;\n}\ninput[type=number] {\n  -moz-appearance: textfield;\n}\ninput[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}")}`;
let b = g = class extends p {
  constructor() {
    super(...arguments), this.disabled = !1, this.literals = [], this.max = g.DATE_PICKER_INPUT_PART_MAX_DEFAULT, this.min = g.DATE_PICKER_INPUT_PART_MIN_DEFAULT, this.readonly = !1, this._showPlaceholder = !0, this._validLiterals = [".", "/"], this._internalValue = void 0, this._userInteraction = !1;
  }
  static get styles() {
    return [u, f];
  }
  get value() {
    return this._internalValue;
  }
  set value(e) {
    const t = this._internalValue;
    this._internalValue = e, this._showPlaceholder = !d(e), this.requestUpdate("value", t);
  }
  emitInputValueChangeEvent(e) {
    this.dispatchEvent(new CustomEvent("date-picker-input-part-value-changed", {
      bubbles: !0,
      composed: !0,
      detail: e
    }));
  }
  focus(e) {
    var t;
    null === (t = this._inputElement) || void 0 === t || t.focus(e);
  }
  get _maxCharacterLength() {
    return this.max.toString().length;
  }
  get _paddedValue() {
    return void 0 !== this.value ? m(this.value, this._maxCharacterLength) : null;
  }
  _handleInputEvent(e) {
    var t;
    e.target.value.length > this._maxCharacterLength && (e.target.value = e.target.value.slice(0, this._maxCharacterLength)), this._showPlaceholder = 0 === e.target.value.length, "insertText" === e.inputType && e.target.value.length === this._maxCharacterLength && (null === (t = y(this)) || void 0 === t || t.focus());
  }
  _handleInputChangeEvent(e) {
    var t, n;
    const a = e.target.value;
    var i, r, l;
    "" === a ? this.value = void 0 : (this.value = (i = Number(a), r = null !== (t = this.min) && void 0 !== t ? t : 0, l = null !== (n = this.max) && void 0 !== n ? n : 0, i < r ? r : i > l ? l : i), this._inputElement.value = this._paddedValue), this._userInteraction = !0;
  }
  _handleBackspaceKeydownEvent(e) {
    var t;
    isNaN(parseInt(e.target.value)) && (e.preventDefault(), null === (t = _(this, "previousElementSibling")) || void 0 === t || t.focus());
  }
  _handleLiteralInputEvent(e) {
    var t;
    e.preventDefault(), isNaN(parseInt(e.target.value)) || null === (t = y(this)) || void 0 === t || t.focus();
  }
  _handleInputKeydownEvent(e) {
    switch (e.key) {
      case "+":
      case ",":
      case "-":
      case "e":
        e.preventDefault();
        break;
      case "Backspace":
        this._handleBackspaceKeydownEvent(e);
        break;
      default:
        this.literals.includes(e.key) ? this._handleLiteralInputEvent(e) : this._validLiterals.filter(e => !this.literals.includes(e)).includes(e.key) && e.preventDefault();
    }
  }
  updated(e) {
    var t;
    super.updated(e), e.has("value") && this._userInteraction && (this.emitInputValueChangeEvent({
      type: this.inputPartType,
      value: null !== (t = this.value) && void 0 !== t ? t : null
    }), this._userInteraction = !1);
  }
  render() {
    return a`<input .value="${this._paddedValue}" ?disabled="${this.disabled}" ?readonly="${this.readonly}" class="${o({
      "show-placeholder": this._showPlaceholder
    })}" max="${this.max}" min="${this.min}" placeholder="${this.placeholder}" type="number" @change="${this._handleInputChangeEvent}" @input="${this._handleInputEvent}" @keydown="${this._handleInputKeydownEvent}">`;
  }
};
b.DATE_PICKER_INPUT_PART_MAX_DEFAULT = 9999, b.DATE_PICKER_INPUT_PART_MIN_DEFAULT = 1, e([i({
  reflect: !0,
  type: Boolean
})], b.prototype, "disabled", void 0), e([i({
  reflect: !0,
  type: String,
  attribute: "input-part-type"
})], b.prototype, "inputPartType", void 0), e([i({
  reflect: !0,
  type: String,
  converter: v
})], b.prototype, "literals", void 0), e([i({
  reflect: !0,
  type: Number
})], b.prototype, "max", void 0), e([i({
  reflect: !0,
  type: Number
})], b.prototype, "min", void 0), e([i({
  reflect: !0,
  type: String
})], b.prototype, "placeholder", void 0), e([i({
  reflect: !0,
  type: Boolean
})], b.prototype, "readonly", void 0), e([i({
  reflect: !0,
  converter: c
})], b.prototype, "value", null), e([h()], b.prototype, "emitInputValueChangeEvent", null), e([r("input")], b.prototype, "_inputElement", void 0), e([l()], b.prototype, "_showPlaceholder", void 0), b = g = e([s("zui-date-picker-input-part")], b);
export { b as D, m as a };
