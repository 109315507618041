import { _ as e } from "./tslib.es6-7f1b1220.js";
import { c as t, u as i, h as n } from "./lit-element-161a1699.js";
import { p as r, a, c as l } from "./decorators-7e9cd43b.js";
import "./class-map-31af68b2.js";
import { BaseElement as u } from "./BaseElement.esm.min.js";
import { h as s } from "./host.styles-cf05db22.js";
import { e as c } from "./event.decorator-98549cbc.js";
import "./types-814d68fb.js";
import { isDefined as o, cycle as p, clamp as d } from "./component.utils.esm.min.js";
import "./_commonjsHelpers-82e0321a.js";
import "./date-picker.utils-7bc542a5.js";
import { a as h } from "./date-picker-input-part.component-63342ded.js";
var m;
const v = t`${i(":host {\n  align-items: center;\n  display: inline-flex;\n  flex-direction: column;\n}\n\ninput {\n  background-color: var(--zui-time-picker-cell-background-color);\n  border-radius: calc(var(--zui-gu) * 0.375);\n  border: 1px solid var(--zui-time-picker-cell-border-color);\n  box-sizing: border-box;\n  color: var(--zui-time-picker-cell-text-color);\n  font: var(--zui-typography-h1);\n  height: calc(var(--zui-gu) * 6);\n  line-height: calc(var(--zui-gu) * 6);\n  margin: calc(var(--zui-gu) * 1.5) 0;\n  padding: 0;\n  text-align: center;\n  width: calc(var(--zui-gu) * 6);\n}\n:host([disabled]) input {\n  opacity: var(--zui-disabled-opacity);\n  pointer-events: none;\n}\ninput:hover {\n  background-color: var(--zui-time-picker-cell-background-color-hovered);\n  border-color: var(--zui-time-picker-cell-border-color-hovered);\n  color: var(--zui-time-picker-cell-text-color-hovered);\n}\ninput:focus {\n  outline: none;\n}\ninput:active {\n  background-color: var(--zui-time-picker-cell-background-color-pressed);\n  border-color: var(--zui-time-picker-cell-border-color-pressed);\n  color: var(--zui-time-picker-cell-text-color-pressed);\n}\ninput::selection {\n  background: var(--zui-color-selection-background);\n}\n\ninput[type=number]::-webkit-outer-spin-button,\ninput[type=number]::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\ninput[type=number] {\n  -moz-appearance: textfield;\n}")}`;
let b = m = class extends u {
  constructor() {
    super(...arguments), this.defaultValue = 0, this.disabled = !1, this.max = 0, this.min = 0, this.step = 1;
  }
  static get styles() {
    return [s, v];
  }
  emitTimePickerCellChangedEvent() {
    this.dispatchEvent(new CustomEvent("time-picker-cell-changed", {
      bubbles: !0,
      cancelable: !1,
      composed: !0,
      detail: {
        value: this.value
      }
    }));
  }
  emitTimePickerCellInputEvent() {
    this.dispatchEvent(new CustomEvent("time-picker-cell-input", {
      bubbles: !0,
      cancelable: !1,
      composed: !1,
      detail: {
        value: parseInt(this._input.value)
      }
    }));
  }
  get _paddedValue() {
    return o(this.value) ? h(this.value, m.MAX_CHARACTER_LENGTH) : null;
  }
  get _currentValue() {
    var e;
    return null !== (e = this.value) && void 0 !== e ? e : this.defaultValue;
  }
  get _currentInputValue() {
    return "" !== this._input.value ? Number(this._input.value) : this.defaultValue;
  }
  _getDecreasedValue(e) {
    return p(e, this.min, this.max, this.step, "decrease");
  }
  _getIncreasedValue(e) {
    return p(e, this.min, this.max, this.step, "increase");
  }
  _decreaseValue() {
    o(this.value) ? this.value = this._getDecreasedValue(this._currentValue) : this.value = this._currentValue, this.emitTimePickerCellChangedEvent();
  }
  _increaseValue() {
    o(this.value) ? this.value = this._getIncreasedValue(this._currentValue) : this.value = this._currentValue, this.emitTimePickerCellChangedEvent();
  }
  _handleInputEvent() {
    this._input.value.length > m.MAX_CHARACTER_LENGTH && (this._input.value = this._input.value.slice(0, m.MAX_CHARACTER_LENGTH)), "" !== this._input.value && (this._input.value = String(d(this.min, Number(this._input.value), this.max))), this.emitTimePickerCellInputEvent();
  }
  _handleInputBlurEvent() {
    const e = this.value;
    this.value = this._currentInputValue, this._input.value = this._paddedValue, e !== this.value && this.emitTimePickerCellChangedEvent();
  }
  _handleInputClickEvent() {
    o(this.value) || (this._input.value = h(this.defaultValue, m.MAX_CHARACTER_LENGTH));
  }
  _handleInputKeydownEvent(e) {
    switch (e.key) {
      case "1":
      case "2":
      case "3":
      case "4":
      case "5":
      case "6":
      case "7":
      case "8":
      case "9":
      case "0":
      case "ArrowLeft":
      case "ArrowRight":
      case "Backspace":
      case "Delete":
      case "Tab":
        break;
      case "ArrowUp":
        e.preventDefault(), e.target.value = String(this._getIncreasedValue(this._currentInputValue)), this.emitTimePickerCellInputEvent();
        break;
      case "ArrowDown":
        e.preventDefault(), e.target.value = String(this._getDecreasedValue(this._currentInputValue)), this.emitTimePickerCellInputEvent();
        break;
      default:
        e.preventDefault();
    }
  }
  render() {
    return n`<zui-interactive-icon emphasis="subtle" id="increase" ?disabled="${this.disabled}" @click="${this._increaseValue}"><zui-icon-arrow-outline-arrow-outline-actually-centred-up></zui-icon-arrow-outline-arrow-outline-actually-centred-up></zui-interactive-icon><input .value="${this._paddedValue}" ?disabled="${this.disabled}" max="${this.max}" min="${this.min}" step="${this.step}" type="number" @blur="${this._handleInputBlurEvent}" @click="${this._handleInputClickEvent}" @input="${this._handleInputEvent}" @keydown="${this._handleInputKeydownEvent}"><zui-interactive-icon emphasis="subtle" id="decrease" ?disabled="${this.disabled}" @click="${this._decreaseValue}"><zui-icon-arrow-outline-arrow-outline-actually-centred-down></zui-icon-arrow-outline-arrow-outline-actually-centred-down></zui-interactive-icon>`;
  }
};
b.MAX_CHARACTER_LENGTH = 2, e([r({
  reflect: !0,
  type: Number,
  attribute: "default-value"
})], b.prototype, "defaultValue", void 0), e([r({
  reflect: !0,
  type: Boolean
})], b.prototype, "disabled", void 0), e([r({
  reflect: !0,
  type: Number
})], b.prototype, "max", void 0), e([r({
  reflect: !0,
  type: Number
})], b.prototype, "min", void 0), e([r({
  reflect: !0,
  type: Number
})], b.prototype, "step", void 0), e([r({
  reflect: !0,
  type: Number
})], b.prototype, "value", void 0), e([c()], b.prototype, "emitTimePickerCellChangedEvent", null), e([c()], b.prototype, "emitTimePickerCellInputEvent", null), e([a("input")], b.prototype, "_input", void 0), b = m = e([l("zui-time-picker-cell")], b);
export { b as TimePickerCell };
