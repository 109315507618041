import { _ as e } from "./tslib.es6-7f1b1220.js";
import { c as t, u as i, h as r } from "./lit-element-161a1699.js";
import { p as s, c as l } from "./decorators-7e9cd43b.js";
import { BaseElement as o } from "./BaseElement.esm.min.js";
import { h as c } from "./host.styles-cf05db22.js";
var n;
const C = t`${i(":host {\n  align-items: center;\n  display: inline-flex;\n  justify-content: center;\n  width: calc(var(--zui-gu) * 3);\n}\n\ncircle {\n  fill: var(--zui-time-picker-cell-divider-color);\n}\n:host([disabled]) circle {\n  opacity: var(--zui-disabled-opacity);\n}")}`;
let a = n = class extends o {
  constructor() {
    super(...arguments), this.disabled = !1, this.literal = ":";
  }
  static get styles() {
    return [c, C];
  }
  get _colonHeight() {
    return n.COLON_SPACING + 2 * n.COLON_CIRCLE_WIDTH;
  }
  _getColonLiteral() {
    return r`<svg width="${n.COLON_CIRCLE_WIDTH}" height="${this._colonHeight}"><circle cx="${n.COLON_CIRCLE_WIDTH / 2}" cy="${n.COLON_CIRCLE_WIDTH}" r="${n.COLON_CIRCLE_WIDTH / 2}"/><circle cx="${n.COLON_CIRCLE_WIDTH / 2}" cy="${this._colonHeight - n.COLON_CIRCLE_WIDTH}" r="${n.COLON_CIRCLE_WIDTH / 2}"/></svg>`;
  }
  render() {
    return this.literal, this._getColonLiteral();
  }
};
a.COLON_SPACING = 10, a.COLON_CIRCLE_WIDTH = 3, e([s({
  reflect: !0,
  type: Boolean
})], a.prototype, "disabled", void 0), e([s({
  reflect: !0,
  type: String
})], a.prototype, "literal", void 0), a = n = e([l("zui-time-picker-cell-divider")], a);
export { a as TimePickerCellDivider };
