import { _ as t } from "./tslib.es6-7f1b1220.js";
import "./lit-element-161a1699.js";
import { p as i } from "./decorators-7e9cd43b.js";
import "./types-814d68fb.js";
import { isBooleanDOMAttributeSet as a } from "./dom.utils.esm.min.js";
const s = (s, {} = {}) => {
  class e extends s {
    constructor() {
      super(...arguments), this.valid = !1, this.invalid = !1, this._isFirstUpdate = !0, this._validators = [e.initialValidator], this._validationState = {
        valid: !0,
        customError: !1,
        badInput: !1,
        rangeOverflow: !1,
        rangeUnderflow: !1,
        stepMismatch: !1,
        tooLong: !1,
        tooShort: !1,
        typeMismatch: !1,
        valueMissing: !1,
        patternMismatch: !1
      }, this._validationMessages = {}, this._defaultValidationMessages = e.initialValidityMessages;
    }
    get willValidate() {
      return !this.disabled && !this.readonly && (this._validators.length > 1 || this.hasOwnProperty("validationCallback"));
    }
    get validationMessage() {
      if (!this.willValidate || this.valid) return "";
      const [t] = Object.entries(this.validity).find(([, t]) => !0 === t) || [],
        i = this.getValidityMessages();
      return i[t] || i.customError || "";
    }
    set validationMessage(t) {
      console.warn("Setting validationMessage is deprecated and will lead to an error in the next major release. Please use setCustomValidity() or setValidityMessages() instead!"), this.setCustomValidity(t);
    }
    get validity() {
      return this.willValidate ? Object.assign(Object.assign({}, this._validationState), {
        valid: this.valid
      }) : Object.keys(this._validationState).reduce((t, i) => (t[i] = !1, t), {});
    }
    get _propertiesForValidationUpdate() {
      return Array.from(new Set(this._validators.map(t => t.validatesOnProperties).flat()));
    }
    _toggleValidationState(t) {
      for (const i in this._validationState) this._validationState[i] = t === i;
    }
    formSubmitCallback(t) {
      const {
        target: i
      } = t;
      a(i, "novalidate") || this.willValidate && !1 === this.checkValidity() && t.preventDefault();
    }
    checkValidity() {
      if (!this.willValidate) return !0;
      const {
          isValid: t = !1,
          message: i = this._validationMessages.customError
        } = this.validationCallback(this.value),
        a = !t,
        s = a ? void 0 : this._validators.find(t => !1 === t.validator(this.value)),
        e = a || void 0 !== s;
      return a && (this.setCustomValidity(i), this._toggleValidationState("customError")), void 0 !== s && this._toggleValidationState(s.type), e ? (this.dispatchEvent(new Event("invalid")), this.valid = !1, this.invalid = !0) : (this.valid = !0, this.invalid = !1), !e;
    }
    setCustomValidity(t) {
      this.setValidityMessages({
        customError: t
      });
    }
    setValidityMessages(t) {
      this._validationMessages = Object.assign(Object.assign({}, this._validationMessages), t);
    }
    getValidityMessages() {
      return Object.assign(Object.assign({}, this._defaultValidationMessages), this._validationMessages);
    }
    setDefaultValidityMessages(t) {
      this._defaultValidationMessages = Object.assign(Object.assign({}, this._defaultValidationMessages), t);
    }
    validationCallback(t) {
      return {
        isValid: !0
      };
    }
    addValidator(t) {
      this._validators.push(t);
    }
    removeValidator(t) {
      this._validators = this._validators.filter(i => i.type !== t);
    }
    resetValidators() {
      this._validators = [e.initialValidator];
    }
    resetValidationState() {
      this.valid = !1, this.invalid = !1;
    }
    updated(t) {
      super.updated(t), (t.has("value") || t.has("disabled") || t.has("readonly") || this._propertiesForValidationUpdate.some(i => t.has(i))) && !this._isFirstUpdate && this.willValidate && this.checkValidity(), this._isFirstUpdate = !1;
    }
  }
  return e.initialValidator = {
    type: "customError",
    validator: () => !0
  }, e.initialValidityMessages = {
    customError: void 0,
    badInput: void 0,
    patternMismatch: void 0,
    rangeOverflow: void 0,
    rangeUnderflow: void 0,
    stepMismatch: void 0,
    tooLong: void 0,
    tooShort: void 0,
    typeMismatch: void 0,
    valueMissing: void 0
  }, t([i({
    reflect: !0,
    type: Boolean
  })], e.prototype, "valid", void 0), t([i({
    reflect: !0,
    type: Boolean
  })], e.prototype, "invalid", void 0), e;
};
export { s as F };
