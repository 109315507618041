import { _ as e } from "./tslib.es6-7f1b1220.js";
import { c as t, u as n, h as o, n as r } from "./lit-element-161a1699.js";
import { p as i, a, c as l } from "./decorators-7e9cd43b.js";
import { c as d } from "./class-map-31af68b2.js";
import { RealBaseElement as c } from "./BaseElement.esm.min.js";
import { h as s } from "./host.styles-cf05db22.js";
import { e as b } from "./event.decorator-98549cbc.js";
import { D as u } from "./delegate-focus.mixin-95600d1f.js";
const h = t`${n(':host([readonly]),\n:host([disabled]) {\n  opacity: var(--zui-disabled-opacity);\n  pointer-events: none;\n}\n\ninput:focus {\n  outline: none;\n}\n\ninput[type=radio] {\n  opacity: 0;\n  position: absolute;\n  z-index: -1;\n}\n\n*,\n*:before,\n*:after {\n  box-sizing: border-box;\n}\n\nlabel {\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n}\nlabel:before {\n  appearance: none;\n  -webkit-appearance: none;\n  margin: 0;\n  display: grid;\n  height: calc(var(--zui-gu) * 2);\n  min-width: calc(var(--zui-gu) * 2);\n  width: calc(var(--zui-gu) * 2);\n  background-color: var(--zui-color-third-hierarchy-bg-default-enabled);\n  border-color: var(--zui-color-radio-button-border-default);\n  border-radius: 50%;\n  border-style: solid;\n  border-width: var(--zui-line-strength-thin);\n  box-sizing: border-box;\n  place-content: center;\n  content: "";\n}\nlabel:hover:before {\n  background-color: var(--zui-color-third-hierarchy-bg-default-hovered);\n}\n\ninput:checked + label:hover:before {\n  box-shadow: 0 0 0 3px inset var(--zui-color-interactive-element-bg-activated-hovered);\n}\ninput:checked + label:before {\n  background: var(--zui-color-radio-button-knob-enabled);\n  border-radius: 50%;\n  border-color: var(--zui-color-radio-button-border-active);\n  border-style: solid;\n  border-width: var(--zui-line-strength-thin);\n  box-shadow: 0 0 0 3px inset var(--zui-color-interactive-element-bg-activated-enabled);\n}\n\ndiv {\n  color: var(--zui-color-text-default);\n  font: var(--zui-typography-body);\n  margin-left: var(--zui-space-small);\n}\n\nlabel.touch:before {\n  height: calc(var(--zui-gu) * 3);\n  width: calc(var(--zui-gu) * 3);\n}\n\ninput:checked + label.touch:before {\n  box-shadow: 0 0 0 5px inset var(--zui-color-interactive-element-bg-activated-enabled);\n}\n\nlabel.touch div {\n  margin-left: calc(var(--zui-gu) * 1.5);\n}')}`;
let p = class extends u(c) {
  constructor() {
    super(...arguments), this.checked = !1, this.disabled = !1, this.tabindex = 0;
  }
  static get styles() {
    return [s, h];
  }
  emitInputEvent() {}
  emitChangeEvent() {
    this.dispatchEvent(new Event("change", {
      bubbles: !1,
      composed: !1
    }));
  }
  _handleChange() {
    this.checked = this._inputRef.checked, this.emitChangeEvent();
  }
  render() {
    return o`<input type="radio" id="radio-button" .value="${this.value}" ?zuiCaptureFocus="${!this.disabled}" ?disabled="${this.disabled}" .checked="${this.checked}" @change="${this._handleChange}"> <label for="radio-button" class="${d({
      touch: this.hasTouch
    })}"><div><slot>${this.label ? o`${this.label}` : r}</slot></div></label>`;
  }
};
e([i({
  reflect: !0,
  type: String
})], p.prototype, "label", void 0), e([i({
  reflect: !0,
  type: Boolean
})], p.prototype, "checked", void 0), e([i({
  reflect: !0,
  type: Boolean
})], p.prototype, "disabled", void 0), e([i({
  reflect: !0,
  type: String
})], p.prototype, "value", void 0), e([i({
  reflect: !0,
  type: Number
})], p.prototype, "tabindex", void 0), e([b()], p.prototype, "emitInputEvent", null), e([b()], p.prototype, "emitChangeEvent", null), e([a('input[type="radio"]')], p.prototype, "_inputRef", void 0), p = e([l("zui-radio-button")], p);
export { p as RadioButton };
