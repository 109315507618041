import { _ as e } from "./tslib.es6-7f1b1220.js";
import { c as s, u as t, h as i } from "./lit-element-161a1699.js";
import { p as r, q as a, c as o } from "./decorators-7e9cd43b.js";
import { BaseElement as n } from "./BaseElement.esm.min.js";
import { h as l } from "./host.styles-cf05db22.js";
import { MenuItem as m } from "./menu-item.component.esm.min.js";
const d = s`${t(":host {\n  display: flex;\n  max-width: calc(var(--zui-gu) * 65);\n  min-width: calc(var(--zui-gu) * 45);\n  position: relative;\n  width: calc(100% - var(--zui-searchbar-results-menu-margin-left) - var(--zui-searchbar-results-menu-margin-right));\n}")}`,
  c = e => e instanceof m;
let u = class extends n {
  constructor() {
    super(...arguments), this.disabled = !1, this.size = "s";
  }
  static get styles() {
    return [l, d];
  }
  _handleSlotchange() {
    Array.from(this._assignedMenuItems).filter(c).forEach(e => e.disabled = this.disabled);
  }
  render() {
    return i`<zui-menu ?disabled="${this.disabled}" size="${this.size}" style="--zui-menu-margin-top:4px"><slot @slotchange="${this._handleSlotchange}"></slot></zui-menu>`;
  }
};
e([r({
  reflect: !0,
  type: Boolean
})], u.prototype, "disabled", void 0), e([r({
  reflect: !0,
  type: String
})], u.prototype, "size", void 0), e([a("", !0)], u.prototype, "_assignedMenuItems", void 0), u = e([o("zui-searchbar-results-menu")], u);
export { u as SearchbarResultsMenu };
