var t = "object" == typeof global && global && global.Object === Object && global,
  e = "object" == typeof self && self && self.Object === Object && self,
  o = t || e || Function("return this")(),
  n = o.Symbol,
  r = Object.prototype,
  l = r.hasOwnProperty,
  a = r.toString,
  c = n ? n.toStringTag : void 0;
var b = Object.prototype.toString;
var i = n ? n.toStringTag : void 0;
function u(t) {
  return null == t ? void 0 === t ? "[object Undefined]" : "[object Null]" : i && i in Object(t) ? function (t) {
    var e = l.call(t, c),
      o = t[c];
    try {
      t[c] = void 0;
      var n = !0;
    } catch (t) {}
    var r = a.call(t);
    return n && (e ? t[c] = o : delete t[c]), r;
  }(t) : function (t) {
    return b.call(t);
  }(t);
}
function f(t) {
  return null != t && "object" == typeof t;
}
function j(t) {
  var e = typeof t;
  return null != t && ("object" == e || "function" == e);
}
export { n as S, j as a, u as b, t as f, f as i, o as r };
