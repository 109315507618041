import { _ as e } from "./tslib.es6-7f1b1220.js";
import { c as t, u as r, h as i } from "./lit-element-161a1699.js";
import { p as s, c as a } from "./decorators-7e9cd43b.js";
import { BaseElement as o } from "./BaseElement.esm.min.js";
import { h as n } from "./host.styles-cf05db22.js";
const l = t`${r(":host {\n  align-items: center;\n  color: var(--zui-color-text-default);\n  display: flex;\n  font: var(--zui-typography-label2);\n  height: var(--zui-picker-header-cell-height, calc(var(--zui-gu) * 8));\n  justify-content: center;\n  overflow: hidden;\n  white-space: nowrap;\n  width: var(--zui-picker-header-cell-width, calc(var(--zui-gu) * 4));\n}\n\n.text-wrapper {\n  margin-top: 1px;\n}")}`;
let c = class extends o {
  constructor() {
    super(...arguments), this.text = "", this.value = "";
  }
  static get styles() {
    return [n, l];
  }
  render() {
    return i`<div class="text-wrapper">${this.text}</div>`;
  }
};
e([s({
  reflect: !0,
  type: String
})], c.prototype, "text", void 0), e([s({
  reflect: !0,
  type: String
})], c.prototype, "value", void 0), c = e([a("zui-picker-header-cell")], c);
export { c as HeaderCell, l as headerCellStyles };
