import { _ as t } from "./tslib.es6-7f1b1220.js";
import { c as e, u as s, h as i } from "./lit-element-161a1699.js";
import { p as o, q as a, c as n } from "./decorators-7e9cd43b.js";
import "./class-map-31af68b2.js";
import { RealBaseElement as r } from "./BaseElement.esm.min.js";
import { h as d } from "./host.styles-cf05db22.js";
import { e as l } from "./event.decorator-98549cbc.js";
import "./types-814d68fb.js";
import { emptyStringToNullWrapperConverter as u, stringUndefinedConverter as p } from "./component.utils.esm.min.js";
import "./mixin.utils.esm.min.js";
import { F as h } from "./form-data-handling.mixin-3ddec2ca.js";
import "./dom.utils.esm.min.js";
import { F as m } from "./form-validation.mixin-0a6c3c34.js";
import { D as c } from "./delegate-focus.mixin-95600d1f.js";
import "./radio-button.component.esm.min.js";
const v = e`${s(":host([readonly]),\n:host([disabled]) {\n  opacity: var(--zui-disabled-opacity);\n  pointer-events: none;\n}")}`;
let b = class extends m(h(c(r), {
  formControlSelector: function () {
    return this._selectedRadioButton;
  }
})) {
  constructor() {
    super(), this.required = !1, this._propagateDisabled = !1, this._isUserInteraction = !1, this.addValidator({
      type: "valueMissing",
      validator: () => !0 !== this.required || void 0 !== this.value && void 0 !== this._assignedRadioButtons.find(t => t.value === this.value),
      validatesOnProperties: ["required"]
    });
  }
  static get styles() {
    return [d, v];
  }
  emitChangedEvent() {
    this.dispatchEvent(new CustomEvent("changed", {
      bubbles: !0,
      composed: !1,
      detail: this.value
    }));
  }
  emitChangeEvent() {
    this.dispatchEvent(new Event("change", {
      bubbles: !0,
      composed: !1
    }));
  }
  emitInputEvent() {
    this.dispatchEvent(new Event("input", {
      bubbles: !0,
      composed: !0
    }));
  }
  emitBlurEvent() {
    this.dispatchEvent(new FocusEvent("blur", {
      bubbles: !0,
      cancelable: !1,
      composed: !1
    }));
  }
  get _selectedRadioButton() {
    var t;
    return null !== (t = this._assignedRadioButtons.find(t => t.value === this.value)) && void 0 !== t ? t : null;
  }
  focusCallback() {
    if (!this.disabled) {
      const [t] = this._assignedRadioButtons;
      null == t || t.focus();
    }
  }
  _handleFocusOut({
    relatedTarget: t
  }) {
    this._assignedRadioButtons.some(e => e === t) || this.emitBlurEvent();
  }
  _inputEventHandler(t) {
    t.stopPropagation(), this.disabled || this.readonly || "zui-radio-button" === t.target.tagName.toLowerCase() && (this.value = t.target.value, this._isUserInteraction = !0, this.emitInputEvent());
  }
  _propagateState() {
    this._assignedRadioButtons.forEach(t => {
      this._propagateDisabled && (t.disabled = this.disabled), this.value ? t.checked = t.value === this.value : t.checked = !1;
    });
  }
  _handleSlotChange() {
    this._propagateState(), this.checkValidity();
  }
  updated(t) {
    super.updated(t), (this.disabled || t.get("disabled")) && (this._propagateDisabled = !0), t.has("value") && this._isUserInteraction && (this.emitChangedEvent(), this.emitChangeEvent(), this._isUserInteraction = !1), this._propagateState();
  }
  render() {
    return i`<div @focusout="${this._handleFocusOut}"><slot id="radio-button-slot" @slotchange="${this._handleSlotChange}" @input="${this._inputEventHandler}"></slot></div>`;
  }
};
t([o({
  reflect: !0,
  converter: u(p)
})], b.prototype, "value", void 0), t([o({
  reflect: !0,
  type: Boolean
})], b.prototype, "required", void 0), t([l()], b.prototype, "emitChangedEvent", null), t([l()], b.prototype, "emitChangeEvent", null), t([l()], b.prototype, "emitInputEvent", null), t([l()], b.prototype, "emitBlurEvent", null), t([a("", !0, "zui-radio-button")], b.prototype, "_assignedRadioButtons", void 0), b = t([n("zui-radio-button-group")], b);
export { b as RadioButtonGroup };
