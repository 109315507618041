import { _ as e } from "./tslib.es6-7f1b1220.js";
import { n as t, h as r } from "./lit-element-161a1699.js";
import { p as a, c as i } from "./decorators-7e9cd43b.js";
import { BaseElement as s } from "./BaseElement.esm.min.js";
import { h as n } from "./host.styles-cf05db22.js";
import { e as c } from "./event.decorator-98549cbc.js";
import "./interactive-icon.component.esm.min.js";
import { i as d } from "./if-defined-123567fc.js";
import "./dom.utils.esm.min.js";
import "./_commonjsHelpers-82e0321a.js";
import { g as o, l, i as h, d as m } from "./date-picker.utils-7bc542a5.js";
import "./grid-cell.component.esm.min.js";
import "./header-cell.component.esm.min.js";
import "./picker-header.component.esm.min.js";
import "./picker-grid.component.esm.min.js";
import "./date-picker-day-picker.component.esm.min.js";
import "./date-picker-month-picker.component.esm.min.js";
import "./date-picker-year-picker.component.esm.min.js";
var p;
!function (e) {
  e.Day = "day", e.Month = "month", e.Year = "year";
}(p || (p = {}));
let D = class extends s {
  constructor() {
    super(...arguments), this.closeOnDateSelected = !1, this.currentDate = new Date(), this.currentPicker = p.Day, this.disabledDates = [], this.disabledMonths = [], this.disabledYears = [], this.disabledDaysOfWeek = [], this.locale = o(), this.max = null, this.min = null, this.selectedDate = null;
  }
  static get styles() {
    return [n];
  }
  get _currentDateDT() {
    return l.DateTime.fromJSDate(this.currentDate);
  }
  get _currentDecadeDT() {
    return this._currentDateDT.minus({
      years: this._currentDateDT.year % 10
    }).startOf("year");
  }
  get _maxDateDT() {
    return this.max ? l.DateTime.fromJSDate(this.max) : void 0;
  }
  get maxDate() {
    return this.max;
  }
  set maxDate(e) {
    console.warn("Deprecated property maxDate used. Use max instead."), this.max = e;
  }
  get minDate() {
    return this.min;
  }
  set minDate(e) {
    console.warn("Deprecated property minDate used. Use min instead."), this.min = e;
  }
  get _minDateDT() {
    return this.min ? l.DateTime.fromJSDate(this.min) : void 0;
  }
  get _selectedDateDT() {
    return this.selectedDate ? l.DateTime.fromJSDate(this.selectedDate) : void 0;
  }
  emitDatePickerCurrentDateChangedEvent(e) {
    this.dispatchEvent(new CustomEvent("date-picker-current-date-changed", {
      bubbles: !0,
      composed: !0,
      detail: e
    }));
  }
  emitDatePickerDateSelectedEvent(e) {
    this.dispatchEvent(new CustomEvent("datePickerDateSelected", {
      bubbles: !0,
      composed: !0,
      detail: e
    })), this.dispatchEvent(new CustomEvent("date-picker-date-selected", {
      bubbles: !0,
      composed: !0,
      detail: e
    }));
  }
  _handleDayPickerDaySelected({
    detail: e
  }) {
    this.selectedDate = e.value, this.currentDate = e.value, this.emitDatePickerDateSelectedEvent({
      value: e.value
    });
  }
  _handleDayPickerMonthSelected({
    detail: e
  }) {
    this.currentDate = e.value, this.currentPicker = p.Month, this.emitDatePickerCurrentDateChangedEvent({
      currentDate: e.value,
      currentPicker: p.Month
    });
  }
  _handleDayPickerNextPreviousSelected({
    detail: e
  }) {
    this.currentDate = e.startOfMonth, this.currentPicker = p.Day, this.emitDatePickerCurrentDateChangedEvent({
      currentDate: e.startOfMonth,
      currentPicker: p.Day
    });
  }
  _handleMonthPickerMonthSelected({
    detail: e
  }) {
    this.currentDate = e.value, this.currentPicker = p.Day, this.emitDatePickerCurrentDateChangedEvent({
      currentDate: e.value,
      currentPicker: p.Day
    });
  }
  _handleMonthPickerYearSelected({
    detail: e
  }) {
    this.currentDate = e.value, this.currentPicker = p.Year, this.emitDatePickerCurrentDateChangedEvent({
      currentDate: e.value,
      currentPicker: p.Year
    });
  }
  _handleYearPickerYearSelected({
    detail: e
  }) {
    this.currentDate = e.value, this.currentPicker = p.Month, this.emitDatePickerCurrentDateChangedEvent({
      currentDate: e.value,
      currentPicker: p.Month
    });
  }
  get _pickerTemplates() {
    var e;
    switch (this.currentPicker) {
      case p.Day:
        return r`<zui-date-picker-day-picker .disabledDates="${this.disabledDates}" .disabledMonths="${this.disabledMonths}" .disabledYears="${this.disabledYears}" current-date="${this._currentDateDT}" disabled-days-of-week="${this.disabledDaysOfWeek.join(",")}" locale="${this.locale}" max="${d(this._maxDateDT)}" min="${d(this._minDateDT)}" selected-date="${d(this._selectedDateDT)}" week-start="${d(null !== (e = this.weekStart) && void 0 !== e ? e : void 0)}" @day-picker-day-selected="${this._handleDayPickerDaySelected}" @day-picker-month-selected="${this._handleDayPickerMonthSelected}" @day-picker-next-month-selected="${this._handleDayPickerNextPreviousSelected}" @day-picker-previous-month-selected="${this._handleDayPickerNextPreviousSelected}"></zui-date-picker-day-picker>`;
      case p.Month:
        return r`<zui-date-picker-month-picker .disabledMonths="${this.disabledMonths}" .disabledYears="${this.disabledYears}" current-year="${this._currentDateDT}" locale="${this.locale}" max="${d(this._maxDateDT)}" min="${d(this._minDateDT)}" selected-month="${d(this._selectedDateDT)}" @month-picker-month-selected="${this._handleMonthPickerMonthSelected}" @month-picker-year-selected="${this._handleMonthPickerYearSelected}"></zui-date-picker-month-picker>`;
      case p.Year:
        return r`<zui-date-picker-year-picker .disabledYears="${this.disabledYears}" current-decade="${this._currentDecadeDT}" decade-select-disabled locale="${this.locale}" max="${d(this._maxDateDT)}" min="${d(this._minDateDT)}" selected-year="${d(this._selectedDateDT)}" @year-picker-year-selected="${this._handleYearPickerYearSelected}"></zui-date-picker-year-picker>`;
      default:
        return t;
    }
  }
  render() {
    return r`${this._pickerTemplates}`;
  }
};
e([a({
  reflect: !0,
  type: Boolean,
  attribute: "close-on-date-selected"
})], D.prototype, "closeOnDateSelected", void 0), e([a({
  reflect: !0,
  type: String,
  attribute: "current-date",
  converter: h
})], D.prototype, "currentDate", void 0), e([a({
  reflect: !0,
  type: String,
  attribute: "current-picker"
})], D.prototype, "currentPicker", void 0), e([a({
  type: Array,
  attribute: !1
})], D.prototype, "disabledDates", void 0), e([a({
  type: Array,
  attribute: !1
})], D.prototype, "disabledMonths", void 0), e([a({
  type: Array,
  attribute: !1
})], D.prototype, "disabledYears", void 0), e([a({
  reflect: !0,
  type: String,
  attribute: "disabled-days-of-week",
  converter: m
})], D.prototype, "disabledDaysOfWeek", void 0), e([a({
  reflect: !0,
  type: String
})], D.prototype, "locale", void 0), e([a({
  reflect: !0,
  type: String,
  converter: h
})], D.prototype, "max", void 0), e([a({
  reflect: !0,
  type: String,
  attribute: "max-date",
  converter: h
})], D.prototype, "maxDate", null), e([a({
  reflect: !0,
  type: String,
  converter: h
})], D.prototype, "min", void 0), e([a({
  reflect: !0,
  type: String,
  attribute: "min-date",
  converter: h
})], D.prototype, "minDate", null), e([a({
  reflect: !0,
  type: String,
  attribute: "selected-date",
  converter: h
})], D.prototype, "selectedDate", void 0), e([a({
  reflect: !0,
  type: String,
  attribute: "week-start"
})], D.prototype, "weekStart", void 0), e([c()], D.prototype, "emitDatePickerCurrentDateChangedEvent", null), e([c()], D.prototype, "emitDatePickerDateSelectedEvent", null), D = e([i("zui-date-picker")], D);
export { D as DatePicker };
