import { _ as e } from "./tslib.es6-7f1b1220.js";
import { c as t, u as i, h as n } from "./lit-element-161a1699.js";
import { p as o, c as s } from "./decorators-7e9cd43b.js";
import { BaseElement as l } from "./BaseElement.esm.min.js";
import { h as a } from "./host.styles-cf05db22.js";
import { e as d } from "./event.decorator-98549cbc.js";
import "./interactive-icon.component.esm.min.js";
import "./types-814d68fb.js";
import "./pagination-dot-bar.component.esm.min.js";
import "./pagination-dot.component.esm.min.js";
const r = t`${i(":host {\n  --zui-multi-item-slider-pagination-animation-duration: 400ms;\n  display: inline-flex;\n  flex-direction: row;\n}\n\n.pages {\n  --zui-pagination-dot-transition-timing: var(--zui-multi-item-slider-pagination-animation-duration);\n  margin: 0 16px;\n}")}`;
let m = class extends l {
  constructor() {
    super(...arguments), this.itemCount = 1, this.selectedItemIndex = 0;
  }
  emitMultiItemSliderPageSelected(e) {
    this.dispatchEvent(new CustomEvent("multi-item-slider-pagination-page-selected", {
      bubbles: !0,
      composed: !0,
      detail: {
        value: e
      }
    }));
  }
  _handlePreviousClicked() {
    this.selectedItemIndex--, this.emitMultiItemSliderPageSelected(this.selectedItemIndex);
  }
  _handleNextClicked() {
    this.selectedItemIndex++, this.emitMultiItemSliderPageSelected(this.selectedItemIndex);
  }
  _handleDotSelected({
    detail: {
      value: e
    }
  }) {
    this.selectedItemIndex = e, this.emitMultiItemSliderPageSelected(this.selectedItemIndex);
  }
  render() {
    return n`<zui-interactive-icon ?disabled="${0 === this.selectedItemIndex}" @click="${this._handlePreviousClicked}"><zui-icon-arrow-outline-arrow-outline-visually-centered-left></zui-icon-arrow-outline-arrow-outline-visually-centered-left></zui-interactive-icon><zui-pagination-dot-bar class="pages" item-count="${this.itemCount}" selected-item-index="${this.selectedItemIndex}" @pagination-dot-bar-pagination-dot-selected="${this._handleDotSelected}"></zui-pagination-dot-bar><zui-interactive-icon ?disabled="${this.selectedItemIndex === this.itemCount - 1}" @click="${this._handleNextClicked}"><zui-icon-arrow-outline-arrow-outline-visually-centered-right></zui-icon-arrow-outline-arrow-outline-visually-centered-right></zui-interactive-icon>`;
  }
};
m.styles = [a, r], e([o({
  reflect: !0,
  attribute: "item-count",
  type: Number
})], m.prototype, "itemCount", void 0), e([o({
  reflect: !0,
  attribute: "selected-item-index",
  type: Number
})], m.prototype, "selectedItemIndex", void 0), e([d()], m.prototype, "emitMultiItemSliderPageSelected", null), m = e([s("zui-multi-item-slider-pagination")], m);
export { m as MultiItemSliderPagination };
