import { _ as t } from "./tslib.es6-7f1b1220.js";
import { c as i, u as e, h as n } from "./lit-element-161a1699.js";
import { p as s, c as r } from "./decorators-7e9cd43b.js";
import { BaseElement as o } from "./BaseElement.esm.min.js";
import { h } from "./host.styles-cf05db22.js";
const a = i`${e("hr {\n  margin: var(--zui-horizontal-rule-margin, revert);\n  border: none;\n}\n\n:host([size=s]) hr,\n:host([size=s][orientation=horizontal]) hr {\n  height: var(--zui-line-strength-thin);\n}\n\n:host([size=l]) hr,\n:host([size=l][orientation=horizontal]) hr {\n  height: var(--zui-line-strength-thick);\n}\n\n:host([size=s][emphasis=moderate]) hr,\n:host([size=s][emphasis=default]) hr {\n  background-color: var(--zui-color-thin-line-default);\n}\n\n:host([size=s][emphasis=highlight]) hr {\n  background-color: var(--zui-color-thin-line-highlight);\n}\n\n:host([size=l][emphasis=moderate]) hr,\n:host([size=l][emphasis=default]) hr {\n  background-color: var(--zui-color-thick-line-default);\n}\n\n:host([size=l][emphasis=highlight]) hr {\n  background-color: var(--zui-color-thick-line-highlight);\n}\n\n:host([orientation=vertical]) {\n  height: 100%;\n}\n\n:host([size=s][orientation=vertical]) {\n  width: var(--zui-line-strength-thin);\n}\n\n:host([size=l][orientation=vertical]) {\n  width: var(--zui-line-strength-thick);\n}\n\n:host([size=s][orientation=vertical]) hr {\n  width: 100%;\n  height: 100%;\n}\n\n:host([size=l][orientation=vertical]) hr {\n  width: 100%;\n  height: 100%;\n}")}`;
let l = class extends o {
  constructor() {
    super(...arguments), this.size = "s", this.emphasis = "default", this.orientation = "horizontal";
  }
  static get styles() {
    return [h, a];
  }
  update(t) {
    super.update(t), t.has("emphasis") && "moderate" === this.emphasis && console.warn(`Deprecated emphasis: ${this.emphasis} was used on zui-divider.`);
  }
  render() {
    return n`<hr>`;
  }
};
t([s({
  reflect: !0,
  type: String
})], l.prototype, "size", void 0), t([s({
  reflect: !0,
  type: String
})], l.prototype, "emphasis", void 0), t([s({
  reflect: !0,
  type: String
})], l.prototype, "orientation", void 0), l = t([r("zui-divider")], l);
export { l as Divider };
