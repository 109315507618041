var e = "undefined" != typeof globalThis ? globalThis : "undefined" != typeof window ? window : "undefined" != typeof global ? global : "undefined" != typeof self ? self : {};
function n(e) {
  return e && e.__esModule && Object.prototype.hasOwnProperty.call(e, "default") ? e.default : e;
}
function o(e, n, o) {
  return e(o = {
    path: n,
    exports: {},
    require: function (e, n) {
      return r(null == n && o.path);
    }
  }, o.exports), o.exports;
}
function r() {
  throw new Error("Dynamic requires are not currently supported by @rollup/plugin-commonjs");
}
export { e as a, r as b, o as c, n as g };
