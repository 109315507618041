import { a as e, _ as t } from "./tslib.es6-7f1b1220.js";
import { c as i, u as a, h as r, n as s } from "./lit-element-161a1699.js";
import { p as n, a as l, s as d, c as o } from "./decorators-7e9cd43b.js";
import "./class-map-31af68b2.js";
import { RealBaseElement as p } from "./BaseElement.esm.min.js";
import { h as c } from "./host.styles-cf05db22.js";
import { e as h } from "./event.decorator-98549cbc.js";
import "./interactive-icon.component.esm.min.js";
import { i as u } from "./if-defined-123567fc.js";
import "./types-814d68fb.js";
import { isDefined as m } from "./component.utils.esm.min.js";
import "./mixin.utils.esm.min.js";
import { F as v } from "./form-data-handling.mixin-3ddec2ca.js";
import "./dom.utils.esm.min.js";
import { F as D } from "./form-validation.mixin-0a6c3c34.js";
import "./delegate-focus.mixin-95600d1f.js";
import "./style-map-a37d7105.js";
import "./_commonjsHelpers-82e0321a.js";
import { g as _, l as f, f as E, s as y, h as k, j as g, k as b, d as T, i as P } from "./date-picker.utils-7bc542a5.js";
import "./grid-cell.component.esm.min.js";
import "./header-cell.component.esm.min.js";
import "./picker-header.component.esm.min.js";
import "./picker-grid.component.esm.min.js";
import "./date-picker-day-picker.component.esm.min.js";
import "./date-picker-month-picker.component.esm.min.js";
import "./date-picker-year-picker.component.esm.min.js";
import "./date-picker.component.esm.min.js";
import { DatePickerInput as w } from "./date-picker-input.component.esm.min.js";
import "./date-picker-input-part.component-63342ded.js";
import { generateUid as S } from "./portal.utils.esm.min.js";
import { u as I } from "./until-a96d9cd3.js";
import "./overlay.directive.esm.min.js";
import "./popover.component.esm.min.js";
import "./time-picker.utils-e0fd0976.js";
import "./interactive-text.component.esm.min.js";
import "./time-picker-day-time-toggle.component.esm.min.js";
import "./time-picker-cell.component.esm.min.js";
import "./time-picker.component.esm.min.js";
import "./time-picker-input-part-1e800973.js";
import "./time-picker-input-part-day-time.component.esm.min.js";
import "./time-picker-input-part-number.component.esm.min.js";
import "./time-picker-input.component.esm.min.js";
import "./tooltip.component.esm.min.js";
var R, x;
!function (e) {
  e.Day = "day", e.Month = "month", e.Year = "year";
}(x || (x = {}));
const C = i`${a(":host {\n  --zui-textfield-date-picker-input-width: 120px;\n  --zui-textfield-date-picker-day-placeholder-width: 20px;\n  --zui-textfield-date-picker-month-placeholder-width: 24px;\n  --zui-textfield-date-picker-year-placeholder-width: 30px;\n  display: flex;\n  flex: 1 1 auto;\n  position: relative;\n}\n\nzui-date-picker-input {\n  --zui-date-picker-input-day-placeholder-width: var(--zui-textfield-date-picker-day-placeholder-width);\n  --zui-date-picker-input-month-placeholder-width: var(--zui-textfield-date-picker-month-placeholder-width);\n  --zui-date-picker-input-width: var(--zui-textfield-date-picker-input-width);\n  --zui-date-picker-input-year-placeholder-width: var(--zui-textfield-date-picker-year-placeholder-width);\n}")}`;
let j = R = class extends D(v(p)) {
  constructor() {
    var t, i, a, r;
    super(), this.closeOnDateSelected = !1, this.disabledDates = [], this.disabledMonths = [], this.disabledYears = [], this.disabledDaysOfWeek = [], this.level = 1e3, this.locale = _(), this.max = null, this.min = null, this.placeholderDay = "DD", this.placeholderMonth = "MM", this.placeholderYear = "YYYY", this.parentSelector = null, this.required = !1, this.showCalendar = !1, this._currentPicker = x.Day, this._internalDateTimeValue = {
      day: null,
      month: null,
      year: null
    }, this._value = null, this._datePickerPortal = `date-picker-overlay-${S()}`, this._datePickerWarningMessagePortal = `date-picker-warning-message-${S()}`, this._textfieldDatePickerResizeObserver = new ResizeObserver(() => e(this, void 0, void 0, function* () {
      requestAnimationFrame(() => {
        var e, t;
        null === (e = this._textfieldDatePickerPopoverOverlayRef) || void 0 === e || e.forcePositioning(), null === (t = this._textfieldDatePickerWarningMessageOverlayRef) || void 0 === t || t.forcePositioning();
      });
    })), this._disabledDateValidator = () => !this._valueDT || !this._disabledDateConditions.some(e => e(this._valueDT)), this._invalidDateValidator = () => !this.value || f.DateTime.fromJSDate(this.value).isValid, this._rangeOverflowValidator = () => !this._valueDT || !this._maxDateDT || !(this._valueDT.toMillis() > this._maxDateDT.toMillis()), this._rangeUnderflowValidator = () => !this._valueDT || !this._minDateDT || !(this._valueDT.toMillis() < this._minDateDT.toMillis()), this._requiredDateValidator = () => !this.required || m(this.value), this._offset = () => [0, 8], this._datePickerPositionReference = () => {
      var e;
      return null !== (e = this._datePickerInputRef) && void 0 !== e ? e : this;
    }, this._handleOutsideClick = e => {
      if (!this.showCalendar) return;
      const t = this.isSameNode(e.target) && e.composedPath().some(e => e instanceof w),
        i = e.target.getAttribute("name") === this._datePickerPortal;
      t || i || (this.showCalendar = !1);
    }, this.addValidator({
      type: "badInput",
      validator: this._invalidDateValidator
    }), this.addValidator({
      type: "customError",
      validator: this._disabledDateValidator,
      validatesOnProperties: ["disabledDates", "disabledMonths", "disabledYears", "disabledDaysOfWeek"]
    }), this.addValidator({
      type: "rangeOverflow",
      validator: this._rangeOverflowValidator,
      validatesOnProperties: ["max"]
    }), this.addValidator({
      type: "rangeUnderflow",
      validator: this._rangeUnderflowValidator,
      validatesOnProperties: ["min"]
    }), this.addValidator({
      type: "valueMissing",
      validator: this._requiredDateValidator,
      validatesOnProperties: ["required"]
    }), this.setDefaultValidityMessages({
      badInput: null !== (t = this.warningInvalid) && void 0 !== t ? t : R.TEXTFIELD_DATE_PICKER_INVALID_ERROR_MESSAGE,
      customError: null !== (i = this.warningDisabled) && void 0 !== i ? i : R.TEXTFIELD_DATE_PICKER_DISABLED_ERROR_MESSAGE,
      rangeOverflow: null !== (a = this.warningDisabled) && void 0 !== a ? a : R.TEXTFIELD_DATE_PICKER_DISABLED_ERROR_MESSAGE,
      rangeUnderflow: null !== (r = this.warningDisabled) && void 0 !== r ? r : R.TEXTFIELD_DATE_PICKER_DISABLED_ERROR_MESSAGE,
      valueMissing: R.TEXTFIELD_DATE_PICKER_REQUIRED_ERROR_MESSAGE
    });
  }
  static get styles() {
    return [c, C];
  }
  get _disabledDatesDT() {
    return E(this.disabledDates);
  }
  get _disabledMonthsDT() {
    return E(this.disabledMonths);
  }
  get _disabledYearsDT() {
    return E(this.disabledYears);
  }
  get _maxDateDT() {
    return this.max ? f.DateTime.fromJSDate(this.max) : void 0;
  }
  get maxDate() {
    return this.max;
  }
  set maxDate(e) {
    console.warn("Deprecated property maxDate used. Use max instead."), this.max = e;
  }
  get _minDateDT() {
    return this.min ? f.DateTime.fromJSDate(this.min) : void 0;
  }
  get minDate() {
    return this.min;
  }
  set minDate(e) {
    console.warn("Deprecated property minDate used. Use min instead."), this.min = e;
  }
  get value() {
    return this._value;
  }
  set value(e) {
    var t;
    const i = this._value;
    this._value = e, this._currentDate = null !== (t = this._value) && void 0 !== t ? t : new Date(), this.requestUpdate("value", i);
  }
  get _valueDT() {
    return this.value && f.DateTime.fromJSDate(this.value).isValid ? f.DateTime.fromJSDate(this.value) : void 0;
  }
  emitTextfieldDatePickerDateSelectedEvent(e) {
    this.dispatchEvent(new CustomEvent("textfieldDatePickerDateSelected", {
      bubbles: !0,
      composed: !0,
      detail: e
    })), this.dispatchEvent(new CustomEvent("textfield-date-picker-date-selected", {
      bubbles: !0,
      composed: !0,
      detail: e
    }));
  }
  emitInputEvent() {
    this.dispatchEvent(new Event("input", {
      bubbles: !0,
      composed: !0
    }));
  }
  emitChangeEvent() {
    this.dispatchEvent(new Event("change", {
      bubbles: !0,
      composed: !1
    }));
  }
  get _currentDateDT() {
    return f.DateTime.fromJSDate(this._currentDate);
  }
  get _disabledDateConditions() {
    return [e => y(e, this._disabledDatesDT), e => k(e, this._disabledMonthsDT), e => g(e, this._disabledYearsDT), e => b(e, this.disabledDaysOfWeek)];
  }
  connectedCallback() {
    super.connectedCallback(), this.addEventListener("textfield-date-picker-date-selected", this._handleDatePickerDateSelectedEvent), window.addEventListener("click", this._handleOutsideClick);
  }
  disconnectedCallback() {
    [this, this._datePickerInputRef].filter(e => null !== e).forEach(e => this._textfieldDatePickerResizeObserver.unobserve(e)), this.removeEventListener("textfield-date-picker-date-selected", this._handleDatePickerDateSelectedEvent), window.removeEventListener("click", this._handleOutsideClick), super.disconnectedCallback();
  }
  formResetCallback() {
    super.formResetCallback(), this._internalDateTimeValue = {
      day: null,
      month: null,
      year: null
    };
  }
  _getDelayedInvalidWarningOrNothing() {
    return e(this, void 0, void 0, function* () {
      return this._showInvalidWarning ? (yield new Promise(e => setTimeout(() => {
        this._showInvalidWarning && e();
      }, R.TEXTFIELD_DATE_PICKER_INVALID_WARNING_DELAY)), r`<zui-overlay-directive .offsetHandler="${this._offset}" .placements="${R.TEXTFIELD_DATE_PICKER_WARNING_MESSAGE_PLACEMENTS}" .positionReferenceCallback="${this._datePickerPositionReference}" id="textfield-date-picker-warning-message" level="${this.level}" portal="${this._datePickerWarningMessagePortal}"><zui-tooltip emphasis="warning">${this.validationMessage}</zui-tooltip></zui-overlay-directive>`) : s;
    });
  }
  get _inputPartDayValue() {
    var e, t, i;
    return null !== (i = null !== (t = null === (e = this._valueDT) || void 0 === e ? void 0 : e.day) && void 0 !== t ? t : this._internalDateTimeValue.day) && void 0 !== i ? i : void 0;
  }
  get _inputPartMonthValue() {
    var e, t, i;
    return null !== (i = null !== (t = null === (e = this._valueDT) || void 0 === e ? void 0 : e.month) && void 0 !== t ? t : this._internalDateTimeValue.month) && void 0 !== i ? i : void 0;
  }
  get _inputPartYearValue() {
    var e, t, i;
    return null !== (i = null !== (t = null === (e = this._valueDT) || void 0 === e ? void 0 : e.year) && void 0 !== t ? t : this._internalDateTimeValue.year) && void 0 !== i ? i : void 0;
  }
  get _showInvalidWarning() {
    return this.willValidate && this.invalid && !this.showCalendar;
  }
  _handleDatePickerInputCalendarSelected() {
    this.showCalendar = !this.showCalendar;
  }
  _handleDatePickerInputChanged({
    detail: e
  }) {
    const t = this.value;
    this.value = e.value, void 0 !== e.error && (this._internalDateTimeValue = Object.assign({}, e.error)), null === this._value && null === t || (this.emitTextfieldDatePickerDateSelectedEvent({
      value: this.value
    }), this.emitInputEvent());
  }
  _handleDatePickerCurrentDateChanged({
    detail: e
  }) {
    this._currentDate = e.currentDate, this._currentPicker = e.currentPicker;
  }
  _handleDatePickerInputFocused() {
    this.showCalendar = !1;
  }
  _handleDatePickerDateSelected({
    detail: e
  }) {
    this.value = e.value, this.showCalendar = !this.closeOnDateSelected, this.emitTextfieldDatePickerDateSelectedEvent({
      value: e.value
    }), this.emitInputEvent();
  }
  _handleDatePickerDateSelectedEvent() {
    this.emitChangeEvent();
  }
  firstUpdated(t) {
    const i = Object.create(null, {
      firstUpdated: {
        get: () => super.firstUpdated
      }
    });
    return e(this, void 0, void 0, function* () {
      i.firstUpdated.call(this, t), [this, this._datePickerInputRef].filter(e => null !== e).forEach(e => this._textfieldDatePickerResizeObserver.observe(e));
    });
  }
  updated(e) {
    super.updated(e), e.has("showCalendar") && (this.showCalendar || (this._currentPicker = x.Day)), (e.has("warningDisabled") || e.has("warningInvalid")) && console.warn("Deprecated warning messages were used. Use setValidityMessages() instead."), e.has("parentSelector") && this.parentSelector && console.warn("Deprecated parent-selector use detected. The internal overlay is now used and it is not needed anymore.");
  }
  render() {
    var e;
    return r`<zui-date-picker-input ?calendar-opened="${this.showCalendar}" ?disabled="${this.disabled}" ?invalid="${this.willValidate && this.invalid}" ?readonly="${this.readonly}" input-part-day-value="${u(this._inputPartDayValue)}" input-part-month-value="${u(this._inputPartMonthValue)}" input-part-year-value="${u(this._inputPartYearValue)}" locale="${this.locale}" placeholder-day="${this.placeholderDay}" placeholder-month="${this.placeholderMonth}" placeholder-year="${this.placeholderYear}" selected-date="${u(this._valueDT)}" @date-picker-input-calendar-selected="${this._handleDatePickerInputCalendarSelected}" @date-picker-input-changed="${this._handleDatePickerInputChanged}" @date-picker-input-focused="${this._handleDatePickerInputFocused}"></zui-date-picker-input>${I(this._getDelayedInvalidWarningOrNothing())} ${this.showCalendar ? r`<zui-overlay-directive .offsetHandler="${this._offset}" .placements="${R.TEXTFIELD_DATE_PICKER_POPOVER_PLACEMENTS}" .positionReferenceCallback="${this._datePickerPositionReference}" flip id="textfield-date-picker-popover" level="${this.level}" portal="${this._datePickerPortal}"><zui-popover style="width:auto;min-width:auto;padding:32px"><zui-date-picker .disabledDates="${this.disabledDates}" .disabledMonths="${this.disabledMonths}" .disabledYears="${this.disabledYears}" ?close-on-date-selected="${this.closeOnDateSelected}" current-date="${u(this._currentDateDT)}" current-picker="${this._currentPicker}" disabled-days-of-week="${this.disabledDaysOfWeek.join(",")}" locale="${this.locale}" max="${this._maxDateDT}" min="${this._minDateDT}" selected-date="${u(this._valueDT)}" week-start="${u(null !== (e = this.weekStart) && void 0 !== e ? e : void 0)}" @date-picker-date-selected="${this._handleDatePickerDateSelected}" @date-picker-current-date-changed="${this._handleDatePickerCurrentDateChanged}"></zui-date-picker></zui-popover></zui-overlay-directive>` : s}`;
  }
};
j.TEXTFIELD_DATE_PICKER_DISABLED_ERROR_MESSAGE = "This date is not allowed.", j.TEXTFIELD_DATE_PICKER_INVALID_ERROR_MESSAGE = "Please enter a valid date.", j.TEXTFIELD_DATE_PICKER_INVALID_WARNING_DELAY = 500, j.TEXTFIELD_DATE_PICKER_POPOVER_PLACEMENTS = ["bottom-end", "bottom-start"], j.TEXTFIELD_DATE_PICKER_REQUIRED_ERROR_MESSAGE = "A date is required.", j.TEXTFIELD_DATE_PICKER_WARNING_MESSAGE_PLACEMENTS = ["bottom-start"], t([n({
  reflect: !0,
  type: Boolean,
  attribute: "close-on-date-selected"
})], j.prototype, "closeOnDateSelected", void 0), t([n({
  type: Array,
  attribute: !1
})], j.prototype, "disabledDates", void 0), t([n({
  type: Array,
  attribute: !1
})], j.prototype, "disabledMonths", void 0), t([n({
  type: Array,
  attribute: !1
})], j.prototype, "disabledYears", void 0), t([n({
  reflect: !0,
  type: String,
  attribute: "disabled-days-of-week",
  converter: T
})], j.prototype, "disabledDaysOfWeek", void 0), t([n({
  reflect: !0,
  type: Number
})], j.prototype, "level", void 0), t([n({
  reflect: !0,
  type: String
})], j.prototype, "locale", void 0), t([n({
  reflect: !0,
  type: String,
  converter: P
})], j.prototype, "max", void 0), t([n({
  reflect: !0,
  type: String,
  attribute: "max-date",
  converter: P
})], j.prototype, "maxDate", null), t([n({
  reflect: !0,
  type: String,
  converter: P
})], j.prototype, "min", void 0), t([n({
  reflect: !0,
  type: String,
  attribute: "min-date",
  converter: P
})], j.prototype, "minDate", null), t([n({
  reflect: !0,
  type: String,
  attribute: "placeholder-day"
})], j.prototype, "placeholderDay", void 0), t([n({
  reflect: !0,
  type: String,
  attribute: "placeholder-month"
})], j.prototype, "placeholderMonth", void 0), t([n({
  reflect: !0,
  type: String,
  attribute: "placeholder-year"
})], j.prototype, "placeholderYear", void 0), t([n({
  reflect: !0,
  type: String,
  attribute: "parent-selector"
})], j.prototype, "parentSelector", void 0), t([n({
  reflect: !0,
  type: Boolean
})], j.prototype, "required", void 0), t([n({
  reflect: !0,
  type: Boolean,
  attribute: "show-calendar"
})], j.prototype, "showCalendar", void 0), t([n({
  reflect: !0,
  type: String,
  converter: P
})], j.prototype, "value", null), t([n({
  reflect: !0,
  type: String,
  attribute: "warning-disabled"
})], j.prototype, "warningDisabled", void 0), t([n({
  reflect: !0,
  type: String,
  attribute: "warning-invalid"
})], j.prototype, "warningInvalid", void 0), t([n({
  reflect: !0,
  type: String,
  attribute: "week-start"
})], j.prototype, "weekStart", void 0), t([h()], j.prototype, "emitTextfieldDatePickerDateSelectedEvent", null), t([h()], j.prototype, "emitInputEvent", null), t([h()], j.prototype, "emitChangeEvent", null), t([l("zui-overlay-directive#textfield-date-picker-popover")], j.prototype, "_textfieldDatePickerPopoverOverlayRef", void 0), t([l("zui-overlay-directive#textfield-date-picker-warning-message")], j.prototype, "_textfieldDatePickerWarningMessageOverlayRef", void 0), t([d()], j.prototype, "_currentPicker", void 0), t([d()], j.prototype, "_internalDateTimeValue", void 0), t([l("zui-date-picker-input")], j.prototype, "_datePickerInputRef", void 0), j = R = t([o("zui-textfield-date-picker")], j);
export { j as TextfieldDatePicker };
