const e = e => "touches" in e,
  t = t => {
    const {
      clientX: n,
      clientY: o
    } = e(t) ? t.touches[0] : t;
    return {
      clientX: n,
      clientY: o
    };
  };
function n(e, t, n = 1, o = t => t.tagName === e.tagName) {
  const i = e => Array.from({
    length: n
  }).reduce(e => e ? (e => "previous" === t ? e.previousElementSibling : e.nextElementSibling)(e) : null, e);
  let c = e;
  do {
    c = i(c);
  } while (c && !1 === o(c));
  return c;
}
function o(e, t) {
  const n = e.getAttribute(t);
  return "" === n || n === t;
}
const i = (e, t) => (t.compareDocumentPosition(e) & Node.DOCUMENT_POSITION_PRECEDING) === Node.DOCUMENT_POSITION_PRECEDING,
  c = (e, t) => (t.compareDocumentPosition(e) & Node.DOCUMENT_POSITION_FOLLOWING) === Node.DOCUMENT_POSITION_FOLLOWING,
  r = (e, t = !1) => e.assignedNodes().reduce((e, n) => e + (t && "outerHTML" in n ? n.outerHTML : n.textContent), ""),
  N = e => e.scrollWidth > e.offsetWidth;
export { t as getCursorPosition, r as getProjectedText, N as hasOverflow, o as isBooleanDOMAttributeSet, c as isElementAfterOther, i as isElementBeforeOther, e as isTouchEvent, n as traverseDOMSiblingsByStepAndDirection };
