import { _ as e } from "./tslib.es6-7f1b1220.js";
import "./lit-element-161a1699.js";
import { p as s } from "./decorators-7e9cd43b.js";
import { BaseElement as t } from "./BaseElement.esm.min.js";
class o extends t {
  constructor() {
    super(...arguments), this.isIconDeprecated = !0, this.isIcon = !0;
  }
  update(e) {
    super.update(e), e.has("size") && (o.VALID_ICON_SIZES.includes(this.size) || console.warn(`Deprecated/unsupported size: ${this.size} was used on ${this.tagName.toLowerCase()}! Use string-based-unit (e.g. s16...) instead.`));
  }
}
o.MAP_ICON_SIZE_TO_DEPRECATED = {
  s8: "xs",
  s12: "s",
  s16: "m",
  s24: "l",
  s32: "xl",
  s48: "2xl",
  s64: "3xl",
  s72: "3xl",
  s80: "4xl"
}, o.VALID_ICON_SIZES = Object.keys(o.MAP_ICON_SIZE_TO_DEPRECATED), e([s({
  reflect: !0,
  attribute: "zuiIcon",
  type: Boolean
})], o.prototype, "isIconDeprecated", void 0), e([s({
  reflect: !0,
  attribute: "zui-icon",
  type: Boolean
})], o.prototype, "isIcon", void 0), e([s({
  reflect: !0,
  attribute: "size",
  type: String
})], o.prototype, "size", void 0);
export { o as IconBaseElement };
