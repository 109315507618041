import { _ as e } from "./tslib.es6-7f1b1220.js";
import { c as t, u as a, h as s } from "./lit-element-161a1699.js";
import { p as n, q as i, c as o } from "./decorators-7e9cd43b.js";
import { BaseElement as r } from "./BaseElement.esm.min.js";
import { h as m } from "./host.styles-cf05db22.js";
import { e as u } from "./event.decorator-98549cbc.js";
import "./types-814d68fb.js";
import "./menu-tab-icon-item.component.esm.min.js";
import "./menu-tab-text-item.component.esm.min.js";
const l = t`${a(":host {\n  display: flex;\n  flex: 1 1 0;\n  justify-content: center;\n  max-width: 100%;\n}")}`;
let p = class extends r {
  constructor() {
    super(...arguments), this.size = "m";
  }
  static get styles() {
    return [m, l];
  }
  emitMenuTabBarSelectionChangedEvent(e) {
    this.dispatchEvent(new CustomEvent("menu-tab-bar-selection-changed", {
      bubbles: !0,
      composed: !0,
      detail: e
    }));
  }
  _propagateMenuTabBarSize() {
    this._assignedMenuTabTextItems.forEach(e => e.size = this.size);
  }
  _propagateMenuTabBarValue() {
    this.value && (this._assignedMenuTabIconItems.forEach(e => {
      e.emphasis = e.value === this.value ? "selected" : "default";
    }), this._assignedMenuTabTextItems.forEach(e => {
      e.emphasis = e.value === this.value ? "selected" : "default";
    }));
  }
  _handleMenuTabItemSelected({
    target: e
  }) {
    this.value = e.value, this.emitMenuTabBarSelectionChangedEvent({
      value: e.value
    });
  }
  _handleMenuTabItemSlotchange() {
    this._propagateMenuTabBarSize(), this._propagateMenuTabBarValue();
  }
  updated(e) {
    e.has("size") && this._propagateMenuTabBarSize(), e.has("value") && this._propagateMenuTabBarValue();
  }
  render() {
    return s`<slot @click="${this._handleMenuTabItemSelected}" @slotchange="${this._handleMenuTabItemSlotchange}"></slot>`;
  }
};
e([n({
  reflect: !0,
  type: String
})], p.prototype, "size", void 0), e([n({
  reflect: !0,
  type: String
})], p.prototype, "value", void 0), e([u()], p.prototype, "emitMenuTabBarSelectionChangedEvent", null), e([i("", !0, "zui-menu-tab-icon-item")], p.prototype, "_assignedMenuTabIconItems", void 0), e([i("", !0, "zui-menu-tab-text-item")], p.prototype, "_assignedMenuTabTextItems", void 0), p = e([o("zui-menu-tab-bar")], p);
export { p as MenuTabBar };
