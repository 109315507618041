import { _ as e } from "./tslib.es6-7f1b1220.js";
import { c as t, u as r, h as n } from "./lit-element-161a1699.js";
import { p as i, c as o } from "./decorators-7e9cd43b.js";
import { BaseElement as s } from "./BaseElement.esm.min.js";
import { h as l } from "./host.styles-cf05db22.js";
const a = t`${r(":host {\n  background-color: var(--zui-color-text-box-bg);\n  border-width: var(--zui-line-strength-thin);\n  border-style: solid;\n  border-color: var(--zui-color-thin-line-highlight);\n  border-left: none;\n  border-right: none;\n  box-sizing: border-box;\n  padding: calc(var(--zui-space-extra-small) - var(--zui-line-strength-thin)) var(--zui-space-large);\n  overflow: hidden;\n}\n\n:host([header-alignment=left]),\n:host([force-left-alignment]) {\n  text-align: left !important;\n}\n\n:host([header-alignment=center]) {\n  text-align: center;\n}\n\n::slotted(*) {\n  color: var(--zui-color-text-divider);\n  font: var(--zui-typography-label2);\n  text-transform: uppercase;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}")}`;
let d = class extends s {
  constructor() {
    super(...arguments), this.headerAlignment = "left", this._widthObserver = new ResizeObserver(() => {
      this.offsetWidth > 559 ? this.setAttribute("force-left-alignment", "") : this.removeAttribute("force-left-alignment");
    });
  }
  static get styles() {
    return [l, a];
  }
  firstUpdated(e) {
    super.firstUpdated(e), this._widthObserver.observe(this);
  }
  render() {
    return n`<slot></slot>`;
  }
};
e([i({
  reflect: !0,
  attribute: "header-alignment"
})], d.prototype, "headerAlignment", void 0), d = e([o("zui-section-divider")], d);
export { d as SectionDivider };
