import { _ as t } from "./tslib.es6-7f1b1220.js";
import { c as o, u as i, h as r } from "./lit-element-161a1699.js";
import { p as e, a as n, c as s } from "./decorators-7e9cd43b.js";
import { BaseElement as a } from "./BaseElement.esm.min.js";
import { h as l } from "./host.styles-cf05db22.js";
const c = o`${i(":host {\n  ---zui-tooltip-color-background: var(--zui-color-text-box-bg);\n  ---zui-tooltip-color-border: var(--zui-color-tooltip-border);\n  ---zui-tooltip-color-text: var(--zui-color-text-default);\n  --zui-tooltip-max-width: calc(var(--zui-gu) * 46.5);\n  --zui-tooltip-padding: calc(var(--zui-gu) * 0.25) calc(var(--zui-gu) * 1) calc(var(--zui-gu) * 0.5);\n  background-color: var(---zui-tooltip-color-background);\n  color: var(---zui-tooltip-color-text);\n  border: var(--zui-line-strength-thin) solid var(---zui-tooltip-color-border);\n  box-shadow: var(--zui-box-shadow-default);\n  font: var(--zui-typography-caption);\n  display: block;\n  padding: var(--zui-tooltip-padding);\n  max-width: var(--zui-tooltip-max-width);\n  width: -moz-max-content;\n  width: max-content;\n  cursor: default;\n  pointer-events: none;\n  user-select: none;\n}\n\n:host([zui-internal-no-text]) {\n  --zui-tooltip-padding: calc(var(--zui-gu) * 1);\n}\n\n:host([emphasis=warning]) {\n  ---zui-tooltip-color-background: var(--zui-color-sc-r-110);\n  ---zui-tooltip-color-border: var(--zui-color-sc-r-110);\n  ---zui-tooltip-color-text: var(--zui-color-gs-10);\n}")}`;
let u = class extends a {
  constructor() {
    super(...arguments), this.emphasis = "default", this.message = "", this.isNotText = !1;
  }
  _checkSlotContent() {
    this.isNotText = this._slotRef.assignedElements().length > 0;
  }
  firstUpdated(t) {
    super.firstUpdated(t), this._checkSlotContent();
  }
  render() {
    return r`<slot @slotchange="${this._checkSlotContent}">${this.message}</slot>`;
  }
};
u.styles = [l, c], t([e({
  reflect: !0,
  type: String
})], u.prototype, "emphasis", void 0), t([e({
  reflect: !0,
  type: String
})], u.prototype, "message", void 0), t([e({
  reflect: !0,
  type: Boolean,
  attribute: "zui-internal-no-text"
})], u.prototype, "isNotText", void 0), t([n("slot")], u.prototype, "_slotRef", void 0), u = t([s("zui-tooltip")], u);
export { c as TOOLTIP_STYLES, u as Tooltip };
