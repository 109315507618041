import { _ as t } from "./tslib.es6-7f1b1220.js";
import { h as e } from "./lit-element-161a1699.js";
import { p as r, c as n } from "./decorators-7e9cd43b.js";
import { BaseElement as s } from "./BaseElement.esm.min.js";
var i;
!function (t) {
  t.ItemCount = "itemCount", t.Item = "item", t.Items = "items", t.SelectedCount = "selectedCount", t.Selected = "selected", t.Selection = "selection";
}(i || (i = {}));
let a = class extends s {
  constructor() {
    super(...arguments), this.patternAll = "%selectedCount selected", this.patternMany = "%selectedCount selected", this.patternSingle = "%selected";
  }
  format(t, e, r) {
    if (r) return t.length === e.length ? this.usePattern(this.patternAll, t, e) : e.length > 1 ? this.usePattern(this.patternMany, t, e) : this.usePattern(this.patternSingle, t, e);
    return (e[0] || this.renderRoot).innerHTML;
  }
  extractText(t) {
    return Array.from(t.childNodes).map(({
      textContent: t
    }) => t).join("").trim();
  }
  usePattern(t, e, r) {
    const n = Object.values(i),
      s = new RegExp(`%(${n.join("|")})`, "gi");
    return t.replaceAll(s, (t, n) => {
      switch (n) {
        case i.ItemCount:
          return e.length.toString();
        case i.Item:
          return this.extractText(e[0]);
        case i.Items:
          return e.map(t => this.extractText(t)).join(", ");
        case i.SelectedCount:
          return r.length.toString();
        case i.Selected:
          return this.extractText(r[0]);
        case i.Selection:
          return r.map(t => this.extractText(t)).join(", ");
        default:
          return t;
      }
    });
  }
  render() {
    return e`<slot></slot>`;
  }
};
t([r({
  reflect: !0,
  type: String,
  attribute: "pattern-all"
})], a.prototype, "patternAll", void 0), t([r({
  reflect: !0,
  type: String,
  attribute: "pattern-many"
})], a.prototype, "patternMany", void 0), t([r({
  reflect: !0,
  type: String,
  attribute: "pattern-single"
})], a.prototype, "patternSingle", void 0), a = t([n("zui-select-placeholder")], a);
export { a as SelectPlaceholder };
