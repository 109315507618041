import { _ as e } from "./tslib.es6-7f1b1220.js";
import { c as t, u as i, h as s } from "./lit-element-161a1699.js";
import { p as r, s as a, a as u, c as n } from "./decorators-7e9cd43b.js";
import { c as p } from "./class-map-31af68b2.js";
import { RealBaseElement as l } from "./BaseElement.esm.min.js";
import { h as o } from "./host.styles-cf05db22.js";
import { e as m } from "./event.decorator-98549cbc.js";
import { i as h } from "./if-defined-123567fc.js";
import "./types-814d68fb.js";
import { isDefined as c, cycle as d, numberUndefinedConverter as v } from "./component.utils.esm.min.js";
import { D as f } from "./delegate-focus.mixin-95600d1f.js";
import "./_commonjsHelpers-82e0321a.js";
import "./date-picker.utils-7bc542a5.js";
import { a as b } from "./date-picker-input-part.component-63342ded.js";
import { s as P } from "./time-picker.utils-e0fd0976.js";
import { c as N } from "./time-picker-input-part-1e800973.js";
const _ = t`${i(N)}`,
  k = t`${i("input {\n  width: calc(var(--zui-gu) * 2);\n}\ninput.show-placeholder {\n  width: var(--zui-time-picker-input-part-number-placeholder-width, calc(var(--zui-gu) * 2.5));\n}\n\n:host([readonly]) input::placeholder {\n  color: transparent;\n  opacity: unset;\n}")}`;
let y = class extends f(l) {
  constructor() {
    super(...arguments), this.disabled = !1, this.readonly = !1, this.step = 1, this.showPlaceholder = !0;
  }
  static get styles() {
    return [o, _, k];
  }
  get value() {
    return this._internalValue;
  }
  set value(e) {
    const t = this._internalValue;
    this._internalValue = e, this.showPlaceholder = !c(e), this.requestUpdate("value", t);
  }
  emitTimePickerInputPartFocusNextEvent() {
    this.dispatchEvent(new CustomEvent("time-picker-input-part-focus-next", {
      bubbles: !0,
      composed: !0
    }));
  }
  emitTimePickerInputPartFocusPreviousEvent() {
    this.dispatchEvent(new CustomEvent("time-picker-input-part-focus-previous", {
      bubbles: !0,
      composed: !0
    }));
  }
  emitTimePickerInputPartNumberChangedEvent() {
    var e;
    this.dispatchEvent(new CustomEvent("time-picker-input-part-number-changed", {
      bubbles: !0,
      composed: !0,
      detail: {
        value: null !== (e = this.value) && void 0 !== e ? e : null
      }
    }));
  }
  emitTimePickerInputPartNumberInputEvent() {
    this.dispatchEvent(new CustomEvent("time-picker-input-part-number-input", {
      bubbles: !0,
      composed: !0,
      detail: {
        value: isNaN(this._inputRef.valueAsNumber) ? null : this._inputRef.valueAsNumber
      }
    }));
  }
  get _paddedValue() {
    return c(this.value) ? b(this.value, String(this.max).length) : null;
  }
  get _initialValue() {
    var e;
    return null !== (e = this.value) && void 0 !== e ? e : this.defaultValue;
  }
  _handleTimePickerInputPartNumberFocusEvent() {
    this._inputRef.select();
  }
  _handleTimePickerInputPartNumberInputEvent(e) {
    e.stopPropagation(), this._inputRef.value = P(this._inputRef.value, this.min, this.max), this.showPlaceholder = 0 === this._inputRef.value.length, this.emitTimePickerInputPartNumberInputEvent(), this._inputRef.value.length === String(this.max).length && this.emitTimePickerInputPartFocusNextEvent();
  }
  _handleTimePickerInputPartNumberBluredEvent() {
    const e = this.value;
    if (void 0 === e && isNaN(this._inputRef.valueAsNumber)) return;
    const t = !isNaN(this._inputRef.valueAsNumber) && this._inputRef.valueAsNumber >= this.min && this._inputRef.valueAsNumber <= this.max;
    this.value = t ? this._inputRef.valueAsNumber : void 0, this._inputRef.value = this._paddedValue, e !== this.value && this.emitTimePickerInputPartNumberChangedEvent();
  }
  _handleTimePickerInputPartNumberKeydownEvent(e) {
    var t, i;
    if (!this.readonly) switch (e.key) {
      case "1":
      case "2":
      case "3":
      case "4":
      case "5":
      case "6":
      case "7":
      case "8":
      case "9":
      case "0":
      case "ArrowLeft":
      case "ArrowRight":
      case "Delete":
      case "Tab":
        break;
      case "ArrowUp":
        e.preventDefault(), this._inputRef.value = isNaN(this._inputRef.valueAsNumber) ? String(null !== (t = this._initialValue) && void 0 !== t ? t : this.min) : String(d(this._inputRef.valueAsNumber, this.min, this.max, this.step, "increase")), this.showPlaceholder = 0 === this._inputRef.value.length, this.emitTimePickerInputPartNumberInputEvent();
        break;
      case "ArrowDown":
        e.preventDefault(), this._inputRef.value = isNaN(this._inputRef.valueAsNumber) ? String(null !== (i = this._initialValue) && void 0 !== i ? i : this.max) : String(d(this._inputRef.valueAsNumber, this.min, this.max, this.step, "decrease")), this.showPlaceholder = 0 === this._inputRef.value.length, this.emitTimePickerInputPartNumberInputEvent();
        break;
      case "Backspace":
        "" === this._inputRef.value && (e.preventDefault(), this.emitTimePickerInputPartFocusPreviousEvent());
        break;
      default:
        e.preventDefault();
    }
  }
  render() {
    return s`<input .value="${this._paddedValue}" ?disabled="${this.disabled}" ?readonly="${this.readonly}" ?zuiCaptureFocus="${!this.disabled}" class="${p({
      "show-placeholder": this.showPlaceholder
    })}" max="${this.max}" min="${this.min}" placeholder="${h(this.placeholder)}" step="${this.step}" type="number" @blur="${this._handleTimePickerInputPartNumberBluredEvent}" @focus="${this._handleTimePickerInputPartNumberFocusEvent}" @input="${this._handleTimePickerInputPartNumberInputEvent}" @keydown="${this._handleTimePickerInputPartNumberKeydownEvent}">`;
  }
};
e([r({
  reflect: !0,
  type: Boolean
})], y.prototype, "disabled", void 0), e([r({
  reflect: !0,
  type: Number
})], y.prototype, "max", void 0), e([r({
  reflect: !0,
  type: Number
})], y.prototype, "min", void 0), e([r({
  reflect: !0,
  type: String
})], y.prototype, "placeholder", void 0), e([r({
  reflect: !0,
  type: Boolean
})], y.prototype, "readonly", void 0), e([r({
  reflect: !0,
  type: Number
})], y.prototype, "step", void 0), e([r({
  reflect: !0,
  converter: v
})], y.prototype, "value", null), e([r({
  reflect: !0,
  attribute: "default-value",
  converter: v
})], y.prototype, "defaultValue", void 0), e([a()], y.prototype, "showPlaceholder", void 0), e([m()], y.prototype, "emitTimePickerInputPartFocusNextEvent", null), e([m()], y.prototype, "emitTimePickerInputPartFocusPreviousEvent", null), e([m()], y.prototype, "emitTimePickerInputPartNumberChangedEvent", null), e([m()], y.prototype, "emitTimePickerInputPartNumberInputEvent", null), e([u("input")], y.prototype, "_inputRef", void 0), y = e([n("zui-time-picker-input-part-number")], y);
export { y as TimePickerInputPartNumber };
