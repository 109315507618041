import { _ as n } from "./tslib.es6-7f1b1220.js";
import { c as e, u as t, h as o } from "./lit-element-161a1699.js";
import { p as i, s as a, q as s, c as p } from "./decorators-7e9cd43b.js";
import { c as r } from "./class-map-31af68b2.js";
import { BaseElement as m } from "./BaseElement.esm.min.js";
import { h as l } from "./host.styles-cf05db22.js";
import { e as c } from "./event.decorator-98549cbc.js";
const u = e`${t("@keyframes toggle-bottom {\n  from {\n    opacity: 0;\n    transform: translateY(calc(var(--zui-gu) * -4));\n  }\n  to {\n    opacity: 1;\n    transform: translateY(0);\n  }\n}\n@keyframes toggle-top {\n  from {\n    opacity: 0;\n    transform: translateY(calc(var(--zui-gu) * 4));\n  }\n  to {\n    opacity: 1;\n    transform: translateY(0);\n  }\n}\n:host {\n  --zui-select-menu-animation-duration: var(--zui-select-animation-duration, 0.1s);\n  --zui-select-menu-overflow: calc(var(--zui-gu) * 3);\n}\n\n#clipper {\n  clip-path: inset(0px);\n  padding: var(--zui-select-menu-overflow);\n  margin: calc(var(--zui-select-menu-overflow) * -1);\n  pointer-events: none;\n}\n:host([data-popper-placement^=bottom]) #clipper {\n  padding-top: 0;\n  margin-top: 0;\n}\n:host([data-popper-placement^=top]) #clipper {\n  padding-bottom: 0;\n  margin-bottom: 0;\n}\n\n#wrapper {\n  animation-duration: var(--zui-select-menu-animation-duration);\n  animation-fill-mode: forwards;\n}\n#wrapper.open {\n  pointer-events: all;\n  animation-direction: normal;\n  animation-timing-function: ease-out;\n}\n#wrapper.close {\n  pointer-events: none;\n  animation-direction: reverse;\n  animation-timing-function: ease-in;\n}\n:host([data-popper-placement^=bottom]) #wrapper.running {\n  animation-name: toggle-bottom;\n}\n:host([data-popper-placement^=top]) #wrapper.running {\n  animation-name: toggle-top;\n}")}`;
let d = class extends m {
  constructor() {
    super(...arguments), this.open = !1, this._running = !1;
  }
  emitOpenEvent() {
    this.dispatchEvent(new CustomEvent("select-menu-open", {
      bubbles: !0,
      composed: !0
    }));
  }
  emitOpenedEvent() {
    this.dispatchEvent(new CustomEvent("select-menu-opened", {
      bubbles: !0,
      composed: !0
    }));
  }
  emitCloseEvent() {
    this.dispatchEvent(new CustomEvent("select-menu-close", {
      bubbles: !0,
      composed: !0
    }));
  }
  emitClosedEvent() {
    this.dispatchEvent(new CustomEvent("select-menu-closed", {
      bubbles: !0,
      composed: !0
    }));
  }
  _handleTransitionEnd() {
    this._running = !1, this.open ? this.emitOpenedEvent() : this.emitClosedEvent();
  }
  update(n) {
    super.update(n), n.has("open") && (this._running = !0, this.open ? this.emitOpenEvent() : this.emitCloseEvent());
  }
  updated(n) {
    super.updated(n), n.has("_zuiPlacement") && (this._menuRef[0].placement = this._zuiPlacement);
  }
  render() {
    return o`<div id="clipper"><div id="wrapper" class="${r({
      open: this.open,
      close: !this.open,
      running: this._running
    })}" @animationcancel="${this._handleTransitionEnd}" @animationend="${this._handleTransitionEnd}"><slot></slot></div></div>`;
  }
};
d.styles = [l, u], n([i({
  reflect: !0,
  type: Boolean
})], d.prototype, "open", void 0), n([c()], d.prototype, "emitOpenEvent", null), n([c()], d.prototype, "emitOpenedEvent", null), n([c()], d.prototype, "emitCloseEvent", null), n([c()], d.prototype, "emitClosedEvent", null), n([i({
  reflect: !0,
  type: String,
  attribute: "data-popper-placement"
})], d.prototype, "_zuiPlacement", void 0), n([a()], d.prototype, "_running", void 0), n([s(void 0, !0, "zui-menu")], d.prototype, "_menuRef", void 0), d = n([p("zui-select-menu")], d);
export { d as SelectMenu };
