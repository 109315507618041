import { a as t } from "./tslib.es6-7f1b1220.js";
import { B as e } from "./types-814d68fb.js";
const n = {
  [e.XS]: {
    min: 0,
    max: 767
  },
  [e.S]: {
    min: 768,
    max: 1023
  },
  [e.M]: {
    min: 1024,
    max: 1279
  },
  [e.L]: {
    min: 1280,
    max: 1 / 0
  }
};
function o(t) {
  return !!t && t.trim().length > 0;
}
function r(t) {
  return !o(t);
}
function i(t) {
  return t.assignedNodes().length > 0;
}
function u(t) {
  return !i(t);
}
function s(t) {
  const e = document.createElement("div");
  e.setAttribute("style", "font: var(--zui-typography-" + t + ");"), document.documentElement.appendChild(e);
  const n = window.getComputedStyle(e).getPropertyValue("font");
  return document.documentElement.removeChild(e), n;
}
function c(t, e) {
  return m(window.getComputedStyle(document.documentElement).getPropertyValue("--zui-color-" + t), e);
}
function l(t, e) {
  return window.getComputedStyle(t).getPropertyValue(e);
}
function m(t, e) {
  const n = t.trim(),
    o = parseInt(n.slice(1, 3), 16),
    r = parseInt(n.slice(3, 5), 16),
    i = parseInt(n.slice(5, 7), 16);
  return e ? `rgba(${o}, ${r}, ${i}, ${e})` : `rgb(${o}, ${r}, ${i})`;
}
function a(t, e) {
  var n;
  const o = null === (n = t.shadowRoot) || void 0 === n ? void 0 : n.querySelector(e);
  return o ? window.getComputedStyle(o) : window.getComputedStyle(document.createElement("p"));
}
function d(t, e, n) {
  return ["top", "right", "bottom", "left"].every(o => n === l(t, `border-${o}-${e}`));
}
function f(t, e) {
  return Array.from(t.shadowRoot.querySelector(`slot[name=${e}]`).assignedNodes()).filter(t => t instanceof HTMLElement);
}
function p(t, e, n, o) {
  Array.from(t.assignedElements()).filter(e).forEach(t => t[n] = o);
}
function b(t) {
  if (t.assignedNodes().length > 0) {
    const e = document.createElement("span");
    return t.assignedNodes().forEach(t => e.append(t.cloneNode(!0))), e.innerHTML;
  }
}
function g(t, e) {
  return t.shadowRoot.querySelector("slot" + (e ? `[name="${e}"]` : ":not([name])"));
}
function y(t) {
  return Object.keys(n).find(e => t >= n[e].min && t <= n[e].max);
}
const h = {
  "<": (t, e) => t.max < e.min,
  ">": (t, e) => t.min > e.max
};
function A(t, e, o) {
  return (0, h[e])(n[t], n[o]);
}
const w = {
    fromAttribute: t => "true" === t,
    toAttribute: t => t ? "true" : "false"
  },
  v = (t = " ", e = {
    fromAttribute: t => t,
    toAttribute: t => t
  }) => ({
    fromAttribute: n => null === n || 0 === n.length ? [] : n.split(t).map(e.fromAttribute),
    toAttribute: n => void 0 === n || 0 === n.length ? "" : n.map(e.toAttribute).join(t)
  }),
  S = {
    fromAttribute: t => null === t ? void 0 : Number(t),
    toAttribute: t => t ? String(t) : null
  },
  $ = {
    fromAttribute: t => null === t ? void 0 : t,
    toAttribute: t => "string" == typeof t ? t : null
  },
  E = t => ({
    fromAttribute: e => 0 === (null == e ? void 0 : e.length) ? t.fromAttribute(null) : t.fromAttribute(e),
    toAttribute: t.toAttribute
  }),
  x = t => window.getComputedStyle(t).getPropertyValue("background-color"),
  C = t => window.getComputedStyle(t).getPropertyValue("opacity"),
  P = (t, e, n) => Math.max(t, Math.min(e, n));
function M(t) {
  return null != t;
}
const N = (t, e, n, o, r) => {
  switch (r) {
    case "decrease":
      {
        const r = t - o;
        return r >= e ? r : n + 1 - Math.abs(e - r);
      }
    case "increase":
      {
        const r = t + o;
        return r <= n ? r : e - 1 + Math.abs(r - n);
      }
  }
};
function V(e, n, o = 1) {
  let r,
    i,
    u,
    s = o;
  const c = new Promise(t => r = t),
    l = new Promise(t => i = t),
    m = new Promise(t => u = t),
    a = () => {
      s >= 1 && r(), s >= 2 && i(), s >= 3 && u();
    };
  a();
  const d = e[n];
  return e[n] = function (...t) {
    s++;
    const n = d.apply(e, t);
    return a(), n;
  }, {
    once: () => t(this, void 0, void 0, function* () {
      return c;
    }),
    twice: () => t(this, void 0, void 0, function* () {
      return l;
    }),
    thrice: () => t(this, void 0, void 0, function* () {
      return m;
    })
  };
}
function j(t, e = 1) {
  return V(t, "render", e);
}
const L = {
  fromAttribute: t => (null == t ? void 0 : t.length) > 0 ? t.split(",").map(t => t.trim()) : [],
  toAttribute: t => M(t) ? String(t) : ""
};
function q(t) {
  return {
    fromAttribute(e) {
      const n = !0 === (null == e ? void 0 : e.includes(",")),
        o = !0 === (null == e ? void 0 : e.includes(" "));
      return !n && o ? (console.warn("Deprecated list format detected. The separation of lists by spaces is deprecated. Lists should be separated by commas instead."), t.fromAttribute(e)) : L.fromAttribute(e);
    },
    toAttribute: t => L.toAttribute(t)
  };
}
export { w as booleanStringConverter, P as clamp, L as commaListConverter, A as compareMediaBreakpoint, N as cycle, q as deprecatedListWrapperConverter, j as elementHasRendered, E as emptyStringToNullWrapperConverter, y as getBreakpointForWidth, x as getComputedBackgroundColor, C as getComputedOpacity, a as getComputedStyleForQueryInShadowDom, l as getCssPropertyFromElement, g as getSlotByName, f as getSlottedElementsFromNamedSlot, v as getStringArrayConverter, c as getZuiColor, s as getZuiFont, d as hasElementBorderPropertyValue, m as hexToRgb, M as isDefined, u as isSlotEmpty, i as isSlotNotEmpty, r as isStringEmpty, o as isStringNotEmpty, S as numberUndefinedConverter, p as propagateValueToSlotContentByPredicate, b as serializeSlotContent, $ as stringUndefinedConverter, V as waitForInvocationsOfMethod };
