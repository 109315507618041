import { _ as e } from "./tslib.es6-7f1b1220.js";
import { c as t, u as o, h as s } from "./lit-element-161a1699.js";
import { p as n, c as r } from "./decorators-7e9cd43b.js";
import { BaseElement as i } from "./BaseElement.esm.min.js";
import { h as l } from "./host.styles-cf05db22.js";
import { e as a } from "./event.decorator-98549cbc.js";
import "./types-814d68fb.js";
import "./component.utils.esm.min.js";
import { registerPortal as p, unregisterPortal as d } from "./portal.utils.esm.min.js";
var c;
const m = t`${o(":host {\n  ---zui-portal-level: var(--zui-portal-level, 10);\n  position: fixed;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  top: 0;\n  z-index: var(---zui-portal-level);\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  pointer-events: none;\n}\n:host * {\n  pointer-events: all;\n}")}`;
let h = c = class extends i {
  constructor() {
    super(...arguments), this.restore = !1, this.clone = !1, this._parentRefs = new WeakMap();
  }
  static get styles() {
    return [l, m];
  }
  emitReadyEvent() {
    this.dispatchEvent(new CustomEvent("portal-ready", {
      bubbles: !1,
      cancelable: !1,
      composed: !0
    }));
  }
  connectedCallback() {
    super.connectedCallback(), p(this.name, this);
  }
  disconnectedCallback() {
    super.disconnectedCallback(), this.removeExistingContents(), d(this.name);
  }
  showContent(e) {
    var t;
    null === (t = this.shadowRoot.getElementById("placeholder")) || void 0 === t || t.remove(), this.removeExistingContents(), this._parentRefs.set(e, e.parentElement), this.clone ? this.shadowRoot.innerHTML += e.outerHTML : this.shadowRoot.append(e);
  }
  resetContent() {
    this.removeExistingContents(), this.shadowRoot.innerHTML = '<slot id="placeholder"></slot>';
  }
  update(e) {
    super.update(e), e.has("restore") && console.warn("Deprecated restore: Please use the `clone` option instead.");
  }
  firstUpdated(e) {
    super.firstUpdated(e), this.emitReadyEvent();
  }
  removeExistingContents() {
    this.restore && Array.from(this.shadowRoot.children).forEach(e => {
      var t;
      null === (t = this._parentRefs.get(e)) || void 0 === t || t.prepend(e);
    }), this.shadowRoot.innerHTML = `<style>${c.styles.map(({
      cssText: e
    }) => e).join("")}</style>`;
  }
  render() {
    return void 0 !== this.level && this.style.setProperty("---zui-portal-level", `${this.level}`), s`<slot id="placeholder"></slot>`;
  }
};
e([n({
  reflect: !0,
  type: String
})], h.prototype, "name", void 0), e([n({
  reflect: !0,
  type: Number
})], h.prototype, "level", void 0), e([n({
  reflect: !0,
  type: Boolean
})], h.prototype, "restore", void 0), e([n({
  reflect: !0,
  type: Boolean
})], h.prototype, "clone", void 0), e([a()], h.prototype, "emitReadyEvent", null), h = c = e([r("zui-portal")], h);
export { h as Portal };
