import { _ as t } from "./tslib.es6-7f1b1220.js";
import "./lit-element-161a1699.js";
import { p as e } from "./decorators-7e9cd43b.js";
import { BaseElement as r } from "./BaseElement.esm.min.js";
class i extends r {
  constructor() {
    super(...arguments), this.min = 0, this.max = 1, this.step = "any", this.activeLineStart = "min";
  }
}
t([e({
  reflect: !0,
  type: Number
})], i.prototype, "min", void 0), t([e({
  reflect: !0,
  type: Number
})], i.prototype, "max", void 0), t([e({
  reflect: !0
})], i.prototype, "step", void 0), t([e({
  reflect: !0,
  type: String,
  attribute: "active-line-start"
})], i.prototype, "activeLineStart", void 0);
export { i as SliderBaseClass };
