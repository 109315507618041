import { _ as t } from "./tslib.es6-7f1b1220.js";
import "./lit-element-161a1699.js";
import { p as e, a as s } from "./decorators-7e9cd43b.js";
import { isDefined as i } from "./component.utils.esm.min.js";
import { getParentElementBySelector as r } from "./mixin.utils.esm.min.js";
let a, n, o, l;
const d = Boolean("FormDataEvent" in window),
  h = (h, {
    disableSubmitOnEnter: u = !1,
    formControlSelector: m = "*[zuiFormControl]"
  } = {}) => {
    class p extends h {
      constructor() {
        super(...arguments), this.readonly = !1, this.disabled = !1, this._lastResetValueType = "property", this._hasResetBeenInitialized = !1;
      }
      get formControl() {
        return "string" == typeof m ? this._formControl : m.bind(this)();
      }
      get _resetValueAttribute() {
        return this._resetAttributeValue;
      }
      set _resetValueAttribute(t) {
        this._hasResetBeenInitialized = !0, this._lastResetValueType = "attribute", this._resetAttributeValue = t;
      }
      get resetValue() {
        return this._resetPropertyValue;
      }
      set resetValue(t) {
        this._hasResetBeenInitialized = !0, this._lastResetValueType = "property", this._resetPropertyValue = t;
      }
      get _shouldSyncHiddenInput() {
        return i(this.hostForm) && i(this.name) && !d;
      }
      get _hiddenInput() {
        const t = this.hostForm.querySelector(`input[name=${this.name}]`);
        return t || this._addHiddenInput();
      }
      _addHiddenInput() {
        const t = document.createElement("input");
        return t.type = "hidden", t.name = this.name, this.hostForm.append(t), t;
      }
      _syncHiddenInput() {
        const t = this._hiddenInput;
        t.value = this.getAttribute("value"), t.disabled = this.disabled;
      }
      _deleteInput(t) {
        var e;
        null === (e = this.hostForm.querySelector(`input[name=${t}]`)) || void 0 === e || e.remove();
      }
      _handleKey({
        code: t
      }) {
        var e;
        "Enter" === t && (null === (e = this.hostForm) || void 0 === e || e.requestSubmit());
      }
      reset() {
        this.formResetCallback();
      }
      formResetCallback() {
        this._hasResetBeenInitialized ? "property" === this._lastResetValueType ? this.value = this.resetValue : this.setAttribute("value", this._resetValueAttribute) : this.value = this._initialValue;
      }
      formDisabledCallback() {}
      formSubmitCallback(t) {}
      formDataCallback({
        formData: t
      }) {
        this.name && !this.disabled && t.append(this.name, this.getAttribute("value"));
      }
      updated(t) {
        var e, s;
        if (super.updated(t), this._shouldSyncHiddenInput && ((t.has("value") || t.has("disabled")) && this._syncHiddenInput(), t.has("name"))) {
          const e = t.get("name");
          this._deleteInput(e), this._syncHiddenInput();
        }
        u || (l = this._handleKey.bind(this), null === (e = this.formControl) || void 0 === e || e.removeEventListener("keydown", l), null === (s = this.formControl) || void 0 === s || s.addEventListener("keydown", l)), t.has("disabled") && this.disabled && this.formDisabledCallback();
      }
      connectedCallback() {
        super.connectedCallback(), this.hostForm = r(this, "form"), this._initialValue = this.value, this.hostForm && (a = () => {
          this.formResetCallback();
        }, n = t => {
          this.disabled || this.formSubmitCallback(t);
        }, this.hostForm.addEventListener("reset", a), this.hostForm.addEventListener("submit", n), d && (o = this.formDataCallback.bind(this), this.hostForm.addEventListener("formdata", o)));
      }
      disconnectedCallback() {
        this.hostForm && (this.hostForm.removeEventListener("reset", a), this.hostForm.removeEventListener("submit", n), d && this.hostForm.removeEventListener("formdata", o), this._shouldSyncHiddenInput && this._deleteInput(this.name)), super.disconnectedCallback();
      }
    }
    return t([e({
      reflect: !0,
      type: Boolean
    })], p.prototype, "readonly", void 0), t([e({
      reflect: !0,
      type: Boolean
    })], p.prototype, "disabled", void 0), t([e({
      reflect: !0,
      type: String
    })], p.prototype, "name", void 0), t([s("string" == typeof m ? m : "_")], p.prototype, "_formControl", void 0), t([e({
      reflect: !0,
      type: String,
      attribute: "reset-value"
    })], p.prototype, "_resetValueAttribute", null), p;
  };
export { h as F };
