import { _ as t } from "./tslib.es6-7f1b1220.js";
import { c as e, u as s, h as a } from "./lit-element-161a1699.js";
import { p as o, q as i, c as n } from "./decorators-7e9cd43b.js";
import "./class-map-31af68b2.js";
import { BaseElement as l } from "./BaseElement.esm.min.js";
import { h as r } from "./host.styles-cf05db22.js";
import { e as m } from "./event.decorator-98549cbc.js";
import "./types-814d68fb.js";
import { isDefined as p } from "./component.utils.esm.min.js";
import "./content-tab-base.class.esm.min.js";
import "./content-tab-default-item.component.esm.min.js";
import "./content-tab.utils-30bc4825.js";
import "./content-tab-highlight-item.component.esm.min.js";
import "./content-tab-image-item.component.esm.min.js";
const c = e`${s(":host {\n  display: inline-flex;\n  justify-content: center;\n  max-width: 100%;\n}")}`;
let d = class extends l {
  constructor() {
    super(...arguments), this.disabled = !1, this.size = "s", this.hierarchy = "first", this._propagateDisabled = !1;
  }
  static get styles() {
    return [r, c];
  }
  emitContentTabItemClosedEvent(t) {
    this.dispatchEvent(new CustomEvent("content-tab-item-closed", {
      bubbles: !1,
      composed: !1,
      cancelable: !1,
      detail: {
        value: t.detail.value
      }
    }));
  }
  emitContentTabItemSelectedEvent() {
    this.dispatchEvent(new CustomEvent("content-tab-item-selected", {
      bubbles: !1,
      cancelable: !1,
      composed: !1,
      detail: {
        value: this.value
      }
    }));
  }
  _handleSlotchange() {
    this._propagateState();
  }
  _handleClick(t) {
    t.stopPropagation(), t.stopImmediatePropagation(), (t => t.composedPath().some(t => t.tagName && "zui-icon-close" === t.tagName.toLowerCase()))(t) || this.value === t.target.value || (this.value = t.target.value, this.emitContentTabItemSelectedEvent());
  }
  _handleClose(t) {
    t.stopPropagation(), t.stopImmediatePropagation(), this.emitContentTabItemClosedEvent(t);
  }
  _propagateState() {
    this._assignedTabs.forEach(t => {
      this._propagateDisabled && (t.disabled = this.disabled), p(this.value) && (t.emphasis = this.value === t.value ? "selected" : "default"), t.hierarchy = this.hierarchy, t.size = this.size;
    });
  }
  update(t) {
    super.update(t), (this.disabled || t.get("disabled")) && (this._propagateDisabled = !0);
  }
  updated(t) {
    super.updated(t), this._propagateState();
  }
  render() {
    return a`<slot @content-tab-item-closed="${this._handleClose}" @click="${this._handleClick}" @slotchange="${this._handleSlotchange}"></slot>`;
  }
};
t([o({
  reflect: !0,
  type: Boolean
})], d.prototype, "disabled", void 0), t([o({
  reflect: !0,
  type: String
})], d.prototype, "size", void 0), t([o({
  reflect: !0,
  type: String
})], d.prototype, "hierarchy", void 0), t([o({
  reflect: !0,
  type: String
})], d.prototype, "value", void 0), t([m()], d.prototype, "emitContentTabItemClosedEvent", null), t([m()], d.prototype, "emitContentTabItemSelectedEvent", null), t([i("", !0, "[zui-internal-is-content-tab-item]")], d.prototype, "_assignedTabs", void 0), d = t([n("zui-content-tab-bar")], d);
export { d as ContentTabBar };
