import { _ as i } from "./tslib.es6-7f1b1220.js";
import { c as t, u as e, h as n, n as s } from "./lit-element-161a1699.js";
import { p as r, a, q as o, s as l, c as m } from "./decorators-7e9cd43b.js";
import { c as u } from "./class-map-31af68b2.js";
import { BaseElement as d } from "./BaseElement.esm.min.js";
import { h as p } from "./host.styles-cf05db22.js";
import "./event.decorator-98549cbc.js";
import "./interactive-icon.component.esm.min.js";
import "./types-814d68fb.js";
import "./component.utils.esm.min.js";
import { s as c } from "./style-map-a37d7105.js";
import { i as h, b as g, a as f, r as _ } from "./isObject-07b7923e.js";
import { animateWithCSS as v } from "./animation.utils.esm.min.js";
import "./multi-item-slider-item.component.esm.min.js";
import "./multi-item-slider-pagination.component.esm.min.js";
import "./pagination-dot-bar.component.esm.min.js";
import "./pagination-dot.component.esm.min.js";
var I = /\s/;
var z = /^\s+/;
function S(i) {
  return i ? i.slice(0, function (i) {
    for (var t = i.length; t-- && I.test(i.charAt(t)););
    return t;
  }(i) + 1).replace(z, "") : i;
}
var b = /^[-+]0x[0-9a-f]+$/i,
  R = /^0b[01]+$/i,
  T = /^0o[0-7]+$/i,
  y = parseInt;
function M(i) {
  if ("number" == typeof i) return i;
  if (function (i) {
    return "symbol" == typeof i || h(i) && "[object Symbol]" == g(i);
  }(i)) return NaN;
  if (f(i)) {
    var t = "function" == typeof i.valueOf ? i.valueOf() : i;
    i = f(t) ? t + "" : t;
  }
  if ("string" != typeof i) return 0 === i ? i : +i;
  i = S(i);
  var e = R.test(i);
  return e || T.test(i) ? y(i.slice(2), e ? 2 : 8) : b.test(i) ? NaN : +i;
}
var O = function () {
    return _.Date.now();
  },
  x = Math.max,
  A = Math.min;
function E(i, t, e) {
  var n,
    s,
    r,
    a,
    o,
    l,
    m = 0,
    u = !1,
    d = !1,
    p = !0;
  if ("function" != typeof i) throw new TypeError("Expected a function");
  function c(t) {
    var e = n,
      r = s;
    return n = s = void 0, m = t, a = i.apply(r, e);
  }
  function h(i) {
    return m = i, o = setTimeout(_, t), u ? c(i) : a;
  }
  function g(i) {
    var e = i - l;
    return void 0 === l || e >= t || e < 0 || d && i - m >= r;
  }
  function _() {
    var i = O();
    if (g(i)) return v(i);
    o = setTimeout(_, function (i) {
      var e = t - (i - l);
      return d ? A(e, r - (i - m)) : e;
    }(i));
  }
  function v(i) {
    return o = void 0, p && n ? c(i) : (n = s = void 0, a);
  }
  function I() {
    var i = O(),
      e = g(i);
    if (n = arguments, s = this, l = i, e) {
      if (void 0 === o) return h(l);
      if (d) return clearTimeout(o), o = setTimeout(_, t), c(l);
    }
    return void 0 === o && (o = setTimeout(_, t)), a;
  }
  return t = M(t) || 0, f(e) && (u = !!e.leading, r = (d = "maxWait" in e) ? x(M(e.maxWait) || 0, t) : r, p = "trailing" in e ? !!e.trailing : p), I.cancel = function () {
    void 0 !== o && clearTimeout(o), m = 0, n = l = s = o = void 0;
  }, I.flush = function () {
    return void 0 === o ? a : v(O());
  }, I;
}
var j;
const w = t`${e(":host {\n  --zui-multi-item-slider-animation-duration: 800ms;\n  --zui-multi-item-slider-animation-duration-pagination: 400ms;\n  --zui-multi-item-slider-animation-easing: cubic-bezier(0.4, 0, 0.2, 1);\n  --zui-multi-item-slider-pagination-spacing: 8px;\n  --zui-multi-item-slider-item-gap-size: var(--zui-space-medium);\n  ---zui-multi-item-slider-group-size: 4;\n}\n\n/* prettier-ignore */\n.items {\n  ---zui-multi-item-slider-gaps-per-group: calc(var(---zui-multi-item-slider-group-size) - 1);\n  ---zui-multi-item-slider-gap-width-per-group: calc(var(---zui-multi-item-slider-gaps-per-group) * var(--zui-multi-item-slider-item-gap-size));\n  ---zui-multi-item-slider-gap-width-per-item: calc(var(---zui-multi-item-slider-gap-width-per-group) / var(---zui-multi-item-slider-group-size));\n  ---zui-multi-item-slider-item-width-without-gap: calc(100% / var(---zui-multi-item-slider-group-size));\n  ---zui-multi-item-slider-item-width: calc(var(---zui-multi-item-slider-item-width-without-gap) - var(---zui-multi-item-slider-gap-width-per-item));\n  display: grid;\n  grid-auto-flow: column;\n  grid-auto-columns: var(---zui-multi-item-slider-item-width);\n  grid-gap: var(--zui-multi-item-slider-item-gap-size);\n  overflow: scroll;\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n}\n.items::-webkit-scrollbar {\n  display: none;\n}\n.items.snapping {\n  scroll-snap-type: x mandatory;\n}\n.items.snapping ::slotted(zui-multi-item-slider-item) {\n  scroll-snap-align: start;\n}\n\nnav {\n  display: flex;\n  justify-content: center;\n  margin-top: var(--zui-multi-item-slider-pagination-spacing);\n}\nnav zui-multi-item-slider-pagination {\n  --zui-multi-item-slider-pagination-animation-duration: var(--zui-multi-item-slider-animation-duration-pagination);\n}")}`;
let L = j = class extends d {
  constructor() {
    super(...arguments), this.groupSize = 4, this.hidePagination = !1, this._isSnapping = !0, this._selectedItemIndex = 0, this._resizeObserver = new ResizeObserver(E(this._handleResize.bind(this), 200)), this._scrollListener = E(this._handleScroll.bind(this), 20);
  }
  get _isAnimating() {
    var i;
    return "running" === (null === (i = this._currentAnimation) || void 0 === i ? void 0 : i.playState);
  }
  get _pageCount() {
    var i;
    return Math.ceil((null === (i = this._itemRefs) || void 0 === i ? void 0 : i.length) / this.groupSize);
  }
  get _selectedPageIndex() {
    var i;
    const t = Math.floor(this._selectedItemIndex / this.groupSize),
      e = Math.floor((null === (i = this._itemRefs) || void 0 === i ? void 0 : i.length) / this.groupSize);
    return Math.min(t, e);
  }
  disconnectedCallback() {
    this._resizeObserver.disconnect(), super.disconnectedCallback();
  }
  _handleSlotChange() {
    this.requestUpdate();
  }
  _handlePageSelected({
    detail: {
      value: i
    }
  }) {
    const t = i * this.groupSize,
      e = this._itemRefs.length % this.groupSize,
      n = e > 0 ? this.groupSize - e : 0,
      s = (this._pageCount - 1) * this.groupSize - n,
      r = Math.min(t, s);
    this._selectedItemIndex = r, this._animateToItem(this._selectedItemIndex);
  }
  _handleScroll() {
    this._isAnimating || (this._selectedItemIndex = this._getClosestItemIndexToOffset(this._itemsRef.scrollLeft));
  }
  _handleResize() {
    var i;
    null === (i = this._currentAnimation) || void 0 === i || i.cancel();
    const t = this._itemRefs[this._selectedItemIndex];
    this._itemsRef.scrollLeft = this._getItemOffset(t);
  }
  _handleInteractiveScroll() {
    var i;
    null === (i = this._currentAnimation) || void 0 === i || i.cancel(), this._isSnapping = !0;
  }
  _animateToItem(i) {
    var t;
    const e = this._getItemOffset(this._itemRefs[i]),
      n = this._itemsRef.scrollLeft,
      s = e - n;
    return null === (t = this._currentAnimation) || void 0 === t || t.cancel(), this._isSnapping = !1, this._currentAnimation = v(`var(${j.MULTI_ITEM_SLIDER_ANIMATION_EASING})`, `var(${j.MULTI_ITEM_SLIDER_ANIMATION_DURATION})`, i => this._itemsRef.scrollLeft = n + s * i, {
      host: this,
      autostart: !0
    }), this._currentAnimation.finished;
  }
  _getClosestItemIndexToOffset(i) {
    const t = this._itemRefs.map(i => this._getItemOffset(i)),
      e = t.reduce((t, e) => Math.abs(e - i) < Math.abs(t - i) ? e : t);
    return t.indexOf(e);
  }
  _getItemOffset(i) {
    const t = this._itemsRef.getBoundingClientRect().left,
      e = this._itemsRef.scrollLeft,
      n = i.getBoundingClientRect().left - t + e;
    return Math.floor(n);
  }
  firstUpdated(i) {
    super.firstUpdated(i), this._resizeObserver.observe(this._itemsRef);
  }
  render() {
    return n`<div class="${u({
      items: !0,
      snapping: this._isSnapping
    })}" style="${c({
      [j.MULTI_ITEM_SLIDER_GROUP_SIZE_CSS_PROPERTY]: `${this.groupSize}`
    })}" @touchstart="${this._handleInteractiveScroll}" @wheel="${this._handleInteractiveScroll}" @scroll="${this._scrollListener}"><slot @slotchange="${this._handleSlotChange}"></slot></div>${!this.hidePagination && this._pageCount > 1 ? n`<nav><zui-multi-item-slider-pagination selected-item-index="${this._selectedPageIndex}" item-count="${this._pageCount}" @multi-item-slider-pagination-page-selected="${this._handlePageSelected}"></zui-multi-item-slider-pagination></nav>` : s}`;
  }
};
L.MULTI_ITEM_SLIDER_ANIMATION_DURATION = "--zui-multi-item-slider-animation-duration", L.MULTI_ITEM_SLIDER_ANIMATION_EASING = "--zui-multi-item-slider-animation-easing", L.MULTI_ITEM_SLIDER_GROUP_SIZE_CSS_PROPERTY = "---zui-multi-item-slider-group-size", L.styles = [p, w], i([r({
  reflect: !0,
  attribute: "group-size",
  type: Number
})], L.prototype, "groupSize", void 0), i([r({
  reflect: !0,
  attribute: "hide-pagination",
  type: Boolean
})], L.prototype, "hidePagination", void 0), i([a(".items")], L.prototype, "_itemsRef", void 0), i([o("", !0, "zui-multi-item-slider-item")], L.prototype, "_itemRefs", void 0), i([l()], L.prototype, "_isSnapping", void 0), i([l()], L.prototype, "_selectedItemIndex", void 0), L = j = i([m("zui-multi-item-slider")], L);
export { L as MultiItemSlider };
