import { _ as t } from "./tslib.es6-7f1b1220.js";
import { c as e, u as n, h as o, n as a } from "./lit-element-161a1699.js";
import { p as i, q as s, s as r, c } from "./decorators-7e9cd43b.js";
import { BaseElement as l } from "./BaseElement.esm.min.js";
import { h as d } from "./host.styles-cf05db22.js";
import "./interactive-icon.component.esm.min.js";
import "./if-defined-123567fc.js";
import "./headerbar-icon-button.component.esm.min.js";
import "./title-stack.component.esm.min.js";
import "./title-stack-meta-item.component.esm.min.js";
const h = e`${n(":host {\n  --zui-page-header-height: calc(var(--zui-gu) * 10);\n  display: flex;\n  align-items: center;\n  height: var(--zui-page-header-height);\n  background-color: var(--zui-color-headerbar-background);\n}\n\nzui-divider {\n  flex: 0 0 auto;\n  height: var(--zui-page-header-height);\n}\n\nslot[name=iconButton]::slotted(zui-headerbar-icon-button) {\n  flex: 0 0 auto;\n  padding: calc(var(--zui-gu) * 1);\n}\n\nslot:not([name])::slotted(zui-title-stack) {\n  flex: 1 1 auto;\n  padding: 0 calc(var(--zui-gu) * 4);\n  overflow: auto;\n}\nzui-divider ~ slot:not([name])::slotted(zui-title-stack) {\n  padding-left: calc(var(--zui-gu) * 3);\n}")}`;
let u = class extends l {
  constructor() {
    super(...arguments), this.disabled = !1, this._hasIcon = !1;
  }
  _handleIconSlotChange() {
    this._assignedHeaderbarIconButtons.forEach(t => t.size = "m"), this._hasIcon = this._assignedHeaderbarIconButtons.length > 0;
  }
  _handleDefaultSlotchange() {
    this._assignedTitleStacks.forEach(t => t.wrapping = !1);
  }
  render() {
    return o`<slot name="iconButton" @slotchange="${this._handleIconSlotChange}"></slot>${this._hasIcon ? o`<zui-divider emphasis="default" size="s" orientation="vertical"></zui-divider>` : a}<slot @slotchange="${this._handleDefaultSlotchange}"></slot>`;
  }
};
u.styles = [d, h], t([i({
  reflect: !0,
  type: Boolean
})], u.prototype, "disabled", void 0), t([s("iconButton", !0, "zui-headerbar-icon-button")], u.prototype, "_assignedHeaderbarIconButtons", void 0), t([s("", !0, "zui-title-stack")], u.prototype, "_assignedTitleStacks", void 0), t([r()], u.prototype, "_hasIcon", void 0), u = t([c("zui-page-header")], u);
export { u as PageHeader };
