import { _ as t } from "./tslib.es6-7f1b1220.js";
import { c as e, u as i, h as a } from "./lit-element-161a1699.js";
import { p as s, c as r } from "./decorators-7e9cd43b.js";
import "./class-map-31af68b2.js";
import "./BaseElement.esm.min.js";
import { h as l } from "./host.styles-cf05db22.js";
import { e as n } from "./event.decorator-98549cbc.js";
import { i as o } from "./if-defined-123567fc.js";
import "./types-814d68fb.js";
import { numberUndefinedConverter as d } from "./component.utils.esm.min.js";
import "./mixin.utils.esm.min.js";
import { F as m } from "./form-data-handling.mixin-3ddec2ca.js";
import "./dom.utils.esm.min.js";
import { F as c } from "./form-validation.mixin-0a6c3c34.js";
import "./delegate-focus.mixin-95600d1f.js";
import "./style-map-a37d7105.js";
import "./_commonjsHelpers-82e0321a.js";
import "./decimal-fc7b1704.js";
import { SliderBaseClass as h } from "./slider-base.class.esm.min.js";
import { i as u, a as p } from "./slider-custom.component-f3f306be.js";
import "./slider-scale.component.esm.min.js";
import "./format.utils.esm.min.js";
import "./slider-tick-label.component.esm.min.js";
const v = e`${i(":host(:focus) {\n  outline: none;\n}\n\n:host {\n  ---zui-slider-thumb-diameter: calc(var(--zui-gu) * 2);\n  ---zui-slider-thumb-border: calc(var(--zui-gu) * 0.25);\n  ---zui-slider-thumb-diameter-readonly: calc(var(--zui-gu) * 1);\n  ---zui-slider-line-height: calc(var(--zui-gu) * 0.25);\n  ---zui-slider-tick-width: calc(var(--zui-gu) * 0.125);\n  ---zui-slider-tick-height: calc(var(--zui-gu) * 0.5);\n  ---zui-slider-tick-top-margin: calc(var(--zui-gu) * 1);\n  --zui-slider-padding: 8;\n  width: var(--zui-slider-width, 100%);\n  overflow: hidden;\n  min-height: calc(var(--zui-gu) * 3.625);\n}\n:host .main-container {\n  display: grid;\n}\n:host .main-container > * {\n  grid-column-start: 1;\n  grid-row-start: 1;\n}")}`;
let b = class extends c(m(h)) {
  constructor() {
    super(...arguments), this.tabindex = 0, this.tickInterval = 0, this.labelInterval = 0, this.activeLineDisabled = !1, this.ghostHandle = void 0, this.value = this.min, this._overflowValidator = () => this.value <= this.max, this._underflowValidator = () => this.value >= this.min, this._stepMismatchValidator = () => "any" === this.step || 0 === this.step || u(this.min, this.max, this.step) && p(this.min, this.value, this.step);
  }
  emitChangeEvent() {
    this.dispatchEvent(new Event("change", {
      bubbles: !0,
      cancelable: !1,
      composed: !0
    }));
  }
  emitInputEvent() {}
  connectedCallback() {
    super.connectedCallback(), this.addValidator({
      validator: this._overflowValidator,
      type: "rangeOverflow"
    }), this.addValidator({
      validator: this._underflowValidator,
      type: "rangeUnderflow"
    }), this.addValidator({
      validator: this._stepMismatchValidator,
      type: "stepMismatch"
    }), this.setDefaultValidityMessages({
      rangeOverflow: "The given value is greater than max."
    }), this.setDefaultValidityMessages({
      rangeUnderflow: "The given value is less than min."
    }), this.setDefaultValidityMessages({
      stepMismatch: "The given value does not match the step size."
    });
  }
  _syncValue({
    target: {
      value: t
    }
  }) {
    this.value = Number(t);
  }
  updated(t) {
    super.updated(t), (t.has("min") || t.has("max") || t.has("step")) && this.checkValidity();
  }
  render() {
    return a`<div class="main-container"><zui-slider-scale min="${this.min}" max="${this.max}" label-format="${o(this.labelFormat)}" label-interval="${this.labelInterval}" tick-interval="${this.tickInterval}" ?readonly="${this.readonly}" ?disabled="${this.disabled}"><slot></slot></zui-slider-scale><zui-slider-custom zuiFormControl min="${this.min}" max="${this.max}" step="${this.step}" .value="${this.value}" ghost-handle="${o(this.ghostHandle)}" active-line-start="${this.activeLineStart}" ?active-line-disabled="${this.activeLineDisabled}" ?readonly="${this.readonly}" ?disabled="${this.disabled}" @input="${this._syncValue}"></zui-slider-custom></div>`;
  }
};
b.styles = [l, v], t([s({
  reflect: !0,
  type: Number
})], b.prototype, "tabindex", void 0), t([s({
  reflect: !0,
  type: Number,
  attribute: "tick-interval"
})], b.prototype, "tickInterval", void 0), t([s({
  reflect: !0,
  type: Number,
  attribute: "label-interval"
})], b.prototype, "labelInterval", void 0), t([s({
  reflect: !0,
  type: String,
  attribute: "label-format"
})], b.prototype, "labelFormat", void 0), t([s({
  reflect: !0,
  type: Boolean,
  attribute: "active-line-disabled"
})], b.prototype, "activeLineDisabled", void 0), t([s({
  reflect: !0,
  attribute: "ghost-handle",
  converter: d
})], b.prototype, "ghostHandle", void 0), t([s({
  reflect: !0,
  type: Number
})], b.prototype, "value", void 0), t([n()], b.prototype, "emitChangeEvent", null), t([n()], b.prototype, "emitInputEvent", null), b = t([r("zui-slider")], b);
export { b as Slider };
