import "./lit-element-161a1699.js";
import { clamp as t, isDefined as e } from "./component.utils.esm.min.js";
import { l as n } from "./date-picker.utils-7bc542a5.js";
const i = {
  hour: "numeric",
  minute: "numeric"
};
function r(t) {
  return n.DateTime.now().toLocaleParts(Object.assign({
    locale: t
  }, i)).some(({
    type: t
  }) => "dayPeriod" === t);
}
function o(t, e) {
  switch (e) {
    case "AM":
      return t % 12;
    case "PM":
      return t % 12 + 12;
  }
}
function u(t, e, n, i) {
  return e.set({
    hour: "h12" === n ? o(t.hour, i) : t.hour,
    minute: t.minute,
    second: 0,
    millisecond: 0
  });
}
function s(t, e, n) {
  const {
    value: i
  } = t.toLocaleParts({
    locale: e,
    hour: "2-digit",
    hourCycle: n
  }).find(({
    type: t
  }) => "hour" === t);
  return i;
}
function c(t, e) {
  if ("h12" === e) return t.hour < 12 ? "AM" : "PM";
}
function a(e, n, i) {
  const r = String(i).length;
  if (e.length > r && (e = e.slice(0, r)), e.length < r) {
    const r = e.length === String(n).length,
      o = Number(e) > 0;
    return r && o ? String(t(n, Number(e), i)) : e;
  }
  return Number(e) >= n && Number(e) <= i ? e : String(t(n, Number(e), i));
}
function m(t, e) {
  const i = n.DateTime.now().toUTC(),
    r = i.set({
      hour: t.hour,
      minute: t.minute
    }),
    o = i.set({
      hour: e.hour,
      minute: e.minute
    }),
    {
      minutes: u
    } = o.diff(r, ["minutes"], {
      conversionAccuracy: "longterm"
    }).toObject();
  return u;
}
const l = {
  fromAttribute: t => {
    if (null === t) return {
      dayTime: void 0,
      hour: void 0,
      minute: void 0
    };
    const [e, n] = t.split(" ")[0].split(":").map(t => "" !== t ? parseInt(t) : void 0),
      [, i] = t.split(" ");
    return {
      dayTime: i,
      hour: e,
      minute: n
    };
  },
  toAttribute: t => {
    var e, n;
    if (void 0 !== t) return `${null !== (e = t.hour) && void 0 !== e ? e : ""}:${null !== (n = t.minute) && void 0 !== n ? n : ""}${t.dayTime ? " " + t.dayTime : ""}`;
  }
};
function h(t) {
  return 0 === t || 12 === t ? 12 : t % 12;
}
function d(t, n) {
  const {
    dayTime: i,
    hour: r
  } = t;
  return e(r) ? Object.assign(Object.assign({}, t), {
    dayTime: "h12" === n ? null != i ? i : r < 12 ? "AM" : "PM" : void 0,
    hour: "h12" === n ? h(r) : e(i) ? o(r, i) : r
  }) : Object.assign(Object.assign({}, t), {
    dayTime: "h12" === n ? i : void 0
  });
}
export { u as a, s as b, o as c, m as d, d as e, c as g, r as h, a as s, l as t };
