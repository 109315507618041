import { _ as e } from "./tslib.es6-7f1b1220.js";
import { c as t, u as r, h as a } from "./lit-element-161a1699.js";
import { p as s, a as n, q as i, c as l } from "./decorators-7e9cd43b.js";
import { c as u } from "./class-map-31af68b2.js";
import { BaseElement as o } from "./BaseElement.esm.min.js";
import { h } from "./host.styles-cf05db22.js";
import { e as c } from "./event.decorator-98549cbc.js";
import "./interactive-icon.component.esm.min.js";
import "./if-defined-123567fc.js";
import "./types-814d68fb.js";
import "./component.utils.esm.min.js";
import "./mixin.utils.esm.min.js";
import "./form-data-handling.mixin-3ddec2ca.js";
import "./dom.utils.esm.min.js";
import "./form-validation.mixin-0a6c3c34.js";
import "./delegate-focus.mixin-95600d1f.js";
import "./style-map-a37d7105.js";
import "./portal.utils.esm.min.js";
import "./error-message.component.esm.min.js";
import "./unsafe-html-97738913.js";
import "./menu-item.component.esm.min.js";
import "./select.component.esm.min.js";
import "./select-divider.component.esm.min.js";
import "./select-option.component.esm.min.js";
import "./select-all.component.esm.min.js";
import "./select-menu.component.esm.min.js";
import "./select-placeholder.component.esm.min.js";
import "./isObject-07b7923e.js";
import "./searchbar-input.component.esm.min.js";
import "./searchbar-results-menu.component.esm.min.js";
const m = t`${r(":host {\n  --zui-searchbar-results-menu-expanded-height: calc(\n    9.5 *\n      (\n        var(--zui-body-font-size) * var(--zui-body-line-height) + calc(var(--zui-gu) * 0.625) + calc(var(--zui-gu) * 0.875)\n      )\n  );\n  --zui-searchbar-results-menu-animation-duration: 0.1s;\n  --zui-searchbar-results-menu-margin-left: calc(var(--zui-gu) * 1);\n  --zui-searchbar-results-menu-with-filter-margin-left: calc(var(--zui-gu) * 18);\n  --zui-searchbar-results-menu-margin-right: calc(var(--zui-gu) * 1);\n  --zui-searchbar-results-menu-z-index: 1;\n  height: calc(var(--zui-gu) * 4.5);\n  max-width: calc(var(--zui-gu) * 67);\n  min-width: calc(var(--zui-gu) * 40);\n  position: relative;\n  width: 100%;\n}\n\n.searchbar-results-menu-wrapper {\n  clip-path: polygon(-50% 0, 150% 0, 150% 150%, -50% 150%);\n  height: var(--zui-searchbar-results-menu-expanded-height);\n  margin-left: var(--zui-searchbar-results-menu-margin-left);\n  margin-right: var(--zui-searchbar-results-menu-margin-right);\n  max-width: calc(var(--zui-gu) * 67);\n  pointer-events: none;\n  position: absolute;\n  top: calc(var(--zui-gu) * 4.5);\n  width: 100%;\n  z-index: var(--zui-searchbar-results-menu-z-index);\n}\n.searchbar-results-menu-wrapper.has-filter-menu {\n  margin-left: var(--zui-searchbar-results-menu-with-filter-margin-left);\n}\n\n:host([showSearchResults]) .searchbar-results-menu {\n  opacity: 1;\n  pointer-events: all;\n  transform: translateY(0);\n  transition: opacity var(--zui-searchbar-results-menu-animation-duration) ease-out, transform var(--zui-searchbar-results-menu-animation-duration) ease-out;\n}\n:host(:not([showSearchResults])) .searchbar-results-menu {\n  opacity: 0;\n  pointer-events: none;\n  top: 0;\n  transform: translateY(calc(var(--zui-gu) * -5));\n  transition: opacity var(--zui-searchbar-results-menu-animation-duration) ease-in, transform var(--zui-searchbar-results-menu-animation-duration) ease-out;\n}")}`;
let p = class extends o {
  constructor() {
    super(...arguments), this.disabled = !1, this.placeholder = "", this.showSearchResults = !1, this.value = "";
  }
  static get styles() {
    return [h, m];
  }
  emitSearchbarFilterChangedEvent(e) {
    this.dispatchEvent(new CustomEvent("searchbar-filter-changed", {
      bubbles: !0,
      composed: !0,
      detail: {
        value: e
      }
    }));
  }
  emitSearchbarFilterOpenedEvent() {
    this.dispatchEvent(new CustomEvent("searchbar-filter-opened", {
      bubbles: !0,
      composed: !0
    }));
  }
  emitSearchbarInputEvent(e) {
    this.dispatchEvent(new CustomEvent("searchbar-input", {
      bubbles: !0,
      composed: !0,
      detail: {
        value: e
      }
    }));
  }
  emitSearchbarInputBlurredEvent() {
    this.dispatchEvent(new CustomEvent("searchbar-input-blurred", {
      bubbles: !0,
      composed: !0
    }));
  }
  emitSearchbarInputFocusedEvent(e) {
    this.dispatchEvent(new CustomEvent("searchbar-input-focused", {
      bubbles: !0,
      composed: !0,
      detail: {
        value: e
      }
    }));
  }
  emitSearchbarInputSelectedEvent(e) {
    this.dispatchEvent(new CustomEvent("searchbar-input-selected", {
      bubbles: !0,
      composed: !0,
      detail: {
        value: e
      }
    }));
  }
  emitSearchbarResultSelectedEvent(e) {
    this.dispatchEvent(new CustomEvent("searchbar-result-selected", {
      bubbles: !0,
      composed: !0,
      detail: {
        value: e
      }
    }));
  }
  emitSearchbarResultsClosedEvent() {
    this.dispatchEvent(new CustomEvent("searchbar-results-closed", {
      bubbles: !0,
      composed: !0
    }));
  }
  emitSearchbarResultsOpenedEvent() {
    this.dispatchEvent(new CustomEvent("searchbar-results-opened", {
      bubbles: !0,
      composed: !0
    }));
  }
  get _hasFilterMenu() {
    return null !== this._assignedFilterMenu && this._assignedFilterMenu.length > 0;
  }
  closeSearchbarResultsMenu() {
    this.showSearchResults && (this.showSearchResults = !1, this.emitSearchbarResultsClosedEvent());
  }
  openSearchbarResultsMenu() {
    this.showSearchResults || (this.showSearchResults = !0, this.emitSearchbarResultsOpenedEvent());
  }
  _handleSearchbarFilterChangedEvent({
    target: e
  }) {
    this.emitSearchbarFilterChangedEvent(e.value);
  }
  _handleSearchbarFilterOpenedEvent() {
    this.emitSearchbarFilterOpenedEvent();
  }
  _handleSearchbarFilterSlotChange() {
    this.requestUpdate();
  }
  _handleSearchbarResultSelected({
    target: e
  }) {
    this.disabled || "zui-menu-item" === e.tagName.toLowerCase() && this.emitSearchbarResultSelectedEvent(e.value);
  }
  _handleSearchbarInputKeyDownEvent({
    code: e
  }) {
    this.disabled || "Enter" !== e || this.emitSearchbarInputSelectedEvent(this.value);
  }
  _handleSearchbarInputChangedEvent(e) {
    "zui-searchbar-input" === e.target.tagName.toLowerCase() && (this.value = e.detail.value, this.emitSearchbarInputEvent(this.value));
  }
  _handleSearchbarInputBlurredEvent() {
    this.emitSearchbarInputBlurredEvent();
  }
  _handleSearchbarInputFocusedEvent({
    target: e
  }) {
    this.emitSearchbarInputFocusedEvent(void 0 !== e.value ? e.value : "");
  }
  render() {
    return a`<zui-searchbar-input .value="${this.value}" ?disabled="${this.disabled}" placeholder="${this.placeholder}" @blur="${this._handleSearchbarInputBlurredEvent}" @focus="${this._handleSearchbarInputFocusedEvent}" @searchbar-input-changed="${this._handleSearchbarInputChangedEvent}" @keydown="${this._handleSearchbarInputKeyDownEvent}"><slot name="filter" slot="filter" @change="${this._handleSearchbarFilterChangedEvent}" @open="${this._handleSearchbarFilterOpenedEvent}" @slotchange="${this._handleSearchbarFilterSlotChange}"></slot></zui-searchbar-input><div class="${u({
      "searchbar-results-menu-wrapper": !0,
      "has-filter-menu": this._hasFilterMenu
    })}"><zui-searchbar-results-menu ?disabled="${this.disabled}" class="searchbar-results-menu"><slot @click="${this._handleSearchbarResultSelected}"></slot></zui-searchbar-results-menu></div>`;
  }
};
e([s({
  reflect: !0,
  type: Boolean
})], p.prototype, "disabled", void 0), e([s({
  reflect: !0,
  type: String
})], p.prototype, "placeholder", void 0), e([s({
  reflect: !0,
  type: Boolean
})], p.prototype, "showSearchResults", void 0), e([s({
  reflect: !0,
  type: String
})], p.prototype, "value", void 0), e([n("zui-searchbar-results-menu")], p.prototype, "resultsMenu", void 0), e([c()], p.prototype, "emitSearchbarFilterChangedEvent", null), e([c()], p.prototype, "emitSearchbarFilterOpenedEvent", null), e([c()], p.prototype, "emitSearchbarInputEvent", null), e([c()], p.prototype, "emitSearchbarInputBlurredEvent", null), e([c()], p.prototype, "emitSearchbarInputFocusedEvent", null), e([c()], p.prototype, "emitSearchbarInputSelectedEvent", null), e([c()], p.prototype, "emitSearchbarResultSelectedEvent", null), e([c()], p.prototype, "emitSearchbarResultsClosedEvent", null), e([c()], p.prototype, "emitSearchbarResultsOpenedEvent", null), e([i("filter", !0, "zui-select")], p.prototype, "_assignedFilterMenu", void 0), p = e([l("zui-searchbar")], p);
export { p as Searchbar };
