import { _ as e } from "./tslib.es6-7f1b1220.js";
import { c as t, u as s, h as i } from "./lit-element-161a1699.js";
import { p as n, c as o } from "./decorators-7e9cd43b.js";
import { BaseElement as c } from "./BaseElement.esm.min.js";
import { h as a } from "./host.styles-cf05db22.js";
import { e as l } from "./event.decorator-98549cbc.js";
import "./interactive-icon.component.esm.min.js";
const r = t`${s("#icon-container {\n  align-items: center;\n  display: flex;\n  user-select: none;\n}\n\n/* by default the close button is hidden */\n#closebutton {\n  display: none;\n}\n\n/* show the close button when the closable attribute is set */\n:host([closable]) #closebutton {\n  display: block;\n  margin-left: calc(var(--zui-gu) * 2);\n}\n\n/* sets margins for used slots */\n.used {\n  margin-left: calc(var(--zui-gu) * 2);\n}")}`;
let m = class extends c {
  constructor() {
    super(...arguments), this.emphasis = "subtle", this.closable = !1;
  }
  static get styles() {
    return [a, r];
  }
  emitCloseEvent() {
    return this.dispatchEvent(new CustomEvent("close", {
      bubbles: !0,
      composed: !0,
      cancelable: !0
    }));
  }
  _setEventListenerForIconSlots(e) {
    const t = e.querySelector("slot");
    t.addEventListener("slotchange", () => {
      0 === t.assignedNodes().length || this._isSlotEmpty(t) ? e.classList.remove("used") : (e.classList.add("used"), this._setIconSize(t));
    });
  }
  _isSlotEmpty(e) {
    let t = !0;
    return e.assignedNodes().forEach(e => {
      e instanceof HTMLElement && (t = e instanceof HTMLSlotElement && this._isSlotEmpty(e));
    }), t;
  }
  _handleClick() {
    this.dispatchEvent(new CustomEvent("close", {
      bubbles: !0,
      composed: !0,
      cancelable: !0
    }));
  }
  _setIconSize(e) {
    e.assignedNodes().forEach(e => {
      e instanceof HTMLElement && (e instanceof HTMLSlotElement && this._setIconSize(e), e.setAttribute("size", "m"));
    });
  }
  firstUpdated(e) {
    super.firstUpdated(e);
    this.shadowRoot.querySelectorAll(".interactive-icon").forEach(e => {
      this._setEventListenerForIconSlots(e);
    });
  }
  render() {
    return i`<div id="icon-container"><zui-interactive-icon class="interactive-icon" emphasis="${this.emphasis}"><slot name="icon1"></slot></zui-interactive-icon><zui-interactive-icon class="interactive-icon" emphasis="${this.emphasis}"><slot name="icon2"></slot></zui-interactive-icon><zui-interactive-icon class="interactive-icon" emphasis="${this.emphasis}"><slot name="icon3"></slot></zui-interactive-icon>${this.closable ? i`<zui-interactive-icon emphasis="${this.emphasis}" @click="${this._handleClick}" id="closebutton"><zui-icon-close size="s"></zui-icon-close></zui-interactive-icon>` : ""}</div>`;
  }
};
e([n({
  reflect: !0
})], m.prototype, "emphasis", void 0), e([n({
  reflect: !0,
  type: Boolean
})], m.prototype, "closable", void 0), e([l()], m.prototype, "emitCloseEvent", null), m = e([o("zui-icon-bar")], m);
export { m as IconBar };
