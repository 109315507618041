import { _ as t } from "./tslib.es6-7f1b1220.js";
import { c as s, u as e, h as o } from "./lit-element-161a1699.js";
import { c as r } from "./decorators-7e9cd43b.js";
import { BaseElement as i } from "./BaseElement.esm.min.js";
import { h as m } from "./host.styles-cf05db22.js";
const n = s`${e(":host {\n  height: fit-content;\n  width: auto;\n  overflow: hidden;\n}")}`;
let l = class extends i {
  render() {
    return o`<slot></slot>`;
  }
};
l.styles = [m, n], l = t([r("zui-multi-item-slider-item")], l);
export { l as MultiItemSliderItem };
