import { _ as t } from "./tslib.es6-7f1b1220.js";
import { c as e, u as a, h as o } from "./lit-element-161a1699.js";
import { p as l, c as r } from "./decorators-7e9cd43b.js";
import { BaseElement as s } from "./BaseElement.esm.min.js";
import { h as n } from "./host.styles-cf05db22.js";
const c = e`${a(":host {\n  --zui-state-label-background-color: var(--zui-color-state-label-background-color-default);\n  --zui-state-label-text-color: var(--zui-color-state-label-color-default);\n  --zui-state-label-width: calc(var(--zui-gu) * 10);\n  align-items: center;\n  background-color: var(--zui-state-label-background-color);\n  color: var(--zui-state-label-text-color);\n  display: flex;\n  font: var(--zui-typography-label2);\n  height: calc(var(--zui-gu) * 3);\n  justify-content: center;\n  max-width: 100%;\n  min-width: 0;\n  padding: 0 calc(var(--zui-gu) * 1);\n  width: var(--zui-state-label-width);\n}\n\n.state-label-text {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  text-transform: uppercase;\n  white-space: nowrap;\n}\n\n:host([state=default]) {\n  --zui-state-label-background-color: var(--zui-color-state-label-background-color-default);\n  --zui-state-label-text-color: var(--zui-color-state-label-text-color-default);\n}\n\n:host([state=error]) {\n  --zui-state-label-background-color: var(--zui-color-state-label-background-color-error);\n  --zui-state-label-text-color: var(--zui-color-state-label-text-color-error);\n}\n\n:host([state=warning]) {\n  --zui-state-label-background-color: var(--zui-color-state-label-background-color-warning);\n  --zui-state-label-text-color: var(--zui-color-state-label-text-color-warning);\n}\n\n:host([state=progress]) {\n  --zui-state-label-background-color: var(--zui-color-state-label-background-color-progress);\n  --zui-state-label-text-color: var(--zui-color-state-label-text-color-progress);\n}\n\n:host([state=ready]) {\n  --zui-state-label-background-color: var(--zui-color-state-label-background-color-ready);\n  --zui-state-label-text-color: var(--zui-color-state-label-text-color-ready);\n}")}`;
let i = class extends s {
  constructor() {
    super(...arguments), this.state = "default";
  }
  static get styles() {
    return [n, c];
  }
  render() {
    return o`<div class="state-label-text"><slot></slot></div>`;
  }
};
t([l({
  reflect: !0,
  type: String
})], i.prototype, "state", void 0), i = t([r("zui-state-label")], i);
export { i as StateLabel };
