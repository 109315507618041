import { _ as e } from "./tslib.es6-7f1b1220.js";
import { c as t, u as r, h as i } from "./lit-element-161a1699.js";
import { p as s, b as n, c as o } from "./decorators-7e9cd43b.js";
import { BaseElement as a } from "./BaseElement.esm.min.js";
import { h as l } from "./host.styles-cf05db22.js";
import { e as c } from "./event.decorator-98549cbc.js";
import "./interactive-icon.component.esm.min.js";
import "./if-defined-123567fc.js";
import "./dom.utils.esm.min.js";
import "./_commonjsHelpers-82e0321a.js";
import { g as d, l as h, t as u, f as m, h as p, j as v, m as D, n as _, a as b, i as y } from "./date-picker.utils-7bc542a5.js";
import "./grid-cell.component.esm.min.js";
import "./header-cell.component.esm.min.js";
import "./picker-header.component.esm.min.js";
import "./picker-grid.component.esm.min.js";
const S = t`${r(".picker-grid {\n  margin-top: calc(var(--zui-gu) * 3);\n}")}`;
let Y = class extends a {
  constructor() {
    super(...arguments), this.yearSelectDisabled = !1, this.disabledMonths = [], this.disabledYears = [], this.locale = d(), this.max = null, this.min = null, this.selectedMonth = null;
  }
  static get styles() {
    return [l, S];
  }
  get _maxDateDT() {
    return this.max ? h.DateTime.fromJSDate(this.max) : void 0;
  }
  get maxDate() {
    return this.max;
  }
  set maxDate(e) {
    console.warn("Deprecated property maxDate used. Use max instead."), this.max = e;
  }
  get _minDateDT() {
    return this.min ? h.DateTime.fromJSDate(this.min) : void 0;
  }
  get minDate() {
    return this.min;
  }
  set minDate(e) {
    console.warn("Deprecated property minDate used. Use min instead."), this.min = e;
  }
  get _selectedMonthDT() {
    return this.selectedMonth ? h.DateTime.fromJSDate(this.selectedMonth) : void 0;
  }
  get currentYear() {
    var e;
    return this._internalCurrentYear ? this._internalCurrentYear : null !== (e = this.selectedMonth) && void 0 !== e ? e : h.DateTime.now().toJSDate();
  }
  set currentYear(e) {
    const t = this._internalCurrentYear;
    this._internalCurrentYear = e, this.requestUpdate("currentYear", t);
  }
  get _currentYearDT() {
    return h.DateTime.fromJSDate(this.currentYear);
  }
  emitMonthPickerMonthSelectedEvent(e) {
    this.dispatchEvent(new CustomEvent("monthPickerMonthSelected", {
      bubbles: !0,
      composed: !0,
      detail: e
    })), this.dispatchEvent(new CustomEvent("month-picker-month-selected", {
      bubbles: !0,
      composed: !0,
      detail: e
    }));
  }
  emitMonthPickerYearSelectedEvent(e) {
    this.dispatchEvent(new CustomEvent("monthPickerYearSelected", {
      bubbles: !0,
      composed: !0,
      detail: e
    })), this.dispatchEvent(new CustomEvent("month-picker-year-selected", {
      bubbles: !0,
      composed: !0,
      detail: e
    }));
  }
  emitMonthPickerPreviousYearSelectedEvent(e) {
    this.dispatchEvent(new CustomEvent("monthPickerPreviousYearSelected", {
      bubbles: !0,
      composed: !0,
      detail: e
    })), this.dispatchEvent(new CustomEvent("month-picker-previous-year-selected", {
      bubbles: !0,
      composed: !0,
      detail: e
    }));
  }
  emitMonthPickerNextYearSelectedEvent(e) {
    this.dispatchEvent(new CustomEvent("monthPickerNextYearSelected", {
      bubbles: !0,
      composed: !0,
      detail: e
    })), this.dispatchEvent(new CustomEvent("month-picker-next-year-selected", {
      bubbles: !0,
      composed: !0,
      detail: e
    }));
  }
  get _gridCellMonths() {
    return u(this._currentYearDT);
  }
  get _disabledGridCellConditions() {
    const e = m(this.disabledMonths),
      t = m(this.disabledYears);
    return [e => !!this._minDateDT && e.toMillis() < this._minDateDT.toMillis(), e => !!this._maxDateDT && e.toMillis() > this._maxDateDT.toMillis(), t => p(t, e), e => v(e, t)];
  }
  get _gridCellFocusConditions() {
    return [() => !!this._selectedMonthDT && D(this._selectedMonthDT, this._currentYearDT), () => !!this._selectedMonthDT && _(this._selectedMonthDT, this._currentYearDT)];
  }
  get _gridCellSelectedConditions() {
    return [e => !!this._selectedMonthDT && D(e, this._selectedMonthDT), e => _(e, this._currentYearDT)];
  }
  get _gridCellSubtleConditions() {
    return [e => !_(e, this._currentYearDT)];
  }
  _isGridCellDisabled(e) {
    return this._disabledGridCellConditions.some(t => t(e));
  }
  _canFocusGridCell() {
    return this._gridCellFocusConditions.every(e => e());
  }
  _getGridCellEmphasis(e) {
    const t = this._gridCellSelectedConditions.every(t => t(e)),
      r = this._gridCellSubtleConditions.every(t => t(e));
    return t ? "selected" : r ? "subtle" : "default";
  }
  _handleMonthSelected({
    detail: e
  }) {
    const {
      selected: t,
      value: r
    } = e;
    t.disabled || (this.selectedMonth = new Date(r), this.currentYear = this.selectedMonth, this.emitMonthPickerMonthSelectedEvent({
      value: this._selectedMonthDT.startOf("month").toJSDate()
    }));
  }
  _handleYearSelected() {
    this.emitMonthPickerYearSelectedEvent({
      value: this._currentYearDT.startOf("year").toJSDate()
    });
  }
  _handlePreviousYearSelected() {
    this.currentYear = this._currentYearDT.minus({
      year: 1
    }).toJSDate(), this.emitMonthPickerPreviousYearSelectedEvent({
      startOfYear: this._currentYearDT.startOf("year").toJSDate(),
      endOfYear: this._currentYearDT.endOf("year").toJSDate()
    });
  }
  _handleNextYearSelected() {
    this.currentYear = this._currentYearDT.plus({
      year: 1
    }).toJSDate(), this.emitMonthPickerNextYearSelectedEvent({
      startOfYear: this._currentYearDT.startOf("year").toJSDate(),
      endOfYear: this._currentYearDT.endOf("year").toJSDate()
    });
  }
  _updateFocus() {
    if (!this._canFocusGridCell()) return;
    const e = Array.from(this._pickerGridCells).find(e => !!this._selectedMonthDT && D(h.DateTime.fromISO(e.value), this._selectedMonthDT));
    null == e || e.focus();
  }
  updated(e) {
    e.has("selectedMonth") && this._updateFocus();
  }
  render() {
    return i`<zui-picker-header ?disabled="${this.yearSelectDisabled}" value="${this._currentYearDT.year}" @picker-header-current-selected="${this._handleYearSelected}" @picker-header-next-selected="${this._handleNextYearSelected}" @picker-header-previous-selected="${this._handlePreviousYearSelected}"><zui-interactive-icon slot="icon-left"><zui-icon-arrow-outline-arrow-outline-actually-centred-left></zui-icon-arrow-outline-arrow-outline-actually-centred-left></zui-interactive-icon><zui-interactive-icon slot="icon-right"><zui-icon-arrow-outline-arrow-outline-actually-centred-right></zui-icon-arrow-outline-arrow-outline-actually-centred-right></zui-interactive-icon></zui-picker-header><zui-picker-grid class="picker-grid" columns="4" @picker-grid-cell-selected="${this._handleMonthSelected}">${this._gridCellMonths.map(e => {
      const t = this._isGridCellDisabled(e),
        r = this._getGridCellEmphasis(e);
      return i`<zui-picker-grid-cell ?disabled="${t}" emphasis="${r}" slot="pickerGridCells" style="--zui-picker-grid-cell-width:56px;--zui-picker-grid-cell-height:56px" value="${e.toISO()}">${b(e.year, e.month, this.locale, "short")}</zui-picker-grid-cell>`;
    })}</zui-picker-grid>`;
  }
};
e([s({
  reflect: !0,
  type: Boolean,
  attribute: "year-select-disabled"
})], Y.prototype, "yearSelectDisabled", void 0), e([s({
  type: Array,
  attribute: !1
})], Y.prototype, "disabledMonths", void 0), e([s({
  type: Array,
  attribute: !1
})], Y.prototype, "disabledYears", void 0), e([s({
  reflect: !0,
  type: String
})], Y.prototype, "locale", void 0), e([s({
  reflect: !0,
  type: String,
  converter: y
})], Y.prototype, "max", void 0), e([s({
  reflect: !0,
  type: String,
  attribute: "max-date",
  converter: y
})], Y.prototype, "maxDate", null), e([s({
  reflect: !0,
  type: String,
  converter: y
})], Y.prototype, "min", void 0), e([s({
  reflect: !0,
  type: String,
  attribute: "min-date",
  converter: y
})], Y.prototype, "minDate", null), e([s({
  reflect: !0,
  type: String,
  attribute: "selected-month",
  converter: y
})], Y.prototype, "selectedMonth", void 0), e([s({
  reflect: !0,
  type: String,
  attribute: "current-year",
  converter: y
})], Y.prototype, "currentYear", null), e([c()], Y.prototype, "emitMonthPickerMonthSelectedEvent", null), e([c()], Y.prototype, "emitMonthPickerYearSelectedEvent", null), e([c()], Y.prototype, "emitMonthPickerPreviousYearSelectedEvent", null), e([c()], Y.prototype, "emitMonthPickerNextYearSelectedEvent", null), e([n("zui-picker-grid-cell")], Y.prototype, "_pickerGridCells", void 0), Y = e([o("zui-date-picker-month-picker")], Y);
export { Y as DatePickerMonthPicker };
