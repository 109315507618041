import { _ as e } from "./tslib.es6-7f1b1220.js";
import "./lit-element-161a1699.js";
import { a as s } from "./decorators-7e9cd43b.js";
const c = (c, {
  selector: t = "*[zuiCaptureFocus]"
} = {}) => {
  class o extends c {
    connectedCallback() {
      super.connectedCallback(), this.addEventListener("focus", this.focusCallback);
    }
    disconnectedCallback() {
      this.removeEventListener("focus", this.focusCallback), super.disconnectedCallback();
    }
    focus() {
      this.focusCallback();
    }
    focusCallback() {
      var e;
      null === (e = this._focusableElement) || void 0 === e || e.focus();
    }
  }
  return e([s(t)], o.prototype, "_focusableElement", void 0), o;
};
export { c as D };
