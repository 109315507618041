import { _ as n } from "./tslib.es6-7f1b1220.js";
import { c as o, u as e, h as t } from "./lit-element-161a1699.js";
import { c as s } from "./decorators-7e9cd43b.js";
import { BaseElement as l } from "./BaseElement.esm.min.js";
import { h as i } from "./host.styles-cf05db22.js";
const r = o`${e(":host {\n  align-items: center;\n  background-color: var(--zui-color-pill-background);\n  border-radius: 16px;\n  border: 1px solid var(--zui-color-pill-border);\n  box-sizing: border-box;\n  color: var(--zui-color-pill-text);\n  cursor: default;\n  display: inline-flex;\n  font: var(--zui-typography-body);\n  height: calc(var(--zui-gu) * 4);\n  justify-content: center;\n  min-width: calc(var(--zui-gu) * 6);\n  padding: 0 calc(var(--zui-gu) * 2);\n}\n:host slot[name=iconClose]::slotted([tabindex]:focus) {\n  outline: none;\n}\n\n:host(:focus) {\n  outline: none;\n}\n\n:host([disabled]) {\n  opacity: 0.5;\n  pointer-events: none;\n}\n:host([disabled]) slot[name=iconClose]::slotted(*) {\n  pointer-events: none;\n}\n\n:host([tabindex]:not([disabled]):focus-visible) {\n  border: 1px dashed var(--zui-color-pill-border);\n}\n\n:host([tabindex]:not([disabled])) slot[name=iconClose]::slotted([tabindex]:focus-visible) {\n  outline: 1px dashed var(--zui-color-pill-border);\n}\n\nslot[name=iconClose]::slotted(*) {\n  margin-left: calc(var(--zui-gu) * 1);\n}")}`;
let a = class extends l {
  static get styles() {
    return [i, r];
  }
  render() {
    return t`<slot></slot><slot name="icon"></slot><slot name="iconClose"></slot>`;
  }
};
a = n([s("zui-pill-content")], a);
export { a as PillContent };
