import { _ as s } from "./tslib.es6-7f1b1220.js";
import { c as o, u as e, h as r } from "./lit-element-161a1699.js";
import { c as t } from "./decorators-7e9cd43b.js";
import { BaseElement as n } from "./BaseElement.esm.min.js";
import { h as i } from "./host.styles-cf05db22.js";
const a = o`${e("span:focus {\n  outline: none;\n  border: 1px dashed;\n  border-radius: var(--zui-border-radius-on-focus);\n}\n\nspan {\n  color: var(--zui-color-ewiq-dialog-question-text-color);\n  font: var(--zui-typography-subtitle1);\n  margin: 0;\n  border: 1px solid transparent;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}")}`;
let l = class extends n {
  static get styles() {
    return [i, a];
  }
  render() {
    return r`<span tabindex="0"><slot></slot></span>`;
  }
};
l = s([t("zui-ewiq-dialog-question")], l);
export { l as EwiqDialogQuestion };
