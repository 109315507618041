import { _ as t } from "./tslib.es6-7f1b1220.js";
import { c as s, u as e, h as o } from "./lit-element-161a1699.js";
import { p as i, q as a, c as n } from "./decorators-7e9cd43b.js";
import { BaseElement as r } from "./BaseElement.esm.min.js";
import { h as l } from "./host.styles-cf05db22.js";
const c = s`${e(":host {\n  height: calc(var(--zui-gu) * 8);\n  position: relative;\n  width: calc(var(--zui-gu) * 8);\n}\n\nslot[name=stateDot]::slotted(zui-state-dot) {\n  pointer-events: none;\n  position: absolute;\n}\n:host([size=m]) slot[name=stateDot]::slotted(zui-state-dot) {\n  right: calc(var(--zui-gu) * 2.25);\n  top: calc(var(--zui-gu) * 2.5);\n}\n:host([size=l]) slot[name=stateDot]::slotted(zui-state-dot) {\n  right: calc(var(--zui-gu) * 1.75);\n  top: calc(var(--zui-gu) * 2);\n}\n:host(:not([has-notifications])) slot[name=stateDot]::slotted(zui-state-dot) {\n  display: none;\n}")}`;
let h = class extends r {
  constructor() {
    super(...arguments), this.emphasis = "default", this.hasNotifications = !1, this.size = "m";
  }
  static get styles() {
    return [l, c];
  }
  _handleStateDotSlotchange() {
    this._assignedStateDots.forEach(t => t.size = "xs");
  }
  render() {
    return o`<zui-headerbar-icon-button emphasis="${this.emphasis}" size="${this.size}"><slot name="icon" slot="icon"></slot></zui-headerbar-icon-button><slot name="stateDot" @slotchange="${this._handleStateDotSlotchange}"></slot>`;
  }
};
t([i({
  reflect: !0,
  type: String
})], h.prototype, "emphasis", void 0), t([i({
  reflect: !0,
  type: Boolean,
  attribute: "has-notifications"
})], h.prototype, "hasNotifications", void 0), t([i({
  reflect: !0,
  type: String
})], h.prototype, "size", void 0), t([a("stateDot", !0, "zui-state-dot")], h.prototype, "_assignedStateDots", void 0), h = t([n("zui-headerbar-notification-button")], h);
export { h as HeaderbarNotificationButton };
