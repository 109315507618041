import { _ as e } from "./tslib.es6-7f1b1220.js";
import "./lit-element-161a1699.js";
import { c as t } from "./decorators-7e9cd43b.js";
import { BaseElement as s } from "./BaseElement.esm.min.js";
let r = class extends s {
  connectedCallback() {
    super.connectedCallback(), "" === this.style.getPropertyValue("display") && this.style.setProperty("display", "block");
  }
  createRenderRoot() {
    return this;
  }
};
r = e([t("zui-div")], r);
export { r as Div };
