import { _ as e } from "./tslib.es6-7f1b1220.js";
import "./lit-element-161a1699.js";
import { p as t } from "./decorators-7e9cd43b.js";
import { BaseElement as s } from "./BaseElement.esm.min.js";
class i extends s {
  constructor() {
    super(...arguments), this.isMenubarItem = !0, this.disabled = !1, this.emphasis = "default", this.variant = "primary";
  }
  update(e) {
    super.update(e), e.has("emphasis") && ("active" !== this.emphasis && "active-primary" !== this.emphasis || console.warn(`Deprecated emphasis: ${this.emphasis} was used on zui-menubar.`));
  }
}
e([t({
  reflect: !0,
  type: Boolean,
  attribute: "zui-internal-is-menubar-item"
})], i.prototype, "isMenubarItem", void 0), e([t({
  reflect: !0,
  type: Boolean
})], i.prototype, "disabled", void 0), e([t({
  reflect: !0,
  type: String
})], i.prototype, "emphasis", void 0), e([t({
  reflect: !0,
  type: String
})], i.prototype, "value", void 0), e([t({
  reflect: !0,
  type: String
})], i.prototype, "variant", void 0);
export { i as MenubarItemBase };
