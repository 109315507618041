import { _ as s } from "./tslib.es6-7f1b1220.js";
import { c as o, u as r, h as e } from "./lit-element-161a1699.js";
import { c as t } from "./decorators-7e9cd43b.js";
import { BaseElement as n } from "./BaseElement.esm.min.js";
import { h as a } from "./host.styles-cf05db22.js";
const i = o`${r(":host {\n  display: block;\n  margin: 0;\n  color: var(--zui-color-error);\n  font: var(--zui-typography-caption);\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}")}`;
let l = class extends n {
  render() {
    return e`<slot></slot>`;
  }
};
l.styles = [a, i], l = s([t("zui-error-message")], l);
export { l as ErrorMessage };
