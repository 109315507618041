import { a as e, _ as t } from "./tslib.es6-7f1b1220.js";
import { h as i } from "./lit-element-161a1699.js";
import { p as r, b as a, c as s } from "./decorators-7e9cd43b.js";
import { BaseElement as l } from "./BaseElement.esm.min.js";
import { h as n } from "./host.styles-cf05db22.js";
import { e as d } from "./event.decorator-98549cbc.js";
import "./interactive-icon.component.esm.min.js";
import "./if-defined-123567fc.js";
import "./dom.utils.esm.min.js";
import "./_commonjsHelpers-82e0321a.js";
import { g as o, l as c, a as h, b as u, c as p, e as m, f as D, s as y, h as v, j as b, k as _, m as k, n as S, o as g, p as f, q as C, i as T, d as E } from "./date-picker.utils-7bc542a5.js";
import "./grid-cell.component.esm.min.js";
import "./header-cell.component.esm.min.js";
import "./picker-header.component.esm.min.js";
import "./picker-grid.component.esm.min.js";
let w = class extends l {
  constructor() {
    super(...arguments), this.monthSelectDisabled = !1, this.max = null, this.min = null, this.disabledDates = [], this.disabledMonths = [], this.disabledYears = [], this.disabledDaysOfWeek = [], this.locale = o(), this.selectedDate = null, this._internalCurrentDate = null, this._headerCellTemplate = ({
      text: e,
      value: t
    }) => i`<zui-picker-header-cell slot="pickerGridHeaderCells" style="--zui-picker-header-cell-width:32px;--zui-picker-header-cell-height:56px" text="${e}" value="${t}"></zui-picker-header-cell>`, this._gridCellTemplate = ({
      content: e,
      disabled: t,
      emphasis: r,
      value: a
    }) => i`<zui-picker-grid-cell ?disabled="${t}" emphasis="${r}" slot="pickerGridCells" style="--zui-picker-grid-cell-width:32px" value="${a}">${e}</zui-picker-grid-cell>`;
  }
  static get styles() {
    return [n];
  }
  get maxDate() {
    return this.max;
  }
  set maxDate(e) {
    console.warn("Deprecated property maxDate used. Use max instead."), this.max = e;
  }
  get _maxDateDT() {
    return this.max ? c.DateTime.fromJSDate(this.max) : void 0;
  }
  get _minDateDT() {
    return this.min ? c.DateTime.fromJSDate(this.min) : void 0;
  }
  get minDate() {
    return this.min;
  }
  set minDate(e) {
    console.warn("Deprecated property minDate used. Use min instead."), this.min = e;
  }
  get _selectedDateDT() {
    return this.selectedDate ? c.DateTime.fromJSDate(this.selectedDate) : void 0;
  }
  get currentDate() {
    var e;
    return this._internalCurrentDate ? this._internalCurrentDate : null !== (e = this.selectedDate) && void 0 !== e ? e : new Date();
  }
  set currentDate(e) {
    const t = this._internalCurrentDate;
    this._internalCurrentDate = e, this.requestUpdate("currentDate", t);
  }
  get _currentDateDT() {
    return c.DateTime.fromJSDate(this.currentDate);
  }
  emitDayPickerMonthSelectedEvent(e) {
    this.dispatchEvent(new CustomEvent("dayPickerMonthSelected", {
      bubbles: !0,
      composed: !0,
      detail: e
    })), this.dispatchEvent(new CustomEvent("day-picker-month-selected", {
      bubbles: !0,
      composed: !0,
      detail: e
    }));
  }
  emitDayPickerNextMonthSelectedEvent(e) {
    this.dispatchEvent(new CustomEvent("dayPickerNextMonthSelected", {
      bubbles: !0,
      composed: !0,
      detail: e
    })), this.dispatchEvent(new CustomEvent("day-picker-next-month-selected", {
      bubbles: !0,
      composed: !0,
      detail: e
    }));
  }
  emitDayPickerPreviousMonthSelectedEvent(e) {
    this.dispatchEvent(new CustomEvent("dayPickerPreviousMonthSelected", {
      bubbles: !0,
      composed: !0,
      detail: e
    })), this.dispatchEvent(new CustomEvent("day-picker-previous-month-selected", {
      bubbles: !0,
      composed: !0,
      detail: e
    }));
  }
  emitDayPickerDaySelectedEvent(e) {
    this.dispatchEvent(new CustomEvent("dayPickerDaySelected", {
      bubbles: !0,
      composed: !0,
      detail: e
    })), this.dispatchEvent(new CustomEvent("day-picker-day-selected", {
      bubbles: !0,
      composed: !0,
      detail: e
    }));
  }
  emitDayPickerWeekdaySelectedEvent(e) {
    this.dispatchEvent(new CustomEvent("dayPickerWeekdaySelected", {
      bubbles: !0,
      composed: !0,
      detail: e
    })), this.dispatchEvent(new CustomEvent("day-picker-weekday-selected", {
      bubbles: !0,
      composed: !0,
      detail: e
    }));
  }
  get _pickerHeaderValue() {
    return `${h(this._currentDateDT.year, this._currentDateDT.month, this.locale)} ${this._currentDateDT.year}`;
  }
  get _gridCellDates() {
    const e = u(this._currentDateDT.year, this._currentDateDT.month);
    return p(e, this.locale, this.weekStart);
  }
  get _headerCellData() {
    return m(this.locale, this.weekStart);
  }
  get _disabledGridCellConditions() {
    const e = D(this.disabledDates),
      t = D(this.disabledMonths),
      i = D(this.disabledYears);
    return [e => !!this._minDateDT && e.toMillis() < this._minDateDT.toMillis(), e => !!this._maxDateDT && e.toMillis() > this._maxDateDT.toMillis(), t => y(t, e), e => v(e, t), e => b(e, i), e => _(e, this.disabledDaysOfWeek)];
  }
  get _gridCellFocusConditions() {
    return [() => !!this._selectedDateDT && k(this._selectedDateDT, this._currentDateDT), () => !!this._selectedDateDT && S(this._selectedDateDT, this._currentDateDT)];
  }
  get _gridCellSelectedConditions() {
    return [e => !!this._selectedDateDT && g(e, this._selectedDateDT)];
  }
  get _gridCellSubtleConditions() {
    const e = u(this._currentDateDT.year, this._currentDateDT.month);
    return [t => !k(t, e)];
  }
  _isGridCellDisabled(e) {
    return this._disabledGridCellConditions.some(t => t(e));
  }
  _canFocusGridCell() {
    return this._gridCellFocusConditions.every(e => e());
  }
  _getGridCellEmphasis(e) {
    const t = this._gridCellSelectedConditions.every(t => t(e)),
      i = this._gridCellSubtleConditions.every(t => t(e));
    return t ? "selected" : i ? "subtle" : "default";
  }
  _focusSelectedDate() {
    if (!this._canFocusGridCell()) return;
    const e = Array.from(this._pickerGridCells).find(e => !!this._selectedDateDT && g(c.DateTime.fromISO(e.value), this._selectedDateDT));
    null == e || e.focus();
  }
  _handleDaySelected({
    detail: t
  }) {
    return e(this, void 0, void 0, function* () {
      const {
        selected: e,
        value: i
      } = t;
      e.disabled || (this.selectedDate = new Date(i), this.emitDayPickerDaySelectedEvent({
        value: c.DateTime.fromISO(i).toJSDate()
      }));
    });
  }
  _handleMonthSelected() {
    this.emitDayPickerMonthSelectedEvent({
      value: this.currentDate
    });
  }
  _handleNextMonthSelected() {
    this.currentDate = f(this._currentDateDT.year, this._currentDateDT.month).toJSDate(), this.emitDayPickerNextMonthSelectedEvent({
      startOfMonth: this.currentDate,
      endOfMonth: this._currentDateDT.endOf("month").toJSDate()
    });
  }
  _handlePreviousMonthSelected() {
    this.currentDate = C(this._currentDateDT.year, this._currentDateDT.month).toJSDate(), this.emitDayPickerPreviousMonthSelectedEvent({
      startOfMonth: this.currentDate,
      endOfMonth: this._currentDateDT.endOf("month").toJSDate()
    });
  }
  _handleWeekdaySelected({
    detail: e
  }) {
    this.emitDayPickerWeekdaySelectedEvent({
      value: e.value
    });
  }
  updated(e) {
    (e.has("selectedDate") || e.has("currentDate")) && this._focusSelectedDate();
  }
  render() {
    return i`<zui-picker-header ?disabled="${this.monthSelectDisabled}" value="${this._pickerHeaderValue}" @picker-header-current-selected="${this._handleMonthSelected}" @picker-header-next-selected="${this._handleNextMonthSelected}" @picker-header-previous-selected="${this._handlePreviousMonthSelected}"><zui-interactive-icon slot="icon-left"><zui-icon-arrow-outline-arrow-outline-actually-centred-left></zui-icon-arrow-outline-arrow-outline-actually-centred-left></zui-interactive-icon><zui-interactive-icon slot="icon-right"><zui-icon-arrow-outline-arrow-outline-actually-centred-right></zui-icon-arrow-outline-arrow-outline-actually-centred-right></zui-interactive-icon></zui-picker-header><zui-picker-grid columns="7" @picker-grid-cell-selected="${this._handleDaySelected}" @picker-header-cell-selected="${this._handleWeekdaySelected}">${this._headerCellData.map(this._headerCellTemplate)} ${this._gridCellDates.map(e => {
      const t = this._isGridCellDisabled(e),
        i = this._getGridCellEmphasis(e);
      return this._gridCellTemplate({
        content: e.day.toString(),
        disabled: t,
        emphasis: i,
        value: e.toISO()
      });
    })}</zui-picker-grid>`;
  }
};
t([r({
  reflect: !0,
  type: Boolean,
  attribute: "month-select-disabled"
})], w.prototype, "monthSelectDisabled", void 0), t([r({
  reflect: !0,
  type: String,
  converter: T
})], w.prototype, "max", void 0), t([r({
  reflect: !0,
  type: String,
  attribute: "max-date",
  converter: T
})], w.prototype, "maxDate", null), t([r({
  reflect: !0,
  type: String,
  converter: T
})], w.prototype, "min", void 0), t([r({
  reflect: !0,
  type: String,
  attribute: "min-date",
  converter: T
})], w.prototype, "minDate", null), t([r({
  type: Array,
  attribute: !1
})], w.prototype, "disabledDates", void 0), t([r({
  type: Array,
  attribute: !1
})], w.prototype, "disabledMonths", void 0), t([r({
  type: Array,
  attribute: !1
})], w.prototype, "disabledYears", void 0), t([r({
  reflect: !0,
  type: String,
  attribute: "disabled-days-of-week",
  converter: E
})], w.prototype, "disabledDaysOfWeek", void 0), t([r({
  reflect: !0,
  type: String
})], w.prototype, "locale", void 0), t([r({
  reflect: !0,
  type: String,
  attribute: "selected-date",
  converter: T
})], w.prototype, "selectedDate", void 0), t([r({
  reflect: !0,
  type: String,
  attribute: "current-date",
  converter: T
})], w.prototype, "currentDate", null), t([r({
  reflect: !0,
  type: String,
  attribute: "week-start"
})], w.prototype, "weekStart", void 0), t([d()], w.prototype, "emitDayPickerMonthSelectedEvent", null), t([d()], w.prototype, "emitDayPickerNextMonthSelectedEvent", null), t([d()], w.prototype, "emitDayPickerPreviousMonthSelectedEvent", null), t([d()], w.prototype, "emitDayPickerDaySelectedEvent", null), t([d()], w.prototype, "emitDayPickerWeekdaySelectedEvent", null), t([a("zui-picker-grid-cell")], w.prototype, "_pickerGridCells", void 0), w = t([s("zui-date-picker-day-picker")], w);
export { w as DatePickerDayPicker };
