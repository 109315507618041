import { _ as e } from "./tslib.es6-7f1b1220.js";
import { h as t } from "./lit-element-161a1699.js";
import { p as o, c as r } from "./decorators-7e9cd43b.js";
import { BaseElement as s } from "./BaseElement.esm.min.js";
let i = class extends s {
  constructor() {
    super(...arguments), this.role = "option", this.ariaSelected = "false", this.disabled = !1, this.selected = !1, this.value = "", this.id = "";
  }
  update(e) {
    super.update(e), this.ariaSelected = String(this.selected), "" === this.id && (this.id = this.value.replace(/\s/g, "").toLocaleLowerCase());
  }
  render() {
    return t`<slot name="icon"></slot><slot></slot>`;
  }
};
e([o({
  reflect: !0
})], i.prototype, "role", void 0), e([o({
  reflect: !0,
  attribute: "aria-selected",
  type: String
})], i.prototype, "ariaSelected", void 0), e([o({
  reflect: !0,
  type: Boolean
})], i.prototype, "disabled", void 0), e([o({
  reflect: !0,
  type: Boolean
})], i.prototype, "selected", void 0), e([o({
  reflect: !0,
  type: String
})], i.prototype, "value", void 0), e([o({
  reflect: !0,
  type: String
})], i.prototype, "id", void 0), i = e([r("zui-select-option")], i);
export { i as SelectOption };
