import { _ as e } from "./tslib.es6-7f1b1220.js";
import { h as s } from "./lit-element-161a1699.js";
import { c as r } from "./decorators-7e9cd43b.js";
import { BaseElement as t } from "./BaseElement.esm.min.js";
let o = class extends t {
  render() {
    return s`<slot></slot>`;
  }
};
o = e([r("zui-select-divider")], o);
export { o as SelectDivider };
