import { _ as e } from "./tslib.es6-7f1b1220.js";
import { c as t, u as i, h as a } from "./lit-element-161a1699.js";
import { p as n, c as s } from "./decorators-7e9cd43b.js";
import { BaseElement as r } from "./BaseElement.esm.min.js";
import { h as o } from "./host.styles-cf05db22.js";
import { e as m } from "./event.decorator-98549cbc.js";
import "./types-814d68fb.js";
import { s as l } from "./style-map-a37d7105.js";
import "./interactive-text.component.esm.min.js";
const c = t`${i(":host {\n  ---zui-time-picker-day-time-toggle-font: var(--zui-typography-label2);\n  display: inline-flex;\n  flex-direction: column;\n}\n:host * + * {\n  margin: calc(var(--zui-gu) * 0.75) 0 0 0;\n}\n\n:host([integrated]) {\n  flex-direction: row;\n}\n:host([integrated]) * + * {\n  margin: 0 0 0 calc(var(--zui-gu) * 2);\n}")}`;
let p = class extends r {
  constructor() {
    super(...arguments), this.integrated = !1;
  }
  static get styles() {
    return [o, c];
  }
  emitTimePickerDayTimeChangedEvent() {
    this.dispatchEvent(new CustomEvent("time-picker-day-time-changed", {
      bubbles: !0,
      cancelable: !1,
      composed: !0,
      detail: {
        value: this.value
      }
    }));
  }
  _handleAmPmToggle(e) {
    const t = e.target.getAttribute("id");
    t !== this.value && (this.value = t, this.emitTimePickerDayTimeChangedEvent());
  }
  render() {
    return a`<zui-interactive-text id="AM" style="${l({
      "--zui-interactive-text-font": "var(---zui-time-picker-day-time-toggle-font)",
      "--zui-interactive-text-hitarea-size": "4px"
    })}" emphasis="${"AM" === this.value ? "highlight" : "subtle"}" @click="${this._handleAmPmToggle}"><span>AM</span></zui-interactive-text><zui-interactive-text id="PM" style="${l({
      "--zui-interactive-text-font": "var(---zui-time-picker-day-time-toggle-font)",
      "--zui-interactive-text-hitarea-size": "4px"
    })}" emphasis="${"PM" === this.value ? "highlight" : "subtle"}" @click="${this._handleAmPmToggle}"><span>PM</span></zui-interactive-text>`;
  }
};
e([n({
  reflect: !0,
  type: Boolean
})], p.prototype, "integrated", void 0), e([n({
  reflect: !0,
  type: String
})], p.prototype, "value", void 0), e([m()], p.prototype, "emitTimePickerDayTimeChangedEvent", null), p = e([s("zui-time-picker-day-time-toggle")], p);
export { p as TimePickerDayTimeToggle };
