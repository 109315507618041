import { _ as e } from "./tslib.es6-7f1b1220.js";
import { L as t } from "./lit-element-161a1699.js";
import { p as n, i } from "./decorators-7e9cd43b.js";
var s;
!function (e) {
  e.AUTO = "auto", e.DESKTOP = "desktop", e.TOUCH = "touch";
}(s || (s = {}));
const r = "environment";
class a extends t {
  constructor() {
    super(), this.isElement = !0, this._globalEnv = document.documentElement.getAttribute(r) || s.AUTO, this._environment = s.AUTO, this._handleMatchMedia = this._handleMatchMedia.bind(this), this._handleEnvironmentChange = this._handleEnvironmentChange.bind(this), this._mediaObserver = window.matchMedia("(pointer:coarse)"), this._mediaEnv = this._mediaObserver.matches ? s.TOUCH : s.DESKTOP, this._envObserver = new MutationObserver(this._handleEnvironmentChange);
  }
  get environment() {
    return this._environment !== s.AUTO ? this._environment : this._globalEnv !== s.AUTO ? this._globalEnv : this._mediaEnv;
  }
  set environment(e) {
    const t = this._environment;
    this._environment = e, this.requestUpdate("environment", t);
  }
  get hasTouch() {
    return this.environment === s.TOUCH;
  }
  connectedCallback() {
    super.connectedCallback(), this._mediaObserver.addEventListener ? this._mediaObserver.addEventListener("change", this._handleMatchMedia) : this._mediaObserver.addListener(this._handleMatchMedia), this._envObserver.observe(document.documentElement, {
      attributeFilter: [r]
    });
  }
  disconnectedCallback() {
    this._mediaObserver.removeEventListener ? this._mediaObserver.removeEventListener("change", this._handleMatchMedia) : this._mediaObserver.removeListener(this._handleMatchMedia), this._envObserver.disconnect(), super.disconnectedCallback();
  }
  _handleEnvironmentChange(e) {
    e.filter(e => e.attributeName === r && e.target === document.documentElement).forEach(e => this._globalEnv = e.target.getAttribute(e.attributeName));
  }
  _handleMatchMedia(e) {
    this._mediaEnv = e.matches ? s.TOUCH : s.DESKTOP;
  }
  generateClass(e) {
    const t = this[e];
    return "string" == typeof t && t.length > 0 ? `${e}_${t}` : "";
  }
}
e([n({
  reflect: !0
})], a.prototype, "theme", void 0), e([n({
  reflect: !0,
  attribute: "zui-element",
  type: Boolean
})], a.prototype, "isElement", void 0), e([n({
  reflect: !0
})], a.prototype, "environment", null), e([i()], a.prototype, "_globalEnv", void 0), e([i()], a.prototype, "_mediaEnv", void 0);
class o extends a {}
export { a as BaseElement, s as Environment, o as RealBaseElement };
