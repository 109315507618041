import { a as o } from "./tslib.es6-7f1b1220.js";
import "./types-814d68fb.js";
import { isDefined as i } from "./component.utils.esm.min.js";
const e = (o = "default", i) => {
    var e, n;
    null === (n = null === (e = window.zui) || void 0 === e ? void 0 : e.portals) || void 0 === n || n.set(o, i);
  },
  n = (o = "default") => {
    var i, e, n;
    null === (i = d(o)) || void 0 === i || i.remove(), null === (n = null === (e = window.zui) || void 0 === e ? void 0 : e.portals) || void 0 === n || n.delete(o);
  },
  l = (o = "default") => {
    var e;
    return !!i(null === (e = window.zui) || void 0 === e ? void 0 : e.portals) && window.zui.portals.has(o);
  },
  d = (o = "default") => {
    if (l(o)) return window.zui.portals.get(o);
  },
  t = (i = "default", e) => o(void 0, void 0, void 0, function* () {
    return window.zui || (window.zui = {}), window.zui.portals || (window.zui.portals = new Map()), l(i) ? d(i) : new Promise(o => {
      const n = window.document.createElement("zui-portal");
      n.name = i, n.level = e, n.addEventListener("portal-ready", () => o(n), {
        once: !0,
        passive: !0
      }), window.document.body.appendChild(n);
    });
  }),
  r = (o, i) => {
    var e, n, l, t;
    let r;
    return r = i ? null === (n = null === (e = d(o)) || void 0 === e ? void 0 : e.shadowRoot) || void 0 === n ? void 0 : n.querySelectorAll(i) : null === (t = null === (l = d(o)) || void 0 === l ? void 0 : l.shadowRoot) || void 0 === t ? void 0 : t.children, Array.from(null != r ? r : []);
  },
  u = () => Math.random().toString(36).slice(-6),
  a = o => {
    const [i] = o.assignedElements();
    return i instanceof HTMLSlotElement ? a(i) : i;
  };
export { u as generateUid, r as getContentsFromPortal, d as getPortal, l as portalExists, t as preparePortal, e as registerPortal, n as unregisterPortal, a as unwrapFirstSlottedElement };
