import { _ as e } from "./tslib.es6-7f1b1220.js";
import { c as t, u as i, L as r, h as o, n as s } from "./lit-element-161a1699.js";
import { p as n, a as l, s as a, c } from "./decorators-7e9cd43b.js";
import { h as d } from "./host.styles-cf05db22.js";
import { i as h } from "./if-defined-123567fc.js";
import "./types-814d68fb.js";
import { deprecatedListWrapperConverter as u, getStringArrayConverter as p } from "./component.utils.esm.min.js";
import { getParentElementBySelector as v, getHostElementFromChild as g } from "./mixin.utils.esm.min.js";
import { generateUid as m } from "./portal.utils.esm.min.js";
const f = (e, t) => ({
  x: e,
  y: t,
  width: 0,
  height: 0,
  top: t,
  right: e,
  bottom: t,
  left: e
});
const _ = t`${i(":host {\n  position: absolute;\n  pointer-events: none;\n  width: 100%;\n}\n\n.tooltip-content {\n  position: absolute;\n  bottom: auto;\n  left: auto;\n  right: auto;\n  top: auto;\n  transition: opacity ease-in-out 0.1s;\n}\n:host([zui-internal-visible]) .tooltip-content {\n  opacity: 1;\n}\n:host(:not([zui-internal-visible])) .tooltip-content {\n  opacity: 0;\n}")}`,
  y = ["bottom-start", "bottom", "bottom-end", "top-start", "top", "top-end", "right-start", "right", "right-end", "left-start", "left", "left-end"],
  b = "tooltip",
  E = 300,
  L = 200,
  R = ["keydown"],
  P = ["focus", "mouseenter", "mousemove", "click"],
  k = ["blur", "mouseleave", "keydown"];
let C = class extends r {
  constructor() {
    super(...arguments), this.role = "tooltip", this.delayed = !1, this.anchoring = "cursor", this.trigger = ["click", "focus"], this.triggerHostSelector = "*", this.visible = !1, this.portal = `tooltip-${m()}`, this._clientRect = f(0, 0), this._triggerEvents = new Set(R), this._triggerListeners = new Map(), this._pseudoCursor = {
      getBoundingClientRect: () => this._clientRect
    }, this._hasBeenFocusedPreviously = !1;
  }
  connectedCallback() {
    super.connectedCallback(), this._parentElementRef = v(this.parentElement, this.triggerHostSelector), this._addTriggerListeners();
  }
  disconnectedCallback() {
    this._removeTriggerListeners(), this._removeMoveListener(), super.disconnectedCallback();
  }
  _deriveTriggerEvents() {
    this._triggerEvents.clear(), ((e = [], t = []) => e.reduce((e, t) => {
      switch (t) {
        case "click":
          return e.concat(["click", "mouseleave"]);
        case "focus":
          return e.concat(["blur", "focus"]);
        case "hover":
          return e.concat(["mouseenter", "mousemove", "mouseleave"]);
        default:
          return e;
      }
    }, t))(this.trigger, [...R]).forEach(e => this._triggerEvents.add(e));
  }
  _addTriggerListeners() {
    this._triggerEvents.forEach(e => {
      const t = () => this._handleParentEvent(e);
      this._parentElementRef.addEventListener(e, t, !1), this._triggerListeners.set(e, t);
    });
  }
  _removeTriggerListeners() {
    this._triggerListeners.forEach((e, t) => {
      this._parentElementRef.removeEventListener(t, e, !1);
    }), this._triggerListeners.clear();
  }
  _handleParentEvent(e) {
    if ("focus" === e && (this._hasBeenFocusedPreviously = !0), "click" === e && this._hasBeenFocusedPreviously) return void (this._hasBeenFocusedPreviously = !1);
    let t;
    P.includes(e) && (t = !0), k.includes(e) && (t = !1);
    const i = e => {
      this.visible !== e && (this.visible = e, requestAnimationFrame(() => {
        var e;
        return null === (e = this._overlayRef) || void 0 === e ? void 0 : e.forcePositioning();
      }));
    };
    this.delayed || !1 === t ? (window.clearTimeout(this._visibilityTimeout), this._visibilityTimeout = window.setTimeout(() => i(t), t ? 300 : 200)) : i(t);
  }
  _addMoveListener() {
    this._parentElementRef.addEventListener("mousemove", this._updatePseudoCursor.bind(this), !1);
    const {
      bottom: e,
      left: t
    } = this._parentElementRef.getBoundingClientRect();
    this._clientRect = f(t, e - 12);
  }
  _removeMoveListener() {
    this._parentElementRef.removeEventListener("mousemove", this._updatePseudoCursor.bind(this), !1);
  }
  _updatePseudoCursor({
    clientX: e,
    clientY: t
  }) {
    var i;
    this._clientRect = f(e, t), null === (i = this._overlayRef) || void 0 === i || i.forcePositioning();
  }
  getPositionReference() {
    return "cursor" === this.anchoring ? this._pseudoCursor : g(this, !1);
  }
  offsetHandler({
    placement: e
  }) {
    if ("cursor" === this.anchoring) {
      return [0, e.includes("bottom") || e.includes("right") ? 22 : 6];
    }
    return [0, 8];
  }
  updated(e) {
    e.has("trigger") && (this._deriveTriggerEvents(), this._removeTriggerListeners(), this._addTriggerListeners()), e.has("anchoring") && (this._removeMoveListener(), this.anchoring && this._addMoveListener()), super.updated(e);
  }
  render() {
    return o`${this.visible ? o`<zui-overlay-directive clone flip flip-padding="16" level="${h(this.level)}" portal="${h(this.portal)}" .offsetHandler="${this.offsetHandler.bind(this)}" .placements="${y}" .positionReferenceCallback="${this.getPositionReference.bind(this)}"><slot></slot></zui-overlay-directive>` : s}`;
  }
};
C.styles = [d, _], e([n({
  reflect: !0,
  type: String
})], C.prototype, "role", void 0), e([n({
  reflect: !0,
  type: Boolean
})], C.prototype, "delayed", void 0), e([n({
  reflect: !0,
  type: String
})], C.prototype, "anchoring", void 0), e([n({
  reflect: !0,
  converter: u(p())
})], C.prototype, "trigger", void 0), e([n({
  reflect: !0,
  attribute: "trigger-host-selector",
  type: String
})], C.prototype, "triggerHostSelector", void 0), e([n({
  reflect: !0,
  type: Boolean,
  attribute: "zui-internal-visible"
})], C.prototype, "visible", void 0), e([n({
  reflect: !0,
  type: String
})], C.prototype, "portal", void 0), e([n({
  reflect: !0,
  type: Number
})], C.prototype, "level", void 0), e([l("zui-overlay-directive")], C.prototype, "_overlayRef", void 0), e([a()], C.prototype, "_clientRect", void 0), C = e([c("zui-tooltip-directive")], C);
export { R as TOOLTIP_DEFAULT_EVENTS, _ as TOOLTIP_DIRECTIVE_STYLES, L as TOOLTIP_HIDE_DELAY, k as TOOLTIP_HIDE_EVENTS, y as TOOLTIP_PLACEMENTS, b as TOOLTIP_PORTAL, E as TOOLTIP_SHOW_DELAY, P as TOOLTIP_SHOW_EVENTS, C as TooltipDirective };
