import { a as e, _ as t } from "./tslib.es6-7f1b1220.js";
import { c as i, u as r, h as a, n as o } from "./lit-element-161a1699.js";
import { p as n, a as s, s as l, c as u } from "./decorators-7e9cd43b.js";
import "./class-map-31af68b2.js";
import { RealBaseElement as m } from "./BaseElement.esm.min.js";
import { h } from "./host.styles-cf05db22.js";
import { e as d } from "./event.decorator-98549cbc.js";
import "./interactive-icon.component.esm.min.js";
import { i as p } from "./if-defined-123567fc.js";
import "./types-814d68fb.js";
import { isDefined as c } from "./component.utils.esm.min.js";
import "./mixin.utils.esm.min.js";
import { F as v } from "./form-data-handling.mixin-3ddec2ca.js";
import "./dom.utils.esm.min.js";
import { F as _ } from "./form-validation.mixin-0a6c3c34.js";
import "./delegate-focus.mixin-95600d1f.js";
import "./style-map-a37d7105.js";
import "./_commonjsHelpers-82e0321a.js";
import { l as f, g as k, i as T } from "./date-picker.utils-7bc542a5.js";
import "./date-picker-input-part.component-63342ded.js";
import { generateUid as y } from "./portal.utils.esm.min.js";
import { u as g } from "./until-a96d9cd3.js";
import "./overlay.directive.esm.min.js";
import "./popover.component.esm.min.js";
import { d as E, h as P, g as I, t as w, a as R } from "./time-picker.utils-e0fd0976.js";
import "./interactive-text.component.esm.min.js";
import "./time-picker-day-time-toggle.component.esm.min.js";
import "./time-picker-cell.component.esm.min.js";
import "./time-picker.component.esm.min.js";
import "./time-picker-input-part-1e800973.js";
import "./time-picker-input-part-day-time.component.esm.min.js";
import "./time-picker-input-part-number.component.esm.min.js";
import { TimePickerInput as b } from "./time-picker-input.component.esm.min.js";
import "./tooltip.component.esm.min.js";
var D;
const C = i`${r(":host {\n  --zui-textfield-time-picker-input-hour-placeholder-width: calc(var(--zui-gu) * 2.5);\n  --zui-textfield-time-picker-input-minute-placeholder-width: calc(var(--zui-gu) * 3);\n  --zui-textfield-time-picker-input-width: 100%;\n  display: flex;\n}\n\nzui-time-picker-input {\n  --zui-time-picker-input-hour-placeholder-width: var(--zui-textfield-time-picker-input-hour-placeholder-width);\n  --zui-time-picker-input-minute-placeholder-width: var(--zui-textfield-time-picker-input-minute-placeholder-width);\n  --zui-time-picker-input-width: var(--zui-textfield-time-picker-input-width);\n}")}`;
let V = D = class extends _(v(m)) {
  constructor() {
    super(), this.defaultValue = f.DateTime.now().set({
      second: 0,
      millisecond: 0
    }).toJSDate(), this.level = 1e3, this.locale = k(), this.placeholderDayTime = "AM", this.placeholderHour = "HH", this.placeholderMinute = "MM", this.required = !1, this.value = null, this._showTimePicker = !1, this._userChange = !1, this._timePickerPortal = `time-picker-${y()}`, this._warningMessagePortal = `time-picker-warning-message-${y()}`, this._textfieldTimePickerResizeObserver = new ResizeObserver(() => {
      requestAnimationFrame(() => {
        var e;
        null === (e = this._textfieldTimePickerOverlayRef) || void 0 === e || e.forcePositioning();
      });
    }), this._handleKeydown = ({
      code: e
    }) => {
      this._showTimePicker && "Escape" === e && (this._showTimePicker = !1);
    }, this._handleOutsideClick = e => {
      if (!this._showTimePicker) return;
      const t = this.isSameNode(e.target) && e.composedPath().some(e => e instanceof b),
        i = e.target.getAttribute("name") === this._timePickerPortal;
      t || i || (this._showTimePicker = !1);
    }, this._maxValidator = () => !c(this._maxValueDT) || !c(this._valueDT) || E(this._valueDT, this._maxValueDT) >= 0, this._minValidator = () => !c(this._minValueDT) || !c(this._valueDT) || E(this._valueDT, this._minValueDT) <= 0, this._offset = () => [0, 8], this._requiredValidator = () => !this.required || c(this.value), this._timePickerPositionReference = () => this._timePickerInputRef, this.addValidator({
      type: "rangeOverflow",
      validator: this._maxValidator,
      validatesOnProperties: ["max"]
    }), this.addValidator({
      type: "rangeUnderflow",
      validator: this._minValidator,
      validatesOnProperties: ["min"]
    }), this.addValidator({
      type: "valueMissing",
      validator: this._requiredValidator,
      validatesOnProperties: ["required"]
    }), this.setDefaultValidityMessages({
      rangeOverflow: D.MAX_ERROR_MESSAGE,
      rangeUnderflow: D.MIN_ERROR_MESSAGE,
      valueMissing: D.REQUIRED_ERROR_MESSAGE
    });
  }
  static get styles() {
    return [h, C];
  }
  get _defaultValueDT() {
    return f.DateTime.fromJSDate(this.defaultValue);
  }
  get hourCycle() {
    return this._internalHourCycle ? this._internalHourCycle : P(this.locale) ? "h12" : "h23";
  }
  set hourCycle(e) {
    const t = this._internalHourCycle;
    this._internalHourCycle = e, this.requestUpdate("hourCycle", t);
  }
  get _maxValueDT() {
    return this.max ? f.DateTime.fromJSDate(this.max) : void 0;
  }
  get _minValueDT() {
    return this.min ? f.DateTime.fromJSDate(this.min) : void 0;
  }
  get _valueDT() {
    return this.value ? f.DateTime.fromJSDate(this.value) : void 0;
  }
  emitTextfieldTimePickerInputChangedEvent() {
    var e, t;
    this.dispatchEvent(new CustomEvent("textfield-time-picker-input-changed", {
      bubbles: !0,
      composed: !0,
      detail: {
        value: null !== (t = null === (e = this._valueDT) || void 0 === e ? void 0 : e.toISO()) && void 0 !== t ? t : null
      }
    }));
  }
  emitInputEvent() {
    this.dispatchEvent(new InputEvent("input", {
      bubbles: !0,
      cancelable: !1,
      composed: !1
    }));
  }
  emitChangeEvent() {
    this.dispatchEvent(new Event("change", {
      bubbles: !0,
      cancelable: !1,
      composed: !1
    }));
  }
  get _currentValueDT() {
    var e;
    return null !== (e = this._valueDT) && void 0 !== e ? e : this._defaultValueDT;
  }
  get _dayTime() {
    return this._is12HourFormat ? I(this._currentValueDT, this.hourCycle) : void 0;
  }
  get _formattedValue() {
    return this._valueDT ? this._valueDT.toFormat(this._timeFormat) : w.toAttribute(this._timePickerInputValue);
  }
  get _is12HourFormat() {
    return "h12" === this.hourCycle;
  }
  get _showInvalidWarning() {
    return this.willValidate && this.invalid && !this._showTimePicker;
  }
  get _timeFormat() {
    return "h12" === this.hourCycle ? D.FORMAT_TIME_H12 : D.FORMAT_TIME_H23;
  }
  connectedCallback() {
    super.connectedCallback(), window.addEventListener("click", this._handleOutsideClick), window.addEventListener("keydown", this._handleKeydown);
  }
  disconnectedCallback() {
    this._textfieldTimePickerResizeObserver.unobserve(this._timePickerInputRef), window.removeEventListener("click", this._handleOutsideClick), window.removeEventListener("keydown", this._handleKeydown), super.disconnectedCallback();
  }
  formResetCallback() {
    super.formResetCallback(), this._timePickerInputValue = f.DateTime.fromISO(this.resetValue).isValid ? w.fromAttribute(this.resetValue) : {
      dayTime: void 0,
      hour: void 0,
      minute: void 0
    };
  }
  _getDelayedInvalidWarningOrNothing() {
    return e(this, void 0, void 0, function* () {
      return this._showInvalidWarning ? (yield new Promise(e => setTimeout(() => {
        this._showInvalidWarning && e();
      }, D.INVALID_WARNING_DELAY)), a`<zui-overlay-directive .offsetHandler="${this._offset}" .positionReferenceCallback="${this._timePickerPositionReference}" level="${this.level}" placements="${D.WARNING_MESSAGE_PLACEMENTS.toString()}" portal="${this._warningMessagePortal}"><zui-tooltip emphasis="warning">${this.validationMessage}</zui-tooltip></zui-overlay-directive>`) : o;
    });
  }
  _handleTimePickerChangedEvent({
    detail: e
  }) {
    this._userChange = !0, this.value = new Date(e.value), this.emitInputEvent();
  }
  _handleTimePickerInputEvent() {
    this.emitInputEvent();
  }
  _handleTimePickerInputInputEvent() {
    this.emitInputEvent();
  }
  _handleTimePickerInputChangedEvent({
    detail: e
  }) {
    this._timePickerInputValue = Object.assign({}, this._timePickerInputRef.value), this._userChange = !0, this.value = c(e.value) ? new Date(e.value) : null, this.emitInputEvent();
  }
  _handleTimePickerInputFocusInEvent() {
    this._showTimePicker && (this._showTimePicker = !1);
  }
  _handleTimePickerInputToggle() {
    var e, t, i;
    if (this._showTimePicker = !this._showTimePicker, !this._showTimePicker) return;
    if (requestAnimationFrame(() => {
      var e;
      null === (e = this._textfieldTimePickerOverlayRef) || void 0 === e || e.forcePositioning();
    }), c(this.value)) return;
    const {
      dayTime: r,
      hour: a,
      minute: o
    } = null !== (e = this._timePickerInputRef.value) && void 0 !== e ? e : {
      dayTime: void 0,
      hour: void 0,
      minute: void 0
    };
    (this._is12HourFormat ? c(r) || c(a) || c(o) : c(a) || c(o)) && (this._userChange = !0, this.value = R({
      hour: null != a ? a : null === (t = this._defaultValueDT) || void 0 === t ? void 0 : t.hour,
      minute: null != o ? o : null === (i = this._defaultValueDT) || void 0 === i ? void 0 : i.minute
    }, this._defaultValueDT, this.hourCycle, null != r ? r : this._dayTime).toJSDate(), this.emitInputEvent());
  }
  firstUpdated(e) {
    super.firstUpdated(e), this._textfieldTimePickerResizeObserver.observe(this._timePickerInputRef);
  }
  updated(e) {
    super.updated(e), e.has("value") && this._userChange && (this.emitChangeEvent(), this.emitTextfieldTimePickerInputChangedEvent(), this._userChange = !1);
  }
  render() {
    return a`<zui-time-picker-input zuiFormControl ?disabled="${this.disabled}" ?readonly="${this.readonly}" ?invalid="${this.willValidate && this.invalid}" hour-cycle="${this.hourCycle}" placeholder-day-time="${this.placeholderDayTime}" placeholder-hour="${this.placeholderHour}" placeholder-minute="${this.placeholderMinute}" default-value="${this._defaultValueDT.toFormat("HH:mm")}" value="${p(this._formattedValue)}" @focusin="${this._handleTimePickerInputFocusInEvent}" @time-picker-input-changed="${this._handleTimePickerInputChangedEvent}" @time-picker-input-input="${this._handleTimePickerInputInputEvent}" @time-picker-input-toggle="${this._handleTimePickerInputToggle}"></zui-time-picker-input>${g(this._getDelayedInvalidWarningOrNothing())} ${this._showTimePicker ? a`<zui-overlay-directive .offsetHandler="${this._offset}" .positionReferenceCallback="${this._timePickerPositionReference}" flip id="textfield-time-picker" level="${this.level}" placements="${D.TIME_PICKER_PLACEMENTS.toString()}" portal="${this._timePickerPortal}"><zui-popover style="width:auto;min-width:auto;padding:32px"><zui-time-picker ?disabled="${this.disabled}" default-value="${this._defaultValueDT}" hour-cycle="${this.hourCycle}" locale="${this.locale}" value="${p(this._valueDT)}" @time-picker-changed="${this._handleTimePickerChangedEvent}" @time-picker-input="${this._handleTimePickerInputEvent}"></zui-time-picker></zui-popover></zui-overlay-directive>` : o}`;
  }
};
V.FORMAT_TIME_H12 = "hh:mm a", V.FORMAT_TIME_H23 = "HH:mm", V.INVALID_WARNING_DELAY = 500, V.MAX_ERROR_MESSAGE = "This time is not allowed.", V.MIN_ERROR_MESSAGE = "This time is not allowed.", V.REQUIRED_ERROR_MESSAGE = "The value is required.", V.TIME_PICKER_PLACEMENTS = ["bottom-end", "bottom-start"], V.WARNING_MESSAGE_PLACEMENTS = ["bottom-start"], t([n({
  reflect: !0,
  type: String,
  attribute: "default-value",
  converter: T
})], V.prototype, "defaultValue", void 0), t([n({
  reflect: !0,
  type: String,
  attribute: "hour-cycle"
})], V.prototype, "hourCycle", null), t([n({
  reflect: !0,
  type: Number
})], V.prototype, "level", void 0), t([n({
  reflect: !0,
  type: String
})], V.prototype, "locale", void 0), t([n({
  reflect: !0,
  type: String,
  converter: T
})], V.prototype, "max", void 0), t([n({
  reflect: !0,
  type: String,
  converter: T
})], V.prototype, "min", void 0), t([n({
  reflect: !0,
  type: String,
  attribute: "placeholder-day-time"
})], V.prototype, "placeholderDayTime", void 0), t([n({
  reflect: !0,
  type: String,
  attribute: "placeholder-hour"
})], V.prototype, "placeholderHour", void 0), t([n({
  reflect: !0,
  type: String,
  attribute: "placeholder-minute"
})], V.prototype, "placeholderMinute", void 0), t([n({
  reflect: !0,
  type: Boolean
})], V.prototype, "required", void 0), t([n({
  reflect: !0,
  type: String,
  converter: T
})], V.prototype, "value", void 0), t([d()], V.prototype, "emitTextfieldTimePickerInputChangedEvent", null), t([d()], V.prototype, "emitInputEvent", null), t([d()], V.prototype, "emitChangeEvent", null), t([s("zui-overlay-directive#textfield-time-picker")], V.prototype, "_textfieldTimePickerOverlayRef", void 0), t([s("zui-time-picker-input")], V.prototype, "_timePickerInputRef", void 0), t([l()], V.prototype, "_showTimePicker", void 0), t([l()], V.prototype, "_internalHourCycle", void 0), t([l()], V.prototype, "_timePickerInputValue", void 0), V = D = t([u("zui-textfield-time-picker")], V);
export { V as TextfieldTimePicker };
