import { _ as e } from "./tslib.es6-7f1b1220.js";
import { c as t, u as n, h as i, n as a } from "./lit-element-161a1699.js";
import { p as r, a as s, q as o, s as l, c as h } from "./decorators-7e9cd43b.js";
import { c } from "./class-map-31af68b2.js";
import { RealBaseElement as p } from "./BaseElement.esm.min.js";
import { h as u } from "./host.styles-cf05db22.js";
import { e as d } from "./event.decorator-98549cbc.js";
import "./interactive-icon.component.esm.min.js";
import { i as m } from "./if-defined-123567fc.js";
import "./types-814d68fb.js";
import { numberUndefinedConverter as v, stringUndefinedConverter as b } from "./component.utils.esm.min.js";
import "./mixin.utils.esm.min.js";
import { F as g } from "./form-data-handling.mixin-3ddec2ca.js";
import "./dom.utils.esm.min.js";
import { F as f } from "./form-validation.mixin-0a6c3c34.js";
import { D as y } from "./delegate-focus.mixin-95600d1f.js";
import "./style-map-a37d7105.js";
import "./portal.utils.esm.min.js";
import "./error-message.component.esm.min.js";
import "./unsafe-html-97738913.js";
import "./select.component.esm.min.js";
import "./select-divider.component.esm.min.js";
import "./select-option.component.esm.min.js";
import "./select-all.component.esm.min.js";
import "./select-menu.component.esm.min.js";
import "./select-placeholder.component.esm.min.js";
import "./isObject-07b7923e.js";
const x = t`${n(':host {\n  --zui-color-searchbar-placeholder-text: var(--zui-color-gs-80);\n  background-color: var(--zui-color-searchbar-input-background);\n  border-radius: 18px;\n  box-sizing: border-box;\n  display: flex;\n  height: calc(var(--zui-gu) * 4.5);\n  max-width: calc(var(--zui-gu) * 67);\n  min-width: calc(var(--zui-gu) * 40);\n  padding: 0 calc(var(--zui-gu) * 2);\n}\n\n:host([disabled]) {\n  pointer-events: none;\n}\n\n:host([readonly]),\n:host([disabled]) {\n  opacity: var(--zui-disabled-opacity);\n}\n\nslot[name=filter] {\n  display: inline-flex;\n  height: calc(var(--zui-gu) * 4.5);\n}\n\n.searchbar-input-wrapper {\n  align-items: center;\n  display: flex;\n  flex: 1;\n  height: 100%;\n}\n\n.input-wrapper {\n  flex: 1;\n}\n.searchbar-input-wrapper.has-filter .input-wrapper:before {\n  content: "";\n  border-right: 1px solid var(--zui-color-searchbar-filter-divider);\n  box-sizing: border-box;\n  height: calc(var(--zui-gu) * 3);\n  margin: 0 calc(var(--zui-gu) * 1);\n}\n\n#searchbar-input {\n  background: transparent;\n  border: 0;\n  box-shadow: none;\n  color: var(--zui-color-searchbar-query-text);\n  font: var(--zui-typography-body);\n  outline: 0;\n  padding: 0;\n  text-overflow: ellipsis;\n  width: 100%;\n}\n#searchbar-input::placeholder {\n  color: var(--zui-color-searchbar-placeholder-text);\n}\n:host(:not([readonly])) #searchbar-input:focus-within::placeholder {\n  color: transparent;\n}\n\n#close-icon,\n#search-icon {\n  margin: 2px 0 2px 4px;\n}\n\n#search-icon {\n  color: var(--zui-color-searchbar-search-icon);\n  flex-shrink: 0;\n}')}`;
let _ = class extends f(g(y(p))) {
  constructor() {
    super(), this.placeholder = "", this.required = !1, this._hasValue = !1, this.addValidator({
      type: "patternMismatch",
      validatesOnProperties: ["pattern"],
      validator: () => !this._searchbarInput.validity.patternMismatch
    }), this.addValidator({
      type: "tooLong",
      validatesOnProperties: ["maxlength"],
      validator: () => !(void 0 !== this.value && this.value.length > 0 && this.maxlength) || this.value.length <= this.maxlength
    }), this.addValidator({
      type: "tooShort",
      validatesOnProperties: ["minlength"],
      validator: () => !(void 0 !== this.value && this.value.length > 0 && this.minlength) || this.value.length >= this.minlength
    }), this.addValidator({
      type: "valueMissing",
      validatesOnProperties: ["required"],
      validator: () => !this._searchbarInput.validity.valueMissing
    });
  }
  static get styles() {
    return [u, x];
  }
  get value() {
    return this._internalValue;
  }
  set value(e) {
    const t = this.value;
    this._internalValue = e, this._hasValue = void 0 !== e && 0 !== e.length, this.requestUpdate("value", t);
  }
  emitInputEvent() {
    this.dispatchEvent(new InputEvent("input", {
      bubbles: !0,
      cancelable: !1,
      composed: !0
    }));
  }
  emitChangeEvent() {
    this.dispatchEvent(new Event("change", {
      bubbles: !0,
      cancelable: !1,
      composed: !1
    }));
  }
  emitSearchbarInputChangedEvent(e) {
    this.dispatchEvent(new CustomEvent("searchbar-input-changed", {
      bubbles: !0,
      composed: !0,
      detail: {
        value: void 0 !== e ? e : ""
      }
    }));
  }
  get _hasFilter() {
    return null !== this._assignedFilterMenu && this._assignedFilterMenu.length > 0;
  }
  get _showClearInputIcon() {
    return this._hasValue && !this.disabled && !this.readonly;
  }
  get _showSearchIcon() {
    return !this._hasValue;
  }
  _handleClearInputValue() {
    this.value = "", this._searchbarInput.focus(), this.emitInputEvent(), this.emitSearchbarInputChangedEvent(this.value);
  }
  _handleSearchbarInputEvent() {
    this._hasValue = this._searchbarInput.value.length > 0, this.emitSearchbarInputChangedEvent(this._searchbarInput.value);
  }
  _handleSearchbarInputChangeEvent() {
    this.value = this._searchbarInput.value, this.emitChangeEvent();
  }
  _handleSlotChange() {
    this.requestUpdate();
  }
  render() {
    return i`<div class="${c({
      "searchbar-input-wrapper": !0,
      "has-filter": this._hasFilter
    })}"><slot name="filter" @slotchange="${this._handleSlotChange}"></slot><div class="input-wrapper"><input zuiFormControl .value="${void 0 !== this.value ? this.value : null}" ?disabled="${this.disabled}" ?readonly="${this.readonly}" ?required="${this.required}" ?zuiCaptureFocus="${!this.disabled}" id="searchbar-input" maxlength="${m(this.maxlength)}" minlength="${m(this.minlength)}" pattern="${m(this.pattern)}" placeholder="${this.placeholder}" type="text" @change="${this._handleSearchbarInputChangeEvent}" @input="${this._handleSearchbarInputEvent}"></div>${this._showClearInputIcon ? i`<zui-interactive-icon id="close-icon" emphasis="subtle"><zui-icon-close size="s" @click="${this._handleClearInputValue}"></zui-icon-close></zui-interactive-icon>` : a} ${this._showSearchIcon ? i`<zui-icon-search-search id="search-icon" size="m"></zui-icon-search-search>` : a}</div>`;
  }
};
e([r({
  reflect: !0,
  converter: v
})], _.prototype, "maxlength", void 0), e([r({
  reflect: !0,
  converter: v
})], _.prototype, "minlength", void 0), e([r({
  reflect: !0,
  converter: b
})], _.prototype, "pattern", void 0), e([r({
  reflect: !0,
  type: String
})], _.prototype, "placeholder", void 0), e([r({
  reflect: !0,
  type: Boolean
})], _.prototype, "required", void 0), e([r({
  reflect: !0,
  converter: b
})], _.prototype, "value", null), e([d()], _.prototype, "emitInputEvent", null), e([d()], _.prototype, "emitChangeEvent", null), e([d()], _.prototype, "emitSearchbarInputChangedEvent", null), e([s("#searchbar-input")], _.prototype, "_searchbarInput", void 0), e([o("filter", !0, "zui-select")], _.prototype, "_assignedFilterMenu", void 0), e([l()], _.prototype, "_hasValue", void 0), _ = e([h("zui-searchbar-input")], _);
export { _ as SearchbarInput };
