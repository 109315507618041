import { _ as s } from "./tslib.es6-7f1b1220.js";
import { c as o, u as t, h as e } from "./lit-element-161a1699.js";
import { c as r } from "./decorators-7e9cd43b.js";
import { BaseElement as l } from "./BaseElement.esm.min.js";
import { h as n } from "./host.styles-cf05db22.js";
const a = o`${t(":host {\n  font: var(--zui-typography-label2);\n  color: var(--zui-color-slider-tick-label);\n}\n\n:host(:focus) {\n  outline: none;\n}")}`;
let i = class extends l {
  render() {
    return e`<slot></slot>`;
  }
};
i.styles = [n, a], i = s([r("zui-slider-tick-label")], i);
export { i as SliderTickLabel };
