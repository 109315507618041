import { _ as t } from "./tslib.es6-7f1b1220.js";
import { c as n, u as o, h as e, n as i } from "./lit-element-161a1699.js";
import { p as r, a as s, q as a, s as c, c as l } from "./decorators-7e9cd43b.js";
import { BaseElement as d } from "./BaseElement.esm.min.js";
import { h as u } from "./host.styles-cf05db22.js";
import "./tooltip.component.esm.min.js";
const p = n`${o(":host {\n  display: inline-block;\n}\n\n.tooltip-container {\n  display: inline-block;\n  border-radius: 12px;\n}\n\nbutton {\n  align-items: center;\n  box-sizing: border-box;\n  display: inline-flex;\n  justify-content: center;\n  color: var(--zui-color-tag-text);\n  cursor: pointer;\n  background-color: var(--zui-color-tag-background-enabled);\n  border-radius: 12px;\n  border: 1px solid var(--zui-color-tag-border);\n  font: var(--zui-typography-caption);\n  height: calc(var(--zui-gu) * 3);\n  min-width: calc(var(--zui-gu) * 4);\n  max-width: calc(var(--zui-gu) * 20);\n  padding: 0 calc(var(--zui-gu) * 1);\n  margin: 0;\n}\n\n:host(:not([disabled]):not([non-interactive])) button:hover {\n  background-color: var(--zui-color-tag-background-hovered);\n}\n\n:host(:not([disabled]):not([non-interactive])) button:active {\n  background-color: var(--zui-color-tag-background-active);\n}\n\nbutton:focus {\n  outline: none;\n}\n\n:host([non-interactive]) button {\n  cursor: default;\n}\n\n:host([disabled]) button {\n  pointer-events: none;\n}\n\n:host([disabled]) button {\n  background-color: var(--zui-color-tag-background-enabled);\n  opacity: 0.5;\n}\n\n:host slot[name=icon]::slotted(*) {\n  margin-left: calc(var(--zui-gu) * 1);\n}\n\n.text-container {\n  min-height: 1em;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}")}`;
let v = class extends d {
  constructor() {
    super(...arguments), this.disabled = !1, this.nonInteractive = !1, this._activeEllipsis = !1;
  }
  static get styles() {
    return [u, p];
  }
  _updateIconSize() {
    this._iconSlotNodes.forEach(t => t.setAttribute("size", "s"));
  }
  _checkActiveEllipsis() {
    this._activeEllipsis = this._textContainerRef.offsetWidth < this._textContainerRef.scrollWidth;
  }
  updated(t) {
    t.has("text") && requestAnimationFrame(() => this._checkActiveEllipsis());
  }
  render() {
    return e`<zui-div class="tooltip-container"><button ?disabled="${this.disabled}"><span class="text-container">${this.text}</span><slot name="icon" @slotchange="${this._updateIconSize}" slot="icon"></slot></button> ${this._activeEllipsis ? e`<zui-tooltip-directive anchoring="cursor" trigger="hover,focus"><zui-tooltip>${this.text}</zui-tooltip></zui-tooltip-directive>` : i}</zui-div>`;
  }
};
t([r({
  reflect: !0,
  type: Boolean
})], v.prototype, "disabled", void 0), t([r({
  reflect: !0,
  type: String
})], v.prototype, "value", void 0), t([r({
  reflect: !0,
  type: String
})], v.prototype, "text", void 0), t([r({
  reflect: !0,
  type: Boolean,
  attribute: "non-interactive"
})], v.prototype, "nonInteractive", void 0), t([s(".text-container")], v.prototype, "_textContainerRef", void 0), t([a("icon", !0, "[zuiIcon]")], v.prototype, "_iconSlotNodes", void 0), t([c()], v.prototype, "_activeEllipsis", void 0), v = t([l("zui-tag")], v);
export { v as Tag };
