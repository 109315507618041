import { _ as e } from "./tslib.es6-7f1b1220.js";
import { c as t, u as s, h as r } from "./lit-element-161a1699.js";
import { p as o, a as i, c as n } from "./decorators-7e9cd43b.js";
import { BaseElement as a } from "./BaseElement.esm.min.js";
import { h as l } from "./host.styles-cf05db22.js";
import { ListItem as h } from "./list-item.component.esm.min.js";
const d = t`${s(":host {\n  overflow-x: hidden;\n}")}`,
  u = {
    xs: 3,
    s: 4,
    m: 6,
    l: 9,
    xl: 13
  };
let p = class extends a {
  constructor() {
    super(...arguments), this._disabled = !1, this._hierarchy = "first", this._readonly = !1, this._integrated = !1, this._size = "m", this._heightObserver = new ResizeObserver(() => {
      "number" != typeof this.numberOfSkeletons && this.requestUpdate();
    });
  }
  static get styles() {
    return [l, d];
  }
  get disabled() {
    return this._disabled;
  }
  set disabled(e) {
    this._updatePropertyAndSlot("disabled", e);
  }
  get hierarchy() {
    return this._hierarchy;
  }
  set hierarchy(e) {
    this._updatePropertyAndSlot("hierarchy", e);
  }
  get readonly() {
    return this._readonly;
  }
  set readonly(e) {
    this._updatePropertyAndSlot("readonly", e);
  }
  get integrated() {
    return this._integrated;
  }
  set integrated(e) {
    this._updatePropertyAndSlot("integrated", e);
  }
  get size() {
    return this._size;
  }
  set size(e) {
    this._updatePropertyAndSlot("size", e);
  }
  _updatePropertyAndSlot(e, t, s) {
    const r = this[e];
    this[`_${e}`] = t;
    const o = s || e;
    this._slotItem && this._slotItem.assignedNodes().filter(e => e instanceof h).forEach(e => {
      t ? "boolean" == typeof t ? e.setAttribute(o, "") : e.setAttribute(o, t.toString()) : e.removeAttribute(o);
    }), this.requestUpdate(e, r);
  }
  _getSkeletonHeight() {
    return 8 * u[this.size];
  }
  _getNumberOfSkeletons() {
    if ("number" == typeof this.numberOfSkeletons) return this.numberOfSkeletons;
    const e = Number(getComputedStyle(this).height.replace("px", "")) / 2 / this._getSkeletonHeight();
    return Math.ceil(e);
  }
  _generateSkeletons() {
    const e = [],
      t = this._getNumberOfSkeletons();
    for (let s = 0; s < t; s++) e.push(this._createSkeletonListItem());
    return e;
  }
  _createSkeletonListItem() {
    if (!(this.skeleton instanceof HTMLTemplateElement)) {
      const e = new h();
      return this._updateListItemAttributes(e), e;
    }
    const e = this.skeleton.content.cloneNode(!0);
    return e instanceof DocumentFragment && Array.from(e.children).forEach(e => this._updateListItemAttributes(e)), e;
  }
  _slotChangeHandler() {
    this._slotItem.assignedNodes().filter(e => e instanceof h).forEach(e => {
      this._updateListItemAttributes(e);
    });
  }
  _updateListItemAttributes(e) {
    if (e instanceof h) e.disabled = this.disabled, e.integrated = this.integrated, e.readonly = this.readonly, e.size = this.size, e.hierarchy = this.hierarchy;else if (e instanceof Element && "zui-list-item" === e.nodeName.toLowerCase()) {
      const t = t => this[t] ? e.setAttribute(t, this[t].toString()) : e.removeAttribute(t);
      ["readonly", "integrated", "disabled"].forEach(t => this[t] ? e.setAttribute(t, t) : e.removeAttribute(t)), ["size", "hierarchy"].forEach(t);
    }
  }
  update(e) {
    super.update(e), e.has("numberOfSkeletons") && console.warn("Deprecated attribute number-of-skeletons was used on zui-list.  The skeleton generation function of the list doesn't get supported anymore."), e.has("skeleton") && console.warn("Deprecated attribute skeleton was used on zui-list. The skeleton generation function of the list doesn't get supported anymore."), e.has("skeletonReact") && console.warn("Deprecated attribute skeletonReact was used on zui-list. The skeleton generation function of the list doesn't get supported anymore.");
  }
  firstUpdated(e) {
    super.firstUpdated(e), this._heightObserver.observe(this);
  }
  render() {
    return r`<slot @slotchange="${this._slotChangeHandler}">${this._generateSkeletons()}</slot>`;
  }
};
e([o({
  reflect: !0,
  type: Boolean
})], p.prototype, "disabled", null), e([o({
  reflect: !0
})], p.prototype, "hierarchy", null), e([o({
  reflect: !0,
  type: Boolean
})], p.prototype, "readonly", null), e([o({
  reflect: !0,
  type: Boolean
})], p.prototype, "integrated", null), e([o({
  reflect: !0
})], p.prototype, "size", null), e([o({
  reflect: !0,
  type: Number,
  attribute: "number-of-skeletons"
})], p.prototype, "numberOfSkeletons", void 0), e([o({
  attribute: !1
})], p.prototype, "skeleton", void 0), e([o({
  attribute: !1
})], p.prototype, "skeletonReact", void 0), e([i("slot:not([name])")], p.prototype, "_slotItem", void 0), p = e([n("zui-list")], p);
export { p as List };
