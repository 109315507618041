import { c as n, a as e } from "./_commonjsHelpers-82e0321a.js";
var i = n(function (n) {
  !function (e) {
    var i,
      r,
      t,
      s,
      o = 9e15,
      u = 1e9,
      c = "0123456789abcdef",
      f = "2.3025850929940456840179914546843642076011014886287729760333279009675726096773524802359972050895982983419677840422862486334095254650828067566662873690987816894829072083255546808437998948262331985283935053089653777326288461633662222876982198867465436674744042432743651550489343149393914796194044002221051017141748003688084012647080685567743216228355220114804663715659121373450747856947683463616792101806445070648000277502684916746550586856935673420670581136429224554405758925724208241314695689016758940256776311356919292033376587141660230105703089634572075440370847469940168269282808481184289314848524948644871927809676271275775397027668605952496716674183485704422507197965004714951050492214776567636938662976979522110718264549734772662425709429322582798502585509785265383207606726317164309505995087807523710333101197857547331541421808427543863591778117054309827482385045648019095610299291824318237525357709750539565187697510374970888692180205189339507238539205144634197265287286965110862571492198849978748873771345686209167058",
      a = "3.1415926535897932384626433832795028841971693993751058209749445923078164062862089986280348253421170679821480865132823066470938446095505822317253594081284811174502841027019385211055596446229489549303819644288109756659334461284756482337867831652712019091456485669234603486104543266482133936072602491412737245870066063155881748815209209628292540917153643678925903600113305305488204665213841469519415116094330572703657595919530921861173819326117931051185480744623799627495673518857527248912279381830119491298336733624406566430860213949463952247371907021798609437027705392171762931767523846748184676694051320005681271452635608277857713427577896091736371787214684409012249534301465495853710507922796892589235420199561121290219608640344181598136297747713099605187072113499999983729780499510597317328160963185950244594553469083026425223082533446850352619311881710100031378387528865875332083814206171776691473035982534904287554687311595628638823537875937519577818577805321712268066130019278766111959092164201989380952572010654858632789",
      l = {
        precision: 20,
        rounding: 4,
        modulo: 1,
        toExpNeg: -7,
        toExpPos: 21,
        minE: -o,
        maxE: o,
        crypto: !1
      },
      h = !0,
      d = "[DecimalError] ",
      p = d + "Invalid argument: ",
      g = d + "Precision limit exceeded",
      m = d + "crypto unavailable",
      w = "[object Decimal]",
      v = Math.floor,
      N = Math.pow,
      b = /^0b([01]+(\.[01]*)?|\.[01]+)(p[+-]?\d+)?$/i,
      x = /^0x([0-9a-f]+(\.[0-9a-f]*)?|\.[0-9a-f]+)(p[+-]?\d+)?$/i,
      E = /^0o([0-7]+(\.[0-7]*)?|\.[0-7]+)(p[+-]?\d+)?$/i,
      y = /^(\d+(\.\d*)?|\.\d+)(e[+-]?\d+)?$/i,
      M = 1e7,
      q = f.length - 1,
      O = a.length - 1,
      D = {
        toStringTag: w
      };
    function F(n) {
      var e,
        i,
        r,
        t = n.length - 1,
        s = "",
        o = n[0];
      if (t > 0) {
        for (s += o, e = 1; e < t; e++) (i = 7 - (r = n[e] + "").length) && (s += C(i)), s += r;
        (i = 7 - (r = (o = n[e]) + "").length) && (s += C(i));
      } else if (0 === o) return "0";
      for (; o % 10 == 0;) o /= 10;
      return s + o;
    }
    function A(n, e, i) {
      if (n !== ~~n || n < e || n > i) throw Error(p + n);
    }
    function S(n, e, i, r) {
      var t, s, o, u;
      for (s = n[0]; s >= 10; s /= 10) --e;
      return --e < 0 ? (e += 7, t = 0) : (t = Math.ceil((e + 1) / 7), e %= 7), s = N(10, 7 - e), u = n[t] % s | 0, null == r ? e < 3 ? (0 == e ? u = u / 100 | 0 : 1 == e && (u = u / 10 | 0), o = i < 4 && 99999 == u || i > 3 && 49999 == u || 5e4 == u || 0 == u) : o = (i < 4 && u + 1 == s || i > 3 && u + 1 == s / 2) && (n[t + 1] / s / 100 | 0) == N(10, e - 2) - 1 || (u == s / 2 || 0 == u) && 0 == (n[t + 1] / s / 100 | 0) : e < 4 ? (0 == e ? u = u / 1e3 | 0 : 1 == e ? u = u / 100 | 0 : 2 == e && (u = u / 10 | 0), o = (r || i < 4) && 9999 == u || !r && i > 3 && 4999 == u) : o = ((r || i < 4) && u + 1 == s || !r && i > 3 && u + 1 == s / 2) && (n[t + 1] / s / 1e3 | 0) == N(10, e - 3) - 1, o;
    }
    function Z(n, e, i) {
      for (var r, t, s = [0], o = 0, u = n.length; o < u;) {
        for (t = s.length; t--;) s[t] *= e;
        for (s[0] += c.indexOf(n.charAt(o++)), r = 0; r < s.length; r++) s[r] > i - 1 && (void 0 === s[r + 1] && (s[r + 1] = 0), s[r + 1] += s[r] / i | 0, s[r] %= i);
      }
      return s.reverse();
    }
    D.absoluteValue = D.abs = function () {
      var n = new this.constructor(this);
      return n.s < 0 && (n.s = 1), R(n);
    }, D.ceil = function () {
      return R(new this.constructor(this), this.e + 1, 2);
    }, D.clampedTo = D.clamp = function (n, e) {
      var i = this,
        r = i.constructor;
      if (n = new r(n), e = new r(e), !n.s || !e.s) return new r(NaN);
      if (n.gt(e)) throw Error(p + e);
      return i.cmp(n) < 0 ? n : i.cmp(e) > 0 ? e : new r(i);
    }, D.comparedTo = D.cmp = function (n) {
      var e,
        i,
        r,
        t,
        s = this,
        o = s.d,
        u = (n = new s.constructor(n)).d,
        c = s.s,
        f = n.s;
      if (!o || !u) return c && f ? c !== f ? c : o === u ? 0 : !o ^ c < 0 ? 1 : -1 : NaN;
      if (!o[0] || !u[0]) return o[0] ? c : u[0] ? -f : 0;
      if (c !== f) return c;
      if (s.e !== n.e) return s.e > n.e ^ c < 0 ? 1 : -1;
      for (e = 0, i = (r = o.length) < (t = u.length) ? r : t; e < i; ++e) if (o[e] !== u[e]) return o[e] > u[e] ^ c < 0 ? 1 : -1;
      return r === t ? 0 : r > t ^ c < 0 ? 1 : -1;
    }, D.cosine = D.cos = function () {
      var n,
        e,
        i = this,
        r = i.constructor;
      return i.d ? i.d[0] ? (n = r.precision, e = r.rounding, r.precision = n + Math.max(i.e, i.sd()) + 7, r.rounding = 1, i = function (n, e) {
        var i, r, t;
        if (e.isZero()) return e;
        (r = e.d.length) < 32 ? t = (1 / G(4, i = Math.ceil(r / 3))).toString() : (i = 16, t = "2.3283064365386962890625e-10");
        n.precision += i, e = z(n, 1, e.times(t), new n(1));
        for (var s = i; s--;) {
          var o = e.times(e);
          e = o.times(o).minus(o).times(8).plus(1);
        }
        return n.precision -= i, e;
      }(r, K(r, i)), r.precision = n, r.rounding = e, R(2 == s || 3 == s ? i.neg() : i, n, e, !0)) : new r(1) : new r(NaN);
    }, D.cubeRoot = D.cbrt = function () {
      var n,
        e,
        i,
        r,
        t,
        s,
        o,
        u,
        c,
        f,
        a = this,
        l = a.constructor;
      if (!a.isFinite() || a.isZero()) return new l(a);
      for (h = !1, (s = a.s * N(a.s * a, 1 / 3)) && Math.abs(s) != 1 / 0 ? r = new l(s.toString()) : (i = F(a.d), (s = ((n = a.e) - i.length + 1) % 3) && (i += 1 == s || -2 == s ? "0" : "00"), s = N(i, 1 / 3), n = v((n + 1) / 3) - (n % 3 == (n < 0 ? -1 : 2)), (r = new l(i = s == 1 / 0 ? "5e" + n : (i = s.toExponential()).slice(0, i.indexOf("e") + 1) + n)).s = a.s), o = (n = l.precision) + 3;;) if (f = (c = (u = r).times(u).times(u)).plus(a), r = P(f.plus(a).times(u), f.plus(c), o + 2, 1), F(u.d).slice(0, o) === (i = F(r.d)).slice(0, o)) {
        if ("9999" != (i = i.slice(o - 3, o + 1)) && (t || "4999" != i)) {
          +i && (+i.slice(1) || "5" != i.charAt(0)) || (R(r, n + 1, 1), e = !r.times(r).times(r).eq(a));
          break;
        }
        if (!t && (R(u, n + 1, 0), u.times(u).times(u).eq(a))) {
          r = u;
          break;
        }
        o += 4, t = 1;
      }
      return h = !0, R(r, n, l.rounding, e);
    }, D.decimalPlaces = D.dp = function () {
      var n,
        e = this.d,
        i = NaN;
      if (e) {
        if (i = 7 * ((n = e.length - 1) - v(this.e / 7)), n = e[n]) for (; n % 10 == 0; n /= 10) i--;
        i < 0 && (i = 0);
      }
      return i;
    }, D.dividedBy = D.div = function (n) {
      return P(this, new this.constructor(n));
    }, D.dividedToIntegerBy = D.divToInt = function (n) {
      var e = this.constructor;
      return R(P(this, new e(n), 0, 1, 1), e.precision, e.rounding);
    }, D.equals = D.eq = function (n) {
      return 0 === this.cmp(n);
    }, D.floor = function () {
      return R(new this.constructor(this), this.e + 1, 3);
    }, D.greaterThan = D.gt = function (n) {
      return this.cmp(n) > 0;
    }, D.greaterThanOrEqualTo = D.gte = function (n) {
      var e = this.cmp(n);
      return 1 == e || 0 === e;
    }, D.hyperbolicCosine = D.cosh = function () {
      var n,
        e,
        i,
        r,
        t,
        s = this,
        o = s.constructor,
        u = new o(1);
      if (!s.isFinite()) return new o(s.s ? 1 / 0 : NaN);
      if (s.isZero()) return u;
      i = o.precision, r = o.rounding, o.precision = i + Math.max(s.e, s.sd()) + 4, o.rounding = 1, (t = s.d.length) < 32 ? e = (1 / G(4, n = Math.ceil(t / 3))).toString() : (n = 16, e = "2.3283064365386962890625e-10"), s = z(o, 1, s.times(e), new o(1), !0);
      for (var c, f = n, a = new o(8); f--;) c = s.times(s), s = u.minus(c.times(a.minus(c.times(a))));
      return R(s, o.precision = i, o.rounding = r, !0);
    }, D.hyperbolicSine = D.sinh = function () {
      var n,
        e,
        i,
        r,
        t = this,
        s = t.constructor;
      if (!t.isFinite() || t.isZero()) return new s(t);
      if (e = s.precision, i = s.rounding, s.precision = e + Math.max(t.e, t.sd()) + 4, s.rounding = 1, (r = t.d.length) < 3) t = z(s, 2, t, t, !0);else {
        n = (n = 1.4 * Math.sqrt(r)) > 16 ? 16 : 0 | n, t = z(s, 2, t = t.times(1 / G(5, n)), t, !0);
        for (var o, u = new s(5), c = new s(16), f = new s(20); n--;) o = t.times(t), t = t.times(u.plus(o.times(c.times(o).plus(f))));
      }
      return s.precision = e, s.rounding = i, R(t, e, i, !0);
    }, D.hyperbolicTangent = D.tanh = function () {
      var n,
        e,
        i = this,
        r = i.constructor;
      return i.isFinite() ? i.isZero() ? new r(i) : (n = r.precision, e = r.rounding, r.precision = n + 7, r.rounding = 1, P(i.sinh(), i.cosh(), r.precision = n, r.rounding = e)) : new r(i.s);
    }, D.inverseCosine = D.acos = function () {
      var n,
        e = this,
        i = e.constructor,
        r = e.abs().cmp(1),
        t = i.precision,
        s = i.rounding;
      return -1 !== r ? 0 === r ? e.isNeg() ? U(i, t, s) : new i(0) : new i(NaN) : e.isZero() ? U(i, t + 4, s).times(.5) : (i.precision = t + 6, i.rounding = 1, e = e.asin(), n = U(i, t + 4, s).times(.5), i.precision = t, i.rounding = s, n.minus(e));
    }, D.inverseHyperbolicCosine = D.acosh = function () {
      var n,
        e,
        i = this,
        r = i.constructor;
      return i.lte(1) ? new r(i.eq(1) ? 0 : NaN) : i.isFinite() ? (n = r.precision, e = r.rounding, r.precision = n + Math.max(Math.abs(i.e), i.sd()) + 4, r.rounding = 1, h = !1, i = i.times(i).minus(1).sqrt().plus(i), h = !0, r.precision = n, r.rounding = e, i.ln()) : new r(i);
    }, D.inverseHyperbolicSine = D.asinh = function () {
      var n,
        e,
        i = this,
        r = i.constructor;
      return !i.isFinite() || i.isZero() ? new r(i) : (n = r.precision, e = r.rounding, r.precision = n + 2 * Math.max(Math.abs(i.e), i.sd()) + 6, r.rounding = 1, h = !1, i = i.times(i).plus(1).sqrt().plus(i), h = !0, r.precision = n, r.rounding = e, i.ln());
    }, D.inverseHyperbolicTangent = D.atanh = function () {
      var n,
        e,
        i,
        r,
        t = this,
        s = t.constructor;
      return t.isFinite() ? t.e >= 0 ? new s(t.abs().eq(1) ? t.s / 0 : t.isZero() ? t : NaN) : (n = s.precision, e = s.rounding, r = t.sd(), Math.max(r, n) < 2 * -t.e - 1 ? R(new s(t), n, e, !0) : (s.precision = i = r - t.e, t = P(t.plus(1), new s(1).minus(t), i + n, 1), s.precision = n + 4, s.rounding = 1, t = t.ln(), s.precision = n, s.rounding = e, t.times(.5))) : new s(NaN);
    }, D.inverseSine = D.asin = function () {
      var n,
        e,
        i,
        r,
        t = this,
        s = t.constructor;
      return t.isZero() ? new s(t) : (e = t.abs().cmp(1), i = s.precision, r = s.rounding, -1 !== e ? 0 === e ? ((n = U(s, i + 4, r).times(.5)).s = t.s, n) : new s(NaN) : (s.precision = i + 6, s.rounding = 1, t = t.div(new s(1).minus(t.times(t)).sqrt().plus(1)).atan(), s.precision = i, s.rounding = r, t.times(2)));
    }, D.inverseTangent = D.atan = function () {
      var n,
        e,
        i,
        r,
        t,
        s,
        o,
        u,
        c,
        f = this,
        a = f.constructor,
        l = a.precision,
        d = a.rounding;
      if (f.isFinite()) {
        if (f.isZero()) return new a(f);
        if (f.abs().eq(1) && l + 4 <= O) return (o = U(a, l + 4, d).times(.25)).s = f.s, o;
      } else {
        if (!f.s) return new a(NaN);
        if (l + 4 <= O) return (o = U(a, l + 4, d).times(.5)).s = f.s, o;
      }
      for (a.precision = u = l + 10, a.rounding = 1, n = i = Math.min(28, u / 7 + 2 | 0); n; --n) f = f.div(f.times(f).plus(1).sqrt().plus(1));
      for (h = !1, e = Math.ceil(u / 7), r = 1, c = f.times(f), o = new a(f), t = f; -1 !== n;) if (t = t.times(c), s = o.minus(t.div(r += 2)), t = t.times(c), void 0 !== (o = s.plus(t.div(r += 2))).d[e]) for (n = e; o.d[n] === s.d[n] && n--;);
      return i && (o = o.times(2 << i - 1)), h = !0, R(o, a.precision = l, a.rounding = d, !0);
    }, D.isFinite = function () {
      return !!this.d;
    }, D.isInteger = D.isInt = function () {
      return !!this.d && v(this.e / 7) > this.d.length - 2;
    }, D.isNaN = function () {
      return !this.s;
    }, D.isNegative = D.isNeg = function () {
      return this.s < 0;
    }, D.isPositive = D.isPos = function () {
      return this.s > 0;
    }, D.isZero = function () {
      return !!this.d && 0 === this.d[0];
    }, D.lessThan = D.lt = function (n) {
      return this.cmp(n) < 0;
    }, D.lessThanOrEqualTo = D.lte = function (n) {
      return this.cmp(n) < 1;
    }, D.logarithm = D.log = function (n) {
      var e,
        i,
        r,
        t,
        s,
        o,
        u,
        c,
        f = this,
        a = f.constructor,
        l = a.precision,
        d = a.rounding;
      if (null == n) n = new a(10), e = !0;else {
        if (i = (n = new a(n)).d, n.s < 0 || !i || !i[0] || n.eq(1)) return new a(NaN);
        e = n.eq(10);
      }
      if (i = f.d, f.s < 0 || !i || !i[0] || f.eq(1)) return new a(i && !i[0] ? -1 / 0 : 1 != f.s ? NaN : i ? 0 : 1 / 0);
      if (e) if (i.length > 1) s = !0;else {
        for (t = i[0]; t % 10 == 0;) t /= 10;
        s = 1 !== t;
      }
      if (h = !1, o = V(f, u = l + 5), r = e ? L(a, u + 10) : V(n, u), S((c = P(o, r, u, 1)).d, t = l, d)) do {
        if (o = V(f, u += 10), r = e ? L(a, u + 10) : V(n, u), c = P(o, r, u, 1), !s) {
          +F(c.d).slice(t + 1, t + 15) + 1 == 1e14 && (c = R(c, l + 1, 0));
          break;
        }
      } while (S(c.d, t += 10, d));
      return h = !0, R(c, l, d);
    }, D.minus = D.sub = function (n) {
      var e,
        i,
        r,
        t,
        s,
        o,
        u,
        c,
        f,
        a,
        l,
        d,
        p = this,
        g = p.constructor;
      if (n = new g(n), !p.d || !n.d) return p.s && n.s ? p.d ? n.s = -n.s : n = new g(n.d || p.s !== n.s ? p : NaN) : n = new g(NaN), n;
      if (p.s != n.s) return n.s = -n.s, p.plus(n);
      if (f = p.d, d = n.d, u = g.precision, c = g.rounding, !f[0] || !d[0]) {
        if (d[0]) n.s = -n.s;else {
          if (!f[0]) return new g(3 === c ? -0 : 0);
          n = new g(p);
        }
        return h ? R(n, u, c) : n;
      }
      if (i = v(n.e / 7), a = v(p.e / 7), f = f.slice(), s = a - i) {
        for ((l = s < 0) ? (e = f, s = -s, o = d.length) : (e = d, i = a, o = f.length), s > (r = Math.max(Math.ceil(u / 7), o) + 2) && (s = r, e.length = 1), e.reverse(), r = s; r--;) e.push(0);
        e.reverse();
      } else {
        for ((l = (r = f.length) < (o = d.length)) && (o = r), r = 0; r < o; r++) if (f[r] != d[r]) {
          l = f[r] < d[r];
          break;
        }
        s = 0;
      }
      for (l && (e = f, f = d, d = e, n.s = -n.s), o = f.length, r = d.length - o; r > 0; --r) f[o++] = 0;
      for (r = d.length; r > s;) {
        if (f[--r] < d[r]) {
          for (t = r; t && 0 === f[--t];) f[t] = M - 1;
          --f[t], f[r] += M;
        }
        f[r] -= d[r];
      }
      for (; 0 === f[--o];) f.pop();
      for (; 0 === f[0]; f.shift()) --i;
      return f[0] ? (n.d = f, n.e = _(f, i), h ? R(n, u, c) : n) : new g(3 === c ? -0 : 0);
    }, D.modulo = D.mod = function (n) {
      var e,
        i = this,
        r = i.constructor;
      return n = new r(n), !i.d || !n.s || n.d && !n.d[0] ? new r(NaN) : !n.d || i.d && !i.d[0] ? R(new r(i), r.precision, r.rounding) : (h = !1, 9 == r.modulo ? (e = P(i, n.abs(), 0, 3, 1)).s *= n.s : e = P(i, n, 0, r.modulo, 1), e = e.times(n), h = !0, i.minus(e));
    }, D.naturalExponential = D.exp = function () {
      return j(this);
    }, D.naturalLogarithm = D.ln = function () {
      return V(this);
    }, D.negated = D.neg = function () {
      var n = new this.constructor(this);
      return n.s = -n.s, R(n);
    }, D.plus = D.add = function (n) {
      var e,
        i,
        r,
        t,
        s,
        o,
        u,
        c,
        f,
        a,
        l = this,
        d = l.constructor;
      if (n = new d(n), !l.d || !n.d) return l.s && n.s ? l.d || (n = new d(n.d || l.s === n.s ? l : NaN)) : n = new d(NaN), n;
      if (l.s != n.s) return n.s = -n.s, l.minus(n);
      if (f = l.d, a = n.d, u = d.precision, c = d.rounding, !f[0] || !a[0]) return a[0] || (n = new d(l)), h ? R(n, u, c) : n;
      if (s = v(l.e / 7), r = v(n.e / 7), f = f.slice(), t = s - r) {
        for (t < 0 ? (i = f, t = -t, o = a.length) : (i = a, r = s, o = f.length), t > (o = (s = Math.ceil(u / 7)) > o ? s + 1 : o + 1) && (t = o, i.length = 1), i.reverse(); t--;) i.push(0);
        i.reverse();
      }
      for ((o = f.length) - (t = a.length) < 0 && (t = o, i = a, a = f, f = i), e = 0; t;) e = (f[--t] = f[t] + a[t] + e) / M | 0, f[t] %= M;
      for (e && (f.unshift(e), ++r), o = f.length; 0 == f[--o];) f.pop();
      return n.d = f, n.e = _(f, r), h ? R(n, u, c) : n;
    }, D.precision = D.sd = function (n) {
      var e,
        i = this;
      if (void 0 !== n && n !== !!n && 1 !== n && 0 !== n) throw Error(p + n);
      return i.d ? (e = k(i.d), n && i.e + 1 > e && (e = i.e + 1)) : e = NaN, e;
    }, D.round = function () {
      var n = this,
        e = n.constructor;
      return R(new e(n), n.e + 1, e.rounding);
    }, D.sine = D.sin = function () {
      var n,
        e,
        i = this,
        r = i.constructor;
      return i.isFinite() ? i.isZero() ? new r(i) : (n = r.precision, e = r.rounding, r.precision = n + Math.max(i.e, i.sd()) + 7, r.rounding = 1, i = function (n, e) {
        var i,
          r = e.d.length;
        if (r < 3) return e.isZero() ? e : z(n, 2, e, e);
        i = (i = 1.4 * Math.sqrt(r)) > 16 ? 16 : 0 | i, e = e.times(1 / G(5, i)), e = z(n, 2, e, e);
        for (var t, s = new n(5), o = new n(16), u = new n(20); i--;) t = e.times(e), e = e.times(s.plus(t.times(o.times(t).minus(u))));
        return e;
      }(r, K(r, i)), r.precision = n, r.rounding = e, R(s > 2 ? i.neg() : i, n, e, !0)) : new r(NaN);
    }, D.squareRoot = D.sqrt = function () {
      var n,
        e,
        i,
        r,
        t,
        s,
        o = this,
        u = o.d,
        c = o.e,
        f = o.s,
        a = o.constructor;
      if (1 !== f || !u || !u[0]) return new a(!f || f < 0 && (!u || u[0]) ? NaN : u ? o : 1 / 0);
      for (h = !1, 0 == (f = Math.sqrt(+o)) || f == 1 / 0 ? (((e = F(u)).length + c) % 2 == 0 && (e += "0"), f = Math.sqrt(e), c = v((c + 1) / 2) - (c < 0 || c % 2), r = new a(e = f == 1 / 0 ? "5e" + c : (e = f.toExponential()).slice(0, e.indexOf("e") + 1) + c)) : r = new a(f.toString()), i = (c = a.precision) + 3;;) if (r = (s = r).plus(P(o, s, i + 2, 1)).times(.5), F(s.d).slice(0, i) === (e = F(r.d)).slice(0, i)) {
        if ("9999" != (e = e.slice(i - 3, i + 1)) && (t || "4999" != e)) {
          +e && (+e.slice(1) || "5" != e.charAt(0)) || (R(r, c + 1, 1), n = !r.times(r).eq(o));
          break;
        }
        if (!t && (R(s, c + 1, 0), s.times(s).eq(o))) {
          r = s;
          break;
        }
        i += 4, t = 1;
      }
      return h = !0, R(r, c, a.rounding, n);
    }, D.tangent = D.tan = function () {
      var n,
        e,
        i = this,
        r = i.constructor;
      return i.isFinite() ? i.isZero() ? new r(i) : (n = r.precision, e = r.rounding, r.precision = n + 10, r.rounding = 1, (i = i.sin()).s = 1, i = P(i, new r(1).minus(i.times(i)).sqrt(), n + 10, 0), r.precision = n, r.rounding = e, R(2 == s || 4 == s ? i.neg() : i, n, e, !0)) : new r(NaN);
    }, D.times = D.mul = function (n) {
      var e,
        i,
        r,
        t,
        s,
        o,
        u,
        c,
        f,
        a = this,
        l = a.constructor,
        d = a.d,
        p = (n = new l(n)).d;
      if (n.s *= a.s, !(d && d[0] && p && p[0])) return new l(!n.s || d && !d[0] && !p || p && !p[0] && !d ? NaN : d && p ? 0 * n.s : n.s / 0);
      for (i = v(a.e / 7) + v(n.e / 7), (c = d.length) < (f = p.length) && (s = d, d = p, p = s, o = c, c = f, f = o), s = [], r = o = c + f; r--;) s.push(0);
      for (r = f; --r >= 0;) {
        for (e = 0, t = c + r; t > r;) u = s[t] + p[r] * d[t - r - 1] + e, s[t--] = u % M | 0, e = u / M | 0;
        s[t] = (s[t] + e) % M | 0;
      }
      for (; !s[--o];) s.pop();
      return e ? ++i : s.shift(), n.d = s, n.e = _(s, i), h ? R(n, l.precision, l.rounding) : n;
    }, D.toBinary = function (n, e) {
      return Q(this, 2, n, e);
    }, D.toDecimalPlaces = D.toDP = function (n, e) {
      var i = this,
        r = i.constructor;
      return i = new r(i), void 0 === n ? i : (A(n, 0, u), void 0 === e ? e = r.rounding : A(e, 0, 8), R(i, n + i.e + 1, e));
    }, D.toExponential = function (n, e) {
      var i,
        r = this,
        t = r.constructor;
      return void 0 === n ? i = T(r, !0) : (A(n, 0, u), void 0 === e ? e = t.rounding : A(e, 0, 8), i = T(r = R(new t(r), n + 1, e), !0, n + 1)), r.isNeg() && !r.isZero() ? "-" + i : i;
    }, D.toFixed = function (n, e) {
      var i,
        r,
        t = this,
        s = t.constructor;
      return void 0 === n ? i = T(t) : (A(n, 0, u), void 0 === e ? e = s.rounding : A(e, 0, 8), i = T(r = R(new s(t), n + t.e + 1, e), !1, n + r.e + 1)), t.isNeg() && !t.isZero() ? "-" + i : i;
    }, D.toFraction = function (n) {
      var e,
        i,
        r,
        t,
        s,
        o,
        u,
        c,
        f,
        a,
        l,
        d,
        g = this,
        m = g.d,
        w = g.constructor;
      if (!m) return new w(g);
      if (f = i = new w(1), r = c = new w(0), o = (s = (e = new w(r)).e = k(m) - g.e - 1) % 7, e.d[0] = N(10, o < 0 ? 7 + o : o), null == n) n = s > 0 ? e : f;else {
        if (!(u = new w(n)).isInt() || u.lt(f)) throw Error(p + u);
        n = u.gt(e) ? s > 0 ? e : f : u;
      }
      for (h = !1, u = new w(F(m)), a = w.precision, w.precision = s = 7 * m.length * 2; l = P(u, e, 0, 1, 1), 1 != (t = i.plus(l.times(r))).cmp(n);) i = r, r = t, t = f, f = c.plus(l.times(t)), c = t, t = e, e = u.minus(l.times(t)), u = t;
      return t = P(n.minus(i), r, 0, 1, 1), c = c.plus(t.times(f)), i = i.plus(t.times(r)), c.s = f.s = g.s, d = P(f, r, s, 1).minus(g).abs().cmp(P(c, i, s, 1).minus(g).abs()) < 1 ? [f, r] : [c, i], w.precision = a, h = !0, d;
    }, D.toHexadecimal = D.toHex = function (n, e) {
      return Q(this, 16, n, e);
    }, D.toNearest = function (n, e) {
      var i = this,
        r = i.constructor;
      if (i = new r(i), null == n) {
        if (!i.d) return i;
        n = new r(1), e = r.rounding;
      } else {
        if (n = new r(n), void 0 === e ? e = r.rounding : A(e, 0, 8), !i.d) return n.s ? i : n;
        if (!n.d) return n.s && (n.s = i.s), n;
      }
      return n.d[0] ? (h = !1, i = P(i, n, 0, e, 1).times(n), h = !0, R(i)) : (n.s = i.s, i = n), i;
    }, D.toNumber = function () {
      return +this;
    }, D.toOctal = function (n, e) {
      return Q(this, 8, n, e);
    }, D.toPower = D.pow = function (n) {
      var e,
        i,
        r,
        t,
        s,
        o,
        u = this,
        c = u.constructor,
        f = +(n = new c(n));
      if (!(u.d && n.d && u.d[0] && n.d[0])) return new c(N(+u, f));
      if ((u = new c(u)).eq(1)) return u;
      if (r = c.precision, s = c.rounding, n.eq(1)) return R(u, r, s);
      if ((e = v(n.e / 7)) >= n.d.length - 1 && (i = f < 0 ? -f : f) <= 9007199254740991) return t = H(c, u, i, r), n.s < 0 ? new c(1).div(t) : R(t, r, s);
      if ((o = u.s) < 0) {
        if (e < n.d.length - 1) return new c(NaN);
        if (0 == (1 & n.d[e]) && (o = 1), 0 == u.e && 1 == u.d[0] && 1 == u.d.length) return u.s = o, u;
      }
      return (e = 0 != (i = N(+u, f)) && isFinite(i) ? new c(i + "").e : v(f * (Math.log("0." + F(u.d)) / Math.LN10 + u.e + 1))) > c.maxE + 1 || e < c.minE - 1 ? new c(e > 0 ? o / 0 : 0) : (h = !1, c.rounding = u.s = 1, i = Math.min(12, (e + "").length), (t = j(n.times(V(u, r + i)), r)).d && S((t = R(t, r + 5, 1)).d, r, s) && (e = r + 10, +F((t = R(j(n.times(V(u, e + i)), e), e + 5, 1)).d).slice(r + 1, r + 15) + 1 == 1e14 && (t = R(t, r + 1, 0))), t.s = o, h = !0, c.rounding = s, R(t, r, s));
    }, D.toPrecision = function (n, e) {
      var i,
        r = this,
        t = r.constructor;
      return void 0 === n ? i = T(r, r.e <= t.toExpNeg || r.e >= t.toExpPos) : (A(n, 1, u), void 0 === e ? e = t.rounding : A(e, 0, 8), i = T(r = R(new t(r), n, e), n <= r.e || r.e <= t.toExpNeg, n)), r.isNeg() && !r.isZero() ? "-" + i : i;
    }, D.toSignificantDigits = D.toSD = function (n, e) {
      var i = this.constructor;
      return void 0 === n ? (n = i.precision, e = i.rounding) : (A(n, 1, u), void 0 === e ? e = i.rounding : A(e, 0, 8)), R(new i(this), n, e);
    }, D.toString = function () {
      var n = this,
        e = n.constructor,
        i = T(n, n.e <= e.toExpNeg || n.e >= e.toExpPos);
      return n.isNeg() && !n.isZero() ? "-" + i : i;
    }, D.truncated = D.trunc = function () {
      return R(new this.constructor(this), this.e + 1, 1);
    }, D.valueOf = D.toJSON = function () {
      var n = this,
        e = n.constructor,
        i = T(n, n.e <= e.toExpNeg || n.e >= e.toExpPos);
      return n.isNeg() ? "-" + i : i;
    };
    var P = function () {
      function n(n, e, i) {
        var r,
          t = 0,
          s = n.length;
        for (n = n.slice(); s--;) r = n[s] * e + t, n[s] = r % i | 0, t = r / i | 0;
        return t && n.unshift(t), n;
      }
      function e(n, e, i, r) {
        var t, s;
        if (i != r) s = i > r ? 1 : -1;else for (t = s = 0; t < i; t++) if (n[t] != e[t]) {
          s = n[t] > e[t] ? 1 : -1;
          break;
        }
        return s;
      }
      function i(n, e, i, r) {
        for (var t = 0; i--;) n[i] -= t, t = n[i] < e[i] ? 1 : 0, n[i] = t * r + n[i] - e[i];
        for (; !n[0] && n.length > 1;) n.shift();
      }
      return function (t, s, o, u, c, f) {
        var a,
          l,
          h,
          d,
          p,
          g,
          m,
          w,
          N,
          b,
          x,
          E,
          y,
          q,
          O,
          D,
          F,
          A,
          S,
          Z,
          P = t.constructor,
          T = t.s == s.s ? 1 : -1,
          _ = t.d,
          L = s.d;
        if (!(_ && _[0] && L && L[0])) return new P(t.s && s.s && (_ ? !L || _[0] != L[0] : L) ? _ && 0 == _[0] || !L ? 0 * T : T / 0 : NaN);
        for (f ? (p = 1, l = t.e - s.e) : (f = M, p = 7, l = v(t.e / p) - v(s.e / p)), S = L.length, F = _.length, b = (N = new P(T)).d = [], h = 0; L[h] == (_[h] || 0); h++);
        if (L[h] > (_[h] || 0) && l--, null == o ? (q = o = P.precision, u = P.rounding) : q = c ? o + (t.e - s.e) + 1 : o, q < 0) b.push(1), g = !0;else {
          if (q = q / p + 2 | 0, h = 0, 1 == S) {
            for (d = 0, L = L[0], q++; (h < F || d) && q--; h++) O = d * f + (_[h] || 0), b[h] = O / L | 0, d = O % L | 0;
            g = d || h < F;
          } else {
            for ((d = f / (L[0] + 1) | 0) > 1 && (L = n(L, d, f), _ = n(_, d, f), S = L.length, F = _.length), D = S, E = (x = _.slice(0, S)).length; E < S;) x[E++] = 0;
            (Z = L.slice()).unshift(0), A = L[0], L[1] >= f / 2 && ++A;
            do {
              d = 0, (a = e(L, x, S, E)) < 0 ? (y = x[0], S != E && (y = y * f + (x[1] || 0)), (d = y / A | 0) > 1 ? (d >= f && (d = f - 1), 1 == (a = e(m = n(L, d, f), x, w = m.length, E = x.length)) && (d--, i(m, S < w ? Z : L, w, f))) : (0 == d && (a = d = 1), m = L.slice()), (w = m.length) < E && m.unshift(0), i(x, m, E, f), -1 == a && (a = e(L, x, S, E = x.length)) < 1 && (d++, i(x, S < E ? Z : L, E, f)), E = x.length) : 0 === a && (d++, x = [0]), b[h++] = d, a && x[0] ? x[E++] = _[D] || 0 : (x = [_[D]], E = 1);
            } while ((D++ < F || void 0 !== x[0]) && q--);
            g = void 0 !== x[0];
          }
          b[0] || b.shift();
        }
        if (1 == p) N.e = l, r = g;else {
          for (h = 1, d = b[0]; d >= 10; d /= 10) h++;
          N.e = h + l * p - 1, R(N, c ? o + N.e + 1 : o, u, g);
        }
        return N;
      };
    }();
    function R(n, e, i, r) {
      var t,
        s,
        o,
        u,
        c,
        f,
        a,
        l,
        d,
        p = n.constructor;
      n: if (null != e) {
        if (!(l = n.d)) return n;
        for (t = 1, u = l[0]; u >= 10; u /= 10) t++;
        if ((s = e - t) < 0) s += 7, o = e, c = (a = l[d = 0]) / N(10, t - o - 1) % 10 | 0;else if ((d = Math.ceil((s + 1) / 7)) >= (u = l.length)) {
          if (!r) break n;
          for (; u++ <= d;) l.push(0);
          a = c = 0, t = 1, o = (s %= 7) - 7 + 1;
        } else {
          for (a = u = l[d], t = 1; u >= 10; u /= 10) t++;
          c = (o = (s %= 7) - 7 + t) < 0 ? 0 : a / N(10, t - o - 1) % 10 | 0;
        }
        if (r = r || e < 0 || void 0 !== l[d + 1] || (o < 0 ? a : a % N(10, t - o - 1)), f = i < 4 ? (c || r) && (0 == i || i == (n.s < 0 ? 3 : 2)) : c > 5 || 5 == c && (4 == i || r || 6 == i && (s > 0 ? o > 0 ? a / N(10, t - o) : 0 : l[d - 1]) % 10 & 1 || i == (n.s < 0 ? 8 : 7)), e < 1 || !l[0]) return l.length = 0, f ? (e -= n.e + 1, l[0] = N(10, (7 - e % 7) % 7), n.e = -e || 0) : l[0] = n.e = 0, n;
        if (0 == s ? (l.length = d, u = 1, d--) : (l.length = d + 1, u = N(10, 7 - s), l[d] = o > 0 ? (a / N(10, t - o) % N(10, o) | 0) * u : 0), f) for (;;) {
          if (0 == d) {
            for (s = 1, o = l[0]; o >= 10; o /= 10) s++;
            for (o = l[0] += u, u = 1; o >= 10; o /= 10) u++;
            s != u && (n.e++, l[0] == M && (l[0] = 1));
            break;
          }
          if (l[d] += u, l[d] != M) break;
          l[d--] = 0, u = 1;
        }
        for (s = l.length; 0 === l[--s];) l.pop();
      }
      return h && (n.e > p.maxE ? (n.d = null, n.e = NaN) : n.e < p.minE && (n.e = 0, n.d = [0])), n;
    }
    function T(n, e, i) {
      if (!n.isFinite()) return $(n);
      var r,
        t = n.e,
        s = F(n.d),
        o = s.length;
      return e ? (i && (r = i - o) > 0 ? s = s.charAt(0) + "." + s.slice(1) + C(r) : o > 1 && (s = s.charAt(0) + "." + s.slice(1)), s = s + (n.e < 0 ? "e" : "e+") + n.e) : t < 0 ? (s = "0." + C(-t - 1) + s, i && (r = i - o) > 0 && (s += C(r))) : t >= o ? (s += C(t + 1 - o), i && (r = i - t - 1) > 0 && (s = s + "." + C(r))) : ((r = t + 1) < o && (s = s.slice(0, r) + "." + s.slice(r)), i && (r = i - o) > 0 && (t + 1 === o && (s += "."), s += C(r))), s;
    }
    function _(n, e) {
      var i = n[0];
      for (e *= 7; i >= 10; i /= 10) e++;
      return e;
    }
    function L(n, e, i) {
      if (e > q) throw h = !0, i && (n.precision = i), Error(g);
      return R(new n(f), e, 1, !0);
    }
    function U(n, e, i) {
      if (e > O) throw Error(g);
      return R(new n(a), e, i, !0);
    }
    function k(n) {
      var e = n.length - 1,
        i = 7 * e + 1;
      if (e = n[e]) {
        for (; e % 10 == 0; e /= 10) i--;
        for (e = n[0]; e >= 10; e /= 10) i++;
      }
      return i;
    }
    function C(n) {
      for (var e = ""; n--;) e += "0";
      return e;
    }
    function H(n, e, i, r) {
      var t,
        s = new n(1),
        o = Math.ceil(r / 7 + 4);
      for (h = !1;;) {
        if (i % 2 && X((s = s.times(e)).d, o) && (t = !0), 0 === (i = v(i / 2))) {
          i = s.d.length - 1, t && 0 === s.d[i] && ++s.d[i];
          break;
        }
        X((e = e.times(e)).d, o);
      }
      return h = !0, s;
    }
    function I(n) {
      return 1 & n.d[n.d.length - 1];
    }
    function B(n, e, i) {
      for (var r, t = new n(e[0]), s = 0; ++s < e.length;) {
        if (!(r = new n(e[s])).s) {
          t = r;
          break;
        }
        t[i](r) && (t = r);
      }
      return t;
    }
    function j(n, e) {
      var i,
        r,
        t,
        s,
        o,
        u,
        c,
        f = 0,
        a = 0,
        l = 0,
        d = n.constructor,
        p = d.rounding,
        g = d.precision;
      if (!n.d || !n.d[0] || n.e > 17) return new d(n.d ? n.d[0] ? n.s < 0 ? 0 : 1 / 0 : 1 : n.s ? n.s < 0 ? 0 : n : NaN);
      for (null == e ? (h = !1, c = g) : c = e, u = new d(.03125); n.e > -2;) n = n.times(u), l += 5;
      for (c += r = Math.log(N(2, l)) / Math.LN10 * 2 + 5 | 0, i = s = o = new d(1), d.precision = c;;) {
        if (s = R(s.times(n), c, 1), i = i.times(++a), F((u = o.plus(P(s, i, c, 1))).d).slice(0, c) === F(o.d).slice(0, c)) {
          for (t = l; t--;) o = R(o.times(o), c, 1);
          if (null != e) return d.precision = g, o;
          if (!(f < 3 && S(o.d, c - r, p, f))) return R(o, d.precision = g, p, h = !0);
          d.precision = c += 10, i = s = u = new d(1), a = 0, f++;
        }
        o = u;
      }
    }
    function V(n, e) {
      var i,
        r,
        t,
        s,
        o,
        u,
        c,
        f,
        a,
        l,
        d,
        p = 1,
        g = n,
        m = g.d,
        w = g.constructor,
        v = w.rounding,
        N = w.precision;
      if (g.s < 0 || !m || !m[0] || !g.e && 1 == m[0] && 1 == m.length) return new w(m && !m[0] ? -1 / 0 : 1 != g.s ? NaN : m ? 0 : g);
      if (null == e ? (h = !1, a = N) : a = e, w.precision = a += 10, r = (i = F(m)).charAt(0), !(Math.abs(s = g.e) < 15e14)) return f = L(w, a + 2, N).times(s + ""), g = V(new w(r + "." + i.slice(1)), a - 10).plus(f), w.precision = N, null == e ? R(g, N, v, h = !0) : g;
      for (; r < 7 && 1 != r || 1 == r && i.charAt(1) > 3;) r = (i = F((g = g.times(n)).d)).charAt(0), p++;
      for (s = g.e, r > 1 ? (g = new w("0." + i), s++) : g = new w(r + "." + i.slice(1)), l = g, c = o = g = P(g.minus(1), g.plus(1), a, 1), d = R(g.times(g), a, 1), t = 3;;) {
        if (o = R(o.times(d), a, 1), F((f = c.plus(P(o, new w(t), a, 1))).d).slice(0, a) === F(c.d).slice(0, a)) {
          if (c = c.times(2), 0 !== s && (c = c.plus(L(w, a + 2, N).times(s + ""))), c = P(c, new w(p), a, 1), null != e) return w.precision = N, c;
          if (!S(c.d, a - 10, v, u)) return R(c, w.precision = N, v, h = !0);
          w.precision = a += 10, f = o = g = P(l.minus(1), l.plus(1), a, 1), d = R(g.times(g), a, 1), t = u = 1;
        }
        c = f, t += 2;
      }
    }
    function $(n) {
      return String(n.s * n.s / 0);
    }
    function W(n, e) {
      var i, r, t;
      for ((i = e.indexOf(".")) > -1 && (e = e.replace(".", "")), (r = e.search(/e/i)) > 0 ? (i < 0 && (i = r), i += +e.slice(r + 1), e = e.substring(0, r)) : i < 0 && (i = e.length), r = 0; 48 === e.charCodeAt(r); r++);
      for (t = e.length; 48 === e.charCodeAt(t - 1); --t);
      if (e = e.slice(r, t)) {
        if (t -= r, n.e = i = i - r - 1, n.d = [], r = (i + 1) % 7, i < 0 && (r += 7), r < t) {
          for (r && n.d.push(+e.slice(0, r)), t -= 7; r < t;) n.d.push(+e.slice(r, r += 7));
          r = 7 - (e = e.slice(r)).length;
        } else r -= t;
        for (; r--;) e += "0";
        n.d.push(+e), h && (n.e > n.constructor.maxE ? (n.d = null, n.e = NaN) : n.e < n.constructor.minE && (n.e = 0, n.d = [0]));
      } else n.e = 0, n.d = [0];
      return n;
    }
    function J(n, e) {
      var r, t, s, o, u, c, f, a, l;
      if (e.indexOf("_") > -1) {
        if (e = e.replace(/(\d)_(?=\d)/g, "$1"), y.test(e)) return W(n, e);
      } else if ("Infinity" === e || "NaN" === e) return +e || (n.s = NaN), n.e = NaN, n.d = null, n;
      if (x.test(e)) r = 16, e = e.toLowerCase();else if (b.test(e)) r = 2;else {
        if (!E.test(e)) throw Error(p + e);
        r = 8;
      }
      for ((o = e.search(/p/i)) > 0 ? (f = +e.slice(o + 1), e = e.substring(2, o)) : e = e.slice(2), u = (o = e.indexOf(".")) >= 0, t = n.constructor, u && (o = (c = (e = e.replace(".", "")).length) - o, s = H(t, new t(r), o, 2 * o)), o = l = (a = Z(e, r, M)).length - 1; 0 === a[o]; --o) a.pop();
      return o < 0 ? new t(0 * n.s) : (n.e = _(a, l), n.d = a, h = !1, u && (n = P(n, s, 4 * c)), f && (n = n.times(Math.abs(f) < 54 ? N(2, f) : i.pow(2, f))), h = !0, n);
    }
    function z(n, e, i, r, t) {
      var s,
        o,
        u,
        c,
        f = n.precision,
        a = Math.ceil(f / 7);
      for (h = !1, c = i.times(i), u = new n(r);;) {
        if (o = P(u.times(c), new n(e++ * e++), f, 1), u = t ? r.plus(o) : r.minus(o), r = P(o.times(c), new n(e++ * e++), f, 1), void 0 !== (o = u.plus(r)).d[a]) {
          for (s = a; o.d[s] === u.d[s] && s--;);
          if (-1 == s) break;
        }
        s = u, u = r, r = o, o = s;
      }
      return h = !0, o.d.length = a + 1, o;
    }
    function G(n, e) {
      for (var i = n; --e;) i *= n;
      return i;
    }
    function K(n, e) {
      var i,
        r = e.s < 0,
        t = U(n, n.precision, 1),
        o = t.times(.5);
      if ((e = e.abs()).lte(o)) return s = r ? 4 : 1, e;
      if ((i = e.divToInt(t)).isZero()) s = r ? 3 : 2;else {
        if ((e = e.minus(i.times(t))).lte(o)) return s = I(i) ? r ? 2 : 3 : r ? 4 : 1, e;
        s = I(i) ? r ? 1 : 4 : r ? 3 : 2;
      }
      return e.minus(t).abs();
    }
    function Q(n, e, i, t) {
      var s,
        o,
        f,
        a,
        l,
        h,
        d,
        p,
        g,
        m = n.constructor,
        w = void 0 !== i;
      if (w ? (A(i, 1, u), void 0 === t ? t = m.rounding : A(t, 0, 8)) : (i = m.precision, t = m.rounding), n.isFinite()) {
        for (w ? (s = 2, 16 == e ? i = 4 * i - 3 : 8 == e && (i = 3 * i - 2)) : s = e, (f = (d = T(n)).indexOf(".")) >= 0 && (d = d.replace(".", ""), (g = new m(1)).e = d.length - f, g.d = Z(T(g), 10, s), g.e = g.d.length), o = l = (p = Z(d, 10, s)).length; 0 == p[--l];) p.pop();
        if (p[0]) {
          if (f < 0 ? o-- : ((n = new m(n)).d = p, n.e = o, p = (n = P(n, g, i, t, 0, s)).d, o = n.e, h = r), f = p[i], a = s / 2, h = h || void 0 !== p[i + 1], h = t < 4 ? (void 0 !== f || h) && (0 === t || t === (n.s < 0 ? 3 : 2)) : f > a || f === a && (4 === t || h || 6 === t && 1 & p[i - 1] || t === (n.s < 0 ? 8 : 7)), p.length = i, h) for (; ++p[--i] > s - 1;) p[i] = 0, i || (++o, p.unshift(1));
          for (l = p.length; !p[l - 1]; --l);
          for (f = 0, d = ""; f < l; f++) d += c.charAt(p[f]);
          if (w) {
            if (l > 1) if (16 == e || 8 == e) {
              for (f = 16 == e ? 4 : 3, --l; l % f; l++) d += "0";
              for (l = (p = Z(d, s, e)).length; !p[l - 1]; --l);
              for (f = 1, d = "1."; f < l; f++) d += c.charAt(p[f]);
            } else d = d.charAt(0) + "." + d.slice(1);
            d = d + (o < 0 ? "p" : "p+") + o;
          } else if (o < 0) {
            for (; ++o;) d = "0" + d;
            d = "0." + d;
          } else if (++o > l) for (o -= l; o--;) d += "0";else o < l && (d = d.slice(0, o) + "." + d.slice(o));
        } else d = w ? "0p+0" : "0";
        d = (16 == e ? "0x" : 2 == e ? "0b" : 8 == e ? "0o" : "") + d;
      } else d = $(n);
      return n.s < 0 ? "-" + d : d;
    }
    function X(n, e) {
      if (n.length > e) return n.length = e, !0;
    }
    function Y(n) {
      return new this(n).abs();
    }
    function nn(n) {
      return new this(n).acos();
    }
    function en(n) {
      return new this(n).acosh();
    }
    function rn(n, e) {
      return new this(n).plus(e);
    }
    function tn(n) {
      return new this(n).asin();
    }
    function sn(n) {
      return new this(n).asinh();
    }
    function on(n) {
      return new this(n).atan();
    }
    function un(n) {
      return new this(n).atanh();
    }
    function cn(n, e) {
      n = new this(n), e = new this(e);
      var i,
        r = this.precision,
        t = this.rounding,
        s = r + 4;
      return n.s && e.s ? n.d || e.d ? !e.d || n.isZero() ? (i = e.s < 0 ? U(this, r, t) : new this(0)).s = n.s : !n.d || e.isZero() ? (i = U(this, s, 1).times(.5)).s = n.s : e.s < 0 ? (this.precision = s, this.rounding = 1, i = this.atan(P(n, e, s, 1)), e = U(this, s, 1), this.precision = r, this.rounding = t, i = n.s < 0 ? i.minus(e) : i.plus(e)) : i = this.atan(P(n, e, s, 1)) : (i = U(this, s, 1).times(e.s > 0 ? .25 : .75)).s = n.s : i = new this(NaN), i;
    }
    function fn(n) {
      return new this(n).cbrt();
    }
    function an(n) {
      return R(n = new this(n), n.e + 1, 2);
    }
    function ln(n, e, i) {
      return new this(n).clamp(e, i);
    }
    function hn(n) {
      if (!n || "object" != typeof n) throw Error(d + "Object expected");
      var e,
        i,
        r,
        t = !0 === n.defaults,
        s = ["precision", 1, u, "rounding", 0, 8, "toExpNeg", -o, 0, "toExpPos", 0, o, "maxE", 0, o, "minE", -o, 0, "modulo", 0, 9];
      for (e = 0; e < s.length; e += 3) if (i = s[e], t && (this[i] = l[i]), void 0 !== (r = n[i])) {
        if (!(v(r) === r && r >= s[e + 1] && r <= s[e + 2])) throw Error(p + i + ": " + r);
        this[i] = r;
      }
      if (i = "crypto", t && (this[i] = l[i]), void 0 !== (r = n[i])) {
        if (!0 !== r && !1 !== r && 0 !== r && 1 !== r) throw Error(p + i + ": " + r);
        if (r) {
          if ("undefined" == typeof crypto || !crypto || !crypto.getRandomValues && !crypto.randomBytes) throw Error(m);
          this[i] = !0;
        } else this[i] = !1;
      }
      return this;
    }
    function dn(n) {
      return new this(n).cos();
    }
    function pn(n) {
      return new this(n).cosh();
    }
    function gn(n, e) {
      return new this(n).div(e);
    }
    function mn(n) {
      return new this(n).exp();
    }
    function wn(n) {
      return R(n = new this(n), n.e + 1, 3);
    }
    function vn() {
      var n,
        e,
        i = new this(0);
      for (h = !1, n = 0; n < arguments.length;) if ((e = new this(arguments[n++])).d) i.d && (i = i.plus(e.times(e)));else {
        if (e.s) return h = !0, new this(1 / 0);
        i = e;
      }
      return h = !0, i.sqrt();
    }
    function Nn(n) {
      return n instanceof i || n && n.toStringTag === w || !1;
    }
    function bn(n) {
      return new this(n).ln();
    }
    function xn(n, e) {
      return new this(n).log(e);
    }
    function En(n) {
      return new this(n).log(2);
    }
    function yn(n) {
      return new this(n).log(10);
    }
    function Mn() {
      return B(this, arguments, "lt");
    }
    function qn() {
      return B(this, arguments, "gt");
    }
    function On(n, e) {
      return new this(n).mod(e);
    }
    function Dn(n, e) {
      return new this(n).mul(e);
    }
    function Fn(n, e) {
      return new this(n).pow(e);
    }
    function An(n) {
      var e,
        i,
        r,
        t,
        s = 0,
        o = new this(1),
        c = [];
      if (void 0 === n ? n = this.precision : A(n, 1, u), r = Math.ceil(n / 7), this.crypto) {
        if (crypto.getRandomValues) for (e = crypto.getRandomValues(new Uint32Array(r)); s < r;) (t = e[s]) >= 429e7 ? e[s] = crypto.getRandomValues(new Uint32Array(1))[0] : c[s++] = t % 1e7;else {
          if (!crypto.randomBytes) throw Error(m);
          for (e = crypto.randomBytes(r *= 4); s < r;) (t = e[s] + (e[s + 1] << 8) + (e[s + 2] << 16) + ((127 & e[s + 3]) << 24)) >= 214e7 ? crypto.randomBytes(4).copy(e, s) : (c.push(t % 1e7), s += 4);
          s = r / 4;
        }
      } else for (; s < r;) c[s++] = 1e7 * Math.random() | 0;
      for (n %= 7, (r = c[--s]) && n && (t = N(10, 7 - n), c[s] = (r / t | 0) * t); 0 === c[s]; s--) c.pop();
      if (s < 0) i = 0, c = [0];else {
        for (i = -1; 0 === c[0]; i -= 7) c.shift();
        for (r = 1, t = c[0]; t >= 10; t /= 10) r++;
        r < 7 && (i -= 7 - r);
      }
      return o.e = i, o.d = c, o;
    }
    function Sn(n) {
      return R(n = new this(n), n.e + 1, this.rounding);
    }
    function Zn(n) {
      return (n = new this(n)).d ? n.d[0] ? n.s : 0 * n.s : n.s || NaN;
    }
    function Pn(n) {
      return new this(n).sin();
    }
    function Rn(n) {
      return new this(n).sinh();
    }
    function Tn(n) {
      return new this(n).sqrt();
    }
    function _n(n, e) {
      return new this(n).sub(e);
    }
    function Ln() {
      var n = 0,
        e = arguments,
        i = new this(e[n]);
      for (h = !1; i.s && ++n < e.length;) i = i.plus(e[n]);
      return h = !0, R(i, this.precision, this.rounding);
    }
    function Un(n) {
      return new this(n).tan();
    }
    function kn(n) {
      return new this(n).tanh();
    }
    function Cn(n) {
      return R(n = new this(n), n.e + 1, 1);
    }
    i = function n(e) {
      var i, r, t;
      function s(n) {
        var e,
          i,
          r,
          t = this;
        if (!(t instanceof s)) return new s(n);
        if (t.constructor = s, Nn(n)) return t.s = n.s, void (h ? !n.d || n.e > s.maxE ? (t.e = NaN, t.d = null) : n.e < s.minE ? (t.e = 0, t.d = [0]) : (t.e = n.e, t.d = n.d.slice()) : (t.e = n.e, t.d = n.d ? n.d.slice() : n.d));
        if ("number" === (r = typeof n)) {
          if (0 === n) return t.s = 1 / n < 0 ? -1 : 1, t.e = 0, void (t.d = [0]);
          if (n < 0 ? (n = -n, t.s = -1) : t.s = 1, n === ~~n && n < 1e7) {
            for (e = 0, i = n; i >= 10; i /= 10) e++;
            return void (h ? e > s.maxE ? (t.e = NaN, t.d = null) : e < s.minE ? (t.e = 0, t.d = [0]) : (t.e = e, t.d = [n]) : (t.e = e, t.d = [n]));
          }
          return 0 * n != 0 ? (n || (t.s = NaN), t.e = NaN, void (t.d = null)) : W(t, n.toString());
        }
        if ("string" !== r) throw Error(p + n);
        return 45 === (i = n.charCodeAt(0)) ? (n = n.slice(1), t.s = -1) : (43 === i && (n = n.slice(1)), t.s = 1), y.test(n) ? W(t, n) : J(t, n);
      }
      if (s.prototype = D, s.ROUND_UP = 0, s.ROUND_DOWN = 1, s.ROUND_CEIL = 2, s.ROUND_FLOOR = 3, s.ROUND_HALF_UP = 4, s.ROUND_HALF_DOWN = 5, s.ROUND_HALF_EVEN = 6, s.ROUND_HALF_CEIL = 7, s.ROUND_HALF_FLOOR = 8, s.EUCLID = 9, s.config = s.set = hn, s.clone = n, s.isDecimal = Nn, s.abs = Y, s.acos = nn, s.acosh = en, s.add = rn, s.asin = tn, s.asinh = sn, s.atan = on, s.atanh = un, s.atan2 = cn, s.cbrt = fn, s.ceil = an, s.clamp = ln, s.cos = dn, s.cosh = pn, s.div = gn, s.exp = mn, s.floor = wn, s.hypot = vn, s.ln = bn, s.log = xn, s.log10 = yn, s.log2 = En, s.max = Mn, s.min = qn, s.mod = On, s.mul = Dn, s.pow = Fn, s.random = An, s.round = Sn, s.sign = Zn, s.sin = Pn, s.sinh = Rn, s.sqrt = Tn, s.sub = _n, s.sum = Ln, s.tan = Un, s.tanh = kn, s.trunc = Cn, void 0 === e && (e = {}), e && !0 !== e.defaults) for (t = ["precision", "rounding", "toExpNeg", "toExpPos", "maxE", "minE", "modulo", "crypto"], i = 0; i < t.length;) e.hasOwnProperty(r = t[i++]) || (e[r] = this[r]);
      return s.config(e), s;
    }(l), i.prototype.constructor = i, i.default = i.Decimal = i, f = new i(f), a = new i(a), n.exports ? ("function" == typeof Symbol && "symbol" == typeof Symbol.iterator && (D[Symbol.for("nodejs.util.inspect.custom")] = D.toString, D[Symbol.toStringTag] = "Decimal"), n.exports = i) : (e || (e = "undefined" != typeof self && self && self.self == self ? self : window), t = e.Decimal, i.noConflict = function () {
      return e.Decimal = t, i;
    }, e.Decimal = i);
  }(e);
});
export { i as d };
