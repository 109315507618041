import { _ as i } from "./tslib.es6-7f1b1220.js";
import { c as n, u as e, h as r } from "./lit-element-161a1699.js";
import { p as o, c as t } from "./decorators-7e9cd43b.js";
import { c as s } from "./class-map-31af68b2.js";
import { BaseElement as a } from "./BaseElement.esm.min.js";
import { h as d } from "./host.styles-cf05db22.js";
const l = n`${e(":host {\n  box-sizing: border-box;\n  display: inline-block;\n  max-width: 800px;\n  text-align: center;\n  overflow-wrap: anywhere;\n}\n\n#headline {\n  font: var(--zui-typography-subtitle1);\n  color: var(--zui-color-loader-box-headline-text);\n}\n\n#description {\n  font: var(--zui-typography-body);\n  color: var(--zui-color-loader-box-description-text);\n}\n\n#container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  padding: calc(var(--zui-gu) * 4);\n  border-radius: calc(var(--zui-gu) * 1);\n  background-color: var(--zui-color-loader-box-bg);\n}\n#container.description-only, #container.headline-only, #container.headline-with-description {\n  padding-bottom: 27px;\n}\n#container.description-only zui-progress-ring, #container.headline-only zui-progress-ring, #container.headline-with-description zui-progress-ring {\n  margin-bottom: 19px;\n}\n#container.headline-with-description #headline {\n  margin-bottom: 6px;\n}\n#container.headline-with-description #description {\n  color: var(--zui-color-loader-box-description-text-with-headline);\n}\n\nzui-progress-ring {\n  --zui-progress-ring-rail-color: var(--zui-color-loader-box-progress-ring-rail);\n  --zui-progress-ring-progress-color: var(--zui-color-loader-box-progress-ring-progress);\n}")}`;
let c = class extends a {
  constructor() {
    super(), this.headline = "", this.description = "", this.mode = "activity", this.theme = "translucent";
  }
  static get styles() {
    return [d, l];
  }
  get _contentClass() {
    return this.headline && this.headline.trim() && this.description && this.description.trim() ? "headline-with-description" : this.headline && this.headline.trim() ? "headline-only" : this.description && this.description.trim() ? "description-only" : "_";
  }
  render() {
    return r`<div id="container" class="${s({
      [this._contentClass]: !0
    })}"><zui-progress-ring size="l"></zui-progress-ring><div id="headline">${this.headline}</div><div id="description">${this.description}</div></div>`;
  }
};
i([o({
  reflect: !0,
  type: String
})], c.prototype, "headline", void 0), i([o({
  reflect: !0,
  type: String
})], c.prototype, "description", void 0), i([o({
  reflect: !0,
  type: String
})], c.prototype, "mode", void 0), c = i([t("zui-loader-box")], c);
export { c as LoaderBox };
