import { _ as e } from "./tslib.es6-7f1b1220.js";
import { c as t, u as i, h as l, n as r } from "./lit-element-161a1699.js";
import { p as a, a as s, s as n, c as o } from "./decorators-7e9cd43b.js";
import "./class-map-31af68b2.js";
import { BaseElement as u } from "./BaseElement.esm.min.js";
import { h as m } from "./host.styles-cf05db22.js";
import { e as c } from "./event.decorator-98549cbc.js";
import { i as h } from "./if-defined-123567fc.js";
import "./types-814d68fb.js";
import "./component.utils.esm.min.js";
import "./style-map-a37d7105.js";
import "./_commonjsHelpers-82e0321a.js";
import { g as d, l as p, i as v } from "./date-picker.utils-7bc542a5.js";
import "./date-picker-input-part.component-63342ded.js";
import { h as _, g, b as y, c as k, a as f } from "./time-picker.utils-e0fd0976.js";
import "./interactive-text.component.esm.min.js";
import "./time-picker-day-time-toggle.component.esm.min.js";
import "./time-picker-cell.component.esm.min.js";
var T;
const C = t`${i(":host {\n  align-items: center;\n  display: inline-flex;\n}\n\n:host([integrated]) {\n  flex-direction: column;\n}\n\n.time-picker {\n  align-items: center;\n  display: inline-flex;\n  justify-content: center;\n}\n\n.day-time-toggle {\n  margin: 0 0 0 calc(var(--zui-gu) * 2);\n}\n:host([integrated]) .day-time-toggle {\n  margin: 0 0 calc(58px - 16px - 12px) 0;\n}")}`;
let D = T = class extends u {
  constructor() {
    super(...arguments), this.defaultValue = new Date(), this.disabled = !1, this.integrated = !1, this.locale = d(), this.step = 1, this.value = null;
  }
  static get styles() {
    return [m, C];
  }
  get _defaultValueDT() {
    return p.DateTime.fromJSDate(this.defaultValue);
  }
  get hourCycle() {
    return this._internalHourCycle ? this._internalHourCycle : _(this.locale) ? "h12" : "h23";
  }
  set hourCycle(e) {
    const t = this._internalHourCycle;
    this._internalHourCycle = e, this.requestUpdate("_internalHourCycle", t);
  }
  get _valueDT() {
    return this.value ? p.DateTime.fromJSDate(this.value) : void 0;
  }
  emitTimePickerChangedEvent() {
    var e;
    this.dispatchEvent(new CustomEvent("time-picker-changed", {
      bubbles: !0,
      cancelable: !1,
      composed: !0,
      detail: {
        value: null === (e = this.value) || void 0 === e ? void 0 : e.toISOString()
      }
    }));
  }
  emitTimePickerInputEvent(e) {
    this.dispatchEvent(new CustomEvent("time-picker-input", {
      bubbles: !0,
      cancelable: !1,
      composed: !0,
      detail: {
        value: e
      }
    }));
  }
  get _currentDate() {
    var e;
    return null !== (e = this._valueDT) && void 0 !== e ? e : this._defaultValueDT;
  }
  get _dayTime() {
    return this._is12HourFormat ? g(this._currentDate, this.hourCycle) : void 0;
  }
  get _defaultHour() {
    return y(this._defaultValueDT, this.locale, this.hourCycle);
  }
  get _hour() {
    return this._valueDT ? y(this._valueDT, this.locale, this.hourCycle) : void 0;
  }
  get _is12HourFormat() {
    return "h12" === this.hourCycle;
  }
  get _literal() {
    return this._currentDate.toLocaleParts({
      hour: "numeric",
      minute: "numeric"
    }).find(({
      type: e
    }) => "literal" === e).value;
  }
  _handleTimePickerDayTimeChangedEvent({
    detail: e
  }) {
    const t = k(this._currentDate.hour, e.value);
    this.value = this._currentDate.set({
      hour: t
    }).toJSDate(), this.emitTimePickerInputEvent(this._valueDT.toISO()), this.emitTimePickerChangedEvent();
  }
  _handleTimePickerCellChangedEvent() {
    var e, t;
    const i = null !== (e = this._timePickerCellHour.value) && void 0 !== e ? e : this._timePickerCellHour.defaultValue,
      l = null !== (t = this._timePickerCellMinute.value) && void 0 !== t ? t : this._timePickerCellMinute.defaultValue;
    this.value = f({
      hour: i,
      minute: l
    }, this._currentDate, this.hourCycle, this._dayTime).toJSDate(), this.emitTimePickerInputEvent(this._valueDT.toISO()), this.emitTimePickerChangedEvent();
  }
  _handleTimePickerCellInputEvent() {
    var e, t;
    const i = null !== (e = this._timePickerCellHour.value) && void 0 !== e ? e : this._timePickerCellHour.defaultValue,
      l = null !== (t = this._timePickerCellMinute.value) && void 0 !== t ? t : this._timePickerCellMinute.defaultValue,
      r = f({
        hour: i,
        minute: l
      }, this._currentDate, this.hourCycle, this._dayTime);
    this.emitTimePickerInputEvent(r.toISO());
  }
  _getDayTimeToggleTemplate() {
    return l`<div class="day-time-toggle"><zui-time-picker-day-time-toggle ?integrated="${this.integrated}" value="${h(this._dayTime)}" @time-picker-day-time-changed="${this._handleTimePickerDayTimeChangedEvent}"></zui-time-picker-day-time-toggle></div>`;
  }
  render() {
    var e;
    return l`${this._is12HourFormat && this.integrated ? this._getDayTimeToggleTemplate() : r}<div class="time-picker" @time-picker-cell-changed="${this._handleTimePickerCellChangedEvent}" @time-picker-cell-input="${this._handleTimePickerCellInputEvent}"><zui-time-picker-cell ?disabled="${this.disabled}" default-value="${this._defaultHour}" id="hour" max="${this._is12HourFormat ? T.MAX_12_HOURS : T.MAX_24_HOURS}" min="${this._is12HourFormat ? T.MIN_12_HOURS : T.MIN_24_HOURS}" step="${this.step}" value="${h(this._hour)}"></zui-time-picker-cell><zui-time-picker-cell-divider ?disabled="${this.disabled}" literal="${this._literal}"></zui-time-picker-cell-divider><zui-time-picker-cell ?disabled="${this.disabled}" default-value="${this._defaultValueDT.minute}" id="minute" max="59" min="0" step="${this.step}" value="${h(null === (e = this._valueDT) || void 0 === e ? void 0 : e.minute)}"></zui-time-picker-cell>${this._is12HourFormat && !this.integrated ? this._getDayTimeToggleTemplate() : r}</div>`;
  }
};
D.MAX_12_HOURS = 12, D.MAX_24_HOURS = 23, D.MIN_12_HOURS = 1, D.MIN_24_HOURS = 0, e([a({
  reflect: !0,
  type: String,
  attribute: "default-value",
  converter: v
})], D.prototype, "defaultValue", void 0), e([a({
  reflect: !0,
  type: Boolean
})], D.prototype, "disabled", void 0), e([a({
  reflect: !0,
  type: String,
  attribute: "hour-cycle"
})], D.prototype, "hourCycle", null), e([a({
  reflect: !0,
  type: Boolean
})], D.prototype, "integrated", void 0), e([a({
  reflect: !0,
  type: String
})], D.prototype, "locale", void 0), e([a({
  reflect: !0,
  type: Number
})], D.prototype, "step", void 0), e([a({
  reflect: !0,
  type: String,
  converter: v
})], D.prototype, "value", void 0), e([c()], D.prototype, "emitTimePickerChangedEvent", null), e([c()], D.prototype, "emitTimePickerInputEvent", null), e([s("zui-time-picker-cell#hour")], D.prototype, "_timePickerCellHour", void 0), e([s("zui-time-picker-cell#minute")], D.prototype, "_timePickerCellMinute", void 0), e([n()], D.prototype, "_internalHourCycle", void 0), D = T = e([o("zui-time-picker")], D);
export { D as TimePicker };
