import { _ as r } from "./tslib.es6-7f1b1220.js";
import { c as e, u as o, h as s } from "./lit-element-161a1699.js";
import { p as t, c as n } from "./decorators-7e9cd43b.js";
import { BaseElement as a } from "./BaseElement.esm.min.js";
import { h as i } from "./host.styles-cf05db22.js";
const d = e`${o("h1:focus {\n  outline: none;\n  border: 1px dashed;\n  border-radius: var(--zui-border-radius-on-focus);\n}\n\nh1 {\n  color: var(--zui-color-ewiq-dialog-header-text-color);\n  font: var(--zui-typography-h1);\n  border: 1px solid transparent;\n  margin: 0;\n}")}`;
let l = class extends a {
  constructor() {
    super(...arguments), this.role = "heading";
  }
  static get styles() {
    return [i, d];
  }
  render() {
    return s`<h1 tabindex="0"><slot></slot></h1>`;
  }
};
r([t({
  reflect: !0
})], l.prototype, "role", void 0), l = r([n("zui-ewiq-dialog-headline")], l);
export { l as EwiqDialogHeadline };
