import { _ as t } from "./tslib.es6-7f1b1220.js";
import { c as e, u as n, h as i } from "./lit-element-161a1699.js";
import { q as s, a as o, c as r } from "./decorators-7e9cd43b.js";
import { c as d } from "./class-map-31af68b2.js";
import { BaseElement as l } from "./BaseElement.esm.min.js";
import { h as a } from "./host.styles-cf05db22.js";
const c = e`${n(":host {\n  display: block;\n}\n\n#container {\n  height: calc(var(--zui-gu) * 8);\n  width: 100%;\n  background-color: var(--zui-focus-view-bar-background-color);\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n}\n\n#left-icon-button {\n  display: flex;\n  justify-content: start;\n  flex-direction: row;\n  align-items: center;\n}\n\n#left-aligned-content {\n  display: flex;\n  justify-content: start;\n  flex-direction: row;\n  align-items: center;\n  overflow: hidden;\n  height: 100%;\n}\n\n#center-aligned-content {\n  display: flex;\n  justify-content: center;\n  flex-direction: row;\n  align-items: center;\n  margin-left: calc(var(--zui-gu) * 2);\n  margin-right: calc(var(--zui-gu) * 2);\n  height: 100%;\n  overflow: hidden;\n  flex-shrink: 0;\n}\n\n#center-aligned-content.used {\n  margin-left: calc(var(--zui-gu) * 4);\n  margin-right: calc(var(--zui-gu) * 4);\n}\n\n#right-aligned-content {\n  display: flex;\n  justify-content: end;\n  flex-direction: row;\n  align-items: center;\n  overflow: hidden;\n  height: 100%;\n}\n\n#right-icon-button {\n  display: flex;\n  justify-content: end;\n  flex-direction: row;\n  align-items: center;\n}\n\n#left {\n  display: flex;\n  justify-content: start;\n  flex-direction: row;\n  align-items: center;\n  overflow: hidden;\n  height: 100%;\n  flex-shrink: 1;\n}\n\n#left.separator zui-divider,\n#right.separator zui-divider {\n  display: unset;\n  flex-shrink: 0;\n}\n\nzui-divider {\n  height: calc(var(--zui-gu) * 8);\n  display: none;\n}\n\n#right {\n  display: flex;\n  justify-content: end;\n  flex-direction: row;\n  align-items: center;\n  overflow: hidden;\n  height: 100%;\n  flex-shrink: 1;\n}")}`;
let h = class extends l {
  constructor() {
    super(...arguments), this._widthCenter = 0, this._widthfocusViewBar = 0, this._centerAlignedContentResizeObserver = new ResizeObserver(([{
      contentRect: t
    }]) => {
      requestAnimationFrame(() => {
        this._widthCenter = t.width, this.requestUpdate();
      });
    }), this._focusViewBarResizeObserver = new ResizeObserver(([{
      contentRect: t
    }]) => {
      requestAnimationFrame(() => {
        this._widthfocusViewBar = t.width, this.requestUpdate();
      });
    });
  }
  get _isLeftseparatorNeeded() {
    var t, e;
    return (null === (t = this._leftContent) || void 0 === t ? void 0 : t.length) > 0 && (null === (e = this._leftIconButton) || void 0 === e ? void 0 : e.length) > 0;
  }
  get _isRightseparatorNeeded() {
    var t, e;
    return (null === (t = this._rightContent) || void 0 === t ? void 0 : t.length) > 0 && (null === (e = this._rightIconButton) || void 0 === e ? void 0 : e.length) > 0;
  }
  get _isLeftContentUsed() {
    var t;
    return (null === (t = this._leftContent) || void 0 === t ? void 0 : t.length) > 0;
  }
  get _isRightContentUsed() {
    var t;
    return (null === (t = this._rightContent) || void 0 === t ? void 0 : t.length) > 0;
  }
  get _isCenterContentUsed() {
    var t;
    return (null === (t = this._centerContent) || void 0 === t ? void 0 : t.length) > 0;
  }
  disconnectedCallback() {
    this._centerAlignedContentResizeObserver.unobserve(this._centerContainer), this._focusViewBarResizeObserver.unobserve(this), super.disconnectedCallback();
  }
  static get styles() {
    return [a, c];
  }
  _handleSlotchange() {
    this.requestUpdate();
  }
  get _minWidth() {
    const t = this._isCenterContentUsed ? 64 : 32;
    return (this._widthfocusViewBar - this._widthCenter - t) / 2 + "px";
  }
  get _maxWidth() {
    const t = this._isCenterContentUsed ? 64 : 32,
      e = this._widthfocusViewBar - t - 130;
    return e < 0 ? "0px" : `${e}px`;
  }
  firstUpdated() {
    this._centerAlignedContentResizeObserver.observe(this._centerContainer), this._focusViewBarResizeObserver.observe(this);
  }
  render() {
    return i`<div id="container"><div id="left" class="${d({
      separator: this._isLeftseparatorNeeded
    })}" style="min-width:${this._minWidth}"><div id="left-icon-button"><slot @slotchange="${this._handleSlotchange}" name="left-icon-button"></slot></div><zui-divider emphasis="default" size="s" orientation="vertical"></zui-divider><div id="left-aligned-content" class="${d({
      used: this._isLeftContentUsed
    })}"><slot name="left-content" @slotchange="${this._handleSlotchange}"></slot></div></div><div id="center-aligned-content" class="${d({
      used: this._isCenterContentUsed
    })}" style="max-width:${this._maxWidth}"><slot name="center-content" @slotchange="${this._handleSlotchange}"></slot></div><div id="right" class="${d({
      separator: this._isRightseparatorNeeded
    })}" style="min-width:${this._minWidth}"><div id="right-aligned-content" class="${d({
      used: this._isRightContentUsed
    })}"><slot name="right-content" @slotchange="${this._handleSlotchange}"></slot></div><zui-divider emphasis="default" size="s" orientation="vertical"></zui-divider><div id="right-icon-button"><slot @slotchange="${this._handleSlotchange}" name="right-icon-button"></slot></div></div></div>`;
  }
};
t([s("left-icon-button", !0)], h.prototype, "_leftIconButton", void 0), t([s("left-content", !0)], h.prototype, "_leftContent", void 0), t([s("right-icon-button", !0)], h.prototype, "_rightIconButton", void 0), t([s("right-content", !0)], h.prototype, "_rightContent", void 0), t([s("center-content", !0)], h.prototype, "_centerContent", void 0), t([o("#center-aligned-content", !0)], h.prototype, "_centerContainer", void 0), h = t([r("zui-focus-view-bar")], h);
export { h as FocusViewBar };
