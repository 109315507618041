import { _ as t } from "./tslib.es6-7f1b1220.js";
import { c as n, u as e, h as o } from "./lit-element-161a1699.js";
import { p as i, a as s, c as r } from "./decorators-7e9cd43b.js";
import { BaseElement as a } from "./BaseElement.esm.min.js";
import { h as l } from "./host.styles-cf05db22.js";
import { e as c } from "./event.decorator-98549cbc.js";
import { i as d } from "./if-defined-123567fc.js";
const h = n`${e(":host([disabled]) {\n  pointer-events: none;\n}\n\nbutton {\n  background-color: var(--zui-color-first-hierarchy-bg-default-enabled);\n  height: 100%;\n  width: 100%;\n  padding: 0;\n  outline: none;\n  cursor: pointer;\n  border: 1px solid transparent;\n  pointer-events: none;\n}\n\nbutton:hover {\n  background-color: var(--zui-color-first-hierarchy-bg-default-hovered);\n}\n\nbutton:focus {\n  border: 1px dashed;\n  border-radius: var(--zui-border-radius, 0px);\n}\n\nbutton:active {\n  background-color: var(--zui-color-first-hierarchy-bg-default-pressed);\n}\n\n:host([disabled]) button {\n  opacity: var(--zui-disabled-opacity);\n}\n\n.button-content {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  width: 100%;\n  justify-content: center;\n  box-sizing: border-box;\n  padding: 0 calc(var(--zui-gu) * 1);\n  outline: none;\n  pointer-events: all;\n}\n.button-content #first-line {\n  color: var(--zui-color-dialog-button-default);\n  font: var(--zui-typography-body);\n}\n:host([emphasis=highlight]) .button-content #first-line, :host([emphasis=primary-active]) .button-content #first-line, :host([emphasis=primary-highlight]) .button-content #first-line {\n  font: var(--zui-typography-subtitle1);\n}\n:host([emphasis=highlight]) .button-content #first-line {\n  color: var(--zui-color-text-default);\n}\n:host([emphasis=primary-active]) .button-content #first-line, :host([emphasis=primary-highlight]) .button-content #first-line {\n  color: var(--zui-color-text-primary);\n}\n.button-content #secondary-line {\n  font: var(--zui-typography-caption);\n  color: var(--zui-color-text-secondary-line);\n}\n\n:host([disabled]) .button-content {\n  pointer-events: inherit;\n}\n\nsection {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}")}`;
let p = class extends a {
  constructor() {
    super(...arguments), this.disabled = !1, this.emphasis = "default", this.multiline = !1;
  }
  static get styles() {
    return [l, h];
  }
  emitClickEvent(t) {
    this.dispatchEvent(new CustomEvent("click", {
      bubbles: !0,
      composed: !0,
      detail: t
    }));
  }
  _handleButtonClick(t) {
    t.preventDefault(), t.stopPropagation(), t.stopImmediatePropagation(), this.emitClickEvent(this);
  }
  _checkMultiline(t) {
    t.assignedElements()[0] && t.assignedElements()[0].innerHTML.length > 0 && (this.multiline = !0);
  }
  firstUpdated() {
    this._checkMultiline(this._secondarySlot);
  }
  render() {
    return o`<button id="button" @click="${this._handleButtonClick}" ?disabled="${this.disabled}"><span class="button-content" tabindex="${d(this.disabled ? void 0 : "-1")}"><section id="first-line"><slot></slot></section><section id="secondary-line"><slot name="secondary"></slot></section></span></button>`;
  }
};
t([i({
  reflect: !0,
  type: Boolean
})], p.prototype, "disabled", void 0), t([i({
  reflect: !0,
  type: String
})], p.prototype, "emphasis", void 0), t([i({
  reflect: !0,
  type: Boolean
})], p.prototype, "multiline", void 0), t([c()], p.prototype, "emitClickEvent", null), t([s('slot[name="secondary"]')], p.prototype, "_secondarySlot", void 0), p = t([r("zui-dialog-button")], p);
export { p as DialogButton };
