import { _ as e } from "./tslib.es6-7f1b1220.js";
import "./lit-element-161a1699.js";
import { p as t } from "./decorators-7e9cd43b.js";
import { BaseElement as o } from "./BaseElement.esm.min.js";
class i extends o {
  constructor() {
    super(...arguments), this.isContentTabItem = !0, this.disabled = !1, this.emphasis = "default", this.closable = !1;
  }
  connectedCallback() {
    super.connectedCallback(), this.addEventListener("click", this._handleClick);
  }
  disconnectedCallback() {
    super.disconnectedCallback(), this.removeEventListener("click", this._handleClick);
  }
  _handleClick(e) {
    this.disabled && (e.stopPropagation(), e.stopImmediatePropagation());
  }
}
e([t({
  reflect: !0,
  type: Boolean,
  attribute: "zui-internal-is-content-tab-item"
})], i.prototype, "isContentTabItem", void 0), e([t({
  reflect: !0,
  type: Boolean
})], i.prototype, "disabled", void 0), e([t({
  reflect: !0,
  type: String
})], i.prototype, "emphasis", void 0), e([t({
  reflect: !0,
  type: String
})], i.prototype, "value", void 0), e([t({
  reflect: !0,
  type: Boolean
})], i.prototype, "closable", void 0), e([t({
  reflect: !0,
  type: String
})], i.prototype, "text", void 0);
export { i as ContentTabItemBase };
