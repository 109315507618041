import { _ as t } from "./tslib.es6-7f1b1220.js";
import { c as n, u as o, h as i } from "./lit-element-161a1699.js";
import { p as e, s, a as r, q as a, c as l } from "./decorators-7e9cd43b.js";
import { c as u } from "./class-map-31af68b2.js";
import { BaseElement as d } from "./BaseElement.esm.min.js";
import { h } from "./host.styles-cf05db22.js";
import "./event.decorator-98549cbc.js";
import "./if-defined-123567fc.js";
import "./dialog-button.component.esm.min.js";
const m = n`${o(":host {\n  --zui-ewiq-button-alignment: row;\n  --zui-ewiq-button-border-top: 1px solid var(--zui-color-dialog-box-separator-line);\n  --zui-ewiq-button-border-right: 1px solid var(--zui-color-dialog-box-separator-line);\n}\n\n.align-buttons-in-columns,\n:host([primary-position=auto]) .align-buttons-in-columns {\n  --zui-ewiq-button-alignment: column;\n  --zui-ewiq-button-border-top: 1px solid var(--zui-color-dialog-box-separator-line);\n  --zui-ewiq-button-border-right: none;\n}\n\n:host([primary-position=last]) .align-buttons-in-columns {\n  --zui-ewiq-button-alignment: column-reverse;\n}\n\n:host([primary-position=last]) {\n  --zui-ewiq-button-alignment: row-reverse;\n}\n\n:host([primary-position=auto]) {\n  --zui-ewiq-button-alignment: var(--zui-internal-action-button-order);\n}\n\n#button-container:not(.primary-exists) {\n  --zui-ewiq-button-alignment: row;\n}\n\n#button-container {\n  display: flex;\n  flex-direction: var(--zui-ewiq-button-alignment);\n  justify-content: center;\n  height: auto;\n  width: 100%;\n}\n#button-container ::slotted(*) {\n  height: calc(var(--zui-gu) * 8);\n  width: 100%;\n  border: none;\n  border-top: var(--zui-ewiq-button-border-top);\n  border-right: var(--zui-ewiq-button-border-right);\n}\n\n:host([primary-position=first]) #button-container ::slotted(*:last-child),\n:host([primary-position=auto]) #button-container:not(.row-reverse) ::slotted(*:last-child) {\n  border-right: none;\n}\n\n.row-reverse ::slotted(*:first-child) {\n  border-right: none !important;\n}\n\n:host([row-reverse]) slot {\n  border: none;\n}")}`;
let p = class extends d {
  constructor() {
    super(...arguments), this.role = "button", this.primaryPosition = "auto", this._zuiInternalAlignButtonsInColumns = !1, this._primaryExists = !1, this._secondaryExists = !1, this._rowReverse = !1;
  }
  static get styles() {
    return [h, m];
  }
  get _secondaryDialogButtons() {
    return Array.from(this._assignedSecondaryDialogButtons);
  }
  get _isEverySecondaryDialogButtonMultiline() {
    return this._secondaryDialogButtons.length > 0 && this._secondaryDialogButtons.every(t => t.multiline);
  }
  _setFlexDirection() {
    "row-reverse" === getComputedStyle(this).getPropertyValue("--zui-ewiq-button-alignment").trim() && (this._rowReverse = !0);
  }
  _primarySlotChangeHandler() {
    this._slotPrimary.assignedElements().length < 1 || (this._slotPrimary.assignedElements()[0].setAttribute("emphasis", "primary-highlight"), this._primaryExists = !0, this._setFlexDirection());
  }
  _secondarySlotChangeHandler() {
    this._secondaryDialogButtons.length < 1 ? this._secondaryExists = !1 : (this._secondaryDialogButtons.forEach(t => t.emphasis = "highlight"), this._secondaryExists = !0, this._setFlexDirection());
  }
  _defaultSlotChangeHandler() {
    this._slotPrimary.assignedElements().length < 1 || (this._slotDefault.assignedElements().length >= 2 && (this._zuiInternalAlignButtonsInColumns = !0), this._setFlexDirection());
  }
  updated() {
    (this._slotPrimary.assignedElements().every(t => t.hasAttribute("multiline")) || this._isEverySecondaryDialogButtonMultiline || this._slotDefault.assignedElements().every(t => t.hasAttribute("multiline"))) && (this._zuiInternalAlignButtonsInColumns = !0);
  }
  render() {
    return i`<div id="button-container" class="${u({
      "align-buttons-in-columns": this._zuiInternalAlignButtonsInColumns,
      "primary-exists": this._primaryExists,
      "secondary-exists": this._secondaryExists,
      "row-reverse": this._rowReverse
    })}"><slot name="primary" @slotchange="${this._primarySlotChangeHandler}"></slot><slot name="secondary" @slotchange="${this._secondarySlotChangeHandler}"></slot><slot @slotchange="${this._defaultSlotChangeHandler}"></slot></div>`;
  }
};
t([e({
  reflect: !0
})], p.prototype, "role", void 0), t([e({
  reflect: !0,
  type: String,
  attribute: "primary-position"
})], p.prototype, "primaryPosition", void 0), t([s()], p.prototype, "_zuiInternalAlignButtonsInColumns", void 0), t([s()], p.prototype, "_primaryExists", void 0), t([s()], p.prototype, "_secondaryExists", void 0), t([s()], p.prototype, "_rowReverse", void 0), t([r('slot[name="primary"]')], p.prototype, "_slotPrimary", void 0), t([a("secondary", !0, "zui-dialog-button")], p.prototype, "_assignedSecondaryDialogButtons", void 0), t([r("slot:not([name])")], p.prototype, "_slotDefault", void 0), p = t([l("zui-ewiq-dialog-buttons")], p);
export { p as EwiqDialogButtons };
