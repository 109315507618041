import { _ as e } from "./tslib.es6-7f1b1220.js";
import { c as t, u as i, h as r } from "./lit-element-161a1699.js";
import { p as l, c as s } from "./decorators-7e9cd43b.js";
import { BaseElement as n } from "./BaseElement.esm.min.js";
import { h as c } from "./host.styles-cf05db22.js";
import { e as o } from "./event.decorator-98549cbc.js";
import "./if-defined-123567fc.js";
import { traverseDOMSiblingsByStepAndDirection as d } from "./dom.utils.esm.min.js";
import { GridCell as a } from "./grid-cell.component.esm.min.js";
import { HeaderCell as m } from "./header-cell.component.esm.min.js";
function u(e, t = 1) {
  return d(e, "previous", t, t => t.tagName === e.tagName && !t.hasAttribute("disabled"));
}
function p(e, t = 1) {
  return d(e, "next", t, t => t.tagName === e.tagName && !t.hasAttribute("disabled"));
}
const h = t`${i(":host {\n  ---zui-picker-grid-columns: 7;\n  --zui-picker-grid-cell-height: utils.grid-units(4);\n  --zui-picker-grid-cell-width: utils.grid-units(4);\n  width: calc(var(---zui-picker-grid-columns) * var(--zui-picker-grid-cell-width));\n  display: flex;\n  flex-direction: column;\n}\n\nslot[name=pickerGridHeaderCells] {\n  display: grid;\n  grid-template-columns: repeat(var(---zui-picker-grid-columns), min-content);\n}\n\nslot[name=pickerGridCells] {\n  display: grid;\n  grid-template-columns: repeat(var(---zui-picker-grid-columns), min-content);\n}")}`;
var v;
!function (e) {
  e.ArrowUp = "ArrowUp", e.ArrowRight = "ArrowRight", e.ArrowDown = "ArrowDown", e.ArrowLeft = "ArrowLeft";
}(v || (v = {}));
let k = class extends n {
  constructor() {
    super(...arguments), this.columns = 7;
  }
  static get styles() {
    return [c, h];
  }
  emitPickerGridCellSelectedEvent(e) {
    this.dispatchEvent(new CustomEvent("pickerGridCellSelected", {
      bubbles: !0,
      composed: !0,
      detail: e
    })), this.dispatchEvent(new CustomEvent("picker-grid-cell-selected", {
      bubbles: !0,
      composed: !0,
      detail: e
    }));
  }
  emitPickerHeaderCellSelectedEvent(e) {
    this.dispatchEvent(new CustomEvent("pickerHeaderCellSelected", {
      bubbles: !0,
      composed: !0,
      detail: e
    })), this.dispatchEvent(new CustomEvent("picker-header-cell-selected", {
      bubbles: !0,
      composed: !0,
      detail: e
    }));
  }
  connectedCallback() {
    super.connectedCallback(), this.addEventListener("keydown", this._handleKeyEvent), this.addEventListener("click", this._handleClickEvent);
  }
  disconnectedCallback() {
    this.removeEventListener("keydown", this._handleKeyEvent), this.removeEventListener("click", this._handleClickEvent), super.disconnectedCallback();
  }
  _handleKeyEvent(e) {
    let {
      code: t
    } = e;
    const {
      target: i,
      shiftKey: r
    } = e;
    "Tab" === t && (e.preventDefault(), t = r ? v.ArrowLeft : v.ArrowRight);
    let l = null;
    switch (t) {
      case v.ArrowUp:
        l = u(i, this.columns);
        break;
      case v.ArrowLeft:
        l = u(i);
        break;
      case v.ArrowRight:
        l = p(i);
        break;
      case v.ArrowDown:
        l = p(i, this.columns);
    }
    l && l.focus();
  }
  _handleClickEvent({
    target: e
  }) {
    e instanceof a ? this.emitPickerGridCellSelectedEvent({
      value: e.value,
      selected: e
    }) : e instanceof m && this.emitPickerHeaderCellSelectedEvent({
      text: e.text,
      value: e.value,
      selected: e
    });
  }
  render() {
    return r`<slot name="pickerGridHeaderCells" style="---zui-picker-grid-columns:${this.columns}"></slot><slot name="pickerGridCells" style="---zui-picker-grid-columns:${this.columns}"></slot>`;
  }
};
e([l({
  reflect: !0,
  type: Number
})], k.prototype, "columns", void 0), e([o()], k.prototype, "emitPickerGridCellSelectedEvent", null), e([o()], k.prototype, "emitPickerHeaderCellSelectedEvent", null), k = e([s("zui-picker-grid")], k);
export { k as PickerGrid };
