import { _ as o } from "./tslib.es6-7f1b1220.js";
import { c as n, u as r, h as t } from "./lit-element-161a1699.js";
import { c as e } from "./decorators-7e9cd43b.js";
import { BaseElement as s } from "./BaseElement.esm.min.js";
import { h as i } from "./host.styles-cf05db22.js";
const a = n`${r(":host {\n  display: flex;\n  width: 100%;\n}\n\ndiv:focus {\n  outline: none;\n  border: 1px dashed;\n  border-radius: var(--zui-border-radius-on-focus);\n}\n\ndiv {\n  width: 100%;\n  border: 1px solid transparent;\n  background: var(--zui-color-ewiq-dialog-content-background);\n  color: var(--zui-color-ewiq-dialog-content-text-color);\n  font: var(--zui-typography-body);\n  padding: calc(var(--zui-gu) * 2);\n}")}`;
let d = class extends s {
  static get styles() {
    return [i, a];
  }
  render() {
    return t`<div tabindex="0"><slot></slot></div>`;
  }
};
d = o([e("zui-ewiq-dialog-content")], d);
export { d as EwiqDialogContent };
