import { _ as e } from "./tslib.es6-7f1b1220.js";
import { c as r, u as l, h as t } from "./lit-element-161a1699.js";
import { p as o, a as i, q as s, s as a, c as n } from "./decorators-7e9cd43b.js";
import { BaseElement as c } from "./BaseElement.esm.min.js";
const h = r`${l(":host {\n  --zui-scrollable-corner-size: calc(var(--zui-gu) * 1.5);\n  --zui-scrollable-width: auto;\n  --zui-scrollable-height: auto;\n  --zui-scrollable-max-width: none;\n  --zui-scrollable-max-height: none;\n  width: var(--zui-scrollable-width);\n  height: var(--zui-scrollable-height);\n  max-width: var(--zui-scrollable-max-width);\n  max-height: var(--zui-scrollable-max-height);\n  display: flex;\n  flex-flow: column;\n  position: relative;\n}\n\n.wrapper {\n  align-self: stretch;\n  flex-grow: 1;\n  overflow: scroll;\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n}\n.wrapper::-webkit-scrollbar {\n  display: none;\n}\n\n.corner {\n  position: absolute;\n  bottom: 0;\n  left: auto;\n  right: 0;\n  top: auto;\n  opacity: 0;\n  display: block;\n  height: var(--zui-scrollable-corner-size);\n  width: var(--zui-scrollable-corner-size);\n}")}`;
let p = class extends c {
  constructor() {
    super(...arguments), this.background = "hidden", this.hitarea = "enlarged", this._cornerColliding = !1, this._showHorizontal = !1, this._showVertical = !1, this._observer = new ResizeObserver(this._updateScrollbarSizes.bind(this)), this._wrapperScrollOffsets = {
      x: 0,
      y: 0
    }, this._scrollHorizontalTimeout = void 0, this._scrollVerticalTimeout = void 0;
  }
  disconnectedCallback() {
    this._observer.disconnect(), window.clearTimeout(this._scrollHorizontalTimeout), window.clearTimeout(this._scrollVerticalTimeout), super.disconnectedCallback();
  }
  _updateScroll({
    detail: {
      offset: e,
      orientation: r
    }
  }) {
    "horizontal" === r ? this._wrapperRef.scrollLeft = this._wrapperRef.scrollWidth * e / 100 : this._wrapperRef.scrollTop = this._wrapperRef.scrollHeight * e / 100;
  }
  _updateWheel({
    deltaX: e,
    deltaY: r
  }) {
    this._wrapperRef.scrollLeft += e, this._wrapperRef.scrollTop += r;
  }
  _updateScrollbarSizes() {
    const {
      clientHeight: e,
      clientWidth: r,
      scrollHeight: l,
      scrollWidth: t
    } = this._wrapperRef;
    if (this._showHorizontal = t > r, this._showVertical = l > e, this._showHorizontal) {
      const e = r / t * 100;
      this._scrollbarHorizontalRef.style.setProperty("---zui-scrollbar-handle-size", `${e}%`);
    }
    if (this._showVertical) {
      const r = e / l * 100;
      this._scrollbarVerticalRef.style.setProperty("---zui-scrollbar-handle-size", `${r}%`);
    }
    this._updateScrollbarPositions();
  }
  _updateScrollbarPositions() {
    const {
      clientHeight: e,
      clientWidth: r,
      scrollHeight: l,
      scrollWidth: t,
      scrollLeft: o,
      scrollTop: i
    } = this._wrapperRef;
    if (this._showHorizontal) {
      const e = o / (t - r) * 100;
      this._scrollbarHorizontalRef.style.setProperty("---zui-scrollbar-handle-offset", `${e}%`);
    }
    if (this._showVertical) {
      const r = i / (l - e) * 100;
      this._scrollbarVerticalRef.style.setProperty("---zui-scrollbar-handle-offset", `${r}%`);
    }
  }
  _updateScrollDirection() {
    const e = (e, r) => (e.scrolling = !0, window.clearTimeout(r), window.setTimeout(() => {
      e.scrolling = !1;
    }, 1e3));
    this._wrapperScrollOffsets.x !== this._wrapperRef.scrollLeft && (this._scrollHorizontalTimeout = e(this._scrollbarHorizontalRef, this._scrollHorizontalTimeout)), this._wrapperScrollOffsets.y !== this._wrapperRef.scrollTop && (this._scrollVerticalTimeout = e(this._scrollbarVerticalRef, this._scrollVerticalTimeout)), this._wrapperScrollOffsets.x = this._wrapperRef.scrollLeft, this._wrapperScrollOffsets.y = this._wrapperRef.scrollTop;
  }
  _handleSlotChange() {
    this._assignedNodes.forEach(e => this._observer.observe(e)), requestAnimationFrame(() => this._updateScrollbarSizes());
  }
  _handleScroll() {
    this._updateScrollbarPositions(), this._updateScrollDirection();
  }
  _handleCornerEnter() {
    this._cornerColliding = !0;
  }
  _handleCornerLeave() {
    this._cornerColliding = !1;
  }
  firstUpdated(e) {
    super.firstUpdated(e), this._observer.observe(this._wrapperRef);
  }
  render() {
    return t`<div class="wrapper" part="wrapper" @scroll="${this._handleScroll}"><slot @slotchange="${this._handleSlotChange}"></slot></div><zui-scrollbar background="${this.background}" hitarea="${this.hitarea}" orientation="horizontal" part="scrollbar-horizontal" ?colliding="${this._cornerColliding}" ?disabled="${!this._showHorizontal}" ?multiple="${this._showVertical}" @scrollbar="${this._updateScroll}" @wheel="${this._updateWheel}"></zui-scrollbar><zui-scrollbar background="${this.background}" hitarea="${this.hitarea}" orientation="vertical" part="scrollbar-vertical" ?colliding="${this._cornerColliding}" ?disabled="${!this._showVertical}" ?multiple="${this._showHorizontal}" @scrollbar="${this._updateScroll}" @wheel="${this._updateWheel}"></zui-scrollbar><span class="corner" @mouseenter="${this._handleCornerEnter}" @mouseleave="${this._handleCornerLeave}" @wheel="${this._updateWheel}"></span>`;
  }
};
p.styles = [h], e([o({
  reflect: !0,
  type: String
})], p.prototype, "background", void 0), e([o({
  reflect: !0,
  type: String
})], p.prototype, "hitarea", void 0), e([i(".wrapper")], p.prototype, "_wrapperRef", void 0), e([i("zui-scrollbar[orientation=horizontal]")], p.prototype, "_scrollbarHorizontalRef", void 0), e([i("zui-scrollbar[orientation=vertical]")], p.prototype, "_scrollbarVerticalRef", void 0), e([s(void 0, !0, "*")], p.prototype, "_assignedNodes", void 0), e([a()], p.prototype, "_cornerColliding", void 0), e([a()], p.prototype, "_showHorizontal", void 0), e([a()], p.prototype, "_showVertical", void 0), p = e([n("zui-scrollable-directive")], p);
let d = class extends p {
  connectedCallback() {
    super.connectedCallback(), console.warn("The usage of <zui-scrollable> has been deprecated and it will be removed in the next major release! It has been renamed to <zui-scrollable-directive>");
  }
};
d = e([n("zui-scrollable")], d);
export { d as Scrollable, p as ScrollableDirective };
