import { _ as e } from "./tslib.es6-7f1b1220.js";
import { c as t, u as o, h as n } from "./lit-element-161a1699.js";
import { p as i, q as r, c } from "./decorators-7e9cd43b.js";
import { BaseElement as s } from "./BaseElement.esm.min.js";
import { h as a } from "./host.styles-cf05db22.js";
import { e as l } from "./event.decorator-98549cbc.js";
import "./interactive-icon.component.esm.min.js";
const d = t`${o(":host {\n  display: block;\n  height: calc(var(--zui-gu) * 4);\n}\n\n.icon-placeholder {\n  min-width: calc(var(--zui-gu) * 2);\n}\n\n#flex-wrapper {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  flex-direction: row;\n  justify-content: space-between;\n}\n\n#text-button {\n  color: var(--zui-color-picker-header-text-color-default);\n  font: var(--zui-typography-h4);\n  outline: none;\n  background-color: transparent;\n  border: none;\n  cursor: pointer;\n}\n:host([disabled]) #text-button {\n  color: var(--zui-color-picker-header-text-color-disabled);\n  cursor: auto;\n}\n\n#text-button:focus {\n  color: var(--zui-color-picker-header-text-color-active);\n}\n\n#text-button:hover {\n  color: var(--zui-color-picker-header-text-color-hovered);\n}\n\n#text-button:active {\n  color: var(--zui-color-picker-header-text-color-pressed);\n}")}`;
let h = class extends s {
  constructor() {
    super(...arguments), this.value = "", this.disabled = !1;
  }
  static get styles() {
    return [a, d];
  }
  emitPickerHeaderPreviousSelectedEvent() {
    this.dispatchEvent(new CustomEvent("pickerHeaderPreviousSelected", {
      bubbles: !0,
      composed: !1
    })), this.dispatchEvent(new CustomEvent("picker-header-previous-selected", {
      bubbles: !0,
      composed: !1
    }));
  }
  emitPickerHeaderNextSelectedEvent() {
    this.dispatchEvent(new CustomEvent("pickerHeaderNextSelected", {
      bubbles: !0,
      composed: !1
    })), this.dispatchEvent(new CustomEvent("picker-header-next-selected", {
      bubbles: !0,
      composed: !1
    }));
  }
  emitPickerHeaderCurrentSelectedEvent() {
    this.dispatchEvent(new CustomEvent("pickerHeaderCurrentSelected", {
      bubbles: !0,
      composed: !1
    })), this.dispatchEvent(new CustomEvent("picker-header-current-selected", {
      bubbles: !0,
      composed: !1
    }));
  }
  _handleIconLeftSlotchange() {
    this._assignedInteractiveIconsLeft.forEach(e => e.emphasis = "subtle");
  }
  _handleIconRightSlotchange() {
    this._assignedInteractiveIconsRight.forEach(e => e.emphasis = "subtle");
  }
  render() {
    return n`<div id="flex-wrapper"><div class="icon-placeholder"><slot id="left-icon-slot" name="icon-left" @click="${this.emitPickerHeaderPreviousSelectedEvent}" @slotchange="${this._handleIconLeftSlotchange}"></slot></div><button id="text-button" ?disabled="${this.disabled}" tabindex="0" @click="${this.emitPickerHeaderCurrentSelectedEvent}">${this.value}</button><div class="icon-placeholder"><slot id="right-icon-slot" name="icon-right" @click="${this.emitPickerHeaderNextSelectedEvent}" @slotchange="${this._handleIconRightSlotchange}"></slot></div></div>`;
  }
};
e([i({
  reflect: !0,
  type: String
})], h.prototype, "value", void 0), e([i({
  reflect: !0,
  type: Boolean
})], h.prototype, "disabled", void 0), e([l()], h.prototype, "emitPickerHeaderPreviousSelectedEvent", null), e([l()], h.prototype, "emitPickerHeaderNextSelectedEvent", null), e([l()], h.prototype, "emitPickerHeaderCurrentSelectedEvent", null), e([r("icon-left", !0, "zui-interactive-icon")], h.prototype, "_assignedInteractiveIconsLeft", void 0), e([r("icon-right", !0, "zui-interactive-icon")], h.prototype, "_assignedInteractiveIconsRight", void 0), h = e([c("zui-picker-header")], h);
export { h as PickerHeader };
