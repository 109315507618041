import { _ as r } from "./tslib.es6-7f1b1220.js";
import { c as o, u as s, h as e } from "./lit-element-161a1699.js";
import { c as t } from "./decorators-7e9cd43b.js";
import { BaseElement as i } from "./BaseElement.esm.min.js";
import { h as n } from "./host.styles-cf05db22.js";
const a = o`${s("div:focus {\n  outline: none;\n  border: 1px dashed;\n  border-radius: var(--zui-border-radius-on-focus);\n}\n\ndiv {\n  color: var(--zui-color-ewiq-dialog-description-text-color);\n  font: var(--zui-typography-body);\n  border: 1px solid transparent;\n  margin: 0;\n}")}`;
let d = class extends i {
  static get styles() {
    return [n, a];
  }
  render() {
    return e`<div tabindex="0"><slot></slot></div>`;
  }
};
d = r([t("zui-ewiq-dialog-description")], d);
export { d as EwiqDialogDescription };
