function t(t, e = !0) {
  return (e ? d(t) : l(t)) ? t.parentNode.getRootNode().host : t.parentNode;
}
function e(e, n) {
  const o = t(e),
    r = document.createElement("style");
  r.setAttribute("style", "display: none"), r.innerHTML = n.replace(/\n/g, " "), o.append(r);
}
function n(t, e, n = "style-1") {
  const o = document.createElement("style");
  o.innerHTML = e.replace(/\n/g, " "), s(t, o, n);
}
function o(e, n) {
  t(e).classList.add(n);
}
function r(t, e) {
  const n = document.createElement("slot");
  n.setAttribute("name", e), s(t, n, e);
}
function s(e, n, o) {
  const r = t(e),
    s = f(r) ? r.shadowRoot : r,
    i = () => {
      const t = n.cloneNode(!0);
      t.id = o, s.append(t);
    };
  new MutationObserver(t => {
    const e = t.some(t => Array.from(t.addedNodes).some(t => t.tagName === n.tagName && t.getAttribute("id") === o)),
      r = null !== s.querySelector(`#${o}`);
    e || r || i();
  }).observe(s, {
    childList: !0
  }), i();
}
function i(e, n, o) {
  const r = t(e),
    s = r.getAttribute(n);
  o(s);
  new MutationObserver((t, e) => {
    o(r.getAttribute(n));
  }).observe(r, {
    childList: !1,
    subtree: !1,
    attributeFilter: [n]
  });
}
function u(e, n, o) {
  t(e).style.setProperty(n, o);
}
function c(e, n) {
  const o = t(e);
  return !!o && o.tagName.toLowerCase().startsWith(n.toLowerCase());
}
function a(e) {
  return t(e).addEventListener;
}
function d(t) {
  for (; t;) {
    if (l(t)) return !0;
    t = t.parentNode;
  }
  return !1;
}
function l(t) {
  var e;
  return "[object ShadowRoot]" === (null === (e = t.parentNode) || void 0 === e ? void 0 : e.toString());
}
function f(t) {
  return null !== t.shadowRoot;
}
function m(t, e) {
  const n = function (t) {
    if (!t || t === document || t === window) return null;
    t.assignedSlot && (t = t.assignedSlot);
    const o = t.closest(e);
    return o || n(t.getRootNode().host);
  };
  return n(t);
}
export { o as addClassToHostFromChild, r as addSlotToHostFromChild, a as getAddEventListenerOnHostFromChild, t as getHostElementFromChild, m as getParentElementBySelector, c as hasHostWithPrefixFromChild, f as hasShadow, e as injectStyleOnHostFromChild, n as injectStyleOnHostShadowFromChild, l as isInDirectShadow, d as isInShadow, u as setCustomPropertyOnHostFromChild, i as watchAttributeOnHostFromChild };
