import { _ as e } from "./tslib.es6-7f1b1220.js";
import { c as t, u as i, h as s } from "./lit-element-161a1699.js";
import { p as n, q as o, c as a } from "./decorators-7e9cd43b.js";
import { BaseElement as r } from "./BaseElement.esm.min.js";
import { h as c } from "./host.styles-cf05db22.js";
import "./interactive-icon.component.esm.min.js";
import { i as l } from "./if-defined-123567fc.js";
const d = {
    subtle: "subtle",
    default: "default",
    selected: "highlight"
  },
  h = t`${i("button {\n  background-color: transparent;\n  border: none;\n  height: calc(var(--zui-gu) * 8);\n  padding: 0;\n  position: relative;\n  width: calc(var(--zui-gu) * 8);\n}\n:host([emphasis=selected]) button {\n  background-color: var(--zui-color-headerbar-icon-button-background-color-selected);\n}\nbutton:focus-visible {\n  outline: none;\n}\n\n.interactive-icon {\n  align-items: center;\n  display: flex;\n  height: 100%;\n  justify-content: center;\n  width: 100%;\n}")}`;
let p = class extends r {
  constructor() {
    super(...arguments), this.disabled = !1, this.emphasis = "default", this.label = void 0, this.size = "m";
  }
  static get styles() {
    return [c, h];
  }
  _updateIconSize() {
    this._assignedIcons.forEach(e => e.setAttribute("size", "l" === this.size ? "l" : "m"));
  }
  _handleIconSlotchange() {
    this._updateIconSize();
  }
  updated(e) {
    e.has("size") && this._updateIconSize();
  }
  render() {
    return s`<button ?disabled="${this.disabled}" aria-label="${l(this.label)}" tabindex="${l(this.disabled ? void 0 : 0)}" type="button"><zui-interactive-icon ?disabled="${this.disabled}" class="interactive-icon" emphasis="${d[this.emphasis]}"><slot name="icon" @slotchange="${this._handleIconSlotchange}"></slot></zui-interactive-icon></button>`;
  }
};
e([n({
  reflect: !0,
  type: Boolean
})], p.prototype, "disabled", void 0), e([n({
  reflect: !0,
  type: String
})], p.prototype, "emphasis", void 0), e([n({
  type: String
})], p.prototype, "label", void 0), e([n({
  reflect: !0,
  type: String
})], p.prototype, "size", void 0), e([o("icon", !0, "[zuiIcon]")], p.prototype, "_assignedIcons", void 0), p = e([a("zui-headerbar-icon-button")], p);
export { p as HeaderbarIconButton };
