import { _ as e } from "./tslib.es6-7f1b1220.js";
import { c as t, u as i, h as s } from "./lit-element-161a1699.js";
import { p as a, q as o, c as n } from "./decorators-7e9cd43b.js";
import "./class-map-31af68b2.js";
import { RealBaseElement as r } from "./BaseElement.esm.min.js";
import { h as l } from "./host.styles-cf05db22.js";
import { e as d } from "./event.decorator-98549cbc.js";
import "./if-defined-123567fc.js";
import "./types-814d68fb.js";
import { emptyStringToNullWrapperConverter as p, stringUndefinedConverter as h } from "./component.utils.esm.min.js";
import "./mixin.utils.esm.min.js";
import { F as m } from "./form-data-handling.mixin-3ddec2ca.js";
import "./button.component.esm.min.js";
import "./dom.utils.esm.min.js";
import { F as u } from "./form-validation.mixin-0a6c3c34.js";
import { D as c } from "./delegate-focus.mixin-95600d1f.js";
import "./toggle-bar-button.component.esm.min.js";
const g = t`${i(':host {\n  background-color: var(--zui-color-interactive-element-bg-default-enabled);\n  border-radius: var(--zui-border-radius-default);\n  display: flex;\n  max-width: 100%;\n  position: relative;\n  user-select: none;\n}\n:host:before {\n  border-radius: var(--zui-border-radius-default);\n  bottom: 0;\n  box-shadow: inset 0 0 0 1px var(--zui-color-interactive-element-thin-line-default-enabled);\n  content: "";\n  left: 0;\n  position: absolute;\n  right: 0;\n  top: 0;\n}\n\n:host(:not([enable-deselection])) ::slotted(zui-toggle-bar-button[emphasis=highlight]),\n:host(:not([enable-deselection])) ::slotted(zui-toggle-bar-button[emphasis=primary-highlight]) {\n  pointer-events: none;\n}')}`,
  b = {
    selected: {
      buttonEmphasis: "highlight"
    },
    "selected-primary": {
      buttonEmphasis: "primary-highlight"
    },
    default: {
      buttonEmphasis: "default"
    },
    active: {
      buttonEmphasis: "highlight"
    },
    "active-primary": {
      buttonEmphasis: "primary-highlight"
    }
  };
let v = class extends u(m(c(r))) {
  constructor() {
    super(), this.emphasis = "selected", this.size = "s", this.enableDeselection = !1, this.required = !1, this._propagateDisabled = !1, this._propagateReadonly = !1, this.addValidator({
      type: "valueMissing",
      validator: () => !0 !== this.required || void 0 !== this.value && void 0 !== this._assignedToggleBarButtons.find(e => e.value === this.value),
      validatesOnProperties: ["required"]
    });
  }
  static get styles() {
    return [l, g];
  }
  emitChangeEvent() {
    this.dispatchEvent(new Event("change", {
      bubbles: !0,
      composed: !1,
      cancelable: !1
    }));
  }
  emitInputEvent() {
    this.dispatchEvent(new Event("input", {
      bubbles: !0,
      composed: !0,
      cancelable: !1
    }));
  }
  connectedCallback() {
    super.connectedCallback(), this.addEventListener("toggle-bar-button-selected", this._handleToggleBarButtonSelected);
  }
  disconnectedCallback() {
    this.removeEventListener("toggle-bar-button-selected", this._handleToggleBarButtonSelected), super.disconnectedCallback();
  }
  _propagateState() {
    this._assignedToggleBarButtons.forEach(e => {
      this._propagateDisabled && (e.disabled = this.disabled), this._propagateReadonly && (e.readonly = this.readonly), e.value === this.value ? e.emphasis = b[this.emphasis].buttonEmphasis : e.emphasis = "default", e.size = this.size;
    });
  }
  _handleToggleBarButtonSelected(e) {
    e.stopPropagation(), this.value = this.enableDeselection && this.value == e.detail.value ? void 0 : e.detail.value, this.emitChangeEvent(), this.emitInputEvent();
  }
  _handleSlotchange() {
    this._propagateState(), this.checkValidity();
  }
  update(e) {
    super.update(e), (!0 === this.disabled || !0 === e.get("disabled") && !1 === this.disabled) && (this._propagateDisabled = !0), (!0 === this.readonly || !0 === e.get("readonly") && !1 === this.readonly) && (this._propagateReadonly = !0), e.has("emphasis") && ("default" !== this.emphasis && "active" !== this.emphasis && "active-primary" !== this.emphasis || console.warn(`Deprecated emphasis: ${this.emphasis} was used on zui-button.`));
  }
  updated(e) {
    super.updated(e), this._propagateState();
  }
  render() {
    return s`<slot @slotchange="${this._handleSlotchange}"></slot>`;
  }
};
e([a({
  reflect: !0,
  type: String
})], v.prototype, "emphasis", void 0), e([a({
  reflect: !0,
  type: String
})], v.prototype, "size", void 0), e([a({
  reflect: !0,
  converter: p(h)
})], v.prototype, "value", void 0), e([a({
  reflect: !0,
  type: Boolean,
  attribute: "enable-deselection"
})], v.prototype, "enableDeselection", void 0), e([a({
  reflect: !0,
  type: Boolean
})], v.prototype, "required", void 0), e([d()], v.prototype, "emitChangeEvent", null), e([d()], v.prototype, "emitInputEvent", null), e([o("", !0, "zui-toggle-bar-button")], v.prototype, "_assignedToggleBarButtons", void 0), v = e([n("zui-toggle-bar")], v);
export { v as ToggleBar };
