import { _ as t } from "./tslib.es6-7f1b1220.js";
import { c as e, u as i, h as o, n as r } from "./lit-element-161a1699.js";
import { p as s, a as n, s as c, c as p } from "./decorators-7e9cd43b.js";
import { c as l } from "./class-map-31af68b2.js";
import { BaseElement as a } from "./BaseElement.esm.min.js";
import "./host.styles-cf05db22.js";
import { i as m } from "./if-defined-123567fc.js";
import "./types-814d68fb.js";
import { deprecatedListWrapperConverter as u, getStringArrayConverter as d } from "./component.utils.esm.min.js";
import "./mixin.utils.esm.min.js";
import { hasOverflow as h, getProjectedText as v } from "./dom.utils.esm.min.js";
import "./portal.utils.esm.min.js";
import "./tooltip.component.esm.min.js";
import "./div.component.esm.min.js";
import { u as f } from "./unsafe-html-97738913.js";
import "./tooltip.directive.esm.min.js";
const g = e`${i(":host {\n  display: block;\n  overflow: hidden;\n}\n\nzui-div {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\nslot[name=tooltip] {\n  display: none;\n}")}`;
let b = class extends a {
  constructor() {
    super(...arguments), this.preserveMarkup = !1, this.tooltipAnchoring = "cursor", this.tooltipTrigger = ["click", "focus"], this._isTruncated = !1, this._truncationObserver = new ResizeObserver(() => this._checkTruncation());
  }
  disconnectedCallback() {
    this._truncationObserver.disconnect(), super.disconnectedCallback();
  }
  _checkTruncation() {
    this._isTruncated = h(this._truncateRef);
  }
  firstUpdated() {
    this._checkTruncation(), this._truncationObserver.observe(this._truncateRef);
  }
  render() {
    return o`<zui-div class="${l({
      truncated: this._isTruncated
    })}"><slot></slot>${this._isTruncated ? o`<zui-tooltip-directive anchoring="${this.tooltipAnchoring}" level="1000" trigger="${this.tooltipTrigger.toString()}" trigger-host-selector="${m(this.interactiveElementSelector)}"><slot name="tooltip"><zui-tooltip>${f(v(this._truncateSlotRef, this.preserveMarkup))}</zui-tooltip></slot></zui-tooltip-directive>` : r}</zui-div>`;
  }
};
b.styles = [g], t([s({
  reflect: !0,
  attribute: "preserve-markup",
  type: Boolean
})], b.prototype, "preserveMarkup", void 0), t([s({
  reflect: !0,
  attribute: "tooltip-anchoring",
  type: String
})], b.prototype, "tooltipAnchoring", void 0), t([s({
  reflect: !0,
  attribute: "tooltip-trigger",
  converter: u(d())
})], b.prototype, "tooltipTrigger", void 0), t([s({
  reflect: !0,
  attribute: "interactive-element-selector",
  type: String
})], b.prototype, "interactiveElementSelector", void 0), t([n("slot:not([name])")], b.prototype, "_truncateSlotRef", void 0), t([n("zui-div")], b.prototype, "_truncateRef", void 0), t([c()], b.prototype, "_isTruncated", void 0), b = t([p("zui-truncate-with-tooltip")], b);
export { b as TruncateWithTooltip };
