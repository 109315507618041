import { _ as e } from "./tslib.es6-7f1b1220.js";
import { c as s, u as t, h as i } from "./lit-element-161a1699.js";
import { p as o, c as r } from "./decorators-7e9cd43b.js";
import { BaseElement as u } from "./BaseElement.esm.min.js";
import { h as l } from "./host.styles-cf05db22.js";
const m = s`${t(":host {\n  display: contents;\n}")}`;
let a = class extends u {
  constructor() {
    super(...arguments), this.value = !1;
  }
  get selected() {
    return !1 !== this.value;
  }
  focus() {
    this.shadowRoot.querySelector("zui-menu-item").focus();
  }
  render() {
    return i`<zui-menu-item emphasis="default" tabindex="-1"><zui-checkbox enable-mixed value="${this.value}"><slot></slot></zui-checkbox></zui-menu-item><zui-menu-divider skip></zui-menu-divider>`;
  }
};
a.styles = [l, m], e([o({
  reflect: !0
})], a.prototype, "value", void 0), a = e([r("zui-select-all")], a);
export { a as SelectAll };
