import { _ as s } from "./tslib.es6-7f1b1220.js";
import { c as i, u as e, h as r } from "./lit-element-161a1699.js";
import { c as t } from "./decorators-7e9cd43b.js";
import { BaseElement as o } from "./BaseElement.esm.min.js";
import { h as n } from "./host.styles-cf05db22.js";
const m = i`${e(":host {\n  --zui-horizontal-rule-margin: none;\n  --zui-color-thin-line-highlight: var(--zui-color-gs-120);\n}")}`;
let a = class extends o {
  static get styles() {
    return [n, m];
  }
  render() {
    return r`<zui-divider emphasis="highlight" size="s"></zui-divider>`;
  }
};
a = s([t("zui-menubar-divider")], a);
export { a as MenubarDivider };
