import { _ as r } from "./tslib.es6-7f1b1220.js";
import { c as e, u as t, h as a } from "./lit-element-161a1699.js";
import { p as o, c as n } from "./decorators-7e9cd43b.js";
import { c as s } from "./class-map-31af68b2.js";
import { BaseElement as i } from "./BaseElement.esm.min.js";
import { h as p } from "./host.styles-cf05db22.js";
const c = e`${t(":host {\n  height: calc(var(--zui-gu) * 8);\n  display: block;\n}\n\n#flex-wrapper {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  width: 100%;\n  max-width: 100%;\n}\n\n#ellipsis-wrapper {\n  display: inline-block;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  width: 100%;\n}\n#ellipsis-wrapper.has-product-sub-name {\n  color: var(--zui-color-headerbar-product-sub-name-color);\n}\n\n#product-name {\n  color: var(--zui-color-headerbar-product-name-color);\n  font: var(--zui-typography-subtitle1);\n}\n\n#product-sub-name {\n  margin-left: calc(var(--zui-gu) * 0.5);\n  color: var(--zui-color-headerbar-product-sub-name-color);\n  font: var(--zui-typography-body);\n}")}`;
let d = class extends i {
  constructor() {
    super(...arguments), this.productName = "", this.productSubName = "";
  }
  static get styles() {
    return [p, c];
  }
  render() {
    var r;
    return a`<div id="flex-wrapper"><div id="ellipsis-wrapper" class="${s({
      "has-product-sub-name": (null === (r = this.productSubName) || void 0 === r ? void 0 : r.length) > 0
    })}"><span id="product-name">${this.productName}</span><span id="product-sub-name">${this.productSubName}</span></div></div>`;
  }
};
r([o({
  reflect: !0,
  type: String,
  attribute: "product-name"
})], d.prototype, "productName", void 0), r([o({
  reflect: !0,
  type: String,
  attribute: "product-sub-name"
})], d.prototype, "productSubName", void 0), d = r([n("zui-headerbar-product-name")], d);
export { d as HeaderbarProductName };
