import { _ as t } from "./tslib.es6-7f1b1220.js";
import { c as a, u as n, h as i } from "./lit-element-161a1699.js";
import { p as r, c as e } from "./decorators-7e9cd43b.js";
import { BaseElement as o } from "./BaseElement.esm.min.js";
import { h as s } from "./host.styles-cf05db22.js";
const p = a`${n(':host {\n  position: relative;\n  width: fit-content;\n  width: -moz-fit-content;\n  --zui-avatar-color-background: var(--zui-color-sc-b-110);\n  ---zui-avatar-font-padding-top: 0;\n  ---zui-avatar-font-padding-bottom: 0;\n}\n\n:host(:focus) {\n  outline: none;\n}\n\n:host([size="24"]) {\n  --zui-avatar-diameter: calc(var(--zui-gu) * 3);\n}\n\n:host([size="32"]) {\n  --zui-avatar-diameter: calc(var(--zui-gu) * 4);\n}\n\n:host([size="40"]) {\n  --zui-avatar-diameter: calc(var(--zui-gu) * 5);\n}\n\n:host([size="64"]) {\n  --zui-avatar-diameter: calc(var(--zui-gu) * 8);\n}\n\n:host([size="128"]) {\n  --zui-avatar-diameter: calc(var(--zui-gu) * 16);\n}\n\n:host([size="24"]) span {\n  font: var(--zui-typography-label2);\n  ---zui-avatar-font-padding-bottom: 0.5px;\n}\n\n:host([size="32"]) span {\n  font: var(--zui-typography-body);\n}\n\n:host([size="40"]) span {\n  font: var(--zui-typography-h4);\n  ---zui-avatar-font-padding-top: 1px;\n}\n\n:host([size="64"]) span {\n  font: var(--zui-typography-h2);\n}\n\n:host([size="128"]) span {\n  font: var(--zui-typography-h0);\n  ---zui-avatar-font-padding-bottom: 2px;\n}\n\ndiv,\nimg {\n  width: var(--zui-avatar-diameter);\n  height: var(--zui-avatar-diameter);\n  border-radius: 50%;\n}\n\ndiv {\n  background-color: var(--zui-avatar-color-background);\n}\n\nimg {\n  object-fit: cover;\n}\n\nspan {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  padding: var(---zui-avatar-font-padding-top) 0 var(---zui-avatar-font-padding-bottom) 0;\n  transform: translate(-50%, -52%);\n  color: var(--zui-color-text-avatar);\n  text-transform: uppercase;\n}')}`;
let l = class extends o {
  constructor() {
    super(...arguments), this.size = "40", this.initials = "", this._imageUrl = "";
  }
  static get styles() {
    return [s, p];
  }
  get imageUrl() {
    return this._imageUrl;
  }
  set imageUrl(t) {
    const a = this._imageUrl;
    this._imageUrl = t, this.requestUpdate("imageUrl", a), this.requestUpdate("_imageUrlLegacy", a);
  }
  get _imageUrlLegacy() {
    return this._imageUrl;
  }
  set _imageUrlLegacy(t) {
    console.warn("Deprecated attribute imageUrl on component\n%o\n\nUse image-url instead!", this);
    const a = this._imageUrl;
    this._imageUrl = t, this.requestUpdate("imageUrl", a);
  }
  render() {
    return i`<div>${this.imageUrl ? i`<img src="${this.imageUrl}">` : i`<span>${this.initials}</span>`}</div>`;
  }
};
t([r({
  reflect: !0
})], l.prototype, "size", void 0), t([r({
  reflect: !0,
  type: String
})], l.prototype, "initials", void 0), t([r({
  attribute: "image-url",
  reflect: !0,
  type: String
})], l.prototype, "imageUrl", null), t([r({
  attribute: "imageurl",
  reflect: !0,
  type: String
})], l.prototype, "_imageUrlLegacy", null), l = t([e("zui-avatar")], l);
export { l as Avatar };
