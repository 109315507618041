import { a as t, _ as e } from "./tslib.es6-7f1b1220.js";
import { c as o, u as s, L as r, h as i } from "./lit-element-161a1699.js";
import { p as l, q as n, c as p } from "./decorators-7e9cd43b.js";
import { h as a } from "./host.styles-cf05db22.js";
import "./types-814d68fb.js";
import "./component.utils.esm.min.js";
import { preparePortal as c, unregisterPortal as d } from "./portal.utils.esm.min.js";
const h = o`${s(":host {\n  display: none;\n}")}`;
let m = class extends r {
  constructor() {
    super(...arguments), this.restore = !1, this.clone = !1;
  }
  projectContents() {
    return t(this, void 0, void 0, function* () {
      const [t] = this._projectedElements;
      void 0 !== t && this.isConnected && (this._portal = yield c(this.portal, this.level), this._portal.clone = this.clone, this._portal.restore = this.restore, this._portal.showContent(t));
    });
  }
  disconnectedCallback() {
    var t;
    d(null === (t = this._portal) || void 0 === t ? void 0 : t.name), super.disconnectedCallback();
  }
  update(t) {
    super.update(t), t.has("restore") && console.warn("Deprecated restore: Please use the `clone` option instead.");
  }
  render() {
    return i`<slot @slotchange="${this.projectContents}"></slot>`;
  }
};
m.styles = [a, h], e([l({
  reflect: !0
})], m.prototype, "portal", void 0), e([l({
  reflect: !0,
  type: Number
})], m.prototype, "level", void 0), e([l({
  reflect: !0,
  type: Boolean
})], m.prototype, "restore", void 0), e([l({
  reflect: !0,
  type: Boolean
})], m.prototype, "clone", void 0), e([n("", !0, "*")], m.prototype, "_projectedElements", void 0), m = e([p("zui-portal-directive")], m);
export { h as PORTAL_DIRECTIVE_STYLES, m as PortalDirective };
