import { _ as r } from "./tslib.es6-7f1b1220.js";
import { c as a, u as e, h as o } from "./lit-element-161a1699.js";
import { c as s } from "./decorators-7e9cd43b.js";
import { BaseElement as t } from "./BaseElement.esm.min.js";
import { h as i } from "./host.styles-cf05db22.js";
const l = a`${e(":host {\n  width: calc(var(--zui-gu) * 40);\n  min-width: calc(var(--zui-gu) * 26);\n  max-width: calc(var(--zui-gu) * 55);\n  padding: calc(var(--zui-gu) * 3) calc(var(--zui-space-large));\n  background-color: var(--zui-color-first-hierarchy-bg-default-enabled);\n  border-radius: var(--zui-border-radius-default);\n  border-color: var(--zui-color-thin-line-highlight);\n  border-width: var(--zui-line-strength-thin);\n  border-style: solid;\n  box-shadow: var(--zui-box-shadow-large);\n}")}`;
let n = class extends t {
  static get styles() {
    return [i, l];
  }
  render() {
    return o`<slot></slot>`;
  }
};
n = r([s("zui-popover")], n);
export { n as Popover };
