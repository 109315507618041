import { _ as t } from "./tslib.es6-7f1b1220.js";
import { c as n, u as i, h as e } from "./lit-element-161a1699.js";
import { p as a, s as o, c as s } from "./decorators-7e9cd43b.js";
import { BaseElement as r } from "./BaseElement.esm.min.js";
import { h as u } from "./host.styles-cf05db22.js";
const d = n`${i(":host {\n  --zui-pagination-dot-transition-timing: 200ms;\n  display: inline-flex;\n  height: calc(var(--zui-gu) * 1);\n  width: calc(var(--zui-gu) * 1);\n}\n\nbutton {\n  align-items: center;\n  background-color: transparent;\n  border: none;\n  display: flex;\n  height: 100%;\n  justify-content: center;\n  padding: 0;\n  width: 100%;\n}\n:host(:first-child) button {\n  justify-content: flex-end;\n}\n:host(:last-child) button {\n  justify-content: flex-start;\n}\nbutton:hover {\n  cursor: pointer;\n}\n\n.pagination-dot {\n  background-color: var(--zui-color-pagination-dot-background-default);\n  border-radius: 50%;\n  height: calc(var(--zui-gu) * 1);\n  transition: background-color var(--zui-pagination-dot-transition-timing) ease-in-out, width var(--zui-pagination-dot-transition-timing) ease-in-out, height var(--zui-pagination-dot-transition-timing) ease-in-out;\n  width: calc(var(--zui-gu) * 1);\n}\n:host([emphasis=default]) .pagination-dot, :host([emphasis=selected][zui-internal-animate-selected]) .pagination-dot {\n  background-color: var(--zui-color-pagination-dot-background-default);\n}\n:host([emphasis=selected]) .pagination-dot {\n  background-color: var(--zui-color-pagination-dot-background-selected);\n}\n:host([size=s]) .pagination-dot {\n  height: calc(var(--zui-gu) * 0.5);\n  width: calc(var(--zui-gu) * 0.5);\n}\n:host([size=m]) .pagination-dot {\n  height: calc(var(--zui-gu) * 1);\n  width: calc(var(--zui-gu) * 1);\n}")}`;
let c = class extends r {
  constructor() {
    super(...arguments), this.emphasis = "default", this.size = "m", this._timeout = 0, this._animateSelected = !1;
  }
  get animateSelected() {
    return this._animateSelected;
  }
  set animateSelected(t) {
    this._animateSelected = t, this._animateSelected && setTimeout(() => {
      this.removeAttribute("zui-internal-animate-selected");
    }, this._timeout);
  }
  firstUpdated() {
    this._timeout = parseInt(getComputedStyle(this).getPropertyValue("--zui-pagination-dot-transition-timing"), 10);
  }
  render() {
    return e`<button><span class="pagination-dot"></span></button>`;
  }
};
c.styles = [u, d], c.internalAnimateSelectedAttribute = "zui-internal-animate-selected", t([a({
  reflect: !0,
  type: Boolean,
  attribute: "zui-internal-animate-selected"
})], c.prototype, "animateSelected", null), t([a({
  reflect: !0,
  type: String
})], c.prototype, "emphasis", void 0), t([a({
  reflect: !0,
  type: String
})], c.prototype, "size", void 0), t([o()], c.prototype, "_timeout", void 0), c = t([s("zui-pagination-dot")], c);
export { c as PaginationDot };
