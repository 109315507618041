import { _ as e } from "./tslib.es6-7f1b1220.js";
import { c as t, u as s, h as i } from "./lit-element-161a1699.js";
import { p as a, q as o, c as r } from "./decorators-7e9cd43b.js";
import { BaseElement as n } from "./BaseElement.esm.min.js";
import { h as d } from "./host.styles-cf05db22.js";
import "./types-814d68fb.js";
import { stringUndefinedConverter as l } from "./component.utils.esm.min.js";
import { traverseDOMSiblingsByStepAndDirection as p, isElementBeforeOther as h, isElementAfterOther as m } from "./dom.utils.esm.min.js";
import "./step-indicator-item.component.esm.min.js";
const c = t`${s(":host {\n  display: flex;\n}")}`;
let u = class extends n {
  constructor() {
    super(...arguments), this.size = "m";
  }
  static get styles() {
    return [d, c];
  }
  get _selectedStepIndicatorItem() {
    return void 0 !== this.value ? this._assignedStepIndicatorItems.find(e => e.value === this.value) : void 0;
  }
  get _hasSelectedStepIndicatorItem() {
    return void 0 !== this._selectedStepIndicatorItem;
  }
  next() {
    var e, t, s;
    if (this._hasSelectedStepIndicatorItem) this.value = null !== (t = null === (e = this._getSibling("next")) || void 0 === e ? void 0 : e.value) && void 0 !== t ? t : this.value;else {
      const [e] = this._assignedStepIndicatorItems;
      this.value = null !== (s = null == e ? void 0 : e.value) && void 0 !== s ? s : this.value;
    }
  }
  previous() {
    var e, t;
    this._hasSelectedStepIndicatorItem && (this.value = null !== (t = null === (e = this._getSibling("previous")) || void 0 === e ? void 0 : e.value) && void 0 !== t ? t : this.value);
  }
  _getSibling(e) {
    return p(this._selectedStepIndicatorItem, e);
  }
  _propagateSizeChange() {
    this._assignedStepIndicatorItems.forEach(e => e.size = this.size);
  }
  _propagateValueChange() {
    this._hasSelectedStepIndicatorItem ? this._assignedStepIndicatorItems.forEach(e => {
      h(e, this._selectedStepIndicatorItem) ? e.emphasis = "visited" : m(e, this._selectedStepIndicatorItem) ? e.emphasis = "next" : e === this._selectedStepIndicatorItem && (e.emphasis = "selected");
    }) : this._assignedStepIndicatorItems.forEach(e => e.emphasis = "next");
  }
  _handleSlotChange() {
    this._propagateSizeChange(), this._propagateValueChange();
  }
  updated(e) {
    super.updated(e), e.has("size") && this._propagateSizeChange(), e.has("value") && this._propagateValueChange();
  }
  render() {
    return i`<slot @slotchange="${this._handleSlotChange}"></slot>`;
  }
};
e([a({
  reflect: !0,
  type: String
})], u.prototype, "size", void 0), e([a({
  reflect: !0,
  converter: l
})], u.prototype, "value", void 0), e([o("", !0, "zui-step-indicator-item")], u.prototype, "_assignedStepIndicatorItems", void 0), u = e([r("zui-step-indicator-bar")], u);
export { u as StepIndicatorBar };
