import { _ as t } from "./tslib.es6-7f1b1220.js";
import { c as e, u as i, h as s } from "./lit-element-161a1699.js";
import { p as n, c as o } from "./decorators-7e9cd43b.js";
import { BaseElement as r } from "./BaseElement.esm.min.js";
import { h as a } from "./host.styles-cf05db22.js";
const p = e`${i(":host {\n  align-items: center;\n  display: flex;\n  flex: 1 1 0;\n  height: calc(var(--zui-gu) * 6);\n  justify-content: center;\n  min-width: 0;\n  padding: 0 calc(var(--zui-gu) * 0.5);\n}\n\n:host(:not(:first-child)) {\n  margin-left: calc(var(--zui-gu) * 0.25);\n}\n\n:host([emphasis=next]) {\n  box-shadow: inset 0 4px var(--zui-step-indicator-item-border-color-next);\n  color: var(--zui-step-indicator-item-text-color-next);\n}\n:host([emphasis=next][size=s]) {\n  font: var(--zui-typography-caption);\n}\n:host([emphasis=next][size=m]) {\n  font: var(--zui-typography-body);\n}\n\n:host([emphasis=selected]) {\n  box-shadow: inset 0 4px var(--zui-step-indicator-item-border-color-selected);\n  color: var(--zui-step-indicator-item-text-color-selected);\n}\n:host([emphasis=selected][size=s]) {\n  font: var(--zui-typography-label1);\n}\n:host([emphasis=selected][size=m]) {\n  font: var(--zui-typography-subtitle1);\n}\n\n:host([emphasis=visited]) {\n  box-shadow: inset 0 4px var(--zui-step-indicator-item-border-color-visited);\n  color: var(--zui-step-indicator-item-text-color-visited);\n}\n:host([emphasis=visited][size=s]) {\n  font: var(--zui-typography-caption);\n}\n:host([emphasis=visited][size=m]) {\n  font: var(--zui-typography-body);\n}\n\n.step-indicator-item-text {\n  margin-top: 4px;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}")}`;
let c = class extends r {
  constructor() {
    super(...arguments), this.emphasis = "next", this.size = "m";
  }
  static get styles() {
    return [a, p];
  }
  render() {
    return s`<div class="step-indicator-item-text">${this.text}</div>`;
  }
};
t([n({
  reflect: !0,
  type: String
})], c.prototype, "emphasis", void 0), t([n({
  reflect: !0,
  type: String
})], c.prototype, "size", void 0), t([n({
  reflect: !0,
  type: String
})], c.prototype, "text", void 0), t([n({
  reflect: !0,
  type: String
})], c.prototype, "value", void 0), c = t([o("zui-step-indicator-item")], c);
export { c as StepIndicatorItem };
