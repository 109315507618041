import { _ as e } from "./tslib.es6-7f1b1220.js";
import { c as t, u as o, h as n } from "./lit-element-161a1699.js";
import { p as a, q as r, c as i } from "./decorators-7e9cd43b.js";
import { BaseElement as s } from "./BaseElement.esm.min.js";
import { h as c } from "./host.styles-cf05db22.js";
const u = t`${o(":host([disabled]) {\n  pointer-events: none;\n}\n\n.menu-tab-icon-item {\n  align-items: center;\n  background-color: var(--zui-color-menu-tab-item-default-background);\n  border-bottom-color: transparent;\n  border-bottom-style: solid;\n  border-bottom-width: calc(var(--zui-gu) * 0.5);\n  border-left: 0;\n  border-right: 0;\n  border-top: 0;\n  box-sizing: border-box;\n  display: flex;\n  height: calc(var(--zui-gu) * 4);\n  justify-content: center;\n  outline: none;\n  padding: 0;\n  width: calc(var(--zui-gu) * 4);\n}\n:host([emphasis=default]) .menu-tab-icon-item {\n  color: var(--zui-color-menu-tab-item-default);\n  cursor: pointer;\n}\n:host([emphasis=default]) .menu-tab-icon-item:hover {\n  background-color: var(--zui-color-menu-tab-item-default-background-hover);\n}\n:host([emphasis=default]) .menu-tab-icon-item:focus {\n  background-color: var(--zui-color-menu-tab-item-default-background-focus);\n}\n:host([emphasis=default]) .menu-tab-icon-item:active {\n  background-color: var(--zui-color-menu-tab-item-default-background-active);\n}\n:host([emphasis=selected]) .menu-tab-icon-item {\n  border-bottom-color: var(--zui-color-menu-tab-item-selected-border-bottom);\n  color: var(--zui-color-menu-tab-item-selected);\n  cursor: default;\n}\n:host([disabled]) .menu-tab-icon-item {\n  opacity: var(--zui-disabled-opacity);\n}\n\n::slotted(*) {\n  margin-top: calc(var(--zui-gu) * 0.5);\n  pointer-events: none;\n}")}`;
let l = class extends s {
  constructor() {
    super(...arguments), this.disabled = !1, this.emphasis = "default";
  }
  static get styles() {
    return [c, u];
  }
  _propagateMenuTabIconSize() {
    this._assignedMenuTabIconItemIcons.forEach(e => e.setAttribute("size", "m"));
  }
  _handleMenuTabIconItemSlotchange() {
    this._propagateMenuTabIconSize();
  }
  render() {
    return n`<button ?disabled="${this.disabled}" class="menu-tab-icon-item"><slot @slotchange="${this._handleMenuTabIconItemSlotchange}"></slot></button>`;
  }
};
e([a({
  reflect: !0,
  type: Boolean
})], l.prototype, "disabled", void 0), e([a({
  reflect: !0,
  type: String
})], l.prototype, "emphasis", void 0), e([a({
  reflect: !0,
  type: String
})], l.prototype, "value", void 0), e([r("", !0, "[zuiIcon]")], l.prototype, "_assignedMenuTabIconItemIcons", void 0), l = e([i("zui-menu-tab-icon-item")], l);
export { l as MenuTabIconItem };
