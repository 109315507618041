import "./tslib.es6-7f1b1220.js";
import "./types-814d68fb.js";
import { getCssPropertyFromElement as t } from "./component.utils.esm.min.js";
const e = (t, n, r) => {
    const s = getComputedStyle(n).letterSpacing,
      o = parseFloat("normal" === s ? "0px" : s) / 100;
    "running" === r.playState && (t(o), requestAnimationFrame(() => e(t, n, r)));
  },
  n = (e, n) => {
    const r = /^var\(([\w-]+)(?:,.*)?\)$/i;
    return r.test(e) ? t(n, e.replace(r, "$1")) : e;
  },
  r = (t, e) => {
    if ("number" == typeof t) return t;
    const r = n(t.trim(), e);
    return parseFloat(r) * (r.endsWith("ms") ? 1 : 1e3);
  },
  s = (t, s, o, {
    host: i = document.body,
    autostart: a = !1
  } = {}) => {
    const p = (t => {
        const e = document.createElement("span");
        return e.style.setProperty("position", "absolute"), e.style.setProperty("visibility", "hidden"), e.style.setProperty("z-index", "-9999"), t.appendChild(e), e;
      })(i),
      l = p.animate({
        letterSpacing: ["0px", "100px"]
      }, {
        duration: r(s, i),
        easing: n(t, i),
        fill: "forwards"
      });
    l.addEventListener("cancel", () => p.remove(), !1), l.addEventListener("finish", () => {
      o(1), p.remove();
    }, !1);
    const m = l.play;
    return l.play = () => {
      e(o, p, l), m.call(l);
    }, a && l.play(), l;
  };
export { s as animateWithCSS };
