import { _ as t } from "./tslib.es6-7f1b1220.js";
import { c as e, u as o, h as s } from "./lit-element-161a1699.js";
import { p as i, c as n } from "./decorators-7e9cd43b.js";
import "./class-map-31af68b2.js";
import { BaseElement as r } from "./BaseElement.esm.min.js";
import { h as a } from "./host.styles-cf05db22.js";
import { e as l } from "./event.decorator-98549cbc.js";
import "./if-defined-123567fc.js";
import "./types-814d68fb.js";
import "./component.utils.esm.min.js";
import "./mixin.utils.esm.min.js";
import "./form-data-handling.mixin-3ddec2ca.js";
import "./button.component.esm.min.js";
const p = e`${o(":host {\n  display: flex;\n  flex: 1 1 0;\n  min-width: 0;\n}\n\nzui-button {\n  flex: 1 1 0;\n  min-width: 0;\n}\nzui-button::part(button) {\n  max-width: 100%;\n  min-width: 0;\n  width: 100%;\n}\n:host([emphasis=default]) zui-button::part(button):not(:hover) {\n  background-color: transparent;\n  border-color: transparent;\n}")}`;
let m = class extends r {
  constructor() {
    super(...arguments), this.disabled = !1, this.emphasis = "default", this.size = "s", this.readonly = !1;
  }
  static get styles() {
    return [a, p];
  }
  emitToggleBarButtonSelectedEvent() {
    this.dispatchEvent(new CustomEvent("toggle-bar-button-selected", {
      bubbles: !0,
      cancelable: !1,
      composed: !1,
      detail: {
        value: this.value
      }
    }));
  }
  _handleClick(t) {
    t.stopPropagation(), this.emitToggleBarButtonSelectedEvent();
  }
  render() {
    return s`<zui-button ?disabled="${this.disabled}" ?readonly="${this.readonly}" emphasis="${this.emphasis}" size="${this.size}" type="button" @click="${this._handleClick}"><slot></slot><slot name="icon" slot="icon"></slot></zui-button>`;
  }
};
t([i({
  reflect: !0,
  type: Boolean
})], m.prototype, "disabled", void 0), t([i({
  reflect: !0,
  type: String
})], m.prototype, "emphasis", void 0), t([i({
  reflect: !0,
  type: String
})], m.prototype, "size", void 0), t([i({
  reflect: !0,
  type: String
})], m.prototype, "value", void 0), t([i({
  reflect: !0,
  type: Boolean
})], m.prototype, "readonly", void 0), t([l()], m.prototype, "emitToggleBarButtonSelectedEvent", null), m = t([n("zui-toggle-bar-button")], m);
export { m as ToggleBarButton };
