import { _ as i } from "./tslib.es6-7f1b1220.js";
import { c as e, u as n, h as t } from "./lit-element-161a1699.js";
import { p as a, c as r } from "./decorators-7e9cd43b.js";
import { BaseElement as l } from "./BaseElement.esm.min.js";
import { h as o } from "./host.styles-cf05db22.js";
import "./types-814d68fb.js";
import { isSlotNotEmpty as d } from "./component.utils.esm.min.js";
const s = e`${n(':host {\n  --zui-menu-divider-color: var(--zui-color-text-divider);\n  --zui-menu-divider-height: calc(var(--zui-gu) * 0.125);\n  --zui-menu-divider-line-color: var(--zui-color-thin-line-highlight);\n  --zui-menu-divider-line-height: calc(var(--zui-gu) * 0.125);\n  --zui-menu-divider-line-padding: 0;\n  ---zui-menu-divider-line-position: 0;\n  ---zui-menu-divider-line-spacing: -1px;\n  display: block;\n  height: var(--zui-menu-divider-height);\n  width: 100%;\n  font-size: 0;\n  text-align: center;\n  overflow: hidden;\n}\n\n.line {\n  display: inline-block;\n  position: relative;\n  padding: 0 var(--zui-menu-divider-line-padding);\n  min-height: var(--zui-menu-divider-line-height);\n  max-width: calc(100% - var(--zui-menu-divider-line-padding) * 2);\n}\n.line::before, .line::after {\n  position: absolute;\n  bottom: var(---zui-menu-divider-line-position);\n  left: auto;\n  right: auto;\n  top: auto;\n  content: "";\n  background-color: var(--zui-menu-divider-line-color);\n  height: var(--zui-menu-divider-line-height);\n  width: 2000vw;\n}\n.line::before {\n  right: calc(100% + var(---zui-menu-divider-line-spacing));\n}\n.line::after {\n  left: calc(100% + var(---zui-menu-divider-line-spacing));\n}\n\n.label {\n  display: block;\n  height: var(--zui-menu-divider-height);\n  width: 100%;\n  color: var(--zui-menu-divider-color);\n  font: var(--zui-typography-label2);\n  line-height: var(--zui-menu-divider-height);\n  text-transform: uppercase;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n\n:host([has-label]) {\n  --zui-menu-divider-height: calc(var(--zui-gu) * 4);\n  --zui-menu-divider-line-padding: calc(var(--zui-gu) * 1.5);\n  ---zui-menu-divider-line-position: 50%;\n}\n:host([has-label]) .label {\n  margin-bottom: calc(var(--zui-gu) * 0.25);\n}')}`;
let u = class extends l {
  _checkHasLabel({
    target: i
  }) {
    this.hasLabel = d(i);
  }
  render() {
    return t`<span class="line"><span class="label"><slot @slotchange="${this._checkHasLabel}"></slot></span></span>`;
  }
};
u.styles = [o, s], i([a({
  attribute: "has-label",
  reflect: !0,
  type: Boolean
})], u.prototype, "hasLabel", void 0), u = i([r("zui-menu-divider")], u);
export { u as MenuDivider };
