function e(e, ...t) {
  return r(e, t);
}
const t = {
  not_string: /[^s]/,
  not_bool: /[^t]/,
  not_type: /[^T]/,
  not_primitive: /[^v]/,
  number: /[diefg]/,
  numeric_arg: /[bcdiefguxX]/,
  json: /[j]/,
  not_json: /[^j]/,
  text: /^[^\x25]+/,
  modulo: /^\x25{2}/,
  placeholder: /^\x25(?:([1-9]\d*)\$|\(([^)]+)\))?(\+)?(0|'[^$])?(-)?(\d+)?(?:\.(\d+))?([b-gijostTuvxX])/,
  key: /^([a-z_][a-z_\d]*)/i,
  key_access: /^\.([a-z_][a-z_\d]*)/i,
  index_access: /^\[(\d+)\]/,
  sign: /^[+-]/
};
function r(e) {
  return n(i(e), arguments);
}
function n(e, n) {
  let s,
    i,
    a,
    o,
    p,
    c,
    l,
    u,
    f,
    d = 1,
    g = e.length,
    h = "";
  for (i = 0; i < g; i++) if ("string" == typeof e[i]) h += e[i];else if ("object" == typeof e[i]) {
    if (o = e[i], o.keys) for (s = n[d], a = 0; a < o.keys.length; a++) {
      if (null == s) throw new Error(r('[sprintf] Cannot access property "%s" of undefined value "%s"', o.keys[a], o.keys[a - 1]));
      s = s[o.keys[a]];
    } else s = o.param_no ? n[o.param_no] : n[d++];
    if (t.not_type.test(o.type) && t.not_primitive.test(o.type) && s instanceof Function && (s = s()), t.numeric_arg.test(o.type) && "number" != typeof s && isNaN(s)) throw new TypeError(r("[sprintf] expecting number but found %T", s));
    switch (t.number.test(o.type) && (u = s >= 0), o.type) {
      case "b":
        s = parseInt(s, 10).toString(2);
        break;
      case "c":
        s = String.fromCharCode(parseInt(s, 10));
        break;
      case "d":
      case "i":
        s = parseInt(s, 10);
        break;
      case "j":
        s = JSON.stringify(s, null, o.width ? parseInt(o.width) : 0);
        break;
      case "e":
        s = o.precision ? parseFloat(s).toExponential(o.precision) : parseFloat(s).toExponential();
        break;
      case "f":
        s = o.precision ? parseFloat(s).toFixed(o.precision) : parseFloat(s);
        break;
      case "g":
        s = o.precision ? String(Number(s.toPrecision(o.precision))) : parseFloat(s);
        break;
      case "o":
        s = (parseInt(s, 10) >>> 0).toString(8);
        break;
      case "s":
        s = String(s), s = o.precision ? s.substring(0, o.precision) : s;
        break;
      case "t":
        s = String(!!s), s = o.precision ? s.substring(0, o.precision) : s;
        break;
      case "T":
        s = Object.prototype.toString.call(s).slice(8, -1).toLowerCase(), s = o.precision ? s.substring(0, o.precision) : s;
        break;
      case "u":
        s = parseInt(s, 10) >>> 0;
        break;
      case "v":
        s = s.valueOf(), s = o.precision ? s.substring(0, o.precision) : s;
        break;
      case "x":
        s = (parseInt(s, 10) >>> 0).toString(16);
        break;
      case "X":
        s = (parseInt(s, 10) >>> 0).toString(16).toUpperCase();
    }
    t.json.test(o.type) ? h += s : (!t.number.test(o.type) || u && !o.sign ? f = "" : (f = u ? "+" : "-", s = s.toString().replace(t.sign, "")), c = o.pad_char ? "0" === o.pad_char ? "0" : o.pad_char.charAt(1) : " ", l = o.width - (f + s).length, p = o.width && l > 0 ? c.repeat(l) : "", h += o.align ? f + s + p : "0" === c ? f + p + s : p + f + s);
  }
  return h;
}
const s = Object.create(null);
function i(e) {
  if (s[e]) return s[e];
  let r,
    n = e,
    i = [],
    a = 0;
  for (; n;) {
    if (null !== (r = t.text.exec(n))) i.push(r[0]);else if (null !== (r = t.modulo.exec(n))) i.push("%");else {
      if (null === (r = t.placeholder.exec(n))) throw new SyntaxError("[sprintf] unexpected placeholder");
      if (r[2]) {
        a |= 1;
        let e = [],
          n = r[2],
          s = [];
        if (null === (s = t.key.exec(n))) throw new SyntaxError("[sprintf] failed to parse named argument key");
        for (e.push(s[1]); "" !== (n = n.substring(s[0].length));) if (null !== (s = t.key_access.exec(n))) e.push(s[1]);else {
          if (null === (s = t.index_access.exec(n))) throw new SyntaxError("[sprintf] failed to parse named argument key");
          e.push(s[1]);
        }
        r[2] = e;
      } else a |= 2;
      if (3 === a) throw new Error("[sprintf] mixing positional and named placeholders is not (yet) supported");
      i.push({
        placeholder: r[0],
        param_no: r[1],
        keys: r[2],
        sign: r[3],
        pad_char: r[4],
        align: r[5],
        width: r[6],
        precision: r[7],
        type: r[8]
      });
    }
    n = n.substring(r[0].length);
  }
  return s[e] = i;
}
function a(e) {
  const t = e.match(/\x25\.(\d+)f/) || [null, 0];
  return Number(t[1]);
}
function o(e) {
  const t = e.toString().split(""),
    r = t.lastIndexOf(".");
  return r < 0 ? 0 : t.length - r - 1;
}
export { o as countDecimals, a as countSprintfDecimals, e as format };
