const o = new function () {
  this.utf16 = {
    decode: function (o) {
      let r,
        e,
        t = [],
        n = 0,
        f = o.length;
      for (; n < f;) {
        if (r = o.charCodeAt(n++), 55296 == (63488 & r)) {
          if (e = o.charCodeAt(n++), 55296 != (64512 & r) || 56320 != (64512 & e)) throw new RangeError("UTF-16(decode): Illegal UTF-16 sequence");
          r = ((1023 & r) << 10) + (1023 & e) + 65536;
        }
        t.push(r);
      }
      return t;
    },
    encode: function (o) {
      let r,
        e = [],
        t = 0,
        n = o.length;
      for (; t < n;) {
        if (r = o[t++], 55296 == (63488 & r)) throw new RangeError("UTF-16(encode): Illegal UTF-16 value");
        r > 65535 && (r -= 65536, e.push(String.fromCharCode(r >>> 10 & 1023 | 55296)), r = 56320 | 1023 & r), e.push(String.fromCharCode(r));
      }
      return e.join("");
    }
  };
  let r = 36,
    e = 2147483647;
  function t(o, r) {
    return o + 22 + 75 * (o < 26) - ((0 != r) << 5);
  }
  function n(o, e, t) {
    let n;
    for (o = t ? Math.floor(o / 700) : o >> 1, o += Math.floor(o / e), n = 0; o > 455; n += r) o = Math.floor(o / 35);
    return Math.floor(n + 36 * o / (o + 38));
  }
  this.decode = function (o, t) {
    let f,
      h,
      l,
      i,
      a,
      u,
      c,
      d,
      g,
      s,
      p,
      C,
      w,
      A = [],
      E = [],
      M = o.length;
    for (f = 128, l = 0, i = 72, a = o.lastIndexOf("-"), a < 0 && (a = 0), u = 0; u < a; ++u) {
      if (t && (E[A.length] = o.charCodeAt(u) - 65 < 26), o.charCodeAt(u) >= 128) throw new RangeError("Illegal input >= 0x80");
      A.push(o.charCodeAt(u));
    }
    for (c = a > 0 ? a + 1 : 0; c < M;) {
      for (d = l, g = 1, s = r;; s += r) {
        if (c >= M) throw RangeError("punycode_bad_input(1)");
        if (p = (R = o.charCodeAt(c++)) - 48 < 10 ? R - 22 : R - 65 < 26 ? R - 65 : R - 97 < 26 ? R - 97 : r, p >= r) throw RangeError("punycode_bad_input(2)");
        if (p > Math.floor((e - l) / g)) throw RangeError("punycode_overflow(1)");
        if (l += p * g, C = s <= i ? 1 : s >= i + 26 ? 26 : s - i, p < C) break;
        if (g > Math.floor(e / (r - C))) throw RangeError("punycode_overflow(2)");
        g *= r - C;
      }
      if (h = A.length + 1, i = n(l - d, h, 0 === d), Math.floor(l / h) > e - f) throw RangeError("punycode_overflow(3)");
      f += Math.floor(l / h), l %= h, t && E.splice(l, 0, o.charCodeAt(c - 1) - 65 < 26), A.splice(l, 0, f), l++;
    }
    var R;
    if (t) for (l = 0, w = A.length; l < w; l++) E[l] && (A[l] = String.fromCharCode(A[l]).toUpperCase().charCodeAt(0));
    return this.utf16.encode(A);
  }, this.encode = function (o, f) {
    let h, l, i, a, u, c, d, g, s, p, C, w;
    f && (w = this.utf16.decode(o));
    let A = (o = this.utf16.decode(o.toLowerCase())).length;
    if (f) for (c = 0; c < A; c++) w[c] = o[c] != w[c];
    let E = [];
    for (h = 128, l = 0, u = 72, c = 0; c < A; ++c) o[c] < 128 && E.push(String.fromCharCode(w ? (M = o[c], R = w[c], (M -= (M - 97 < 26) << 5) + ((!R && M - 65 < 26) << 5)) : o[c]));
    var M, R;
    for (i = a = E.length, a > 0 && E.push("-"); i < A;) {
      for (d = e, c = 0; c < A; ++c) C = o[c], C >= h && C < d && (d = C);
      if (d - h > Math.floor((e - l) / (i + 1))) throw RangeError("punycode_overflow (1)");
      for (l += (d - h) * (i + 1), h = d, c = 0; c < A; ++c) {
        if (C = o[c], C < h && ++l > e) return Error("punycode_overflow(2)");
        if (C == h) {
          for (g = l, s = r; p = s <= u ? 1 : s >= u + 26 ? 26 : s - u, !(g < p); s += r) E.push(String.fromCharCode(t(p + (g - p) % (r - p), 0))), g = Math.floor((g - p) / (r - p));
          E.push(String.fromCharCode(t(g, f && w[c] ? 1 : 0))), u = n(l, i + 1, i == a), l = 0, ++i;
        }
      }
      ++l, ++h;
    }
    return E.join("");
  }, this.toASCII = function (r) {
    let e = r.split("."),
      t = [];
    for (let r = 0; r < e.length; ++r) {
      let n = e[r];
      t.push(n.match(/[^A-Za-z0-9-]/) ? "xn--" + o.encode(n) : n);
    }
    return t.join(".");
  }, this.toUnicode = function (r) {
    let e = r.split("."),
      t = [];
    for (let r = 0; r < e.length; ++r) {
      let n = e[r];
      t.push(n.match(/^xn--/) ? o.decode(n.slice(4)) : n);
    }
    return t.join(".");
  };
}();
export { o as punycode };
