import { _ as e } from "./tslib.es6-7f1b1220.js";
import { c as t, u as i, h as a } from "./lit-element-161a1699.js";
import { p as n, a as r, c as s } from "./decorators-7e9cd43b.js";
import { RealBaseElement as p } from "./BaseElement.esm.min.js";
import { h as o } from "./host.styles-cf05db22.js";
import { e as u } from "./event.decorator-98549cbc.js";
import { i as m } from "./if-defined-123567fc.js";
import "./types-814d68fb.js";
import { D as l } from "./delegate-focus.mixin-95600d1f.js";
import "./style-map-a37d7105.js";
import "./interactive-text.component.esm.min.js";
import "./time-picker-day-time-toggle.component.esm.min.js";
import { c } from "./time-picker-input-part-1e800973.js";
const d = t`${i(c)}`,
  h = t`${i(':host {\n  display: inline-flex;\n}\n:host:before {\n  content: "\\\\00a0";\n}\n\ninput {\n  width: calc(var(--zui-gu) * 2.75);\n}')}`;
let v = class extends l(p) {
  constructor() {
    super(...arguments), this.disabled = !1, this.readonly = !1;
  }
  static get styles() {
    return [o, d, h];
  }
  emitTimePickerInputPartFocusPreviousEvent() {
    this.dispatchEvent(new CustomEvent("time-picker-input-part-focus-previous", {
      bubbles: !0,
      composed: !0
    }));
  }
  emitTimePickerInputPartDayTimeChangedEvent() {
    this.dispatchEvent(new CustomEvent("time-picker-input-part-day-time-changed", {
      bubbles: !0,
      composed: !0,
      detail: {
        value: this.value
      }
    }));
  }
  emitTimePickerInputPartDayTimeInputEvent() {
    this.dispatchEvent(new CustomEvent("time-picker-input-part-day-time-input", {
      bubbles: !0,
      composed: !0,
      detail: {
        value: this._inputRef.value
      }
    }));
  }
  _handleTimePickerInputPartDayTimeFocusEvent() {
    this._inputRef.select();
  }
  _handleTimePickerInputPartDayTimeBluredEvent() {
    const e = this.value;
    this.value = this._inputRef.value.length > 0 ? this._inputRef.value : null, e !== this.value && this.emitTimePickerInputPartDayTimeChangedEvent();
  }
  _handleTimePickerInputPartDayTimeInputEvent(e) {
    e.stopPropagation(), this.emitTimePickerInputPartDayTimeInputEvent();
  }
  _handleTimePickerInputPartDayTimeKeydownEvent(e) {
    if (!this.readonly) switch (e.key) {
      case "ArrowLeft":
      case "ArrowRight":
      case "Delete":
      case "Tab":
        break;
      case "a":
      case "A":
        e.preventDefault(), this._inputRef.value = "AM", this.emitTimePickerInputPartDayTimeInputEvent();
        break;
      case "Backspace":
        "" === this._inputRef.value && (e.preventDefault(), this.emitTimePickerInputPartFocusPreviousEvent());
        break;
      case "p":
      case "P":
        e.preventDefault(), this._inputRef.value = "PM", this.emitTimePickerInputPartDayTimeInputEvent();
        break;
      case "ArrowUp":
      case "ArrowDown":
        e.preventDefault(), this._inputRef.value = "AM" === this._inputRef.value ? "PM" : "AM", this.emitTimePickerInputPartDayTimeInputEvent();
        break;
      default:
        e.preventDefault();
    }
  }
  render() {
    return a`<input .value="${m(this.value)}" ?disabled="${this.disabled}" ?readonly="${this.readonly}" ?zuiCaptureFocus="${!this.disabled}" placeholder="${m(this.placeholder)}" type="text" @blur="${this._handleTimePickerInputPartDayTimeBluredEvent}" @focus="${this._handleTimePickerInputPartDayTimeFocusEvent}" @input="${this._handleTimePickerInputPartDayTimeInputEvent}" @keydown="${this._handleTimePickerInputPartDayTimeKeydownEvent}">`;
  }
};
e([n({
  reflect: !0,
  type: Boolean
})], v.prototype, "disabled", void 0), e([n({
  reflect: !0,
  type: String
})], v.prototype, "placeholder", void 0), e([n({
  reflect: !0,
  type: Boolean
})], v.prototype, "readonly", void 0), e([n({
  reflect: !0,
  type: String
})], v.prototype, "value", void 0), e([u()], v.prototype, "emitTimePickerInputPartFocusPreviousEvent", null), e([u()], v.prototype, "emitTimePickerInputPartDayTimeChangedEvent", null), e([u()], v.prototype, "emitTimePickerInputPartDayTimeInputEvent", null), e([r("input")], v.prototype, "_inputRef", void 0), v = e([s("zui-time-picker-input-part-day-time")], v);
export { v as TimePickerInputPartDayTime };
