import { _ as t } from "./tslib.es6-7f1b1220.js";
import { c as e, u as i, h as n } from "./lit-element-161a1699.js";
import { p as o, s as a, b as s, c as r } from "./decorators-7e9cd43b.js";
import { BaseElement as m } from "./BaseElement.esm.min.js";
import { h as d } from "./host.styles-cf05db22.js";
import { e as l } from "./event.decorator-98549cbc.js";
import "./types-814d68fb.js";
import { PaginationDot as c } from "./pagination-dot.component.esm.min.js";
const p = (t, e, i) => t <= i || e <= Math.floor(i / 2),
  u = (t, e, i) => t <= i || e >= t - 1 - Math.floor(i / 2);
const h = e`${i(":host {\n  --zui-pagination-dot-bar-transition-timing: 200ms;\n  align-items: center;\n  display: inline-flex;\n  vertical-align: top;\n}\n:host > zui-pagination-dot {\n  --zui-pagination-dot-transition-timing: var(---zui-pagination-dot-bar-transition-timing);\n}\n:host > zui-pagination-dot + zui-pagination-dot {\n  margin-left: calc(var(--zui-gu) * 1);\n}")}`;
let g = class extends m {
  constructor() {
    super(...arguments), this.itemCount = 0, this._maxItems = 5;
  }
  emitPaginationDotBarPaginationDotSelectedEvent(t) {
    this.dispatchEvent(new CustomEvent("pagination-dot-bar-pagination-dot-selected", {
      bubbles: !0,
      composed: !0,
      detail: t
    }));
  }
  _handlePaginationDotSlelected({
    target: t
  }) {
    this.selectedItemIndex = parseInt(t.id, 10), this.emitPaginationDotBarPaginationDotSelectedEvent({
      value: this.selectedItemIndex
    });
  }
  updated(t) {
    if (t.has("selectedItemIndex")) {
      const t = Array.from(this._dotsRef).find(t => parseInt(t.id, 10) === this.selectedItemIndex);
      null == t || t.setAttribute(c.internalAnimateSelectedAttribute, "");
    }
  }
  render() {
    return n`${(t = this.itemCount, e = this.selectedItemIndex, i = this._maxItems, t <= i ? [...new Array(t).keys()] : [...new Array(t).keys()].reduce((n, o) => {
      const a = Math.max(0, e - Math.floor(i / 2)),
        s = Math.min(t - 1, e + Math.floor(i / 2)),
        r = u(t, e, i) ? t - 1 - (i - 1) : a,
        m = p(t, e, i) ? i - 1 : s;
      return o >= r && o <= m ? [...n, o] : n;
    }, [])).map((t, e) => {
      const i = t === this.selectedItemIndex,
        o = 0 === e,
        a = e === this._maxItems - 1,
        s = o && !p(this.itemCount, this.selectedItemIndex, this._maxItems) || a && !u(this.itemCount, this.selectedItemIndex, this._maxItems);
      return n`<zui-pagination-dot id="${t}" emphasis="${i ? "selected" : "default"}" size="${s ? "s" : "m"}" @click="${this._handlePaginationDotSlelected}"></zui-pagination-dot>`;
    })}`;
    var t, e, i;
  }
};
g.styles = [d, h], t([o({
  reflect: !0,
  type: Number,
  attribute: "item-count"
})], g.prototype, "itemCount", void 0), t([o({
  reflect: !0,
  type: Number,
  attribute: "selected-item-index"
})], g.prototype, "selectedItemIndex", void 0), t([l()], g.prototype, "emitPaginationDotBarPaginationDotSelectedEvent", null), t([a()], g.prototype, "_maxItems", void 0), t([s("zui-pagination-dot")], g.prototype, "_dotsRef", void 0), g = t([r("zui-pagination-dot-bar")], g);
export { g as PaginationDotBar };
