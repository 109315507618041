import { a as t, _ as e } from "./tslib.es6-7f1b1220.js";
import { c as n, u as o, L as r, h as i } from "./lit-element-161a1699.js";
import { p as a, c as s } from "./decorators-7e9cd43b.js";
import { h as c } from "./host.styles-cf05db22.js";
import { i as f } from "./if-defined-123567fc.js";
import "./types-814d68fb.js";
import { deprecatedListWrapperConverter as p, getStringArrayConverter as l } from "./component.utils.esm.min.js";
import { generateUid as u, unregisterPortal as d, getContentsFromPortal as h } from "./portal.utils.esm.min.js";
function m(t) {
  var e = t.getBoundingClientRect();
  return {
    width: e.width,
    height: e.height,
    top: e.top,
    right: e.right,
    bottom: e.bottom,
    left: e.left,
    x: e.left,
    y: e.top
  };
}
function v(t) {
  if ("[object Window]" !== t.toString()) {
    var e = t.ownerDocument;
    return e && e.defaultView || window;
  }
  return t;
}
function g(t) {
  var e = v(t);
  return {
    scrollLeft: e.pageXOffset,
    scrollTop: e.pageYOffset
  };
}
function b(t) {
  return t instanceof v(t).Element || t instanceof Element;
}
function y(t) {
  return t instanceof v(t).HTMLElement || t instanceof HTMLElement;
}
function O(t) {
  return t ? (t.nodeName || "").toLowerCase() : null;
}
function w(t) {
  return ((b(t) ? t.ownerDocument : t.document) || window.document).documentElement;
}
function x(t) {
  return m(w(t)).left + g(t).scrollLeft;
}
function j(t) {
  return v(t).getComputedStyle(t);
}
function P(t) {
  var e = j(t),
    n = e.overflow,
    o = e.overflowX,
    r = e.overflowY;
  return /auto|scroll|overlay|hidden/.test(n + r + o);
}
function k(t, e, n) {
  void 0 === n && (n = !1);
  var o,
    r,
    i = w(e),
    a = m(t),
    s = y(e),
    c = {
      scrollLeft: 0,
      scrollTop: 0
    },
    f = {
      x: 0,
      y: 0
    };
  return (s || !s && !n) && (("body" !== O(e) || P(i)) && (c = (o = e) !== v(o) && y(o) ? {
    scrollLeft: (r = o).scrollLeft,
    scrollTop: r.scrollTop
  } : g(o)), y(e) ? ((f = m(e)).x += e.clientLeft, f.y += e.clientTop) : i && (f.x = x(i))), {
    x: a.left + c.scrollLeft - f.x,
    y: a.top + c.scrollTop - f.y,
    width: a.width,
    height: a.height
  };
}
function E(t) {
  return "html" === O(t) ? t : t.assignedSlot || t.parentNode || t.host || w(t);
}
function M(t) {
  return ["html", "body", "#document"].indexOf(O(t)) >= 0 ? t.ownerDocument.body : y(t) && P(t) ? t : M(E(t));
}
function D(t, e) {
  void 0 === e && (e = []);
  var n = M(t),
    o = "body" === O(n),
    r = v(n),
    i = o ? [r].concat(r.visualViewport || [], P(n) ? n : []) : n,
    a = e.concat(i);
  return o ? a : a.concat(D(E(i)));
}
function L(t) {
  return ["table", "td", "th"].indexOf(O(t)) >= 0;
}
function _(t) {
  if (!y(t) || "fixed" === j(t).position) return null;
  var e = t.offsetParent;
  if (e) {
    var n = w(e);
    if ("body" === O(e) && "static" === j(e).position && "static" !== j(n).position) return n;
  }
  return e;
}
function C(t) {
  for (var e = v(t), n = _(t); n && L(n) && "static" === j(n).position;) n = _(n);
  return n && "body" === O(n) && "static" === j(n).position ? e : n || function (t) {
    for (var e = E(t); y(e) && ["html", "body"].indexOf(O(e)) < 0;) {
      var n = j(e);
      if ("none" !== n.transform || "none" !== n.perspective || n.willChange && "auto" !== n.willChange) return e;
      e = e.parentNode;
    }
    return null;
  }(t) || e;
}
var H = "top",
  R = "bottom",
  S = "right",
  W = "left",
  A = [H, R, S, W],
  B = A.reduce(function (t, e) {
    return t.concat([e + "-start", e + "-end"]);
  }, []),
  T = [].concat(A, ["auto"]).reduce(function (t, e) {
    return t.concat([e, e + "-start", e + "-end"]);
  }, []),
  N = ["beforeRead", "read", "afterRead", "beforeMain", "main", "afterMain", "beforeWrite", "write", "afterWrite"];
function I(t) {
  var e = new Map(),
    n = new Set(),
    o = [];
  function r(t) {
    n.add(t.name), [].concat(t.requires || [], t.requiresIfExists || []).forEach(function (t) {
      if (!n.has(t)) {
        var o = e.get(t);
        o && r(o);
      }
    }), o.push(t);
  }
  return t.forEach(function (t) {
    e.set(t.name, t);
  }), t.forEach(function (t) {
    n.has(t.name) || r(t);
  }), o;
}
function $(t) {
  return t.split("-")[0];
}
function q(t, e) {
  var n,
    o = e.getRootNode && e.getRootNode();
  if (t.contains(e)) return !0;
  if (o && ((n = o) instanceof v(n).ShadowRoot || n instanceof ShadowRoot)) {
    var r = e;
    do {
      if (r && t.isSameNode(r)) return !0;
      r = r.parentNode || r.host;
    } while (r);
  }
  return !1;
}
function z(t) {
  return Object.assign(Object.assign({}, t), {}, {
    left: t.x,
    top: t.y,
    right: t.x + t.width,
    bottom: t.y + t.height
  });
}
function V(t, e) {
  return "viewport" === e ? z(function (t) {
    var e = v(t),
      n = w(t),
      o = e.visualViewport,
      r = n.clientWidth,
      i = n.clientHeight,
      a = 0,
      s = 0;
    return o && (r = o.width, i = o.height, /^((?!chrome|android).)*safari/i.test(navigator.userAgent) || (a = o.offsetLeft, s = o.offsetTop)), {
      width: r,
      height: i,
      x: a + x(t),
      y: s
    };
  }(t)) : y(e) ? function (t) {
    var e = m(t);
    return e.top = e.top + t.clientTop, e.left = e.left + t.clientLeft, e.bottom = e.top + t.clientHeight, e.right = e.left + t.clientWidth, e.width = t.clientWidth, e.height = t.clientHeight, e.x = e.left, e.y = e.top, e;
  }(e) : z(function (t) {
    var e = w(t),
      n = g(t),
      o = t.ownerDocument.body,
      r = Math.max(e.scrollWidth, e.clientWidth, o ? o.scrollWidth : 0, o ? o.clientWidth : 0),
      i = Math.max(e.scrollHeight, e.clientHeight, o ? o.scrollHeight : 0, o ? o.clientHeight : 0),
      a = -n.scrollLeft + x(t),
      s = -n.scrollTop;
    return "rtl" === j(o || e).direction && (a += Math.max(e.clientWidth, o ? o.clientWidth : 0) - r), {
      width: r,
      height: i,
      x: a,
      y: s
    };
  }(w(t)));
}
function U(t, e, n) {
  var o = "clippingParents" === e ? function (t) {
      var e = D(E(t)),
        n = ["absolute", "fixed"].indexOf(j(t).position) >= 0 && y(t) ? C(t) : t;
      return b(n) ? e.filter(function (t) {
        return b(t) && q(t, n) && "body" !== O(t);
      }) : [];
    }(t) : [].concat(e),
    r = [].concat(o, [n]),
    i = r[0],
    a = r.reduce(function (e, n) {
      var o = V(t, n);
      return e.top = Math.max(o.top, e.top), e.right = Math.min(o.right, e.right), e.bottom = Math.min(o.bottom, e.bottom), e.left = Math.max(o.left, e.left), e;
    }, V(t, i));
  return a.width = a.right - a.left, a.height = a.bottom - a.top, a.x = a.left, a.y = a.top, a;
}
function F(t) {
  return t.split("-")[1];
}
function X(t) {
  var e,
    n = t.reference,
    o = t.element,
    r = t.placement,
    i = r ? $(r) : null,
    a = r ? F(r) : null,
    s = n.x + n.width / 2 - o.width / 2,
    c = n.y + n.height / 2 - o.height / 2;
  switch (i) {
    case H:
      e = {
        x: s,
        y: n.y - o.height
      };
      break;
    case R:
      e = {
        x: s,
        y: n.y + n.height
      };
      break;
    case S:
      e = {
        x: n.x + n.width,
        y: c
      };
      break;
    case W:
      e = {
        x: n.x - o.width,
        y: c
      };
      break;
    default:
      e = {
        x: n.x,
        y: n.y
      };
  }
  var f = i ? function (t) {
    return ["top", "bottom"].indexOf(t) >= 0 ? "x" : "y";
  }(i) : null;
  if (null != f) {
    var p = "y" === f ? "height" : "width";
    switch (a) {
      case "start":
        e[f] = e[f] - (n[p] / 2 - o[p] / 2);
        break;
      case "end":
        e[f] = e[f] + (n[p] / 2 - o[p] / 2);
    }
  }
  return e;
}
function Y(t, e) {
  void 0 === e && (e = {});
  var n,
    o = e,
    r = o.placement,
    i = void 0 === r ? t.placement : r,
    a = o.boundary,
    s = void 0 === a ? "clippingParents" : a,
    c = o.rootBoundary,
    f = void 0 === c ? "viewport" : c,
    p = o.elementContext,
    l = void 0 === p ? "popper" : p,
    u = o.altBoundary,
    d = void 0 !== u && u,
    h = o.padding,
    v = void 0 === h ? 0 : h,
    g = function (t) {
      return Object.assign(Object.assign({}, {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }), t);
    }("number" != typeof v ? v : (n = v, A.reduce(function (t, e) {
      return t[e] = n, t;
    }, {}))),
    y = "popper" === l ? "reference" : "popper",
    O = t.elements.reference,
    x = t.rects.popper,
    j = t.elements[d ? y : l],
    P = U(b(j) ? j : j.contextElement || w(t.elements.popper), s, f),
    k = m(O),
    E = X({
      reference: k,
      element: x,
      strategy: "absolute",
      placement: i
    }),
    M = z(Object.assign(Object.assign({}, x), E)),
    D = "popper" === l ? M : k,
    L = {
      top: P.top - D.top + g.top,
      bottom: D.bottom - P.bottom + g.bottom,
      left: P.left - D.left + g.left,
      right: D.right - P.right + g.right
    },
    _ = t.modifiersData.offset;
  if ("popper" === l && _) {
    var C = _[i];
    Object.keys(L).forEach(function (t) {
      var e = [S, R].indexOf(t) >= 0 ? 1 : -1,
        n = [H, R].indexOf(t) >= 0 ? "y" : "x";
      L[t] += C[n] * e;
    });
  }
  return L;
}
var G = {
  placement: "bottom",
  modifiers: [],
  strategy: "absolute"
};
function J() {
  for (var t = arguments.length, e = new Array(t), n = 0; n < t; n++) e[n] = arguments[n];
  return !e.some(function (t) {
    return !(t && "function" == typeof t.getBoundingClientRect);
  });
}
function K(t) {
  void 0 === t && (t = {});
  var e = t,
    n = e.defaultModifiers,
    o = void 0 === n ? [] : n,
    r = e.defaultOptions,
    i = void 0 === r ? G : r;
  return function (t, e, n) {
    void 0 === n && (n = i);
    var r,
      a,
      s = {
        placement: "bottom",
        orderedModifiers: [],
        options: Object.assign(Object.assign({}, G), i),
        modifiersData: {},
        elements: {
          reference: t,
          popper: e
        },
        attributes: {},
        styles: {}
      },
      c = [],
      f = !1,
      p = {
        state: s,
        setOptions: function (n) {
          l(), s.options = Object.assign(Object.assign(Object.assign({}, i), s.options), n), s.scrollParents = {
            reference: b(t) ? D(t) : t.contextElement ? D(t.contextElement) : [],
            popper: D(e)
          };
          var r,
            a,
            f = function (t) {
              var e = I(t);
              return N.reduce(function (t, n) {
                return t.concat(e.filter(function (t) {
                  return t.phase === n;
                }));
              }, []);
            }((r = [].concat(o, s.options.modifiers), a = r.reduce(function (t, e) {
              var n = t[e.name];
              return t[e.name] = n ? Object.assign(Object.assign(Object.assign({}, n), e), {}, {
                options: Object.assign(Object.assign({}, n.options), e.options),
                data: Object.assign(Object.assign({}, n.data), e.data)
              }) : e, t;
            }, {}), Object.keys(a).map(function (t) {
              return a[t];
            })));
          return s.orderedModifiers = f.filter(function (t) {
            return t.enabled;
          }), s.orderedModifiers.forEach(function (t) {
            var e = t.name,
              n = t.options,
              o = void 0 === n ? {} : n,
              r = t.effect;
            if ("function" == typeof r) {
              var i = r({
                  state: s,
                  name: e,
                  instance: p,
                  options: o
                }),
                a = function () {};
              c.push(i || a);
            }
          }), p.update();
        },
        forceUpdate: function () {
          if (!f) {
            var t = s.elements,
              e = t.reference,
              n = t.popper;
            if (J(e, n)) {
              var o;
              s.rects = {
                reference: k(e, C(n), "fixed" === s.options.strategy),
                popper: (o = n, {
                  x: o.offsetLeft,
                  y: o.offsetTop,
                  width: o.offsetWidth,
                  height: o.offsetHeight
                })
              }, s.reset = !1, s.placement = s.options.placement, s.orderedModifiers.forEach(function (t) {
                return s.modifiersData[t.name] = Object.assign({}, t.data);
              });
              for (var r = 0; r < s.orderedModifiers.length; r++) if (!0 !== s.reset) {
                var i = s.orderedModifiers[r],
                  a = i.fn,
                  c = i.options,
                  l = void 0 === c ? {} : c,
                  u = i.name;
                "function" == typeof a && (s = a({
                  state: s,
                  options: l,
                  name: u,
                  instance: p
                }) || s);
              } else s.reset = !1, r = -1;
            }
          }
        },
        update: (r = function () {
          return new Promise(function (t) {
            p.forceUpdate(), t(s);
          });
        }, function () {
          return a || (a = new Promise(function (t) {
            Promise.resolve().then(function () {
              a = void 0, t(r());
            });
          })), a;
        }),
        destroy: function () {
          l(), f = !0;
        }
      };
    if (!J(t, e)) return p;
    function l() {
      c.forEach(function (t) {
        return t();
      }), c = [];
    }
    return p.setOptions(n).then(function (t) {
      !f && n.onFirstUpdate && n.onFirstUpdate(t);
    }), p;
  };
}
K();
var Q = {
  passive: !0
};
var Z = {
  top: "auto",
  right: "auto",
  bottom: "auto",
  left: "auto"
};
function tt(t) {
  var e,
    n = t.popper,
    o = t.popperRect,
    r = t.placement,
    i = t.offsets,
    a = t.position,
    s = t.gpuAcceleration,
    c = t.adaptive,
    f = t.roundOffsets ? function (t) {
      var e = t.x,
        n = t.y,
        o = window.devicePixelRatio || 1;
      return {
        x: Math.round(e * o) / o || 0,
        y: Math.round(n * o) / o || 0
      };
    }(i) : i,
    p = f.x,
    l = void 0 === p ? 0 : p,
    u = f.y,
    d = void 0 === u ? 0 : u,
    h = i.hasOwnProperty("x"),
    m = i.hasOwnProperty("y"),
    g = W,
    b = H,
    y = window;
  if (c) {
    var O = C(n);
    O === v(n) && (O = w(n)), r === H && (b = R, d -= O.clientHeight - o.height, d *= s ? 1 : -1), r === W && (g = S, l -= O.clientWidth - o.width, l *= s ? 1 : -1);
  }
  var x,
    j = Object.assign({
      position: a
    }, c && Z);
  return s ? Object.assign(Object.assign({}, j), {}, ((x = {})[b] = m ? "0" : "", x[g] = h ? "0" : "", x.transform = (y.devicePixelRatio || 1) < 2 ? "translate(" + l + "px, " + d + "px)" : "translate3d(" + l + "px, " + d + "px, 0)", x)) : Object.assign(Object.assign({}, j), {}, ((e = {})[b] = m ? d + "px" : "", e[g] = h ? l + "px" : "", e.transform = "", e));
}
var et = {
    name: "applyStyles",
    enabled: !0,
    phase: "write",
    fn: function (t) {
      var e = t.state;
      Object.keys(e.elements).forEach(function (t) {
        var n = e.styles[t] || {},
          o = e.attributes[t] || {},
          r = e.elements[t];
        y(r) && O(r) && (Object.assign(r.style, n), Object.keys(o).forEach(function (t) {
          var e = o[t];
          !1 === e ? r.removeAttribute(t) : r.setAttribute(t, !0 === e ? "" : e);
        }));
      });
    },
    effect: function (t) {
      var e = t.state,
        n = {
          popper: {
            position: e.options.strategy,
            left: "0",
            top: "0",
            margin: "0"
          },
          arrow: {
            position: "absolute"
          },
          reference: {}
        };
      return Object.assign(e.elements.popper.style, n.popper), e.elements.arrow && Object.assign(e.elements.arrow.style, n.arrow), function () {
        Object.keys(e.elements).forEach(function (t) {
          var o = e.elements[t],
            r = e.attributes[t] || {},
            i = Object.keys(e.styles.hasOwnProperty(t) ? e.styles[t] : n[t]).reduce(function (t, e) {
              return t[e] = "", t;
            }, {});
          y(o) && O(o) && (Object.assign(o.style, i), Object.keys(r).forEach(function (t) {
            o.removeAttribute(t);
          }));
        });
      };
    },
    requires: ["computeStyles"]
  },
  nt = K({
    defaultModifiers: [{
      name: "eventListeners",
      enabled: !0,
      phase: "write",
      fn: function () {},
      effect: function (t) {
        var e = t.state,
          n = t.instance,
          o = t.options,
          r = o.scroll,
          i = void 0 === r || r,
          a = o.resize,
          s = void 0 === a || a,
          c = v(e.elements.popper),
          f = [].concat(e.scrollParents.reference, e.scrollParents.popper);
        return i && f.forEach(function (t) {
          t.addEventListener("scroll", n.update, Q);
        }), s && c.addEventListener("resize", n.update, Q), function () {
          i && f.forEach(function (t) {
            t.removeEventListener("scroll", n.update, Q);
          }), s && c.removeEventListener("resize", n.update, Q);
        };
      },
      data: {}
    }, {
      name: "popperOffsets",
      enabled: !0,
      phase: "read",
      fn: function (t) {
        var e = t.state,
          n = t.name;
        e.modifiersData[n] = X({
          reference: e.rects.reference,
          element: e.rects.popper,
          strategy: "absolute",
          placement: e.placement
        });
      },
      data: {}
    }, {
      name: "computeStyles",
      enabled: !0,
      phase: "beforeWrite",
      fn: function (t) {
        var e = t.state,
          n = t.options,
          o = n.gpuAcceleration,
          r = void 0 === o || o,
          i = n.adaptive,
          a = void 0 === i || i,
          s = n.roundOffsets,
          c = void 0 === s || s,
          f = {
            placement: $(e.placement),
            popper: e.elements.popper,
            popperRect: e.rects.popper,
            gpuAcceleration: r
          };
        null != e.modifiersData.popperOffsets && (e.styles.popper = Object.assign(Object.assign({}, e.styles.popper), tt(Object.assign(Object.assign({}, f), {}, {
          offsets: e.modifiersData.popperOffsets,
          position: e.options.strategy,
          adaptive: a,
          roundOffsets: c
        })))), null != e.modifiersData.arrow && (e.styles.arrow = Object.assign(Object.assign({}, e.styles.arrow), tt(Object.assign(Object.assign({}, f), {}, {
          offsets: e.modifiersData.arrow,
          position: "absolute",
          adaptive: !1,
          roundOffsets: c
        })))), e.attributes.popper = Object.assign(Object.assign({}, e.attributes.popper), {}, {
          "data-popper-placement": e.placement
        });
      },
      data: {}
    }, et]
  }),
  ot = {
    left: "right",
    right: "left",
    bottom: "top",
    top: "bottom"
  };
function rt(t) {
  return t.replace(/left|right|bottom|top/g, function (t) {
    return ot[t];
  });
}
var it = {
  start: "end",
  end: "start"
};
function at(t) {
  return t.replace(/start|end/g, function (t) {
    return it[t];
  });
}
function st(t, e) {
  void 0 === e && (e = {});
  var n = e,
    o = n.placement,
    r = n.boundary,
    i = n.rootBoundary,
    a = n.padding,
    s = n.flipVariations,
    c = n.allowedAutoPlacements,
    f = void 0 === c ? T : c,
    p = F(o),
    l = p ? s ? B : B.filter(function (t) {
      return F(t) === p;
    }) : A,
    u = l.filter(function (t) {
      return f.indexOf(t) >= 0;
    });
  0 === u.length && (u = l);
  var d = u.reduce(function (e, n) {
    return e[n] = Y(t, {
      placement: n,
      boundary: r,
      rootBoundary: i,
      padding: a
    })[$(n)], e;
  }, {});
  return Object.keys(d).sort(function (t, e) {
    return d[t] - d[e];
  });
}
var ct = {
  name: "flip",
  enabled: !0,
  phase: "main",
  fn: function (t) {
    var e = t.state,
      n = t.options,
      o = t.name;
    if (!e.modifiersData[o]._skip) {
      for (var r = n.mainAxis, i = void 0 === r || r, a = n.altAxis, s = void 0 === a || a, c = n.fallbackPlacements, f = n.padding, p = n.boundary, l = n.rootBoundary, u = n.altBoundary, d = n.flipVariations, h = void 0 === d || d, m = n.allowedAutoPlacements, v = e.options.placement, g = $(v), b = c || (g === v || !h ? [rt(v)] : function (t) {
          if ("auto" === $(t)) return [];
          var e = rt(t);
          return [at(t), e, at(e)];
        }(v)), y = [v].concat(b).reduce(function (t, n) {
          return t.concat("auto" === $(n) ? st(e, {
            placement: n,
            boundary: p,
            rootBoundary: l,
            padding: f,
            flipVariations: h,
            allowedAutoPlacements: m
          }) : n);
        }, []), O = e.rects.reference, w = e.rects.popper, x = new Map(), j = !0, P = y[0], k = 0; k < y.length; k++) {
        var E = y[k],
          M = $(E),
          D = "start" === F(E),
          L = [H, R].indexOf(M) >= 0,
          _ = L ? "width" : "height",
          C = Y(e, {
            placement: E,
            boundary: p,
            rootBoundary: l,
            altBoundary: u,
            padding: f
          }),
          A = L ? D ? S : W : D ? R : H;
        O[_] > w[_] && (A = rt(A));
        var B = rt(A),
          T = [];
        if (i && T.push(C[M] <= 0), s && T.push(C[A] <= 0, C[B] <= 0), T.every(function (t) {
          return t;
        })) {
          P = E, j = !1;
          break;
        }
        x.set(E, T);
      }
      if (j) for (var N = function (t) {
          var e = y.find(function (e) {
            var n = x.get(e);
            if (n) return n.slice(0, t).every(function (t) {
              return t;
            });
          });
          if (e) return P = e, "break";
        }, I = h ? 3 : 1; I > 0; I--) {
        if ("break" === N(I)) break;
      }
      e.placement !== P && (e.modifiersData[o]._skip = !0, e.placement = P, e.reset = !0);
    }
  },
  requiresIfExists: ["offset"],
  data: {
    _skip: !1
  }
};
var ft = {
  name: "offset",
  enabled: !0,
  phase: "main",
  requires: ["popperOffsets"],
  fn: function (t) {
    var e = t.state,
      n = t.options,
      o = t.name,
      r = n.offset,
      i = void 0 === r ? [0, 0] : r,
      a = T.reduce(function (t, n) {
        return t[n] = function (t, e, n) {
          var o = $(t),
            r = [W, H].indexOf(o) >= 0 ? -1 : 1,
            i = "function" == typeof n ? n(Object.assign(Object.assign({}, e), {}, {
              placement: t
            })) : n,
            a = i[0],
            s = i[1];
          return a = a || 0, s = (s || 0) * r, [W, S].indexOf(o) >= 0 ? {
            x: s,
            y: a
          } : {
            x: a,
            y: s
          };
        }(n, e.rects, i), t;
      }, {}),
      s = a[e.placement],
      c = s.x,
      f = s.y;
    null != e.modifiersData.popperOffsets && (e.modifiersData.popperOffsets.x += c, e.modifiersData.popperOffsets.y += f), e.modifiersData[o] = a;
  }
};
const pt = n`${o(":host {\n  position: relative;\n  pointer-events: none;\n}")}`,
  lt = ["bottom-start"];
let ut = class extends r {
  constructor() {
    super(...arguments), this.portal = `overlay-${u()}`, this.placements = lt, this.restore = !1, this.clone = !1, this.flip = !1, this.flipPadding = 0, this.positionReferenceCallback = () => this, this.offsetHandler = () => [0, 0];
  }
  forcePositioning() {
    var t;
    null === (t = this._popperInstance) || void 0 === t || t.forceUpdate();
  }
  handleSlotChange() {
    requestAnimationFrame(() => this._positionSlotContents());
  }
  disconnectedCallback() {
    d(this.portal), this._endPositioning(), super.disconnectedCallback();
  }
  _positionSlotContents() {
    const [, t] = h(this.portal);
    void 0 !== t && (this._endPositioning(), this._startPositioning(t));
  }
  _startPositioning(t) {
    this._popperInstance = nt(this.positionReferenceCallback(), t), this._setPositioningOptions();
  }
  _setPositioningOptions() {
    return t(this, void 0, void 0, function* () {
      if (!this._popperInstance) return;
      const [t, ...e] = this.placements.length > 0 ? this.placements : lt,
        n = [Object.assign(Object.assign({}, ct), {
          enabled: this.flip,
          options: {
            fallbackPlacements: e,
            padding: this.flipPadding
          }
        }), Object.assign(Object.assign({}, ft), {
          options: {
            offset: this.offsetHandler
          }
        })];
      yield this._popperInstance.setOptions({
        modifiers: n,
        placement: t
      });
    });
  }
  _endPositioning() {
    var t;
    null === (t = this._popperInstance) || void 0 === t || t.destroy(), this._popperInstance = void 0;
  }
  updated(t) {
    super.updated(t), t.has("restore") && console.warn("Deprecated restore: Please use the `clone` option instead."), (t.has("flip") || t.has("offsetHandler") || t.has("placements")) && this._setPositioningOptions();
  }
  update(t) {
    super.update(t), t.has("restore") && console.warn("Deprecated restore: Please use the `clone` option instead.");
  }
  render() {
    return i`<zui-portal-directive ?clone="${this.clone}" ?restore="${this.restore}" level="${f(this.level)}" portal="${this.portal}"><slot @slotchange="${this.handleSlotChange}"></slot></zui-portal-directive>`;
  }
};
ut.styles = [c, pt], e([a({
  reflect: !0,
  type: String
})], ut.prototype, "portal", void 0), e([a({
  reflect: !0,
  type: Number
})], ut.prototype, "level", void 0), e([a({
  reflect: !0,
  converter: p(l())
})], ut.prototype, "placements", void 0), e([a({
  reflect: !0,
  type: Boolean
})], ut.prototype, "restore", void 0), e([a({
  reflect: !0,
  type: Boolean
})], ut.prototype, "clone", void 0), e([a({
  reflect: !0,
  type: Boolean
})], ut.prototype, "flip", void 0), e([a({
  reflect: !0,
  type: Number,
  attribute: "flip-padding"
})], ut.prototype, "flipPadding", void 0), e([a({
  reflect: !1,
  attribute: !1
})], ut.prototype, "positionReferenceCallback", void 0), e([a({
  reflect: !1,
  attribute: !1
})], ut.prototype, "offsetHandler", void 0), ut = e([s("zui-overlay-directive")], ut);
export { pt as OVERLAY_DIRECTIVE_STYLES, ut as OverlayDirective };
